import React from 'react';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import {
    BulkEmail,
    Template,
    TemplatePreview,
} from '../../reducers/template_reducer';
import { SnackbarProps, QueueSnackbar } from '../../actions/snackbar_actions';
import { SearchFilterFormValues } from '../../reducers/advanced_search_reducer';
import { MoreMenu } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { EMPTY_GUID } from '../../utilities/empty_entities';
import { themePalette } from '../../utilities/branding';
import { connect } from '@hmkts/rise';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import {
    DeleteTemplate,
    SendBulkEmail,
    GetTemplateSubstitutions,
    PostTemplate,
    ClearEditedTemplate,
    StoreEditedTemplate,
    ClearTemplateStores,
    GetTemplateWithSubstitutionsById
} from '../../actions/template_actions';
import { AppState } from '../../reducers';
import { BasePageContainer } from '../../components/Layout/BasePage';
import {
    Button, Grid, Card,
    CardContent, TextField,
    Dialog, DialogTitle,
    DialogActions, DialogContent,
    DialogContentText,
    Box
} from '@material-ui/core';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import { emailSendCountText } from '../../assets/common/string_builders';
import { get } from '../../utilities/object_util';
import { getSnackbarInfoProps } from '../../utilities/snackbar_util';
import { Activity } from '../../reducers/activity_reducer';
import { getEmailDomain } from '../../utilities/brokerage_utils';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { Block } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

// const { Editor } = require('@tinymce/tinymce-react');
const STM = Strings.TemplateManagement;
const STP = Strings.TemplatePreview;

interface StateProps {
    template: Template;

    appliedSearchFilters: SearchFilterFormValues;
    agentEmail: string;
    areAgentTemplatesLoading: boolean;
    areCorporateTemplatesLoading: boolean;
    subbedHtml: string;
    selectedContacts: string[];
    advSearchSelectAll: boolean;
    advSearchTotalResultCount: number;
    areSubsLoading: boolean;
    emailFromHistorySummary?: Activity;
}

interface DispatchProps {
    getTemplate: (templateId: string) => void;
    deleteTemplate: (templateId: string) => void;
    sendBulkEmail: (bulkEmail: BulkEmail) => void;
    postTemplate: (template: Template) => void;
    getSubstitutions: (templatePreview: TemplatePreview) => void;
    queueSnackbar: (props: SnackbarProps) => void;
    navigateTo: (path: string) => void;
    navigateBack: () => void;
    clearEditedTemplate: () => void;
    clearTemplateManagerState: () => void;
    storeEditedTemplate: (template: Template) => void;
}

type Props =
    & StateProps
    & DispatchProps
    & NavigationProps;

interface State {
    isSendDialogOpen: boolean;
    isDeleteDialogOpen: boolean;
    isInUseDialogOpen: boolean;
}

class TemplatePreviewPage extends React.Component<Props, State> {
    TEMPLATE_ID: string = '';

    FROM_CONTENT: boolean = false;
    FROM_HISTORY: boolean = false;
    FROM_SENDING: boolean = false;
    FROM_MANAGER: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            isInUseDialogOpen: false,
            isDeleteDialogOpen: false,
            isSendDialogOpen: false,
        };

        const { from, templateId } = props.match.params;
        this.TEMPLATE_ID = templateId;
        this.FROM_HISTORY = from == STM.History;
        this.FROM_SENDING = from == STM.Sending;
        this.FROM_CONTENT = from == STM.Content;
        this.FROM_MANAGER = from == STM.Management;
    }

    componentDidMount() {
        const { queueSnackbar, navigateBack, getTemplate, getSubstitutions, template } = this.props;

        if (this.FROM_HISTORY && this.TEMPLATE_ID == EMPTY_GUID) {
            queueSnackbar(getSnackbarInfoProps(STP.TemplateDeleted));
            navigateBack();
        } else if (this.FROM_CONTENT) {
            getSubstitutions({
                htmlContent: template.htmlContent,
                emailType: get(() => template.emailType, STM.Agent),
            });
        } else if (this.TEMPLATE_ID && this.TEMPLATE_ID != EMPTY_GUID) {
            getTemplate(this.TEMPLATE_ID);
        }
    }

    componentWillUnmount() {
        if (this.FROM_SENDING) {
            this.props.clearTemplateManagerState();
        }
    }

    isReady = () => {
        const { template, areSubsLoading, areAgentTemplatesLoading, areCorporateTemplatesLoading } = this.props;

        const isEmptyTemplate = this.TEMPLATE_ID === Strings.TemplateManagement.NewTemplate && "" === (template && template.id);
        const isExistingTemplate = this.TEMPLATE_ID === (template && template.id);
        const isRightTemplate = isEmptyTemplate || isExistingTemplate;

        const doneLoading = !areSubsLoading && !areAgentTemplatesLoading && !areCorporateTemplatesLoading;

        return isRightTemplate && doneLoading;
    }

    navigateBack = () => {
        this.props.navigateBack();
    };

    closeAllDialogs = () => {
        this.setState({
            isSendDialogOpen: false,
            isDeleteDialogOpen: false,
            isInUseDialogOpen: false
        });
    };

    openDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: true });
    };

    openSendDialog = () => {
        this.setState({ isSendDialogOpen: true });
    };

    openInUseDialog = () => {
        this.setState({ isInUseDialogOpen: true });
    };

    onClickEdit = () => {
        if (this.props.template.isInUseOnAutomation
            && !jwt_auth.hasPermission(P.CorporateTemplatesUpdate)
        ) {
            this.setState({ isInUseDialogOpen: true });
        } else {
            this.props.navigateTo(
                navRoutes.templateContent.path
                    .replace(Strings.Navigation.TemplateId, this.props.template.id)
                    .replace(Strings.Navigation.EditMode, Strings.Boolean.True)
            );
        }
    };

    onClickDelete = () => {
        if (this.state.isDeleteDialogOpen) {
            this.props.deleteTemplate(this.props.template.id);
            this.props.navigateTo(
                navRoutes.templateManagement.path
            );
        } else {
            if (this.props.template.isInUseOnAutomation
                && !jwt_auth.hasPermission(P.CorporateTemplatesUpdate)
            ) {
                this.setState({ isInUseDialogOpen: true });
            } else {
                this.setState({ isDeleteDialogOpen: true });
            }
        }
    };

    onClickSave = () => {
        this.props.postTemplate(this.props.template);
        this.props.navigateTo(
            navRoutes.templateManagement.path
        );
    };

    onClickSend = () => {
        let bulkEmail: BulkEmail = {
            contactIds: this.props.selectedContacts,
            searchCriteria: undefined,
            templateId: this.props.template ? this.props.template.id : EMPTY_GUID,
            emailType: STM.Bulk,
            priority: 0,
        };

        if (this.props.advSearchSelectAll) {
            bulkEmail.searchCriteria = this.props.appliedSearchFilters;
        }

        this.props.clearEditedTemplate();
        this.props.sendBulkEmail(bulkEmail);
        this.props.navigateTo(navRoutes.advancedSearch.path);
    };

    onClickToSearch = () => {
        this.props.clearEditedTemplate();
        this.props.navigateTo(
            navRoutes.advancedSearch.path
        );
    };

    render() {
        return (
            <BasePageContainer
                topComponent={this.renderTopComponent()}
                middleComponent={
                    this.isReady()
                        ? this.renderMiddleComponent()
                        : undefined
                }
            />
        );
    }

    renderTopComponent = (): JSX.Element => {
        return (
            <HeaderBarComponent
                title={Strings.TemplateDefaults.EmailTemplatePreview}
                rightButtons={this.renderRightHeader()}
                isLoading={!this.isReady()}
            />
        );
    };

    renderRightHeader = (): JSX.Element => {
        const { template } = this.props;
        const emailType = get(() => template.emailType, STM.Agent);

        return this.FROM_CONTENT || this.FROM_SENDING
            ? (
                <Grid container justify={Strings.CSS.FlexEnd} style={{ width: 'auto' }}>
                    <Grid item>
                        <Button
                            onClick={
                                this.FROM_SENDING
                                    ? this.openSendDialog
                                    : this.onClickSave
                            }
                            children={
                                this.FROM_SENDING
                                    ? Strings.ButtonText.Send
                                    : Strings.ButtonText.Save
                            }
                            style={{
                                color: themePalette.negative_text
                            }}
                        />
                    </Grid>
                </Grid>
            ) : this.FROM_HISTORY
                ? (<div />)
                : (
                    <MoreMenu
                        children={[{
                            children: Strings.MoreMenu.Edit,
                            onClick: this.onClickEdit,
                            disabled: emailType == STM.Corporate
                                && !jwt_auth.hasPermission(P.Admin)
                                && !jwt_auth.hasPermission(P.CorporateTemplatesUpdate)
                        }, {
                            children: Strings.MoreMenu.Delete,
                            onClick: this.onClickDelete,
                            disabled: emailType == STM.Corporate
                                && !jwt_auth.hasPermission(P.Admin)
                                && !jwt_auth.hasPermission(P.CorporateTemplatesDelete),
                        }]}
                        color={themePalette.negative_text}
                    />
                );
    };

    renderMiddleComponent = (): JSX.Element => {
        const { emailFromHistorySummary } = this.props;
        return (
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item lg={6}>
                            <TextField
                                fullWidth
                                defaultValue={Strings.TemplateDefaults.Email}
                                disabled={true}
                                label={Strings.TemplateDefaults.To}
                                inputProps={{ style: { color: Strings.CSS.Black } }}
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <TextField
                                fullWidth
                                defaultValue={emailFromHistorySummary?.sender || this.props.agentEmail}
                                disabled={true}
                                label={Strings.TemplateDefaults.From}
                                inputProps={{ style: { color: Strings.CSS.Black } }}
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={12}>
                            <TextField
                                fullWidth
                                defaultValue={emailFromHistorySummary?.subject || this.props.template.subject}
                                disabled={true}
                                label={Strings.TemplateDefaults.LabelSubject}
                                inputProps={{ style: { color: Strings.CSS.Black } }}
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Box        
                        component="div"
                        display="block"
                        boxShadow={3}
                        height="auto"
                        minHeight="200px"
                        maxHeight="600px"
                        width="100%"
                        m={0}
                        p={1}
                        overflow={Strings.CSS.Auto}
                        bgcolor={themePalette.default_background}
                    >
                        <div dangerouslySetInnerHTML={{ __html: emailFromHistorySummary?.htmlContent || this.props.subbedHtml }} />
                    </Box>
                    {this.FROM_SENDING
                        ? (<Grid container justify={Strings.CSS.FlexEnd}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color={Strings.Theming.Primary}
                                    style={{ backgroundColor: themePalette.delete_remove_reject_button }}
                                    onClick={this.onClickToSearch}
                                >
                                    {Strings.ButtonText.Cancel}
                                </Button>
                            </Grid>
                        </Grid>
                        ) : (<div />)
                    }
                    {this.renderDeleteDialog()}
                    {this.renderSendDialog()}
                    {this.renderIsInUseDialog()}
                </CardContent>
            </Card>
        );
    };

    renderDeleteDialog = () => {
        return (
            <Dialog
                open={this.state.isDeleteDialogOpen}
                onClose={this.closeAllDialogs}
            >
                <DialogTitle>
                    {STM.ConfirmDelete + (this.props.template.title || STM.ThisTemplateLower) + '?'}
                </DialogTitle>
                <DialogActions>
                    <Button
                        color={Strings.Theming.Secondary}
                        onClick={this.closeAllDialogs}
                        children={Strings.ButtonText.Cancel}
                    />
                    <Button
                        variant='contained'
                        color={Strings.Theming.Primary}
                        style={{ backgroundColor: themePalette.delete_remove_reject_button }}
                        onClick={this.onClickDelete}
                        children={Strings.ButtonText.Delete}
                    />
                </DialogActions>
            </Dialog>
        );
    };

    renderSendDialog = () => {
        const numberOfPeopleToEmail: number = this.props.advSearchSelectAll
            ? this.props.advSearchTotalResultCount
            : this.props.selectedContacts.length;

        return (
            <Dialog
                open={this.state.isSendDialogOpen}
                fullWidth
                onClose={this.closeAllDialogs}
            >
                <DialogTitle>
                    {emailSendCountText(numberOfPeopleToEmail)}
                </DialogTitle>
                <DialogActions>
                    <Button
                        color={Strings.Theming.Secondary}
                        onClick={this.closeAllDialogs}
                        children={Strings.ButtonText.Cancel}
                    />
                    <Button
                        variant='contained'
                        color={Strings.Theming.Primary}
                        style={{ backgroundColor: themePalette.accept_button }}
                        onClick={this.onClickSend}
                        children={Strings.ButtonText.Send}
                    />
                </DialogActions>
            </Dialog >
        );
    };

    renderIsInUseDialog = () => {
        return (
            <Dialog
                open={this.state.isInUseDialogOpen}
                disableEscapeKeyDown
                onClose={this.closeAllDialogs}
            >
                <DialogTitle>
                    {(this.props.template.title || STM.ThisTemplate) + STM.InUse}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {STM.RemoveBeforeEdit}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color={Strings.Theming.Primary}
                        style={{ backgroundColor: themePalette.delete_remove_reject_button }}
                        onClick={this.closeAllDialogs}
                        children={Strings.ButtonText.Ok}
                    />
                </DialogActions>
            </Dialog>
        );
    };
}

function mapStateToProps(state: AppState, ownProps: Props): StateProps {
    
    const { emailHistoryId } = ownProps.match.params;

    return {
        template:
            state.templateManagerState.editedTemplate
            || state.templateManagerState.currentTemplate,
        appliedSearchFilters:
            state.advancedSearch.appliedSearchFilters,
        areAgentTemplatesLoading:
            state.templateManagerState.areAgentTemplatesLoading,
        areCorporateTemplatesLoading:
            state.templateManagerState.areCorporateTemplatesLoading,
        subbedHtml:
            state.templateManagerState.subbedHtml,
        agentEmail:
            state.agent.emailAddress
            || state.agent.firstName + '.' + state.agent.lastName + getEmailDomain(),
        selectedContacts:
            state.advancedSearch.bulkEmailContacts,
        advSearchSelectAll:
            state.advancedSearch.isSelectAll,
        advSearchTotalResultCount:
            state.advancedSearch.totalResultCount,
        areSubsLoading:
            state.templateManagerState.areSubsLoading,
        emailFromHistorySummary:
            state.emailHistorySummary.emailHistorySummarySet.find(email => email.data?.id === emailHistoryId)?.data
    };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
    return {
        navigateBack: () =>
            dispatch(navigateBack()),

        navigateTo: (route: string) =>
            dispatch(navigateTo(route)),

        getTemplate: (templateId: string) =>
            dispatch(GetTemplateWithSubstitutionsById.started(templateId)),

        deleteTemplate: (templateId: string) =>
            dispatch(DeleteTemplate.started(templateId)),

        sendBulkEmail: (bulkEmail: BulkEmail) =>
            dispatch(SendBulkEmail.started(bulkEmail)),

        postTemplate: (template: Template) =>
            dispatch(PostTemplate.started(template)),

        getSubstitutions: (templatePreview: TemplatePreview) =>
            dispatch(GetTemplateSubstitutions.started(templatePreview)),

        queueSnackbar: (props: SnackbarProps) =>
            dispatch(QueueSnackbar(props)),

        clearEditedTemplate: () =>
            dispatch(ClearEditedTemplate()),

        storeEditedTemplate: (template: Template) =>
            dispatch(StoreEditedTemplate(template)),

        clearTemplateManagerState: () =>
            dispatch(ClearTemplateStores())
    };
}

export const TemplatePreviewContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    true,
)(TemplatePreviewPage);