import {
	MenuItem,
	Paper,
	Typography,
} from '@material-ui/core';
import { FormSelect, FormTextField } from '../../utilities/forms';
import React from 'react';
import { Field } from 'redux-form';
import {
	formatCurrency,
} from '../../utilities/formatting/data_normalizations';
import { connect } from '@hmkts/rise';
import { Lookups } from '../../utilities/lookup';

export type VendorFieldsData = {
	campaign: string;
	inputSource: string;
	leadCost: string;
	leadType: string;
	vendor: string;
	rating: string;
	fund: string;
};

// TODO: add rating

interface OwnProps {
	isManual: boolean;
	hasFund?: boolean;
}

interface StateProps {
	leadTypeList: string[];
	inputSourceList: string[];
	vendorList: string[];
	ratingList: string[];
}

type Props = OwnProps & StateProps;

const _VendorFields: React.FC<Props> = (props) => {
	return (
		<div>
			<Typography
				variant="h5"
				style={{ paddingLeft: 16, paddingTop: 16 }}
			>
				Lead Info
			</Typography>
			<Paper elevation={1} style={styles.tableContentStyle}>
				{!props.hasFund &&
					<Field
						name="campaign"
						component={FormTextField}
						label="Campaign"
						disabled={!props.isManual}
						fullWidth
					/>
				}
				<Field
					name="inputSource"
					label="Input Source"
					component={FormSelect}
					children={props.inputSourceList.map(value => (
						<MenuItem value={value}>{value}</MenuItem>
					))}
					disabled={true}
				/>
				<Field
					name="leadCost"
					normalize={formatCurrency}
					component={FormTextField}
					label="Lead Cost"
					disabled={!props.isManual}
					fullWidth
				/>
				<Field
					name="leadType"
					label="Lead Type"
					component={FormSelect}
					disabled={!props.isManual}
					children={props.leadTypeList
						.sort()
						.map(value => <MenuItem value={value}>{value}</MenuItem>)} />
				{!props.hasFund
					&& <Field
						name="vendor"
						component={FormSelect}
						label="Vendor"
						children={props.vendorList
							.sort()
							.map(value => <MenuItem value={value}>{value}</MenuItem>)}
						disabled={!props.isManual}
					/>
				}
				<Field
					name="rating"
					label="Rating"
					component={FormSelect}
					children={props.ratingList.map(value => (
						<MenuItem value={value}>{value}</MenuItem>
					))}
				/>
			</Paper>
		</div >
	);
};

function mapStateToProps(state: any): StateProps {

	return {
		vendorList: state.lookup.getLabels(Lookups.Vendor),
		leadTypeList: state.lookup.getLabels(Lookups.LeadType),
		inputSourceList: state.lookup.getLabels(Lookups.InputSource),
		ratingList: state.lookup.getLabels(Lookups.LeadRating),
	};
}

export const VendorFields = connect<StateProps, {}, OwnProps>(
	mapStateToProps,
	undefined,
	true
)(
	_VendorFields
);;

const styles: { [key: string]: React.CSSProperties } = {
	tableContentStyle: {
		margin: '10px',
		padding: 20,
	},
};
