import React, { PureComponent } from 'react';
import { connect } from '@hmkts/rise';
import { enforceStylesType, getBaseIconStyle } from '../utilities/styles_util';
import { themePalette } from '../utilities/branding';
import { Paper, Grid, Button, Icon, IconButton } from '@material-ui/core';
import { SetupTwilioClient, IncrementHouseholdIndex, EndClickToCallSession, C2CSelectedContact, TwilioOnConnect, TwilioOnDisconnect, TwilioOnCancel, TwilioOnError, TwilioOnReady, TwilioOnIncoming, EndClickToCallPhoneCall, TwilioOnAccept } from '../actions/click_to_call_actions';
import { AudioSettingsDialog } from '../components/click_to_call/audio_settings_dialog';
import { ClickToCallSession, ContactPhoneNumberResult, SessionHousehold } from '../reducers/click_to_call_reducer';
import { timeFromSeconds } from '../utilities/utilities';
import { MoreMenu, createMenuAction } from '../components/nav/more_menu';
import { normalizePhone } from '../utilities/formatting/data_normalizations';
import { Strings } from '../assets/common/strings';
import { NavigationProps, navRoutes } from '../components/nav/Routes';
import { navigateTo } from '../actions/navigation_actions';
import { isMobileDevice } from '../utilities/is_mobile';
const Twilio = require('twilio-client');


interface StateProps {
    enabled: boolean;
    twilioToken: string;
    callsMade: number;
    callTimer: number;
    inCall: boolean;
    totalCallTimer: number;
    householdIndex: number;
    contact?: C2CSelectedContact;
    sessionHousehold?: SessionHousehold,
    session?: ClickToCallSession,
    callerIdVerified?: boolean;
    households: ContactPhoneNumberResult[];
    isTwilioSetup: boolean;
}
interface DispatchProps {
    endCall: () => void;
    endSession: () => void;
    setupTwilio: () => void;
    incrementHouseholds: () => void;
    navigateTo: (route: string) => void;

    twilioOnAccept: (connection?: any) => void;
    twilioOnConnect: (connection?: any) => void;
    twilioOnDisconnect: (connection?: any) => void;
    twilioOnCancel: (connection?: any) => void;
    twilioOnError: (error?: any) => void;
    twilioOnReady: (device?: any) => void;
    twilioOnIncoming: (connection?: any) => void;
}
type Props = StateProps & DispatchProps & NavigationProps;

interface State {
    showAudioSettings: boolean;
}

class _ClickToCall extends PureComponent<Props, State> {

    state = {
        showAudioSettings: false,
    };

    componentDidMount() {
        const { twilioToken } = this.props;
        if (twilioToken) {
            this.setupDevice(twilioToken);
        }
        else {
            // Get the twilio access token
            this.props.setupTwilio();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!Boolean(this.props.twilioToken) && nextProps.twilioToken) {
            this.setupDevice(nextProps.twilioToken);
        }

        // Navigate to the next household
        if (nextProps.householdIndex != this.props.householdIndex && nextProps.householdIndex < this.props.households.length) {
            this.props.navigateTo(navRoutes.household.path.replace(
                Strings.Navigation.HouseholdId,
                this.props.households[nextProps.householdIndex].householdId
            ));
        }
    }

    setupDevice = (token: string) => {
        if (!isMobileDevice && !this.props.inCall && !this.props.isTwilioSetup) {
            Twilio.Device.setup(token);

            // setup handlers
            Twilio.Device.on('connect', this.props.twilioOnConnect);
            Twilio.Device.on('disconnect', this.props.twilioOnDisconnect);
            Twilio.Device.on('cancel', this.props.twilioOnCancel);
            Twilio.Device.on('error', this.props.twilioOnError);
            Twilio.Device.on('ready', this.props.twilioOnReady);
            Twilio.Device.on('incoming', this.props.twilioOnIncoming);
        }
    };

    endCallOrSession = () => {
        const { inCall } = this.props;
        if (inCall) {
            // end the call
            this.props.endCall();
        } else {
            // end the session
            this.props.endSession();
        }
    };

    openAudioSettingsDialog = () => {
        this.setState({ showAudioSettings: true });
    }

    closeAudioSettingsDialog = () => {
        this.setState({ showAudioSettings: false });
    }

    render() {
        const { enabled, contact, inCall, callTimer, totalCallTimer, householdIndex, callsMade, households, incrementHouseholds } = this.props;

        const totalCallTime = timeFromSeconds(totalCallTimer);
        const callTime = timeFromSeconds(callTimer);
        const buttonText = inCall
            ? 'End Call'
            : 'End Session';

        return (
            <div>
                {
                    enabled && (
                        <Paper elevation={0} style={styles.paper}>
                            <Grid container spacing={0} style={styles.card} alignItems='center'>
                                <Grid container xs={4} sm={4} style={styles.c2cText} direction='column'>
                                    <Grid item style={styles.cardData}>{`Total Call Time: ${totalCallTime}`}</Grid>
                                    <Grid item style={styles.cardData}>{`Total Calls Made: ${callsMade}`}</Grid>
                                </Grid>
                                <Grid container xs={4} sm={4} style={{ ...styles.c2cText, textAlign: 'center' }} direction='column'>
                                    <Grid item style={styles.cardData}>{`Calling: ${contact ? contact.name : 'No phone call started'}`}</Grid>
                                    <Grid item style={styles.cardData}>{`${contact ? normalizePhone(contact.number) : ''}`}</Grid>
                                </Grid>
                                <Grid container xs={4} sm={4} style={styles.c2cText} direction='row' justify='flex-end'>
                                    <Grid direction='column' style={{ textAlign: 'right' }}>
                                        <Grid item style={styles.cardData}>{`Call Duration: ${callTime}`}</Grid>
                                        <Grid item style={styles.cardData}>{`${householdIndex + 1} of ${households.length} Households`}</Grid>
                                    </Grid>
                                    <MoreMenu children={[createMenuAction('Audio Settings', this.openAudioSettingsDialog, this.props.session == undefined)]} />
                                </Grid>
                            </Grid>
                            <Grid container style={{ ...styles.card, maxHeight: 50 }} justify='flex-end' alignItems='center' direction='row'>
                                <Grid container xs={6} sm={6} justify='flex-start' alignContent='center' alignItems='center'>
                                    <Button
                                        onClick={this.endCallOrSession}
                                        style={{ backgroundColor: themePalette.primary, color: themePalette.negative_text, maxHeight: 20, marginLeft: 20 }}
                                    >
                                        {buttonText}
                                    </Button>
                                </Grid>
                                <Grid container xs={6} sm={6} style={{ marginBottom: 10 }} justify='flex-end' alignContent='center' alignItems='center'>
                                    <IconButton
                                        onClick={incrementHouseholds}
                                        disabled={inCall || householdIndex >= households.length - 1}
                                        style={getBaseIconStyle(themePalette.negative_icon)}
                                    >
                                        <Icon>
                                            arrow_forward
                                        </Icon>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    )
                }
                {this.props.children}
                <AudioSettingsDialog
                    twilioDevice={Twilio.Device}
                    closeDialog={this.closeAudioSettingsDialog}
                    visible={this.state.showAudioSettings}
                />
            </div>
        );
    }

}

const styles = enforceStylesType({
    card: {
        overflow: 'hidden',
        flexWrap: 'nowrap',
    },
    paper: {
        borderRadius: 0,
        backgroundColor: themePalette.secondary,
        color: themePalette.negative_text,
    },
    cardData: {
        fontSize: 14,
        cursor: 'default',
    },
    c2cText: {
        marginLeft: 10,
        marginTop: 10,
        marginRight: 10,
    }
});

const mapStateToProps = (state): StateProps => {
    

    const { sessionHousehold } = state.clickToCall;
    return {
        enabled: state.clickToCall.enabled && !isMobileDevice,
        twilioToken: state.clickToCall.twilioToken,
        callsMade: state.clickToCall.metadata.callsMade,
        callTimer: state.clickToCall.metadata.callTimer,
        inCall: state.clickToCall.metadata.inCall,
        totalCallTimer: state.clickToCall.metadata.totalCallTimer,
        householdIndex: state.clickToCall.metadata.householdIndex,
        contact: state.clickToCall.metadata.contact,
        sessionHousehold,
        session: state.clickToCall.session,
        callerIdVerified: state.clickToCall.callerIdVerified,
        households: sessionHousehold ? sessionHousehold.households.filter(house => house.dncReason == null) : [],
        isTwilioSetup: state.clickToCall.isTwilioSetup,
    }
};
const mapDispatchToProps = (dispatch): DispatchProps => ({
    endCall: () => dispatch(EndClickToCallPhoneCall()),
    endSession: () => dispatch(EndClickToCallSession.started(undefined)),
    setupTwilio: () => dispatch(SetupTwilioClient.started(undefined)),
    incrementHouseholds: () => dispatch(IncrementHouseholdIndex()),
    navigateTo: (route: string) => dispatch(navigateTo(route)),

    twilioOnAccept: (connection: any) => dispatch(TwilioOnAccept(connection)),
    twilioOnConnect: (connection: any) => dispatch(TwilioOnConnect(connection)),
    twilioOnDisconnect: (connection: any) => dispatch(TwilioOnDisconnect(connection)),
    twilioOnCancel: (connection: any) => dispatch(TwilioOnCancel(connection)),
    twilioOnError: (error: any) => dispatch(TwilioOnError(error)),
    twilioOnReady: (device: any) => dispatch(TwilioOnReady(device)),
    twilioOnIncoming: (connection: any) => dispatch(TwilioOnIncoming(connection)),
});

export const ClickToCall = connect(mapStateToProps, mapDispatchToProps, true)(_ClickToCall);