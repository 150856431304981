import React from 'react';
import { TextField } from '@material-ui/core';
import { Grid } from '@hmkts/rise';
import { useReduxFormFieldProps, ReduxFormsFieldProps } from './use_redux_form_field_props';


export const FormTextField: React.FC<ReduxFormsFieldProps> = (ownProps) => {

    const {
        gridSize,
        componentProps,
        onChange,
        ...props
    } = useReduxFormFieldProps(ownProps);

    return (
        <Grid
            gridSize={gridSize}
        >

            <TextField
                fullWidth
                {...props}
                {...componentProps}
                onChange={onChange}
            />
        </Grid>
    )
}