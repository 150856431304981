import { call, put, takeEvery, all, delay, takeLatest, race, take } from 'redux-saga/effects';
import {
    setIdledDialogVisible,
    AuthOptions,
    Authenticate,
    IncrementAuthTimer,
    NewLogin,
    CancelLogin,
    CheckVersion,
    FailedLogin,
    FailedRefreshLogin,
    LoginTokens,
    Logout,
    RefreshLogin,
    SuccessfulLogin,
    VersionResponse,
    UpdateIdle,
} from '../actions/authentication_actions';
import { isIOS, isMobileDevice } from '../utilities/is_mobile';
import { jwt_auth } from '../utilities/auth';
import { Strings } from '../assets/common/strings';
import { Action as ReduxAction, Action } from 'typescript-fsa';
import { takeLatestForActionType, selectFromImmutable } from '../utilities/saga_util';
import { local_storage } from '../utilities/storage';
import { QueueSnackbar } from '../actions/snackbar_actions';
import { getSnackbarErrorProps, getSnackbarInfoProps } from '../utilities/snackbar_util';
import { getReferrerUrl, isBrokerage } from '../utilities/brokerage_utils';
import { http } from '../utilities';
import { AuthenticationState } from '../reducers/authentication_reducer';
import { getAuthenticationState } from '../selectors/authentication_selectors';
import { submitLog } from '../utilities/logging_util';
import moment from 'moment';
import { getOs } from '../utilities/device_util';
import { ConfigSlice } from '@hmkts/rise';
import { GetAgentFromJwt } from '../actions/agent_actions';
import { GetClickToCallSession } from '../actions/click_to_call_actions';
import { QueueDialog } from '../actions/dialogs_actions';
import { RestoreImpersonation } from '../actions/impersonation_actions';
import { GetLookupData } from '../actions/lookup_actions';
import { GetUserById, GetUserByAgentCode } from '../actions/user_actions';
import { Log_LoginFailed } from '../assets/common/string_builders';
import { makeDialogInstance } from '../containers/dialogs';
import { RegisterNotificationsDialog, EditNotificationSettingsDialog } from '../containers/dialogs/edit_notification_settings_dialog';
import { TimeZoneDialogFirstTimeLogin } from '../containers/dialogs/timezone_dialog';
import { selectIsFirstTimeLogin } from '../selectors/user_selectors';
import { P } from '../utilities/auth/permissions';
import { HttpOptions } from '../utilities/http';
import { GAService } from '../utilities/services/google_analytics';
import { get } from '../utilities/object_util';
import { FcmRegistration } from '../actions/fcm_actions';
import { AppConfig } from '../types/config';


// Actions that should update idle - use () around regex if needing to add to the list
const actionsThatUpdateIdle: RegExp[] = [
    /(@@router\/LOCATION_CHANGE)/,
    /(LOGIN\/SUCCESS)/,
    /(LOGIN\/LOGOUT)/,
    /(FormBuilder\/AddRequiredField)/,
    /(FormBuilder\/SetValidResponse)/,
    /(FormBuilder\/SetInvalidResponse)/,
    /(@@redux-form\/*)/
];
const actionsRegex = new RegExp(actionsThatUpdateIdle.map(r => r.source).join('|'));
const isInterestingAction = (action: Action<any>) => {
    return actionsRegex.test(action.type);
};

function* handleInterestingAction() {
    yield put(UpdateIdle());
}


// TODO: Update auth checks and auth flow so that we aren't duplicating code
// TODO: and uses a better state machine (loginState field => authState)
const checkJwtAuthentication = async () => {
    const jwt = local_storage.getJwt();
    if (jwt) {
        const decodedJwt = jwt_auth.decodeJwt(jwt);
        const currentTime = new Date().getTime();
        if (decodedJwt.exp > currentTime) {
            return true;
        }
    }
    return false;
};

const getAuthConfigs = async () =>
    await http('auth', { method: 'GET', credentials: 'include' });

const checkAuthentication = async () => {
    const jwtAuth = await checkJwtAuthentication();
    if (jwtAuth) {
        return true;
    }
    const [response]: [Response] = await Promise.all([
        getAuthConfigs(),
    ]);
    submitLog(Strings.Log.Info, 'Check Auth', { response });
    return (response.status === 200);
}

function* startAuthentication(action: Action<AuthOptions>) {
    const { redirect = false, skipCheck = false } = action.payload;

    const authState: AuthenticationState = yield selectFromImmutable<AuthenticationState>(getAuthenticationState);
    const { firstLogin, logoutPending } = authState;
    // TODO: We still use VERSION in the UI so agents can reference what build they are on (even though they don't ever use this)
    // TODO: Further cleanup to remove this completely, just not calling api for now
    // // No longer need to check versioning since all app platforms are now using web
    // if (firstLogin) {
    //     yield put(CheckVersion.started({}));
    //     yield race({ success: take(CheckVersion.done.type), failed: take(CheckVersion.failed.type) });
    // }

    let isAuthenticated = false;
    if (!logoutPending && !skipCheck) {
        isAuthenticated = yield call(checkAuthentication);
    }

    if (!isAuthenticated && redirect) {
        // begin auth
        const platform = isMobileDevice
            ? (isIOS ? 'ios' : 'android')
            : 'desktop'
        let url = `${isBrokerage ? AppConfig.broker_web_api : AppConfig.web_api}/auth/start?origin=${isBrokerage ? 'excelsior' : 'healthmarkets'}&platform=${platform}`;
        window.location.href = url;
        return;
    }

    if (isAuthenticated) {
        const jwt = local_storage.getJwt();
        const jwtAuth: boolean = yield call(checkJwtAuthentication);
        if (!jwt || !jwtAuth) {
            yield put(NewLogin());
            // yield race({
            //     success: take(SuccessfulLogin.type),
            //     failed: take([CancelLogin.type, FailedLogin.type, Logout.type])
            // });
            return;
        }
        else {
            yield put(SuccessfulLogin({ jwt }));
            return;
        }
    }
    // authenticated
    yield put(Authenticate.done({ params: action.payload, result: isAuthenticated }));

}

// timeout occurs after 10 minutes, reauthenticate after 9 minutes
// const TIMEOUT_IN_SECONDS = 540;
const timerCheck = 30;
function* authenticationTimeout() {
    while (true) {
        yield delay(timerCheck * 1000);
        let authState: AuthenticationState = yield selectFromImmutable<AuthenticationState>(getAuthenticationState);
        const { isAuthenticated, logoutPending, timeWhenIdleStarted, idledDialogOpen } = authState;

        if (isAuthenticated && !logoutPending) {
            yield put(IncrementAuthTimer(timerCheck));

            // Check idle time
            const minutesPassed = moment().diff(moment(timeWhenIdleStarted), 'minutes');
            if (minutesPassed > 20 && !isMobileDevice && !idledDialogOpen) {
                // idled out, show dialog
                yield put(setIdledDialogVisible(true));
            }

            // Check jwt
            const jwtAuth: boolean = yield call(checkJwtAuthentication);
            if (!jwtAuth) {
                yield call(autoLogoutHelper, 'You have been automatically logged out due to inactivity');
            }
        }

    }
}
function* autoLogoutHelper(message?: string) {
    yield all([
        put(setIdledDialogVisible(false)),
        message ? put(QueueSnackbar(getSnackbarInfoProps(message, 60000))) : undefined,
        put(Logout.started(undefined))
    ]);
}


const refreshBrowserCache = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('reload', Date.now().toString());
    window.location.href = url.toString();
};
function* checkVersion() {
    try {
        const versionEndpoint = `version/${VERSION}/${getOs()}`;
        const response = yield call(http, versionEndpoint);
        if (response.ok) {
            const result: VersionResponse = yield response.json();
            if (result.Deprecated) {
                refreshBrowserCache();
            }
            yield put(CheckVersion.done({ params: {}, result }));
        } else {
            yield put(CheckVersion.failed({ params: {}, error: response.status }));
        }
    } catch (error) {
        yield put(CheckVersion.failed({ params: {}, error }));
    }
}

function* startLoginSaga() {
    try {
        const response = yield call(loginCall);
        if (response.ok) {
            const result: LoginTokens = yield response.json();
            if (result.jwt) {
                yield put(SuccessfulLogin(result));
            }
            else {
                yield put(FailedLogin({ error: 'Unable to login' }));
            }
        } else {
            submitLog(Strings.Log.Warn, Log_LoginFailed(response.status));
            let message: string;
            switch (response.status) {
                case 403:
                    message = Strings.LoginForm.Response_403;
                    break;
                case 401:
                    message = response.statusText || Strings.LoginForm.Response_Bad;
                    break;
                default:
                    message = Strings.LoginForm.Response_Bad;
            }
            yield put(FailedLogin({ error: message }));
        }
    } catch (error) {
        yield put(FailedLogin({ error }));
    }
}

function* handleRefreshLogin() {
    try {
        const jwtAuth: boolean = yield call(checkJwtAuthentication);
        if (jwtAuth) {
            const jwt = local_storage.getJwt();
            yield put(SuccessfulLogin({ jwt: jwt! }));
            const impersonatingJwt = local_storage.getImpersonatingJwt();
            if (impersonatingJwt) {
                yield put(RestoreImpersonation());
            }
        }
    } catch (error) {
        clearStorage();
        submitLog(Strings.Log.Warn, 'Error refreshing login', error);
        yield put(FailedRefreshLogin(error));
    }
}

function loginCall() {
    const options: HttpOptions = {
        method: Strings.Http.Post,
        headers: {
            referrerurl: getReferrerUrl(),
            'Content-Type': Strings.HttpOptions.ContentType,
            Accept: Strings.HttpOptions.ContentJson,
        },
        credentials: 'include'
    };
    return http(Strings.ApiUrls.Login, options);
}

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
function* handleSuccessfulLogin(action: ReduxAction<LoginTokens>) {
    try {
        if (action.payload.jwt) {
            local_storage.storeJwt(action.payload.jwt);

            const jwt = jwt_auth.decodeJwt(action.payload.jwt);
            const currentTime = new Date().getTime();
            const twelveHoursAgo = currentTime - (12 * HOUR_IN_MILLISECONDS);
            const fourHoursAgo = currentTime - (4 * HOUR_IN_MILLISECONDS);
            if (jwt.exp > currentTime) {
                // Get User
                if (jwt.userID) {
                    yield put(GetUserById.started({ id: jwt.userID }));
                } else if (jwt.agentID) {
                    yield put(GetUserByAgentCode.started(jwt.agentID));
                }

                if (jwt_auth.hasPermission(P.Lead)) {
                    yield put(GetAgentFromJwt.started(undefined));
                }

                const userName = action.payload.upn || jwt.agentID;
                const deviceId = get(() => device.uuid) || 'web';
                const message = `Successful Login; User: ${jwt.userID}; Username: ${userName}; Device: ${deviceId};`;
                submitLog(Strings.Log.Info, message);

                yield put(GetLookupData());

                const { success, failed } = yield race({
                    success: take([
                        GetUserById.done.type,
                        GetUserByAgentCode.done.type
                    ]),
                    failed: take([
                        GetUserById.failed.type,
                        GetUserByAgentCode.failed.type,
                    ]),
                });

                if (success) {
                    const notificationPermission = get(() => Notification?.permission, 'denied');
                    if (notificationPermission === 'denied') {
                        submitLog(Strings.Log.Error, 'Device does not have notification ability');
                    }
                    else {
                        if (notificationPermission === 'default') {
                            yield put(QueueDialog(makeDialogInstance(RegisterNotificationsDialog)));
                        }
                        else {
                            yield put(FcmRegistration.started());
                        }
                    }

                    yield put(ConfigSlice.actions.SetSession({
                        agentCode: jwt.agentID,
                        userId: jwt.impersonatingId ? jwt.impersonatingId : jwt.userID,
                        authToken: action.payload.jwt,
                        roles: jwt.roles,
                    }));

                    const isFirstTimeLogin: Boolean = yield selectFromImmutable<Boolean>(selectIsFirstTimeLogin);
                    if (isFirstTimeLogin) {
                        yield firstTimeLoginSaga();
                    }

                    yield put(GetClickToCallSession.started(undefined));
                    yield put(UpdateIdle);
                } else if (failed) {
                    yield put(FailedLogin({ error: Strings.LoginForm.Response_Bad }));
                }

            } else if ((jwt.iat && (jwt.iat > twelveHoursAgo)) || jwt.exp > fourHoursAgo) {
                yield put(FailedLogin({ error: Strings.LoginForm.LoginExpired }))
            } else {
                yield put(FailedLogin({ error: 'Unable to login, please try again.' }))
            }
        }
        else {
            yield put(FailedLogin({ error: 'Unable to login, no token available.' }));
        }

    } catch (error) {
        yield put(FailedLogin({ error }));
        throw (error)
    }
}

function* firstTimeLoginSaga() {
    yield put(QueueDialog(makeDialogInstance(TimeZoneDialogFirstTimeLogin)));
    if (!isBrokerage)
        yield put(QueueDialog(makeDialogInstance(EditNotificationSettingsDialog)));
}


function clearStorage() {
    local_storage.clearFedAuth();
    local_storage.clearJwt();
    local_storage.clearImpersonatingJwt();
}

function* handleLogout(action: Action<undefined>) {
    clearStorage();
    GAService.endSession();

    // Only need the signout redirect if the user actually is still authenticated through adfs
    const response: Response = yield call(getAuthConfigs);
    if (response.status === 200) {
        const platform = isMobileDevice
            ? (isIOS ? 'ios' : 'android')
            : 'desktop'
        const url = `${isBrokerage ? AppConfig.broker_web_api : AppConfig.web_api}/auth/signout?origin=${isBrokerage ? 'excelsior' : 'healthmarkets'}&platform=${platform}`;
        window.location.href = url;
        return;
    }

    yield put(Logout.done({ params: undefined, result: undefined }));
}

export function* authenticationSagas() {
    yield all([
        authenticationTimeout(),
        takeLatestForActionType(Authenticate.started, startAuthentication),
        takeEvery(isInterestingAction, handleInterestingAction),
        takeLatest(
            CheckVersion.started,
            checkVersion
        ),
        takeLatest(
            NewLogin,
            startLoginSaga
        ),
        takeLatest(
            Logout.started,
            handleLogout
        ),
        takeLatest(
            RefreshLogin,
            handleRefreshLogin
        ),
        takeLatest(
            SuccessfulLogin,
            handleSuccessfulLogin
        ),
        takeLatest(
            FailedLogin,
            clearStorage
        ),
    ]);
}
