import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { getCommissionStatementSummary } from '../../selectors/agent_commission_statement_selectors';
import { AgentCommissionStatementSummary } from '../../reducers/commission_statement_reducer';
import { Strings as S } from '../../assets/common/strings';

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: '10px'
	},
	tableCell: {
		border: 'none',
		padding: '0px',
		fontSize: '1rem',
	},
	valueColumn: {
		textAlign: 'center',
	},
	light: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	separator: {
		paddingTop: '6px',
	}
}));

interface StateProps {
	commissionSummary: AgentCommissionStatementSummary;
}

type Props = StateProps;

const createRows = (data: AgentCommissionStatementSummary) => {
	const classes = useStyles();
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		currencySign: 'accounting'
	});

	return [
		{
			label: {
				style: undefined,
				value: S.AgentCommissionStatement.BalanceBeginning,
			},
			amount: {
				style: undefined,
				value: formatter.format(data.BalanceBeginning),
			},
		},
		{
			label: {
				style: classes.separator,
				value: S.AgentCommissionStatement.PersonalEarnedComm,
			},
			amount: {
				style: classes.separator,
				value: formatter.format(data.PersonalEarnedComm),
			}
		},
		{
			label: {
				style: classes.light,
				value: S.AgentCommissionStatement.PersonalFirstYear,
			},
			amount: {
				style: classes.light,
				value: formatter.format(data.PersonalFirstYear),
			}
		},
		{
			label: {
				style: classes.light,
				value: S.AgentCommissionStatement.PersonalRenewal,
			},
			amount: {
				style: classes.light,
				value: formatter.format(data.PersonalRenewal),
			}
		},
		{
			label: {
				style: classes.light,
				value: S.AgentCommissionStatement.UnearnedBalanceLapsePolicy,
			},
			amount: {
				style: classes.light,
				value: formatter.format(data.UnearnedBalanceLapsePolicy),
			}
		},
		{
			label: {
				style: classes.separator,
				value: S.AgentCommissionStatement.OverridesEarnedComm,
			},
			amount: {
				style: classes.separator,
				value: formatter.format(data.OverridesEarnedComm),
			}
		},
		{
			label: {
				style: classes.light,
				value: S.AgentCommissionStatement.OverridesFirstYear,
			},
			amount: {
				style: classes.light,
				value: formatter.format(data.OverridesFirstYear),
			}
		},
		{
			label: {
				style: classes.light,
				value: S.AgentCommissionStatement.OverridesRenewal,
			},
			amount: {
				style: classes.light,
				value: formatter.format(data.OverridesRenewal),
			}
		},
		{
			label: {
				style: classes.separator,
				value: S.AgentCommissionStatement.Adjustments,
			},
			amount: {
				style: classes.separator,
				value: formatter.format(data.Adjustments),
			}
		},
		{
			label: {
				style: undefined,
				value: S.AgentCommissionStatement.BalanceEnding,
			},
			amount: {
				style: undefined,
				value: formatter.format(data.BalanceEnding),
			}
		},
		{
			label: {
				style: undefined,
				value: S.AgentCommissionStatement.UnearnedBalanceClosing,
			},
			amount: {
				style: undefined,
				value: formatter.format(data.UnearnedBalanceClosing),
			}
		}
	];
}

const _CommissionStatementSummary: React.FC<Props> = (props) => {
	const { commissionSummary } = props;
	const classes = useStyles();
	const rows = createRows(commissionSummary);

	return (
		<TableContainer className={classes.container}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={`${classes.tableCell} ${classes.light}`}>{S.AgentCommissionStatement.BalanceSummary}</TableCell>
						<TableCell className={`${classes.tableCell} ${classes.light}`} aria-label='Amount' />
					</TableRow>
				</TableHead>
				<TableBody>
					{
						rows.map((row) => (
							<TableRow key={rows.indexOf(row)}>
								<TableCell className={`${classes.tableCell} ${row.label.style}`}>{row.label.value}</TableCell>
								<TableCell className={`${classes.tableCell} ${classes.valueColumn} ${row.amount.style}`}>{row.amount.value}</TableCell>
							</TableRow>
						))
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	commissionSummary: getCommissionStatementSummary(state),
});

export const CommissionStatementSummary = connect(mapStateToProps, {}, true)(_CommissionStatementSummary);
