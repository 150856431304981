import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	MenuItem,
	Tab,
	Tabs,
	TextField,
	MenuItemProps,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';

import {
	DeleteApplication,
	GetAllUnlinkedApplications,
	GetApplication,
	IUnlinkedGuide,
	LinkApplication,
	UnlinkApplication,
	UpdateApplication,
} from '../../actions/application_actions';
import { ContactLiveSearch } from '../../actions/contact_actions';
import { GetEmployer, SearchEmployers } from '../../actions/employer_actions';
import { GetHouseholdContacts } from '../../actions/household_actions';
import { ResetInfiniteScroll } from '../../actions/infinite_scroll_actions';
import {
	navigateBack,
	navigateTo,
	navigateToWithoutAddingToHistory
} from '../../actions/navigation_actions';
import {
	GetAllUnlinkedPolicies,
	GetPolicy,
	LinkingPolicyPayload,
	LinkPolicy,
	MatchPolicy,
	SetProductTab,
	UnlinkPolicy,
	UnmatchPolicy,
	UpdatePolicy,
} from '../../actions/policy_actions';
import { ApplicationDetail } from '../../components/Application/ApplicationDetail';
import { UnlinkedApplicationList } from '../../components/Application/UnlinkedApplicationList';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PolicyDetail } from '../../components/Policy/PolicyDetail';
import { UnlinkedPolicyList } from '../../components/Policy/UnlinkedPolicyList';
import { WideButton } from '../../components/utility/wide_button';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { Application } from '../../reducers/application_reducer';
import { Contact } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { AppOrPolicy, Policy, ProductStatus } from '../../reducers/policy_reducer';
import { getProductStatus, getProductStatusReason, leftStatusIsFartherAlong, getProductUserId } from '../../utilities/product_util';
import { Loaded } from '../../utilities/utilities';
import { EMPTY_GUID } from '../../utilities/empty_entities';
import { createMenuAction, createHelpAction, MoreMenu } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

interface StateProps {
	currentTab: AppOrPolicy;
	applicationId?: string;
	policyId?: string;
	householdId: string;
	employerId?: string;
	currentApplication?: Loaded<Application>;
	currentPolicy?: Loaded<Policy>;
	applications: Application[];
	policies: Policy[];
	loadedContacts: Loaded<Contact>[];
	contactPageSize: number;
	contactPageIndex: number;
	hasMoreContacts: boolean;
	loadedEmployers: Loaded<Employer>[];
	isLoading: boolean;
	productUserId?: string;
	userId?: string
}

interface DispatchProps extends NavigationProps {
	deleteApplication: (application: Application) => void;
	contactLiveSearch: (
		searchText: string,
		includeEmployees: boolean,
		pageIndex: number,
		pageSize: number
	) => void;
	resetLiveSearch: () => void;
	getApplication: (applicationID: string) => void;
	getAllUnlinkedApplications: (id: string, guide: string) => void;
	getAllUnlinkedPolicies: (id: string, guide: string) => void;
	getPolicy: (policyId: string) => void;
	setProductTab: (tabToDisplay: AppOrPolicy) => void;
	linkPolicy: (linkingPayload: LinkingPolicyPayload) => void;
	linkApplication: (linkingPayload: LinkingPolicyPayload) => void;
	unlinkApplication: (unlinkPayload: LinkingPolicyPayload) => void;
	unlinkPolicy: (unlinkPayload: LinkingPolicyPayload) => void;
	updateApplication: (application: Application) => void;
	updatePolicy: (policy: Policy) => void;
	matchPolicy: (policy: Policy) => void;
	unmatchPolicy: (policyId: string) => void;
	getHouseholdContacts: (householdId: string) => void;
	searchEmployers: (searchText: string) => void;
	getEmployer: (id: string) => void;
}

interface ProductDetailState {
	deleteConfirmationVisibility: boolean;
	statusDialogOpen: boolean;
	newStatus: ProductStatus;
	newStatusReason: string;
}

type ProductDetailPageProps = DispatchProps & StateProps;

class ProductDetailPage extends React.PureComponent<
	ProductDetailPageProps,
	ProductDetailState
	> {
	constructor(props: ProductDetailPageProps) {
		super(props);
		this.state = {
			deleteConfirmationVisibility: false,
			statusDialogOpen: false,
			newStatus: 0,
			newStatusReason: '',
		};
	}

	componentDidMount() {
		if (this.props.applicationId)
			this.props.getApplication(this.props.applicationId);
		if (this.props.policyId) this.props.getPolicy(this.props.policyId);
	}

	componentWillReceiveProps(nextProps: ProductDetailPageProps) {
		const productStatus: number = getProductStatus(
			nextProps.currentApplication && nextProps.currentApplication.data
				? nextProps.currentApplication.data
				: undefined,
			nextProps.currentPolicy && nextProps.currentPolicy.data
				? nextProps.currentPolicy.data
				: undefined
		);

		const productStatusReason: string = getProductStatusReason(
			nextProps.currentApplication && nextProps.currentApplication.data
				? nextProps.currentApplication.data
				: undefined,
			nextProps.currentPolicy && nextProps.currentPolicy.data
				? nextProps.currentPolicy.data
				: undefined
		);

		if (
			this.state.newStatus !== productStatus ||
			this.state.newStatusReason !== productStatusReason
		) {
			this.setState({
				newStatus: productStatus,
				newStatusReason: productStatusReason,
			});
		}
	}

	toggleTabs = () => {
		if (this.props.currentTab === AppOrPolicy.Policy) {
			this.props.setProductTab(AppOrPolicy.Application);
		} else if (this.props.currentTab === AppOrPolicy.Application) {
			this.props.setProductTab(AppOrPolicy.Policy);
		}
	};

	openStatusDialog = () => {
		this.setState({ statusDialogOpen: true });
	};

	handleStatusChange = (status: ProductStatus) => {
		this.setState({ newStatus: status });
	};

	onStatusSave = (currentApplication?: Application, currentPolicy?: Policy) => {
		// Just an Application: update app status and set reason to Agent Set
		// Just a Policy: update policy manual status and set manual reason to Agent Set
		// Policy and Application: update policy manual status and set reason to Agent Set

		if (currentPolicy && currentPolicy.id) {
			if (currentPolicy.manualPolicyStatus !== this.state.newStatus) {
				currentPolicy.manualPolicyStatus = this.state.newStatus;
				currentPolicy.manualStatusReason = 'Agent Set';
				this.props.updatePolicy(currentPolicy);
			}
		} else if (currentApplication && currentApplication.id) {
			if (currentApplication.applicationStatus !== this.state.newStatus) {
				currentApplication.applicationStatus = this.state.newStatus;
				currentApplication.statusReason = 'Agent Set';
				this.props.updateApplication(currentApplication);
			}
		}

		this.setState({ statusDialogOpen: false });
	};

	onStatusCancel = () => {
		this.setState({ statusDialogOpen: false });
	};

	statusIsDisabled = (status: ProductStatus, currentApplication?: Application, currentPolicy?: Policy) => {
		if (
			(!currentApplication || !currentApplication.id) &&
			(!currentPolicy || !currentPolicy.id)
		) {
			return true;
		} else if (
			currentApplication &&
			currentApplication.id &&
			(!currentPolicy || !currentPolicy.id)
		) {
			return false;
		} else {
			return (
				currentPolicy &&
				leftStatusIsFartherAlong(currentPolicy.policyStatus, status)
			);
		}
	};

	canChangeProductStatus = (currentApplication?: Application, currentPolicy?: Policy) => {
		if (
			(!currentApplication || !currentApplication.id) &&
			(!currentPolicy || !currentPolicy.id)
		) {
			return false;
		} else if (
			currentApplication &&
			currentApplication.id &&
			(!currentPolicy || !currentPolicy.id)
		) {
			return true;
		} else {
			return (
				currentPolicy && currentPolicy.policyStatus !== ProductStatus.Terminated
			);
		}
	};

	renderProductStatusDialog = () => {
		const currentApplication = this.props.currentApplication
			? this.props.currentApplication.data
			: ({} as Application);
		const currentPolicy = this.props.currentPolicy
			? this.props.currentPolicy.data
			: ({} as Policy);
		return (
			<Dialog key="statusReasonDialog" fullScreen open={this.state.statusDialogOpen} onClose={this.onStatusCancel}>
				<SaveCancelHeaderBarComponent
					onCancel={this.onStatusCancel}
					title={'Product Status'}
					saveText="Save"
					onSave={() => this.onStatusSave(currentApplication, currentPolicy)}
					isSaveDisabled={this.state.newStatus == undefined}
				/>
				<DialogContent>
					<TextField
						key="status"
						label="Status"
						select
						fullWidth
						value={this.state.newStatus}
						onChange={(event: any) => {
							this.handleStatusChange(event.target.value);
						}}
					>
						<MenuItem
							value={ProductStatus.Pending}
							disabled={this.statusIsDisabled(
								ProductStatus.Pending,
								this.props.currentApplication &&
									this.props.currentApplication.data
									? this.props.currentApplication.data
									: undefined,
								this.props.currentPolicy && this.props.currentPolicy.data
									? this.props.currentPolicy.data
									: undefined
							)}
						>
							Pending
						</MenuItem>
						<MenuItem
							value={ProductStatus.Active}
							disabled={this.statusIsDisabled(
								ProductStatus.Active,
								this.props.currentApplication &&
									this.props.currentApplication.data
									? this.props.currentApplication.data
									: undefined,
								this.props.currentPolicy && this.props.currentPolicy.data
									? this.props.currentPolicy.data
									: undefined
							)}
						>
							Active
						</MenuItem>
						<MenuItem
							value={ProductStatus.Terminated}
							disabled={this.statusIsDisabled(
								ProductStatus.Terminated,
								this.props.currentApplication &&
									this.props.currentApplication.data
									? this.props.currentApplication.data
									: undefined,
								this.props.currentPolicy && this.props.currentPolicy.data
									? this.props.currentPolicy.data
									: undefined
							)}
						>
							Terminated
						</MenuItem>
					</TextField>
				</DialogContent>
			</Dialog>
		);
	};

	closeDeleteConfirmation = () => {
		this.setState({
			deleteConfirmationVisibility: false,
		});
	};

	openDeleteConfirmation = () => {
		this.setState({
			deleteConfirmationVisibility: true,
		});
	};

	confirmedDeleteApplication = () => {
		this.setState({
			deleteConfirmationVisibility: false,
		});
		if (this.props.currentApplication) {
			this.props.deleteApplication(this.props.currentApplication.data);

			if (this.props.currentPolicy) {
				this.props.unlinkApplication({
					policy: this.props.currentPolicy.data,
					history: this.props.history,
					route: navRoutes.productDetail.path.replace(
						Strings.Navigation.ProductId,
						`|${this.props.currentPolicy.data.id}`
					)
				});
			} else {
				this.props.navigateBack();
			}
		}
	};

	getAllUnlinkedPoliciesInternal = () => {
		if (!this.props.currentApplication) {
			return;
		}

		let id = EMPTY_GUID;
		let guide = 'No ID';

		if (this.props.currentApplication.employerId) {
			id = this.props.currentApplication.employerId;
			guide = 'Employer';
		} else if (this.props.currentApplication.data.employerId) {
			id = this.props.currentApplication.data.employerId;
			guide = 'Employer';
		} else if (this.props.currentApplication.householdId) {
			id = this.props.currentApplication.householdId;
			guide = 'Household';
		} else if (
			this.props.currentApplication.data.primaryInsured &&
			this.props.currentApplication.data.primaryInsured.householdId
		) {
			id = this.props.currentApplication.data.primaryInsured.householdId;
			guide = 'Household';
		}

		this.props.getAllUnlinkedPolicies(id, guide);
	};

	getAllUnlinkedApplicationsInternal = () => {
		if (!this.props.currentPolicy) {
			return;
		}

		let id = EMPTY_GUID;
		let guide = 'No ID';

		if (this.props.currentPolicy.employerId) {
			id = this.props.currentPolicy.employerId;
			guide = 'Employer';
		} else if (this.props.currentPolicy.data.employerId) {
			id = this.props.currentPolicy.data.employerId;
			guide = 'Employer';
		} else if (this.props.currentPolicy.householdId) {
			id = this.props.currentPolicy.householdId;
			guide = 'Household';
		} else if (
			this.props.currentPolicy.data.primaryInsured &&
			this.props.currentPolicy.data.primaryInsured.householdId
		) {
			id = this.props.currentPolicy.data.primaryInsured.householdId;
			guide = 'Household';
		}

		this.props.getAllUnlinkedApplications(id, guide);
	};

	renderDeleteConfirmation = () => {
		return (
			<Dialog
				open={this.state.deleteConfirmationVisibility}
				onClose={this.closeDeleteConfirmation}
			>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					{this.props.currentPolicy
						? 'This application is tied to a policy. Are you sure you want to delete the application?'
						: 'Are you sure you want to delete this Application?'}
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeDeleteConfirmation}
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.confirmedDeleteApplication}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderApplicationTab = () => (
		<div hidden={this.props.currentTab !== AppOrPolicy.Application}>
			{
				this.props.applicationId && this.props.currentApplication
					? (
						<ApplicationDetail
							application={this.props.currentApplication.data}
							unlinkApplication={(policy: Policy) =>
								this.props.unlinkApplication({
									policy: policy,
									route: navRoutes.productDetail.path.replace(
										Strings.Navigation.ProductId,
										`|${policy.id}`
									),
									history: this.props.history,
								})
							}
							currentPolicy={this.props.currentPolicy}
						/>
					)
					: this.props.currentPolicy
						? (
							<UnlinkedApplicationList
								applications={this.props.applications}
								currentPolicy={this.props.currentPolicy}
								getAllUnlinkedApplications={this.getAllUnlinkedApplicationsInternal}
								linkApplication={(application: Application, policy: Policy) =>
									this.props.linkApplication({
										policy: policy,
										route: navRoutes.productDetail.path.replace(
											Strings.Navigation.ProductId,
											`${application.id}|${policy.id}`
										),
										history: this.props.history,
									})
								}
								householdId={this.props.householdId}
								createApplication={() => {
									let route = navRoutes.applicationUpsert.path.replace(Strings.Navigation.ApplicationId, 'new');
									if (this.props.employerId) {
										route = route.replace(Strings.Navigation.EmployerId, this.props.employerId);
									} else {
										route = route.replace(Strings.Navigation.HouseholdId, this.props.householdId);
									}
									this.props.navigateTo( route);
								}}
							/>
						)
						: undefined
			}
		</div>
	);

	renderPolicyTab = () => (
		<div hidden={this.props.currentTab !== AppOrPolicy.Policy}>
			{
				this.props.policyId && this.props.currentPolicy
					? (
						<PolicyDetail
							policy={this.props.currentPolicy}
							currentApplication={this.props.currentApplication}
							unlinkPolicy={(policy: Policy) =>
								this.props.unlinkPolicy({
									policy: policy,
									history: this.props.history,
									route: navRoutes.productDetail.path.replace(
										Strings.Navigation.ProductId,
										`${this.props.applicationId}|`
									),
								})
							}
							loadedContacts={this.props.loadedContacts}
							loadedEmployers={this.props.loadedEmployers}
							handleContactSearch={(searchString: string) => {
								this.props.contactLiveSearch(
									searchString,
									false,
									this.props.contactPageIndex,
									this.props.contactPageSize
								);
							}}
							hasMoreContacts={this.props.hasMoreContacts}
							history={this.props.history}
							resetLiveSearch={this.props.resetLiveSearch}
							navigateToWithoutAddingToHistory={this.props.navigateToWithoutAddingToHistory}
							getHouseholdContacts={this.props.getHouseholdContacts}
							matchPolicy={this.props.matchPolicy}
							unmatchPolicy={this.props.unmatchPolicy}
							searchEmployers={this.props.searchEmployers}
							getEmployer={this.props.getEmployer}
							isLoading={this.props.isLoading}
						/>
					)
					: this.props.currentApplication
						? (
							<UnlinkedPolicyList
								policies={this.props.policies}
								currentApplication={this.props.currentApplication}
								getAllUnlinkedPolicies={this.getAllUnlinkedPoliciesInternal}
								linkPolicy={(policy: Policy) =>
									this.props.linkPolicy({
										policy: policy,
										history: this.props.history,
										route: navRoutes.productDetail.path.replace(
											Strings.Navigation.ProductId,
											`${this.props.applicationId}|${policy.id}`
										),
									})
								}
								householdId={this.props.householdId}
							/>
						)
						: undefined
			}
		</div>
	);

	openHouseholdPage = () => {
		const householdPage: string = navRoutes.household.path.replace(
			Strings.Navigation.HouseholdId,
			this.props.householdId)
		this.props.navigateTo(householdPage)
	};

	editProduct = () => {
		let route = navRoutes.applicationUpsert.path.replace(Strings.Navigation.ApplicationId, this.props.applicationId!);
		if (this.props.employerId) {
			route = route.replace(Strings.Navigation.EmployerId, this.props.employerId);
		} else {
			route = route.replace(Strings.Navigation.HouseholdId, this.props.householdId);
		}
		this.props.navigateTo(route);
	};

	openDeleteDialog = () => {
		this.setState({
			deleteConfirmationVisibility: true,
		});
	};

	render() {
		const productStatus: number = getProductStatus(
			this.props.currentApplication && this.props.currentApplication.data
				? this.props.currentApplication.data
				: undefined,
			this.props.currentPolicy && this.props.currentPolicy.data
				? this.props.currentPolicy.data
				: undefined
		);
		const productStatusReason = getProductStatusReason(
			this.props.currentApplication && this.props.currentApplication.data
				? this.props.currentApplication.data
				: undefined,
			this.props.currentPolicy && this.props.currentPolicy.data
				? this.props.currentPolicy.data
				: undefined
		);

		const menuItems: MenuItemProps[] = [];
		if (this.props.applicationId && (this.props.employerId || this.props.householdId)) {
			menuItems.push(createMenuAction('Edit', this.editProduct));
			menuItems.push(createMenuAction('Delete Application', this.openDeleteDialog));
		}
		menuItems.push(createHelpAction(themeLinks.helpLinkCRM));

		const title = <span>Product</span>;
		const subtitle = (
			<div onClick={this.openStatusDialog} style={{ cursor: 'pointer', marginTop: -16 }}>
				<span style={{ fontSize: 10 }}>
					{'Status: ' +
						ProductStatus[productStatus] +
						(productStatusReason ? ' - ' + productStatusReason : '')}
				</span>
				<span style={{ paddingLeft: 8, verticalAlign: 'middle' }}>
					<Icon
						style={{
							color: themePalette.negative_text,
							fontSize: 15,
						}}
						onClick={this.openStatusDialog}
					>
						mode_edit
					</Icon>
				</span>
			</div>
		);

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={title}
						subtitle={subtitle}
						rightButtons={
							<MoreMenu
								children={menuItems}
							/>
						}
						customContent={
							<Tabs
								variant='fullWidth'
								value={this.props.currentTab}
								onChange={this.toggleTabs}
								style={{
									backgroundColor: themePalette.header_bar,
									color: themePalette.negative_text,
								}}
								centered
							>

								<Tab
									value={AppOrPolicy.Application}
									label={'Application'}
									icon={<Icon>assignment</Icon>}
									style={styles.tabWidth}
								/>
								<Tab
									value={AppOrPolicy.Policy}
									label={'Policy'}
									icon={<Icon>assignment_turned_in</Icon>}
									style={styles.tabWidth}
								/>

							</Tabs>
						}
					/>
				}
				middleComponent={
					<DesktopPadding>
						{this.props.householdId && this.props.userId == this.props.productUserId &&
							<WideButton
								primary="View Household"
								onClick={this.openHouseholdPage}
								disabled={!this.props.householdId}
							/>
						}
							
						{this.renderApplicationTab()}
						{this.renderPolicyTab()}
							
						{this.renderDeleteConfirmation()}
						{this.renderProductStatusDialog()}
					</DesktopPadding>
				}
			/>
		);
	}
}

function mapStateToProps(state: AppState, ownProps: ProductDetailPageProps): StateProps {
	const paramsProductID: string = ownProps.match.params.productID;
	const pipeIndex: number = paramsProductID.indexOf('|');
	const applicationId = paramsProductID.slice(0, pipeIndex).toLowerCase();
	const policyId = paramsProductID.slice(pipeIndex + 1).toLowerCase();
	const currentApplication = state.application.applications.find(
		application => application.data.id == applicationId
	);

	const currentPolicy = state.policy.policies.find(
		policy => policy.data.id == policyId
	);

	const applications = state.application.applications.map(loadedApp => loadedApp.data);
	const policies = state.policy.policies.map(loadedPolicy => loadedPolicy.data);

	const householdId =
		currentPolicy && currentPolicy.data && currentPolicy.data.primaryInsured
			? currentPolicy.data.primaryInsured.householdId
			: currentApplication &&
				currentApplication.data &&
				currentApplication.data.primaryInsured
				? currentApplication.data.primaryInsured.householdId
				: '';

	const employerId = currentPolicy && currentPolicy.data ? currentPolicy.data.employerId : currentApplication && currentApplication.data ? currentApplication.data.employerId : '';

	const productUserId = getProductUserId(currentApplication && currentApplication.data, currentPolicy && currentPolicy.data);

	return {
		applicationId,
		policyId,
		currentTab: state.policy.applicationOrPolicyTab,
		currentApplication,
		currentPolicy,
		applications,
		policies,
		householdId,
		employerId,
		loadedContacts: state.contact.contacts,
		contactPageSize: state.contact.pageSize,
		contactPageIndex: state.infiniteScroll.pagesReceived,
		hasMoreContacts: state.infiniteScroll.loadMore,
		loadedEmployers: state.employer.employers,
		isLoading: state.contact.isLoading || state.employer.searchLoading,
		productUserId: productUserId,
		userId: state.user.impersonatingId || state.user.id
	};
}

function mapDispatchToProps(dispatch: any): Partial<DispatchProps> {
	return {
		deleteApplication: (application: Application) =>
			dispatch(DeleteApplication.started(application)),
		getAllUnlinkedApplications: (id: string, guide: string) =>
			dispatch(
				GetAllUnlinkedApplications.started({ id, guide } as IUnlinkedGuide)
			),
		getAllUnlinkedPolicies: (id: string, guide: string) =>
			dispatch(GetAllUnlinkedPolicies.started({ id, guide } as IUnlinkedGuide)),
		getApplication: (applicationID: string) =>
			dispatch(GetApplication.started(applicationID)),
		getEmployer: (id: string) => {
			dispatch(GetEmployer.started(id));
		},
		getHouseholdContacts: (householdId: string) =>
			dispatch(GetHouseholdContacts.started(householdId)),
		getPolicy: (policyId: string) => dispatch(GetPolicy.started(policyId)),
		linkApplication: (linkingPayload: LinkingPolicyPayload) =>
			dispatch(LinkApplication.started(linkingPayload)),
		linkPolicy: (linkingPayload: LinkingPolicyPayload) =>
			dispatch(LinkPolicy.started(linkingPayload)),
		matchPolicy: (policy: Policy) => dispatch(MatchPolicy.started(policy)),
		navigateBack: () => dispatch(navigateBack()),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		resetLiveSearch: () => dispatch(ResetInfiniteScroll()),
		searchEmployers: (searchText: string) =>
			dispatch(SearchEmployers.started(searchText)),
		setProductTab: (tabToDisplay: AppOrPolicy) =>
			dispatch(SetProductTab({ appOrPolicy: tabToDisplay })),
		unlinkApplication: (unlinkPayload: LinkingPolicyPayload) =>
			dispatch(UnlinkApplication.started(unlinkPayload)),
		unlinkPolicy: (unlinkPayload: LinkingPolicyPayload) =>
			dispatch(UnlinkPolicy.started(unlinkPayload)),
		updateApplication: (application: Application) =>
			dispatch(UpdateApplication.started(application)),
		updatePolicy: (policy: Policy) => dispatch(UpdatePolicy.started(policy)),
		unmatchPolicy: (policyId: string) =>
			dispatch(UnmatchPolicy.started(policyId)),

		contactLiveSearch: (
			searchText: string,
			includeEmployees: boolean,
			pageIndex: number,
			pageSize: number
		) =>
			dispatch(
				ContactLiveSearch.started({
					searchText,
					includeEmployees,
					pageRequested: pageIndex,
					pageSize,
				})
			),
	};
}

export const ProductDetailPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(ProductDetailPage);

const styles: { [key: string]: React.CSSProperties } = {
	tabWidth: {
		width: '50%',
	}
};