import React from 'react';
import { connect } from '@hmkts/rise';
import { Notification } from '../../containers/notifications_page/notifications_page';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { Dispatch } from 'redux';
import { navRoutes } from '../nav/Routes';
import { AppState } from '../../reducers';
import { getValidPersistentNotifications } from '../../selectors/notifications_selectors';
import { useNavigationProps } from '../../utilities/hooks';
import { SimpleListItem } from '../utility/simple_list_item';

interface ComponentProps {
	disabled?: boolean;
	displayingNotificationId?: string | undefined;
}
interface StateProps {
	persistentNotifications?: Notification[];
}
interface DispatchProps {}
type Props = ComponentProps & StateProps & DispatchProps;

const PersistentNotificationsComponent: React.FC<Props> = (props) => {

	const { persistentNotifications=[], disabled, displayingNotificationId } = props;

	const navigation = useNavigationProps();
	const navigateToDetail = (notification: Notification) => {
		if (!disabled) {
			const detailRoute = navRoutes.notificationListDetail.path.replace(
				Strings.Navigation.NotificationId,
				notification.id
			);
			navigation.navigateTo(detailRoute);
		}
	};

	const notifications = persistentNotifications.filter(n => n.id !== displayingNotificationId);

	return (
		<>
			{notifications.map((notification: Notification) => (
				<SimpleListItem
					key={notification.id}
					style={{ backgroundColor: themePalette.persistent_notification_background, color: themePalette.sub_text }}
					title={notification.title?.substring(0, 50)}
					subtitle={notification.content?.base?.substring(0, 140)}
					onClick={!disabled ? () => navigateToDetail(notification) : undefined}
					hideArrow={disabled}
					divider={false}
				/>
			))}
		</>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	persistentNotifications: getValidPersistentNotifications(state),
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export const PersistentNotifications = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(PersistentNotificationsComponent) as React.ComponentClass<ComponentProps>;
