import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core';
import { FactFinderList } from './fact_finder_list';
import {
    RiseCard,
    Tab,
    Button,
    FactFinderIconSvg,
    Grid,
    RiseList,
    createMenuAction,
    ExceptionCard,
    ConfirmationDialog,
    riseStrings,
    risify,
    RiseStrings,
    useFormActions,
    useConfirmation,
    useLoading, FilterSlice
} from '@hmkts/rise';
import {
    TabContext,
    TabList,
    TabPanel
} from '@material-ui/lab';
import {
    usePowerPointState,
    useFactFinderActions,
} from '../../hooks';
import { useTheme } from "@material-ui/core";
import { FactFinderSlice } from '../..';
import { themePalette } from '../../../../utilities/branding';


const useStyles = makeStyles((theme) => ({
    tabHeader: {
        backgroundColor: theme.palette.background.default,
    },
    tabPanelLeft: {
        padding: '0',
        height: "100%",
    },

    tabPanelRight: {
        padding: '0',
        height: '100%',
    },
    indicator: {
        backgroundColor: themePalette.menuSvgFactFinder,
    },
}));

const _FactFinderPageWidget: React.FC<{}> = () => {
    const classes = useStyles();
    // const theme = useTheme();
    const loading = useLoading();
    const dispatch = useDispatch();
    const [exceptionId] = useState(null);
    const {
        navigateToFormList,
        setCurrentTab
    } = useFactFinderActions();

    const handleFormListNavigation = () => {
        dispatch(FactFinderSlice.actions.enableSearch());
        dispatch(FilterSlice.actions.resetFilter({ filterId: riseStrings._FactFinders }));
        navigateToFormList();
    }

    const powerpoint = usePowerPointState({ count: 4 });

    const formDispatch = useFormActions();

    const currentTab = useSelector(app => FactFinderSlice.selectState(app).currentTab);

    useEffect(() => {
        formDispatch.getRecent();
        powerpoint.getTemplates();
    }, []);

    const confirmDeletePres = useConfirmation({
        onConfirm: powerpoint.deleteTemplate,
        subtitleText: riseStrings.AreYouSureDeletePresentation,
    });

    return !!exceptionId
        ? <ExceptionCard exceptionId={exceptionId || ""} />
        : (
            <TabContext
                value={currentTab}
            >
                <RiseCard
                    cardStyle={{ height: 456, position: "relative" }}
                    container
                    cardActionStyle={{position: "absolute", bottom: 10 }}
                    titleText={
                        <TabList
                            variant="fullWidth"
                            classes={{ indicator: classes.indicator }}
                            onChange={(__, newValue) => setCurrentTab(newValue as RiseStrings.FactFinderTabs)}
                            aria-label="FactFinder Tabs">
                            <Tab
                                label={riseStrings.MyFactFinders}
                                value={riseStrings.FactFinderForm}
                                className={classes.tabHeader}
                            />
                            <Tab
                                label={riseStrings.MyPresentations}
                                value={riseStrings.FactFinderPresentation}
                                className={classes.tabHeader}
                            />
                        </TabList>
                    }
                    cardActions={<>
                        <Button
                            onClick={handleFormListNavigation}
                            children={riseStrings.ViewAll}
                        />
                    </>}
                    headerIcon={
                        <FactFinderIconSvg color={themePalette.menuSvgFactFinder} height={35} width={35} />
                    }
                >
                    <Grid gridSize="12|12">
                        <TabPanel
                            value={riseStrings.FactFinderForm}
                            className={classes.tabPanelLeft}
                        >

                            <FactFinderList
                                onItemClick={formDispatch.navigateToForm}
                                displayCount={4}

                            />
                        </TabPanel>
                        <TabPanel
                            value={riseStrings.FactFinderPresentation}
                            className={classes.tabPanelLeft}
                        >
                            <RiseList
                                items={powerpoint.templates}
                                onClick={powerpoint.navigateToPresentation}
                                defaultEmptyText={loading.isLoading ? "Loading..." : riseStrings.YouCurrentlyDoNotHaveAnyTemplatesSaved}
                                mapIconOptions={(item) => {
                                    return [
                                        createMenuAction("Edit", () => powerpoint.navigateToPresentation(item.id)),
                                        createMenuAction("Delete", () => (confirmDeletePres as any).setOpen(item.id, riseStrings.AreYouSureDeletePresentationName(item.titleText))),
                                        createMenuAction("Download", powerpoint.createDownloadTemplate(item)),
                                    ]
                                }}
                            />
                        </TabPanel>
                    </Grid>
                </RiseCard>
                <ConfirmationDialog {...confirmDeletePres} />
            </TabContext>

        );
}

export const FactFinderDashboardWidget = risify(
    riseStrings._FactFinder,
    riseStrings._FactFinderDashboardWidget,
)(_FactFinderPageWidget);