import { Divider, Paper } from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { change } from 'redux-form';
import { Field } from 'redux-form';
import { AppState } from '../../reducers/index';
import { LineOfBusinessField } from './line_of_business_field';
import { Lookup, Lookups } from '../../utilities/lookup';

export type LineOfBusinessFieldsData = {
	linesOfBusiness: { [key: string]: boolean };
};

interface ComponentProps {
	formName: string;
}

interface StateProps {
	lineOfBusinessTypeList: string[];
}

interface DispatchProps {
	changeFieldValue(form: string, field: string, value: any): void;
}

type Props = ComponentProps & StateProps & DispatchProps;

const _LineOfBusinessFields: React.SFC<Props> = (props) => {
	return (
		<Paper elevation={1} style={styles.lineOfBusinessContentStyle}>
			{props.lineOfBusinessTypeList.map(
				(lineOfBusinessType: string, lineOfBusinessIndex: number) => {
					return (
						<div key={lineOfBusinessIndex}>
							<Field
								name={`linesOfBusiness.${lineOfBusinessType}`}
								changeFieldValue={props.changeFieldValue}
								formName={props.formName}
								component={LineOfBusinessField}
								lineOfBusinessType={lineOfBusinessType}
								lineOfBusinessDescription={''}
							/>
							<Divider />
						</div>
					);
				}
			)}
		</Paper>
	);
};

function mapStateToProps(state: any): StateProps {
	
	return {
		lineOfBusinessTypeList: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		changeFieldValue: (form: string, field: string, value: any) => {
			dispatch(change(form, field, value));
		},
	};
}

export const LineOfBusinessFields = connect<StateProps, DispatchProps, ComponentProps>(
	mapStateToProps,
	mapDispatchToProps, 
	true
)(_LineOfBusinessFields);

const styles: { [key: string]: React.CSSProperties } = {
	lineOfBusinessContentStyle: {
		margin: '10px',
	},
};
