import {
	Chip,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Grid,
} from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from '@hmkts/rise';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { themePalette, themeLinks } from '../../utilities/branding';
import {
	notificationCategories,
	NotificationSummary,
} from '../../components/notifications/notificationSummary';
import { compose, Dispatch } from 'redux';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import Share from './client_connect_share';
import { wrapInCollapsibleCard } from '../../components/higher_order_component/wrap_in_card';
import { createMenuAction, MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { AppState } from '../../reducers';
import { ClientConnectState } from '../../reducers/ClientConnectReducer';
import HistoryIcon from '@material-ui/icons/History';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { WideButton } from '../../components/utility/wide_button';
import { ClearNewClientConnectActivity, FetchNewClientConnectActivity, FetchClientConnectSettings } from '../../actions/client_connect_actions';

const ShareCard = wrapInCollapsibleCard(Share);

interface StateProps {
	clientConnect: ClientConnectState;
}
interface DispatchProps {
	navigateBack(): void;
	navigateTo(route: string): void;
	clearNewClientConnectActivity: () => void;
	fetchNewClientConnectActivity: () => void;
	fetchClientConnectSettings: () => void;
}
type Props =StateProps & DispatchProps & NavigationProps;

class ClientConnectPage extends PureComponent<Props> {

	componentDidMount() {
		if (!this.props.clientConnect.settings.isActivated) {
			this.props.fetchClientConnectSettings();
		}
		this.props.fetchNewClientConnectActivity();
	}

	render() {
		if (!this.props.clientConnect.settings.isActivated) {
			return (
				<Dialog open={true}>
					<DialogContent>
						<DialogContentText>
							Please configure your ClientConnect in your About Me section.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							onClick={() => this.props.navigateBack()}
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								this.props.navigateTo(navRoutes.aboutMe.path);
							}}
						>
							Go to About Me
						</Button>
					</DialogActions>
				</Dialog>
			);
		}

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="ClientConnect"
						rightButtons={
							<MoreMenu
								children={[
									createMenuAction('Clear All Notifications', this.props.clearNewClientConnectActivity),
									createHelpAction(themeLinks.helpLinkClientConnect),
								]}
							/>
						}
					/>
				}
			>
				<PersistentNotifications />
				<NotificationSummary categorySelector={notificationCategories.clientConnect} />
				<DesktopPadding>
					<ShareCard title='Share ClientConnect' subtitle='Send your personalized app to a contact' />
					<WideButton
						leftIcon={<HistoryIcon />}
						primary={
							<Grid container alignItems="center">
								<span>Client Activity </span>
								{this.props.clientConnect.newActivity > 0 && (
									<Chip color="secondary" style={{ marginLeft: 10 }} label={<div style={{ color: themePalette.negative_text }}>New Activity</div>} />
								)}
							</Grid>
						}
						secondary={'View registrations, quote requests, appointment requests, referrals, and contact attempts.'}
						onClick={() => this.props.navigateTo(navRoutes.clientConnectUsage.path)}
					/>
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	clientConnect: state.clientConnect,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateBack: () => dispatch(navigateBack()),
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	clearNewClientConnectActivity: () =>
		dispatch(ClearNewClientConnectActivity()),
	fetchNewClientConnectActivity: () =>
		dispatch(FetchNewClientConnectActivity()),
	fetchClientConnectSettings: () => dispatch(FetchClientConnectSettings()),
});

export const ClientConnectContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true)
)(ClientConnectPage);
