import React from 'react';
import { RisePage, RiseAppBar, riseStrings, useNavigation } from '@hmkts/rise';
import { PowerPointTemplateFormWidget } from '../powerpoint_templates';
import { useTheme, Button, makeStyles, createStyles } from '@material-ui/core';
import { FactFinderSlice, PowerPointActions } from '../../fact_finder_slice';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

export const FactFinderPresentationPage: React.FC<{}> = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const nav = useNavigation({
    path: riseStrings._Route_FactFinder_Presentation,
  })
  const activeTemplate = useSelector(app => FactFinderSlice.selectState(app).activeTemplate, shallowEqual);
  const dirtyTemplate = useSelector(app => FactFinderSlice.selectState(app).activeTemplateDirty, shallowEqual);
  const saveTemplate = () => {
    dispatch(PowerPointActions.saveTemplate(activeTemplate));
    nav.dispatchBack();
  };

  return (
    <RisePage
      path={riseStrings._Route_FactFinder_Presentation}
    >

      <RiseAppBar
        position="static"
        loadingBar
        backAction
        confirmBack={dirtyTemplate && {
          titleText: "This form has unsaved data",
          subtitleText: "Are you sure you want to go back without saving?"
        }}
        forwardAction={
          <Button
            style={{ color: dirtyTemplate ? theme.palette.common.white : theme.palette.text.disabled }}
            disabled={!dirtyTemplate}
            onClick={saveTemplate}>{riseStrings.Save}
          </Button>
        }
      />

      <PowerPointTemplateFormWidget />

    </RisePage >

  );
}


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }),
);