import { Podcast, PodcastFilter } from '../../reducers/podcast_reducer';
import { AppState } from '../../reducers';
import { navRoutes } from '../nav/Routes';
import { FilterPodcasts, ChangePodcast } from '../../actions/podcast_actions';
import { connect } from '@hmkts/rise';
import React, { useEffect } from 'react';
import { Widget } from './base_widget';
import { Button } from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import PodcastSvg from '../svgs/icons/podcast';
import { Dispatch } from '@reduxjs/toolkit';
import { nav } from '../..';
import LoadingList from '../utility/loading_list';
import { getWidgetPodcasts } from '../../selectors/podcast_selectors';
import { PodcastItem } from '../podcast/podcast_item';
import { EmptyMessage } from '../utility/empty_message';

interface StateProps {
    podcasts: Podcast[];
    loading: boolean;
}
interface DispatchProps {
    filterPodcasts: () => void;
    changePodcast: (podcast: Podcast) => void;
}

type Props = StateProps & DispatchProps;

const PodcastsWidget: React.FC<Props> = (props) => {

    const { loading, podcasts, filterPodcasts, changePodcast } = props;

    useEffect(() => {
        filterPodcasts();
    }, []);

    const navToPodcastDetail = (podcastId: string) => nav.push(navRoutes.podcastDetail.path.replace(Strings.Navigation.PodcastId, podcastId));
    const navToPodcastList = () => nav.push(navRoutes.podcastList.path);

    const renderWidgetBody = (): JSX.Element => {
        if (loading && podcasts.length === 0) {
            return (
                <LoadingList size={2} />
            );
        } else {
            if (podcasts && podcasts.length > 0) {
                return <div style={{ width: '100%' }}>{podcasts.map(podcast => (
                    <PodcastItem
                        key={podcast.id}
                        podcast={podcast}
                        handleNavigateToPodcast={navToPodcastDetail}
                        handlePlayPodcast={changePodcast}
                        short
                    />
                ))}</div>;
            } else {
                return (
                    <EmptyMessage text={'No podcasts available'} />
                );
            }
        }
    }

    return (
        <Widget
			title={Strings.WidgetTitles.Podcasts}
			avatar={<PodcastSvg width={30} height={30} fill={themePalette.menuSvgPodcast} />}
			onClick={navToPodcastList}
			actionButtons={[
                <Button onClick={navToPodcastList}>View All</Button>
            ]}
		>
			{renderWidgetBody()}
		</Widget>
    );

}

const mapStateToProps = (state: AppState): StateProps => ({
    podcasts: getWidgetPodcasts(state),
    loading: state.podcast.isLoading
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    filterPodcasts: () => dispatch(FilterPodcasts.started({
        searchString: '',
        sortBy: 'publishedon',
        sortDirection: 'desc',
        status: 'Published',
        pageSize: 20,
        pageNumber: 0
    } as PodcastFilter)),
    changePodcast: (podcast: Podcast) => dispatch(ChangePodcast(podcast))
});

export const PodcastsWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(PodcastsWidget);

