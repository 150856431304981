import React from 'react';
import _ from 'lodash';
import H from 'history';
import { Loaded } from '../../utilities/utilities';
import { Contact } from '../../reducers/ContactReducer';
import { themePalette } from '../../utilities/branding';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import {
	navigateTo,
} from '../../actions/navigation_actions';
import { NavigationProps, navRoutes } from '../nav/Routes';
import { connect } from '@hmkts/rise';
import { Strings } from '../../assets/common/strings';

interface RemoveEmployerProps {
	isOpen: boolean;
	contact: Contact;
	history: H.History;
	onClose: () => void;
	unlink: () => void;
}

interface StateProps {
	contacts: Loaded<Contact>[];
}

interface DispatchProps {
	navigateTo: (route: string) => void;
}

type Props = RemoveEmployerProps &
	StateProps &
	DispatchProps &
	NavigationProps;

interface RemoveEmployerState {
	isDialogOpen: boolean;
}

class RemoveEmployer extends React.Component<Props, RemoveEmployerState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isDialogOpen: false,
		};
	}

	componentWillReceiveProps(nextProps: Props) {
		this.setState({
			isDialogOpen: nextProps.isOpen,
		});
	}

	isPrimary = () => {
		return this.props.contact.employerPrimaryContact;
	};

	isOnlyPerson = () => {
		const people = this.props.contacts.filter(person => {
			return this.props.contact.employerId === person.data.employerId;
		});

		return people.length === 1;
	};

	showDialog = () => {
		this.setState({
			isDialogOpen: true,
		});
	};

	closeDialog = () => {
		this.setState({
			isDialogOpen: false,
		});
	};

	cancel = () => {
		this.closeDialog();
	};

	goToEmployerPage = () => {
		this.props.navigateTo(
			navRoutes.employer.path.replace(
				Strings.Navigation.EmployerId,
				this.props.contact.employerId
			)
		);
	};

	unlinkEmployer = () => {
		this.props.unlink();
		this.props.onClose();
	};

	render() {
		let dialogActions: JSX.Element = <span /> as JSX.Element;
		let dialogContent: string = '';
		if (this.isPrimary()) {
			dialogContent = `${this.props.contact.firstName} ${
				this.props.contact.lastName
				} is the primary contact for the employer. 
            You must choose a new primary contact`;

			dialogActions = (
				<div>
					<Button color="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.goToEmployerPage}
					>
						Okay
					</Button>
				</div>
			);
		} else if (!this.isPrimary() && !this.isOnlyPerson()) {
			dialogContent = 'Are you sure you wish to unlink this employee?';
			dialogActions = (
				<div>
					<Button color="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.unlinkEmployer}
					>
						Unlink
					</Button>
				</div>
			);
		}
		return (
			<Dialog open={this.state.isDialogOpen}>
				<DialogContent>{dialogContent}</DialogContent>
				<DialogActions>{dialogActions}</DialogActions>
			</Dialog>
		);
	}
}

function mapStateToProps(state: any): StateProps {
	

	return {
		contacts: state.contact.contacts,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
	};
}

export const RemoveEmployerDialog = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(RemoveEmployer) as React.ComponentClass<RemoveEmployerProps>;
