import React from 'react';
import { Grid } from '@material-ui/core';
import { ReadOnlyTextField } from '../utility/read_only_text_field';
import { Strings as S } from '../../assets/common/strings'
import { GridSize } from '@hmkts/rise';

interface AdditionalInfoProps {
	middleInitial?: string;
	middleName?: string;
	preferredName: string;
	suffix: string;
	lastFourSSN: string;
	height: string;
	weight: string;
	isTobaccoUser: string;
	isStudent: string;
	isSelfEmployed: string;
	annualIncome: string;
	languagePref: string;
	familyStatusCode: string;
	clientType: string;
	occupation: string;
	salutation: string;
	gender: string;
	lastUnemploymentYear?: string;
}

const generateItem = (label: string, value?: string, gridSize: GridSize=4) => (
	<Grid item xs={gridSize}>
		<ReadOnlyTextField label={label} value={value || ''} />
	</Grid>
);
export const AdditionalInfo = (props: AdditionalInfoProps) => {
	return (
		<Grid container>
			{generateItem(S.PersonInfo.PreferredName, props.preferredName, 12)}
			{generateItem(S.PersonInfo.Salutation, props.salutation)}
			{generateItem(S.PersonInfo.Suffix, props.suffix)}
			{generateItem(S.PersonInfo.Gender, props.gender)}
			{generateItem(S.PersonInfo.MaritalStatus, props.familyStatusCode)}
			{generateItem(S.PersonInfo.AnnualIncome, props.annualIncome)}
			{generateItem(S.PersonInfo.Height, props.height)}
			{generateItem(S.PersonInfo.Weight, props.weight)}
			{generateItem(S.PersonInfo.Occupation, props.occupation)}
			{generateItem(S.PersonInfo.ClientType, props.clientType)}
			{generateItem(S.PersonInfo.isTobaccoUser, props.isTobaccoUser)}
			{generateItem(S.PersonInfo.isStudent, props.isStudent)}
			{generateItem(S.PersonInfo.isSelfEmployed, props.isSelfEmployed)}
			{generateItem(S.PersonInfo.Language, props.languagePref, 12)}
			{generateItem(S.PersonInfo.LastFourSSN, props.lastFourSSN, 6)}
			{generateItem(S.PersonInfo.LastUnemploymentYear, props.lastUnemploymentYear, 6)}
		</Grid>
	);
};
