import React, { useState, useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import {
    Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid
} from '@material-ui/core';
import {
    useDispatch, useSelector, useFormActions, useFormFieldActions
} from '@hmkts/rise';
import { TogglePrescriptionSearchDialog } from '../../../../actions/dialogs_actions';
import {
    PrescriptionSelectedList, PrescriptionSearch, PrescriptionDropdown, PrescriptionOptions, Drug, emptyDrug, dedupeList
} from './prescription_lookup';
import { Strings } from '../../../../assets/common/strings';

const PRESCRIPTION_FIELD_ID = '9398d1c0-6ee8-4071-9e3c-ff5906456538';

export const FactFinderPrescriptionSearchDialog: React.FC<{}> = () => {
    const dispatch = useDispatch();

    const formDispatch = useFormActions();
    const formFieldDispatch = useFormFieldActions();

    const isOpen = useSelector((state: any) => state?.App?.prescriptionSearchDialog.isOpen)
    const isLoading = useSelector((state: any) => state?.FormBuilder?.activeForm?.prescription?.isLoading, shallowEqual);
    const initialAddedDrugField = useSelector((state: any) => state?.FormBuilder?.fields[PRESCRIPTION_FIELD_ID], shallowEqual);
    const isFormCompleted = useSelector((state: any) => 
        Boolean(state?.FormBuilder?.activeForm?.activeForm?.completed) && 
        state?.FormBuilder?.activeForm?.formId === state?.FormBuilder?.activeForm?.activeForm?.id
    );

    const initialAddedDrugResponse = initialAddedDrugField?.response;
    let initialAddedDrugs = []
    try {
        initialAddedDrugs = initialAddedDrugResponse ? JSON.parse(initialAddedDrugResponse) : [];

    } catch(ex) {
        console.warn("Failed to set initialAddedDrugs");
    }

    const [drugName, setDrugName] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [hasSelected, setHasSelected] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [drugToEdit, setDrugToEdit] = useState(emptyDrug);
    const [addedDrugs, setAddedDrugs] = useState(initialAddedDrugs as Drug[])

    const readyToSave = hasChanged && !hasSelected;

    useEffect(() => {
        setAddedDrugs(initialAddedDrugs)
    }, [isOpen])

    const searchInputChange = value => {
        setDrugName(value);
        if (value.length < 2) {
            setHasSearched(false);
            formDispatch.resetPrescriptionSearchResults();
        } else if (value.length === 2) {
            formDispatch.resetPrescriptionSearchResults();
            setHasSearched(true);
        } else if (value.length > 2) {
            formDispatch.prescriptionLookup(value);
            setHasSearched(true);
        }
    }

    const handleDrugSelect = value => {
        formDispatch.prescriptionDosageLookup(value.name);
        setDrugName(value.name);
        setHasSelected(true);
        setHasChanged(true);
    }

    const handleAddDrug = (drug: Drug) => {
        let newDrugList = addedDrugs;
        if (newDrugList.length > 29) {
            return;
        }
        if (drugToEdit.rxcui !== '') {
            newDrugList = newDrugList.filter(item => (item.rxcui !== drugToEdit.rxcui))
        }
        newDrugList = [...newDrugList, drug];
        setAddedDrugs(dedupeList(newDrugList, 'rxcui'))
        setHasSearched(false);
        setHasSelected(false);
        setHasChanged(true);
        setDrugName('');
        setDrugToEdit(emptyDrug);
    }

    const handleCancelDrugAdd = () => {
        formDispatch.resetPrescriptionDosageResults();
        formDispatch.resetPrescriptionSearchResults();
        setDrugName('');
        setHasSearched(false);
        setHasSelected(false);
        setDrugToEdit(emptyDrug);
    }

    const handleDeleteDrug = (drug: Drug) => {
        const newDrugList = addedDrugs.filter(item => item.rxcui !== drug.rxcui);
        setAddedDrugs(newDrugList);
        setDrugName('');
        setHasChanged(true);
    }

    const enterEditMode = (drug: Drug) => {
        formDispatch.prescriptionDosageLookup(drug.name);
        setHasSearched(true);
        setHasSelected(true);
        setDrugName(drug.name);
        setDrugToEdit(drug);
    }

    const closeDialog = () => {
        dispatch(TogglePrescriptionSearchDialog(false))
    }

    const handleClose = () => {
        formDispatch.resetPrescriptionDosageResults();
        formDispatch.resetPrescriptionSearchResults();
        setHasSearched(false);
        setHasSelected(false);
        setHasChanged(false);
        setDrugName('');
        setDrugToEdit(emptyDrug)
        closeDialog();
    }

    const handleSave = () => {
        const prescriptionsListResponse = JSON.stringify(addedDrugs)
        formFieldDispatch.setValidField(PRESCRIPTION_FIELD_ID, prescriptionsListResponse)
        handleClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
        >
            {!isFormCompleted && <DialogContent style={{ marginBottom: 20, overflowY: 'visible' }}>
                <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                    {Strings.FactFinderPrescriptionLookup.prescriptionLookup}
                </DialogTitle>
                <PrescriptionSearch
                    value={drugName}
                    isDisabled={hasSelected || addedDrugs.length >= 30}
                    handleChange={searchInputChange}
                />
            </DialogContent>}
            <DialogContent style={{ minHeight: 200 }}>
                {isFormCompleted && (addedDrugs?.length < 1 || !addedDrugs) &&
                    <DialogTitle style={{ padding: '0px 0px 8px 0px' }}>
                        {'Cannot Add Prescriptions to completed form'}
                    </DialogTitle>}
                {!hasSelected && (
                    <PrescriptionDropdown
                        hasSearched={hasSearched}
                        isLoading={isLoading}
                        drugName={drugName}
                        handleSelect={handleDrugSelect}
                    />)}
                {hasSelected && (
                    <PrescriptionOptions
                        drugName={drugName}
                        addDrug={handleAddDrug}
                        handleCancel={handleCancelDrugAdd}
                        drugToEdit={drugToEdit}

                    />)}
                {addedDrugs.length > 0 && !hasSearched && (
                    <PrescriptionSelectedList
                        drugs={addedDrugs}
                        deleteDrug={handleDeleteDrug}
                        editDrug={enterEditMode}
                        readOnly={isFormCompleted}
                    />)}
            </DialogContent>
            <DialogActions>
                <Grid container item xs={12} justify="flex-end">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{color: 'white'}}
                            disabled={!readyToSave}
                            onClick={handleSave}
                        >
                            {Strings.FactFinderPrescriptionLookup.savePrescriptions}
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleClose}
                            style={{ marginLeft: 10, paddingLeft: 25, paddingRight: 25 }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
