import React, { useEffect } from 'react';
import { connect } from '@hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { Hierarchy } from '../../components/Profile/hierarchy';
import { GetAgentHierarchy } from '../../actions/agent_actions';
import { AgentHierarchyDto } from '../../reducers/agent_reducer';
import { getAgentHierarchy } from '../../selectors/agent_selectors';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

interface ComponentProps {
	agentCode: string;
}
interface StateProps {
	hierarchy?: AgentHierarchyDto;
}
interface DispatchProps {
	getHierarchy: (agentCode: string) => void;
}
type Props = ComponentProps & StateProps & DispatchProps;

const AgentHierarchy: React.FC<Props> = (props) => {

	const { agentCode, hierarchy, getHierarchy } = props;
	useEffect(() => {
		getHierarchy(agentCode);
	}, []);

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Hierarchy"
					isLoading={hierarchy == undefined}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.profile} />
			{hierarchy &&
				<DesktopPadding>
					<Hierarchy
						agentCode={agentCode}
						hierarchy={hierarchy}
					/>
				</DesktopPadding>
			}
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	hierarchy: getAgentHierarchy(state),
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getHierarchy: (agentCode: string) => dispatch(GetAgentHierarchy.started(agentCode))
});

export const AgentHierarchyContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(AgentHierarchy) as React.FunctionComponent<ComponentProps>;
