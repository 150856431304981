import { call, put, all } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { http } from '../utilities';
import { ClearCaches, ImpersonateJwt } from '../actions/authentication_actions';
import { GetAgentFromJwt } from '../actions/agent_actions';
import { ImpersonateAgent, RestoreImpersonation } from '../actions/impersonation_actions';
import { HttpOptions } from '../utilities/http';
import { Strings } from '../assets/common/strings';
import { getReferrerUrl } from '../utilities/brokerage_utils';
import { takeLatestForActionType } from '../utilities/saga_util';
import { local_storage } from '../utilities/storage';
import { jwt_auth } from '../utilities/auth';
import { ConfigSlice } from '@hmkts/rise';

function* impersonateAgent(action: Action<string>) {
    try {
        const options: HttpOptions = {
            method: Strings.Http.Get,
            headers: {
                referrerurl: getReferrerUrl(),
            }
        };
        const response = yield call(http, `impersonate/${action.payload}`, options);
        if (response.ok) {
            const result = yield response.json();
            const jwt = jwt_auth.decodeJwt(result.jwt);

            yield all([
                put(ImpersonateAgent.done({ params: action.payload, result: result.jwt })),
                put(ClearCaches()),
                put(ImpersonateJwt(result.jwt)),
                local_storage.storeImpersonatingJwt(result.jwt),    // save impersonatingJwt cookie first before dispatching GetAgentFromJwt...
                put(GetAgentFromJwt.started(undefined)),
                // Save impersonating ID to RISE
                put(ConfigSlice.actions.SetSession({
                    agentCode: jwt.agentID,
                    userId: jwt.impersonatingId ? jwt.impersonatingId : jwt.userID, 
                    authToken: result.jwt, 
                    roles: jwt.roles,
                })),
            ]);
        } else {
            yield put(ImpersonateAgent.failed({ params: action.payload, error: response.statusText }));
        }
    } catch (error) {
        yield put(ImpersonateAgent.failed({ params: action.payload, error: 'Impersonation Unsuccessful' }));
    }
}

function* restoreImpersonation() {
    const jwt = local_storage.getImpersonatingJwt();
    yield all([
        put(ImpersonateJwt(jwt)),
        put(GetAgentFromJwt.started(undefined)),
    ]);
}

export function* impersonationSagas() {
    yield all([
        takeLatestForActionType(
            ImpersonateAgent.started,
            impersonateAgent
        ),
        takeLatestForActionType(
            RestoreImpersonation,
            restoreImpersonation
        ),
    ]);
}