import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup, Radio, Grid
} from '@material-ui/core';
import { FormSlice, useSelector } from '@hmkts/rise';
import { Strings } from '../../../../../assets/common/strings';

export interface Owner {
    Self: boolean;
    Spouse: boolean;
    Child: boolean;
}

export interface Drug extends FormSlice.PrescriptionLookupResponse {
    owner: Owner
}

export const emptyDrug: Drug = {
    rxcui: '',
    name: '',
    strength: '',
    route: '',
    full_name: '',
    rxterms_dose_form: '',
    rxnorm_dose_form: '',
    owner: {Self: false, Spouse: false, Child: false},
}

interface Props {
    drugName: string;
    addDrug: (drug: Drug) => void;
    handleCancel: () => void;
    drugToEdit: Drug;
}

export const dedupeList = (list: any[], key: string) => list.filter((value, index, array) => array.findIndex(t => (t[key] === value[key])) === index);

export const PrescriptionOptions: React.FC<Props> = props => {
    const classes = useStyles();
    const { drugName, addDrug, handleCancel, drugToEdit } = props

    const dosageOptions = useSelector((state: any) => {
        const results = state?.FormBuilder?.activeForm?.prescriptionInfo?.dosageResults || [];
        return results.filter(result => result.name ===  drugName);
    }, shallowEqual);

    const [formRadioValue, setFormRadioValue] = useState(drugToEdit.rxterms_dose_form);
    const [strengthRadioValue, setStrengthRadioValue] = useState(drugToEdit.strength);
    const [ownerCheckboxValue, setOwnerCheckboxValue] = useState(drugToEdit.owner);

    useEffect(() => {
        setFormRadioValue(drugToEdit.rxterms_dose_form);
        setStrengthRadioValue(drugToEdit.strength);
        setOwnerCheckboxValue(drugToEdit.owner);
    }, [drugToEdit]);

    const handleFormRadioChange = event => {
        setFormRadioValue(event.target.value);
        setStrengthRadioValue('');
    }

    const handleStrengthRadioChange = event => {
        setStrengthRadioValue(event.target.value);
    }

    const handleOwnerCheckboxChange = event => {
        setOwnerCheckboxValue({ ...ownerCheckboxValue, [event.target.name]: event.target.checked });
    }

    const handleAddDrug = () => {
        const selectedDrug = dosageOptions.filter(drug => drug.rxterms_dose_form === formRadioValue && drug.strength === strengthRadioValue)
        if (selectedDrug.length > 0) {
            addDrug({...(selectedDrug[0]), owner: ownerCheckboxValue})
        }
        setFormRadioValue('');
        setStrengthRadioValue('');
        setOwnerCheckboxValue({Self: false, Spouse: false, Child: false});
    }

    const ownerSelected = Object.keys(ownerCheckboxValue).some(key => ownerCheckboxValue[key] === true);
    const dedupedDosageResults = dedupeList(dosageOptions, 'rxterms_dose_form');

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Form</FormLabel>
            <RadioGroup aria-label="Form" name="Form" value={formRadioValue} onChange={handleFormRadioChange}>
                {dedupedDosageResults.map(item => (
                    <FormControlLabel key={item.rxcui} value={item.rxterms_dose_form} control={<Radio />} label={item.rxterms_dose_form} />
                ))}
            </RadioGroup>
            {formRadioValue !== '' && (
                <>
                    <FormLabel component="legend">Size/Strength</FormLabel>
                    <RadioGroup aria-label="Strength" name="Strength" value={strengthRadioValue} onChange={handleStrengthRadioChange}>
                        {dosageOptions.filter(dose => dose.rxterms_dose_form === formRadioValue).map(item => (
                            <FormControlLabel key={item.rxcui} value={item.strength} control={<Radio />} label={item.strength} />
                        ))}
                    </RadioGroup>
                </>)}
            {strengthRadioValue !== '' && (
                <>
                    <FormLabel component="legend">prescription owner</FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={ownerCheckboxValue.Self} onChange={handleOwnerCheckboxChange} name="Self" />}
                            label="Self"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={ownerCheckboxValue.Spouse} onChange={handleOwnerCheckboxChange} name="Spouse" />}
                            label="Spouse"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={ownerCheckboxValue.Child} onChange={handleOwnerCheckboxChange} name="Child" />}
                            label="Child"
                        />
                    </FormGroup>
                </>)}
                <Grid container xs={12} justify="flex-end">
                    <Grid item>
                        {strengthRadioValue !== '' && ownerSelected && (
                                <Button
                                    onClick={handleAddDrug}
                                    variant="contained"
                                    color="primary"
                                    style={{color: 'white'}}
                                    className={classes.button}
                                >
                                    {Strings.FactFinderPrescriptionLookup.addDrug}
                                </Button>)}
                        <Button
                            onClick={handleCancel}
                            color="secondary"
                            className={classes.button}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
        </FormControl>)
};

const useStyles = makeStyles({
    button: {
        margin: 10,
        paddingLeft: 25,
        paddingRight: 25
    },
});
