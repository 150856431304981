import React from 'react';
import { connect } from '@hmkts/rise';
import {
	UpsertLeadFormComponent,
	FORM_NAME,
	FormData,
} from './upsert_lead_form';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { getFormValues } from 'redux-form';
import uuid from 'uuid';
import { createLead, GetLead, UpdateLead } from '../../actions/lead_actions';
import { Lead } from '../../reducers/LeadReducer';
import {
	navigateBack,
} from '../../actions/navigation_actions';
import { NavigationProps } from '../../components/nav/Routes';
import _ from 'lodash';
import moment from 'moment';
import { Contact, Email, Phone } from '../../reducers/ContactReducer';
import { Policy } from '../../reducers/policy_reducer';
import {
	objToArray,
	arrayToObj,
} from '../../utilities/formatting/data_normalizations';
import { GetHousehold } from '../../actions/household_actions';
import { getPrimaryContact } from '../../selectors/contact_selectors';
import { GetPolicy, UpdatePolicy } from '../../actions/policy_actions';
import { normalizeMoneyNumeric } from '../../utilities/formatting/string_normalization';
import { ContactDedupeDialog } from '../../components/contact/contact_dedupe_dialog';
import { ContactDedupeDto } from '../../reducers/dedupe_reducer';
import { ContactDedupeCheck } from '../../actions/dedupe_actions';
import { Strings } from '../../assets/common/strings';

interface Props extends NavigationProps {
	formValues: FormData;
	userId: string;
	impersonatingId: string;
	primaryContact: Contact;
	lead?: Lead;
	isLoading: boolean;
	policy?: Policy;
	syncErrors?: any;
	createLead: (lead: Lead, policy?: Policy) => void;
	updateLead(lead: Lead): void;
	getLead(id: string): void;
	getHousehold: (id: string) => void;
	getPolicy: (id: string) => void;
	updatePolicy: (policy: Policy) => void;
	dedupeCheck: (dto: ContactDedupeDto) => void;
}

interface State {
	isFormInvalid: boolean;
	noContactMethodSelected: boolean;
	noLineOfBusinessSelected: boolean;
	mode?: string;
	matchLeadToPolicy: boolean;
	lead? : Lead;
}

class UpsertLead extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isFormInvalid: true,
			noContactMethodSelected: true,
			noLineOfBusinessSelected: true,
			matchLeadToPolicy: false,
		};

		this.onSave = this.onSave.bind(this);
		this.handleFormUpdate = this.handleFormUpdate.bind(this);
	}

	componentWillMount() {
		const leadID = this.props.match.params.leadID;
		const householdID = this.props.match.params.householdID;
		const policyID = this.props.match.params.policyID;

		if (policyID && policyID !== '0' && this.props.policy) {
			this.props.getPolicy(policyID);
			this.setState({
				matchLeadToPolicy: true,
			});
		}

		if (leadID == 0 && householdID != 0) {
			//this.props.getHousehold(householdID);
			this.setState({
				mode: 'newLeadExistingHousehold',
			});
		} else if (leadID != '0' && householdID != '0') {
			//this.props.getHousehold(householdID);
			if (!this.props.lead) this.props.getLead(this.props.match.params.leadID);
			this.setState({
				mode: 'editLead',
			});
		} else {
			this.setState({
				mode: 'newLeadNewHousehold',
			});
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.state.mode == 'newLeadNewHousehold') {
			const { emails, phones } = nextProps.formValues;
			const noContactHasBeenSelected: boolean =
				(emails || []).length + (phones || []).length === 0;
			this.setState({
				noContactMethodSelected: noContactHasBeenSelected,
			});
		} else {
			this.setState({
				noContactMethodSelected: false,
			});
		}

		const linesOfBusiness = _.get(nextProps, 'formValues.linesOfBusiness', {});
		const isThereASelectedLineOfBusiness: boolean =
			objToArray(linesOfBusiness).length > 0;
		this.setState({
			noLineOfBusinessSelected: !isThereASelectedLineOfBusiness,
		});
	}

	public handleFormUpdate(
		pristine: boolean,
		invalid: boolean,
		submitting: boolean
	) {
		this.setState({
			isFormInvalid: invalid || pristine || submitting,
		});
	}

	dedupeCheck = (lead: Lead, callback?: () => void) => {
		const contact = lead.contacts[0];
		const dto = {
			phones: contact.phones ? contact.phones.map(phone => phone.number.replace(/\D/g,'')) : [],
			emails: contact.emails ? contact.emails.map(email => email.emailAddress) : [],
			callback
		}
		this.props.dedupeCheck(dto);
	}

	onCancel = () => {
		this.props.navigateBack();
	};

	onSave() {
		if (this.state.mode == 'newLeadExistingHousehold') {
			let contact = this.props.primaryContact;

			let householdID = this.props.match.params.householdID;

			let leadToAdd = {
				id: uuid.v4(),
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				status: Strings.Status.NotContacted,
				vendor: this.props.formValues.vendor,
				inputSource: this.props.formValues.inputSource,
				leadType: this.props.formValues.leadType,
				leadCost: Number(normalizeMoneyNumeric(this.props.formValues.leadCost)),
				linesOfBusiness: objToArray(this.props.formValues.linesOfBusiness),
				contacts: [contact],
				householdId: householdID,
				campaign: this.props.formValues.campaign,
				opportunityRatingCode: this.props.formValues.rating,
				updatedOn: new Date(),
			};

			this.props.createLead(leadToAdd as Lead);
			this.props.navigateBack();
		} else if (this.state.mode == 'editLead') {
			let leadToEdit: Lead = Object.assign({} as Lead, this.props.lead);
			leadToEdit.campaign = this.props.formValues.campaign;
			leadToEdit.leadCost = Number(
				normalizeMoneyNumeric(this.props.formValues.leadCost.toString())
			);
			leadToEdit.inputSource = this.props.formValues.inputSource;
			leadToEdit.leadType = this.props.formValues.leadType;
			leadToEdit.vendor = this.props.formValues.vendor;
			leadToEdit.opportunityRatingCode = this.props.formValues.rating;
			leadToEdit.linesOfBusiness = objToArray(
				this.props.formValues.linesOfBusiness
			);

			this.props.updateLead(leadToEdit);
			this.props.navigateBack();
		} else {
			let emails = this.props.formValues.emails;
			let phones = this.props.formValues.phones;
			const validNewEmail =
				(this.props.formValues.email || '').length > 0 &&
				!_.get(this.props.syncErrors, 'email', false);
			const validNewPhone =
				(this.props.formValues.phone || '').length > 0 &&
				!_.get(this.props.syncErrors, 'phone', false);
			const validNewEntry = validNewEmail || validNewPhone;

			if (validNewEntry) {
				if (validNewEmail) {
					emails = (this.props.formValues.emails || []).slice();
					let email: Email = {
						emailAddress: this.props.formValues.email as string,
						type: 'Home',
						isPreferred: true,
						dateCreated: moment().toDate(),
						doNotEmail: false,
					};
					if (emails.length) {
						email.isPreferred = false;
					}
					emails.push(email);
				}
				if (validNewPhone) {
					phones = (this.props.formValues.phones || []).slice();
					let phone: Phone = {
						id: uuid.v4(),
						number: this.props.formValues.phone as string,
						type: Strings.PhoneTypes.Home,
						isPreferred: true,
						isCompanyDnc: false,
						wasCompanyDncSet: false,
						isNationalDnc: false,
						wasRpmPhoneNumberChanged: true,
						extension: '',
						dateCreated: moment().toDate(),
						isSmsDnc: false,
					};
					if (phones.length) {
						// not the first phone
						phone.isPreferred = false;
					}
					phones.push(phone);
				}
			}

			let householdID = uuid.v4();
			const heightFtStr = (this.props.formValues?.heightFt?.toString() || '').split(' ');
			const heightInStr = (this.props.formValues?.heightIn?.toString() || '').split(' ');
			const heightFt = heightFtStr[0] ? parseInt(heightFtStr[0], 10) : undefined;
			const heightIn = heightInStr[0] ? parseInt(heightInStr[0], 10) : undefined;
			let contact: Partial<Contact> = {
				phones: phones,
				addresses: this.props.formValues.addresses,
				emails: emails,
				wasRpmNameChanged: this.props.formValues.wasRpmNameChanged || false,
				firstName: this.props.formValues.firstName,
				lastName: this.props.formValues.lastName,
				birthDate: this.props.formValues.DOB
					? moment(this.props.formValues.DOB).toDate()
					: undefined,
				id: uuid.v4(),
				householdId: householdID,
				householdRole: 0,
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				middleName: this.props.formValues.middleName,
				isSelfEmployed: this.props.formValues.isSelfEmployed,
				weightLbs: this.props.formValues.weight,
				annualIncome: Number(
					normalizeMoneyNumeric(this.props.formValues.annualIncome)
				),
				heightIn,
				heightFt,
				isStudent: this.props.formValues.isStudent,
				preferredName: this.props.formValues.preferredName,
				suffix: this.props.formValues.suffix,
				languagePreference: this.props.formValues.languagePreference,
				isTobaccoUser: this.props.formValues.isTobaccoUser,
				status: this.props.formValues.status,
				salutation: this.props.formValues.salutation,
				occupation: this.props.formValues.occupation,
				jobTitle: this.props.formValues.jobTitle,
				gender: this.props.formValues.gender,
				lastFourSsn: this.props.formValues.lastFourSsn,
				familyStatusCode: this.props.formValues.maritalStatus,
				clientType: this.props.formValues.clientType,
				lastUnemploymentYear: this.props.formValues.lastUnemploymentYear,
			};

			let leadID = uuid.v4();

			let leadToSend: Partial<Lead> = {
				id: leadID,
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				status: Strings.Status.NotContacted,
				vendor: this.props.formValues.vendor,
				inputSource: this.props.formValues.inputSource,
				leadType: this.props.formValues.leadType,
				leadCost: Number(normalizeMoneyNumeric(this.props.formValues.leadCost)),
				linesOfBusiness: objToArray(this.props.formValues.linesOfBusiness),
				contacts: [contact as Contact],
				opportunityRatingCode: this.props.formValues.rating,
				campaign: this.props.formValues.campaign,
			};

			if (this.state.matchLeadToPolicy && this.props.policy) {
				let updatedPolicy = this.props.policy;
				updatedPolicy.primaryInsured = contact as Contact;
				this.setState(
					{ lead: leadToSend as Lead },
					() => this.dedupeCheck(leadToSend as Lead)
				);
			} else {
				this.setState(
					{ lead: leadToSend as Lead },
					() => this.dedupeCheck(leadToSend as Lead)
				);
			}
		}
	}

	createInitalValues() {
		let initialValues = {
			rating: 'Warm',
			inputSource: 'Manual',
		} as Partial<FormData>;
		if (this.state.mode == 'editLead' && this.props.lead) {
			initialValues = {
				campaign: this.props.lead.campaign,
				leadType: this.props.lead.leadType,
				leadCost: `${this.props.lead.leadCost}`,
				vendor: this.props.lead.vendor,
				rating: this.props.lead.opportunityRatingCode,
				linesOfBusiness: arrayToObj(this.props.lead.linesOfBusiness),
				inputSource: this.props.lead.inputSource,
			};
		}
		return initialValues;
	}

	render() {
		const { lead } = this.props;
		const hasFund = Boolean(lead && lead.fund);
		const inputSource = lead && lead.inputSource ? lead.inputSource : 'none';

		return (
			<BasePageContainer
				topComponent={
					<SaveCancelHeaderBarComponent
						isSaveDisabled={
							this.state.isFormInvalid ||
							this.state.noContactMethodSelected ||
							this.state.noLineOfBusinessSelected ||
							this.props.isLoading
						}
						isLoading={this.props.isLoading}
						onSave={this.onSave}
						onCancel={this.onCancel}
						title={this.state.mode == 'editLead' ? 'Edit Lead' : 'New Lead'}
						subtitle={'Household'}
						saveText="Save"
					/>
				}
			>
				<ContactDedupeDialog
					lead={this.state.lead}
					policy={this.props.policy}
					history={this.props.history}
				/>
				<UpsertLeadFormComponent
					mode={this.state.mode}
					onUpdate={this.handleFormUpdate}
					syncronErrors={this.props.syncErrors}
					initialValues={this.createInitalValues()}
					hasFund={hasFund}
					inputSource={inputSource}
				/>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state,
	ownProps: Props
): Partial<Props> {
	
	const leadIndex = state.lead.leads.findIndex(loadedLead =>
			ownProps.match.params.leadID &&
			loadedLead.data.id == ownProps.match.params.leadID
	);
	const loadedLead = leadIndex == -1 ? undefined : state.lead.leads[leadIndex];
	const policyIndex = state.policy.policies.findIndex(
		loadedPolicy =>
			ownProps.match.params.policyID &&
			loadedPolicy.data.id == ownProps.match.params.policyID
	);
	const policy =
		policyIndex === -1 ? undefined : state.policy.policies[policyIndex].data;

	return {
		lead: loadedLead ? loadedLead.data : undefined,
		isLoading: state.lead.isLoading || state.dedupe.loading,
		policy,
		formValues: getFormValues(FORM_NAME)(state),
		syncErrors: _.get(state, `form.${FORM_NAME}.syncErrors`),
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		primaryContact: getPrimaryContact(state, ownProps),
	};
}

function mapDispatchToProps(dispatch: any, ownProps: Props): Partial<Props> {
	return {
		createLead: (lead: Lead, policy?: Policy) =>
			dispatch(createLead(lead, policy, ownProps.history)),
		navigateBack: () => dispatch(navigateBack()),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getLead: (id: string) => dispatch(GetLead.started(id)),
		updateLead: (lead: Lead) => dispatch(UpdateLead.started(lead)),
		getPolicy: (policyId: string) => dispatch(GetPolicy.started(policyId)),
		updatePolicy: (policy: Policy) => dispatch(UpdatePolicy.started(policy)),
		dedupeCheck: (dto: ContactDedupeDto) => dispatch(ContactDedupeCheck.started(dto))
	};
}

export const UpsertLeadContainer = connect(mapStateToProps, mapDispatchToProps, true)(
	UpsertLead
);
