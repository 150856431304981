import React from 'react';
import { connect } from '@hmkts/rise';
import { getFormValues } from 'redux-form';
import { AppState } from '../../reducers';
import { Paper, Typography } from '@material-ui/core';
import { Address } from '../../reducers/ContactReducer';
import { EmailFields, EmailFieldsData } from '../FinancePage/email_fields';
import { PhoneFields, PhoneFieldsData } from './phone_fields';
import { AddressFields, AddressFieldsData } from './address_fields';
import { themePalette } from '../../utilities/branding';
import _ from 'lodash';

export type ContactFieldsData = EmailFieldsData &
	PhoneFieldsData &
	AddressFieldsData;

interface Props {
	formName: string;
	primaryAddress?: Address;
	isNewContact?: boolean;
	isEmployee?: boolean;
}

interface StateProps {
	formValues: ContactFieldsData;
}

const _ContactFields = (props: Props & StateProps) => {
	const { emails, phones } = props.formValues;

	const atLeastOne = (emails || []).length + (phones || []).length > 0;
	const showEmailAsterisk =
		!atLeastOne || (atLeastOne && (phones || []).length == 0);
	const showPhoneAsterisk =
		!atLeastOne || (atLeastOne && (emails || []).length == 0);
	return (
		<div>
			<Typography
				variant="h5"
				style={{ paddingLeft: 16, paddingTop: 16 }}
			>
				Contact Info
			</Typography>
			<div hidden={atLeastOne}>
				<Typography
					variant="caption"
					style={{ color: themePalette.error, padding: '5px 0 16px 16px' }}
				>
					At least one phone number OR one email address is required
				</Typography>
			</div>
			<Paper elevation={1} style={styles.tableContentStyle}>
				<EmailFields
					formName={props.formName}
					showAsterisk={showEmailAsterisk}
				/>
			</Paper>
			<Paper elevation={1} style={styles.tableContentStyle}>
				<PhoneFields
					formName={props.formName}
					showAsterisk={showPhoneAsterisk}
				/>
			</Paper>
			<Paper elevation={1} style={styles.tableContentStyle}>
				<AddressFields
					formName={props.formName}
					primaryAddress={props.primaryAddress}
					isNewContact={props.isNewContact}
					isEmployee={props.isEmployee}
				/>
			</Paper>
		</div>
	);
};

function mapStateToProps(
	state,
	ownProps: Props & StateProps
): StateProps {
	
	return {
		formValues: getFormValues(ownProps.formName)(state),
	};
}

export const ContactFields = connect(
	mapStateToProps, undefined, true)(
	_ContactFields
) as React.ComponentClass<Props>;

const styles: { [key: string]: React.CSSProperties } = {
	tableContentStyle: {
		margin: '10px',
		//paddingBottom: '20px',
	},
};
