import { NavigationProps, navRoutes } from "../../components/nav/Routes";
import React from 'react';
import { GlobalState } from '../../reducers';
import { connect } from '@hmkts/rise';
import { Podcast, PodcastFilter } from '../../reducers/podcast_reducer';
import { FilterPodcasts, ChangePodcast } from '../../actions/podcast_actions';
import { navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { Grid, Paper, List, TextField, MenuItem } from '@material-ui/core';
import { Strings } from '../../assets/common/strings';
import { FabMenu } from '../../components/nav/fab_menu';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { InfiniteScroller } from '../../components/utility/infinite_scroller';
import ReactPlaceholder from 'react-placeholder';
import { styles } from '../FinancePage/FinancePage';
import { PodcastItem } from '../../components/podcast/podcast_item';
import { EMPTY_GUID } from '../../utilities/empty_entities';
import { ListFilter } from "../../components/filter/list_filter";
import { DesktopPadding } from "../../components/Layout/desktop_padding";
import { Dispatch } from "@reduxjs/toolkit";

interface StateProps {
    loading: boolean;
    podcasts: Podcast[];
    morePodcastsToLoad: boolean;
    filter: PodcastFilter;
    uploading: boolean;
}

interface DispatchProps {
    getPodcasts: (filter: PodcastFilter) => void;
    changePodcast: (podcast: Podcast) => void;
}

interface Props extends NavigationProps, StateProps, DispatchProps { }

interface State {
    isDrawerOpen: boolean;
    searchString: string,
    sortString: string,
    statusFilter: string,
}

class PodcastList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isDrawerOpen: false,
            searchString: '',
            sortString: 'publishedon-desc',
            statusFilter: jwt_auth.hasPermission(P.PodcastCreate) ? 'All' : 'Published'
        }
    }

    componentDidMount() {
        this.applyFilterValues(0);
    }

    renderFabMenu = () => {
        if (jwt_auth.hasPermission(P.PodcastCreate)) {
            return (
                <FabMenu
                    items={[
                        {
                            label: Strings.Podcast.Podcast,
                            icon: 'rss_feed',
                            onClick: () => {
                                this.props.navigateTo(navRoutes.podcastUpsert.path.replace(Strings.Navigation.PodcastId, EMPTY_GUID))
                            },
                            disabled: !jwt_auth.hasPermission(P.PodcastCreate)
                        },
                    ]} 
                />
            );
        } else {
            return <></>
        }
    };

    renderFilterSummary = () => {
        const subtitle = this.displayFilterValues();
		return (
			<ListFilter
				drawerTitle="Podcasts"
				onSearch={this.handleOnFilterSave}
				onReset={this.handleFilterReset}
				cardSubtitle={subtitle}
				drawerSubtitle={subtitle}
			>
				{this.renderFilterForm()}
			</ListFilter>
		);
    };

    displayFilterValues = () => {
        const filters: string[] = [];

        if (this.state.searchString) {
            filters.push(this.state.searchString);
        }
        if (jwt_auth.hasPermission(P.PodcastCreate)) {
            filters.push(this.state.statusFilter);
        }
        filters.push(
            this.state.sortString === 'publishedon-desc'
                ? 'Newest to Oldest'
                : 'Oldest to Newest'
        );

        return filters.join(', ');
    };

    renderFilterForm = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        style={styles.filterStyle}
                        label={'Search for name or category'}
                        value={this.state.searchString}
                        placeholder={'Search for name or category'}
                        onChange={this.handleSearchStringChange}
                        fullWidth
                    />
                </Grid>
                {jwt_auth.hasPermission(P.PodcastCreate) && 
                    <Grid item xs={12}>
                        <TextField
                            style={styles.filterStyle}
                            select
                            label="Status"
                            value={this.state.statusFilter}
                            onChange={this.handleStatusFilterChange}
                            fullWidth
                        >
                            <MenuItem value='All'>
                                All
                            </MenuItem>
                            <MenuItem value='Published'>
                                Published
                            </MenuItem>
                            <MenuItem value='Unpublished'>
                                Unpublished
                            </MenuItem>
                        </TextField>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        style={styles.filterStyle}
                        select
                        label="Sort"
                        value={this.state.sortString}
                        onChange={this.handleSortChange}
                        fullWidth
                    >
                        <MenuItem value='publishedon-desc'>
                            Newest to Oldest by Published Date
                        </MenuItem>
                        <MenuItem value='publishedon-asc'>
                            Oldest to Newest by Published Date
                        </MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        );
    }

    applyFilterValues = (pageNumber: number) => {
        var newFilter: PodcastFilter = {
            searchString: this.state.searchString,
            sortBy: this.state.sortString.split('-')[0],
            sortDirection: this.state.sortString.split('-')[1],
            status: this.state.statusFilter,
            pageSize: this.props.filter.pageSize,
            pageNumber: pageNumber
        }

        this.props.getPodcasts(newFilter);
    }

    loadMorePodcasts = () => {
        var pageNumber = this.props.filter.pageNumber;
        if(this.props.morePodcastsToLoad && !this.props.loading && !this.state.isDrawerOpen)
        {
            this.applyFilterValues(pageNumber + 1);
        }
    }

    handleOnFilterSave = () => {
        this.applyFilterValues(0);
    }

    handleFilterReset = () => {
        this.setState({
            searchString: '',
            sortString: 'publishedon-desc',
            statusFilter: jwt_auth.hasPermission(P.PodcastCreate) ? 'All' : 'Published'
        });
    }

    handleStatusFilterChange = (event: any) => {
        this.setState({
            statusFilter: event.target.value
        })
    }
    handleSortChange = (event: any) => {
        this.setState({
            sortString: event.target.value
        })
    }
    handleSearchStringChange = (event: any) => {
        this.setState({
            searchString: event.target.value
        })
    }

    playPodcast = (podcast: Podcast) => {
        this.props.changePodcast(podcast);
    }

    renderPodcasts = () => {
        return this.props.podcasts
            .map((podcast) => <PodcastItem
                key={podcast.id}
                podcast={podcast}
                handlePlayPodcast={this.playPodcast}
				handleNavigateToPodcast={this.handleNavigateToPodcast}
            />);
    }

    handleNavigateToPodcast = (podcastId: string) => {
        this.props.navigateTo(navRoutes.podcastDetail.path.replace(Strings.Navigation.PodcastId, podcastId));
    }

    renderPodcastList = () => {
        let podcastListItems: JSX.Element[] = [];

        podcastListItems = podcastListItems.concat(
            this.renderPodcasts()
        );

        if (podcastListItems.length == 0 && !this.props.loading)
			return (
				<p style={{ textAlign: 'center' }}>
					There are no matches for your search...
				</p>
			);
		else
			return (
				<Paper elevation={0} square>
					<List style={{ padding: 0 }}>{podcastListItems}</List>
				</Paper>
			);
    }

    render() {
        return (
            <BasePageContainer
                topComponent={
                    <HeaderBarComponent
                        title="Podcasts"
                        customContent={this.renderFilterSummary()}
                        isLoading={this.props.loading || this.props.uploading}
                    />
                }
            >
                <div style={{height: '100%'}}>
                    <PersistentNotifications />
                    
                    <InfiniteScroller
                        loadMore={this.loadMorePodcasts}
                        hasMore={this.props.morePodcastsToLoad}
                        useWindow={false}
                    >
                        <DesktopPadding>
                            {this.renderPodcastList()}
                        </DesktopPadding>
                    </InfiniteScroller>
                </div>
                {this.renderFabMenu()}

            </BasePageContainer>
        )
    }
}

const mapStateToProps = (state: GlobalState): StateProps => ({
    loading: state.podcast.isLoading,
    podcasts: state.podcast.podcasts,
    morePodcastsToLoad: state.podcast.morePodcastsToLoad,
    filter: state.podcast.filter,
    uploading: state.podcast.isUploading
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps & Partial<NavigationProps> => ({
    navigateTo: (route: string) => dispatch(navigateTo(route)),
    getPodcasts: (filter: PodcastFilter) => dispatch(FilterPodcasts.started(filter)),
    changePodcast: (podcast: Podcast) => dispatch(ChangePodcast(podcast))
});

export const PodcastListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    true
)(PodcastList);