const InfiniteScroll = require('react-infinite-scroller');

import React, { useEffect } from 'react';
import { AppState } from '../../reducers';
import { connect } from '@hmkts/rise';
import { ClearScrollLocation } from '../../actions/navigation_actions';
import { Dispatch } from '@reduxjs/toolkit';
import { Paper } from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';
import { DesktopPadding } from '../Layout/desktop_padding';

const defaultLoader = (
	<DesktopPadding>
		<Paper elevation={0} style={{ padding: 15 }}>
			<ReactPlaceholder
				showLoadingAnimation
				type="text"
				rows={4}
				ready={false}
			>
				<div />
			</ReactPlaceholder>
		</Paper>
	</DesktopPadding>
);

interface ComponentProps {
	element?: string;
	hasMore?: boolean;
	initialLoad?: boolean;
	isReverse?: boolean;
	loadMore(page: number): void;
	pageStart?: number;
	threshold?: number;
	useCapture?: boolean;
	useWindow?: boolean;
	loader?: React.ReactElement<any>;
	children: React.ReactElement<any> | React.ReactElement<any>[];
}
interface StateProps {
	scrollLocation: string;
}
interface DispatchProps {
	clearScrollLocation: () => void;
}
type Props = ComponentProps & StateProps & DispatchProps;

const Scroller: React.FC<Props> = (props) => {

	const { scrollLocation, clearScrollLocation, ...infiniteScrollProps } = props;

	useEffect(() => scrollToElement());

	const scrollToElement = () => {
		const element = document.getElementById(scrollLocation);
		if (element) {
			element.scrollIntoView();
			clearScrollLocation();
		}
	};

	return (
		<div style={{ height: '100%', overflow: 'auto' }}>
			<InfiniteScroll loader={defaultLoader} {...infiniteScrollProps} />
			<div id="end-of-the-line" />
		</div>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	scrollLocation: state.navigation.scrollToOnBack,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	clearScrollLocation: () => dispatch(ClearScrollLocation()),
});
export const InfiniteScroller = connect(mapStateToProps, mapDispatchToProps, true)(
	Scroller
) as React.FunctionComponent<ComponentProps>;
