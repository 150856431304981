import { Contact } from '../../../reducers/ContactReducer';

import { connect } from '@hmkts/rise';
import React from 'react';
import {
    getPhoneEmailIconStyle,
    IconOptions,
    composePhoneMetaData,
} from '../../../utilities/phone_email_util';
import { Strings } from '../../../assets/common/strings';
import { SmsTemplate } from '../../../reducers/sms_template_reducer';
import { Dictionary } from '../../../utilities/object_util';
import { FilterComponents } from '../../../components/Layout/filter';
import { languageOptions } from '../../sms/sms_template_management_page';
import { RadioSelectDialog } from '../templates/radio_select_dialog';
import { SelectContactDialog } from '../select_contact_dialog';
import { SmsSequenceStep } from '../../../reducers/sequences/sms_sequence_reducer';
import {
    SendSmsPayload,
    CancelSmsSequence,
    SendSms,
    SelectContactForSmsSequence,
    StartSmsSequence,
    SendSmsToLeadListAtIndex,
    StoreSmsTemplate,
    ChangeSmsSequenceStep
} from '../../../actions/sequence/sms_sequence_actions';
import { Icon, IconButton } from '@material-ui/core';
import { themePalette } from '../../../utilities/branding';
import { ListSelectionAction } from '../templates/select_dialog';
import { NavigationProps } from '../../../components/nav/Routes';
import { Dispatch } from 'redux';
import { IncrementSelectedLeadIndex, ResetLeadListIndex, CheckLeadListIndex } from '../../../actions/lead_actions';
import { ProductStatus } from '../../../reducers/policy_reducer';

interface StateProps {
    step: SmsSequenceStep;
    agentSmsTemplates: { [subject: string]: SmsTemplate };
    corporateSmsTemplates: { [subject: string]: SmsTemplate };
    selectedContactId?: string;
    selectedPhoneNumber?: string;

    leadListMultiSelect: boolean;
    leadListIndex: number;
    selectedLeadContacts: Contact[][];
    selectedPhoneId?: string;
    hasActiveBusinessRelationship?: boolean;
}

interface DispatchProps {
    startSmsSequence: () => void;
    closeDialogs: () => void;
    selectContact: (contactId: string, contactPhoneNumber: string) => void;
    sendSmsTemplate: (smsTemplate: SendSmsPayload) => void;
    changeSmsSequenceStep: (step: SmsSequenceStep) => void;

    nextLead: () => void;
    skipLead: () => void;
    storeSmsTemplate: (template: SmsTemplate) => void;
    resetLeadListMultiSelect: () => void;
    checkLeadList: () => void;
}

interface InputProps {
    contacts: Contact[];
    iconOptions?: IconOptions;
    displayIcon?: boolean;
}


type Props = InputProps & StateProps & DispatchProps & NavigationProps;

class _SmsSequence extends React.Component<Props> {

    componentDidMount() {
        this.props.closeDialogs();
    }

    handleSelectContact = (selection: { id: string, value: string }[]) => {
        if (selection.length) {
            this.props.selectContact(selection[0].id, selection[0].value);
        } else {
            console.error("Failed to select contact");
        }
    }

    openMessageForContact = (selection: { [key: string]: { id: string, value: string } }) => {
        const { contacts } = this.props;

        const selectionArray = Object.keys(selection).map(k => ({ ...selection[k] }));

        if (selectionArray.length) {
            const contact = contacts.find(c => c.id == selectionArray[0].id);

            if (contact) {
                this.props.sendSmsTemplate({
                    contact,
                    phoneNumber: selectionArray[0].value,
                });
            }
        } else {
            console.error("Failed to select contact");
        }
    }

    handleSendSms = (smsTemplate: SmsTemplate) => {
        const { contacts, selectedContactId, selectedPhoneNumber, leadListMultiSelect } = this.props;

        if (leadListMultiSelect) {
            // Store the template and send to lead
            this.props.storeSmsTemplate(smsTemplate);
            this.props.nextLead();
        }
        else {
            const contact = contacts.find(c => c.id == selectedContactId);

            if (contact && selectedPhoneNumber) {
                const phone = contact.phones.find(p => p.number == selectedPhoneNumber);
                const phoneId = phone ? phone.id : ''

                this.props.sendSmsTemplate({
                    contact,
                    phoneNumber: selectedPhoneNumber || '',
                    smsTemplate,
                    phoneId
                });
            }
            else {
                console.error("Failed to send SMS");
            }
        }
    }

    skipLead = () => {
        this.props.skipLead();
        this.props.checkLeadList();
    };

    nextLead = (selection: { [key: string]: { id: string, value: string } }) => {
        const selected = Object.keys(selection).map(key => (selection[key]));

        this.props.selectContact(selected[0].id, selected.map(sel => sel.value).join(','));
        this.props.nextLead();
        this.skipLead();
    }

    smsFilter = {
        label: Strings.SmsSequence.TemplateLanguage,
        options: languageOptions,
        compareFn: (t: SmsTemplate, selection: Dictionary<string>) => !!selection["All"] || !!selection[t.language],
        component: FilterComponents.Dropdown,
        defaultSelection: { English: Strings.Languages.English }
    }

    secondarySelectionAction: ListSelectionAction = {
        style: {
            backgroundColor: themePalette.accept_button,
            color: themePalette.negative_text,
        },
        onClick: this.openMessageForContact,
        enforceSelection: true,
        label: 'Open Message',
        iconClass: 'icon-text-contact-button',
    };

    skipSelectionAction: ListSelectionAction = {
        style: {
            backgroundColor: themePalette.accept_button,
            color: themePalette.negative_text,
        },
        onClick: this.skipLead,
        label: 'Skip',
    };

    nextSelectionAction = (): ListSelectionAction => {
        const { leadListIndex, selectedLeadContacts } = this.props;
        const finalLead = leadListIndex == selectedLeadContacts.length - 1;
        return {
            style: {
                backgroundColor: themePalette.accept_button,
                color: themePalette.negative_text,
            },
            onClick: this.nextLead,
            enforceSelection: true,
            label: finalLead ? 'Finish' : 'Next',
        };
    };

    cancel = () => {
        const { leadListMultiSelect, step } = this.props;
        if (!leadListMultiSelect && step == SmsSequenceStep.SelectTemplate) {
            this.props.changeSmsSequenceStep(SmsSequenceStep.SelectContact);
        } else {
            this.props.resetLeadListMultiSelect();
            this.props.closeDialogs();
        }
    };

    renderDialog() {
        const {
            step,
            leadListMultiSelect,
            selectedLeadContacts,
            leadListIndex,
            hasActiveBusinessRelationship
        } = this.props;

        const secondaryActions = leadListMultiSelect
            ? [this.skipSelectionAction, this.nextSelectionAction()]
            : [this.secondarySelectionAction];

        const key = leadListIndex ? `sms-contact-selection-dialog-${leadListIndex}` : 'sms-contact-selection-dialog';
        switch (step) {

            case (SmsSequenceStep.SelectContact):
                return <SelectContactDialog
                    key={key}
                    activityType={Strings.Activity.Sms}
                    handleClose={this.cancel}
                    handleSelection={this.handleSelectContact}
                    contacts={leadListMultiSelect ? selectedLeadContacts[leadListIndex] : this.props.contacts}
                    secondaryActions={secondaryActions}
                    open
                    hasActiveBusinessRelationship={hasActiveBusinessRelationship}
                />

            case (SmsSequenceStep.SelectTemplate):
                return <RadioSelectDialog
                    key={'sms-template-selection-dialog'}
                    title="SMS Templates"
                    open
                    options={this.props.corporateSmsTemplates}
                    secondaryOptions={this.props.agentSmsTemplates}
                    handleCancel={this.cancel}
                    handleSelect={this.handleSendSms}
                    filters={[this.smsFilter]}
                    labelKey={"subject"}
                    saveText={"SEND"}
                />;

            default: return <div />
        }
    }
    render() {
        const {
            displayIcon,
            contacts,
            iconOptions,
            hasActiveBusinessRelationship
        } = this.props;

        return <>
            {this.renderDialog()}
            {displayIcon
                && <SmsSequenceIcon
                    contacts={contacts}
                    iconOptions={iconOptions}
                    hasActiveBusinessRelationship={hasActiveBusinessRelationship}
                />
            }
        </>

    }
}


function mapStateToProps(state: any, ownProps: Props): StateProps {
    
    const dictionary = state.smsTemplate.smsTemplates;
    const agentSmsTemplates: { [subject: string]: SmsTemplate } = {}
    const corporateSmsTemplates: { [subject: string]: SmsTemplate } = {}

    Object.keys(dictionary).forEach(id => {
        const template = dictionary[id];
        if (template.templateType == "Agent") {
            agentSmsTemplates[dictionary[id].subject] = dictionary[id]

        } else if (template.templateType == "Corporate") {
            corporateSmsTemplates[dictionary[id].subject] = dictionary[id]
        }
    });

    let contactIds = ownProps.contacts.map(c => c.householdId);
    if (contactIds.length === 0) {
        contactIds = state.smsSequence.phoneMetaData.phones.map(p => p.contactId)
    }

    const hasPolicies = state.policy.policies.some(p =>
        (p.data.manualPolicyStatus !== ProductStatus.Terminated ||
            (p.data.manualPolicyStatus == null && p.data.policyStatus !== ProductStatus.Terminated)) &&
        (p.data.primaryInsured !== undefined && p.data.primaryInsured !== null) && (
            contactIds.indexOf(p.data.primaryInsured.householdId) !== -1 ||
            contactIds.indexOf(p.data.primaryInsured.id) !== -1
        )
    );

    const hasApplications = state.application.applications.some(p =>
        p.data.applicationStatus !== ProductStatus.Terminated &&
        p.data.primaryInsured && (
            contactIds.indexOf(p.data.primaryInsured.householdId) !== -1 ||
            contactIds.indexOf(p.data.primaryInsured.id) !== -1
        )
    );

    return {
        selectedContactId: state.smsSequence.contactId,
        selectedPhoneNumber: state.smsSequence.contactPhoneNumber,
        step: state.smsSequence.step,
        agentSmsTemplates: agentSmsTemplates,
        corporateSmsTemplates: corporateSmsTemplates,
        leadListMultiSelect: state.smsSequence.leadListMultiSelect,
        leadListIndex: state.lead.selectedLeadIndex,
        selectedLeadContacts: state.lead.selectedLeadContacts,
        hasActiveBusinessRelationship: hasPolicies || hasApplications
    };
}

function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
    return {
        startSmsSequence: () => dispatch(StartSmsSequence(ownProps.contacts)),
        closeDialogs: () => dispatch(CancelSmsSequence()),
        sendSmsTemplate: (sms) => dispatch(SendSms(sms)),
        selectContact: (contactId: string, contactPhoneNumber: string) => dispatch(SelectContactForSmsSequence({ contactId, contactPhoneNumber })),
        changeSmsSequenceStep: (step: SmsSequenceStep) => dispatch(ChangeSmsSequenceStep(step)),
        nextLead: () => dispatch(SendSmsToLeadListAtIndex()),
        skipLead: () => dispatch(IncrementSelectedLeadIndex()),
        storeSmsTemplate: (template: SmsTemplate) => dispatch(StoreSmsTemplate(template)),
        resetLeadListMultiSelect: () => dispatch(ResetLeadListIndex()),
        checkLeadList: () => dispatch(CheckLeadListIndex()),
    };
}

export const SmsSequence = connect(mapStateToProps, mapDispatchToProps, true)(
    _SmsSequence
) as React.ComponentClass<InputProps>;


type SmsSequenceIconComponentProps = {
    contacts: Contact[];
    iconOptions?: IconOptions;
    hasActiveBusinessRelationship?: boolean;
}

type SmsSequenceIconDispatchProps = {
    onClick: () => void;
}

type SmsSequenceIconProps = SmsSequenceIconComponentProps & SmsSequenceIconDispatchProps;

const _SmsSequenceIcon: React.FC<SmsSequenceIconProps> = (props: SmsSequenceIconProps) => {
    const { iconOptions, onClick, hasActiveBusinessRelationship } = props;
    let meta = composePhoneMetaData(props.contacts, hasActiveBusinessRelationship);

    const iconClass = iconOptions && iconOptions.overrideIcon
        ? iconOptions.overrideIcon
        : meta.doNotContact || meta.doNotSms
            ? 'icon-text-dnc-button'
            : 'icon-text-contact-button'

    const disabled = meta.doNotSms || !meta.phones.length || (iconOptions && iconOptions.hidden);
    const iconStyle = getPhoneEmailIconStyle(iconOptions && iconOptions.style, disabled);

    const icon = iconOptions && iconOptions.overrideIcon
        ? <Icon style={iconStyle}>
            {iconOptions.overrideIcon}
        </Icon>
        : <Icon
            onClick={disabled ? undefined : onClick}
            className={iconClass}
            style={iconStyle}
        />;


    return (
        <div style={{ textAlign: 'center' }}>
            {(iconOptions && iconOptions.hidden)
                ? <span />
                : <IconButton disabled={disabled} style={iconStyle} onClick={disabled ? undefined : onClick}>{icon}</IconButton>
            }
        </div>
    );
}

function mapOnClick(dispatch: Dispatch, ownProps: SmsSequenceIconProps): SmsSequenceIconDispatchProps {
    return {
        onClick: () => dispatch(StartSmsSequence(ownProps.contacts))
    }
};

export const SmsSequenceIcon = connect(
    undefined,
    mapOnClick
)(_SmsSequenceIcon) as React.ComponentClass<SmsSequenceIconComponentProps>;
