import React from 'react';
import { shallowEqual } from 'react-redux';
import {
    List, ListItem, ListItemText, LinearProgress, makeStyles, Grid, Typography, Button, Icon
} from '@material-ui/core';
import { useSelector } from '@hmkts/rise';
import { Provider, ProviderDropdownListItem } from './';
import { Strings } from '../../../../../assets/common/strings';

interface Props {
    isLoading: boolean;
    addedProviders: Provider[];
    searchRadius: number;
    handleClearSearch: () => void;
    handleAdd: (provider: Provider) => void;
}

export const ProviderDropdown: React.FC<Props> = ({ isLoading, addedProviders, searchRadius, handleClearSearch, handleAdd }) => {
    const classes = useStyles();

    const searchResults = useSelector((state: any) => {
        const results = state?.FormBuilder?.activeForm?.providerInfo?.searchResults?.providers || [];
        return results;
    }, shallowEqual);

    const matchingSearchResults = searchResults.filter(result => result.distance < searchRadius);

    const checkAgainstProviderList = (provider: Provider) => {
        return addedProviders.some(item => item.provider.npi === provider.provider.npi)
    }

    const titleText = isLoading
        ? Strings.FactFinderProviderLookup.searchingForProvider
        : matchingSearchResults.length === 0
            ? Strings.FactFinderProviderLookup.noDoctorFound
            : `${matchingSearchResults.length} results found`;

    return (
        <>
            <LinearProgress hidden={!isLoading}/>
            <List className={classes.list} style={{paddingTop: 0, paddingBottom: 0}}>
                <ListItem
                    key={'list-title'}
                    className={classes.title}
                >
                    <Grid container alignItems="center" >
                        <Grid item xs={6}>
                            <ListItemText secondary={titleText} />
                        </Grid>
                        <Grid item xs={6} container justify="flex-end" >
                            {!isLoading && (
                                <Button
                                    size="small"
                                    endIcon={<Icon>close</Icon>}
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={handleClearSearch}
                                >
                                    <Typography variant="caption" color="textSecondary">
                                        Clear Results
                                    </Typography>
                                </Button>)}
                        </Grid>
                    </Grid>
                </ListItem>
                {matchingSearchResults.map(provider => (
                    <ProviderDropdownListItem
                            key={provider.provider.npi}
                            provider={provider}
                            isSelected={checkAgainstProviderList(provider)}
                            handleAddProvider={handleAdd}
                    />))}
            </List>
        </>)
};

const useStyles = makeStyles({
    list: {
        maxHeight: 400 ,
        overflow: 'auto' ,
        borderWidth: '1px 1px 0px 1px' ,
        borderStyle: 'solid' ,
        borderColor: '#dcdedf' ,
    },
    title: {
        paddingTop: 0,
        paddingBottom: 0,
        fontStyle: 'italic' ,
        borderBottom: '1px solid #dcdedf',
    },
});
