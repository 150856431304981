import React, { useEffect, useMemo } from 'react';
import { compose, Dispatch } from 'redux';
import { connect, Paper } from '@hmkts/rise';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import {
	notificationCategories,
	NotificationSummary,
} from '../../components/notifications/notificationSummary';
import { ToolItem } from '../../components/Tools/ToolItem';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { GetTools } from '../../actions/tools_actions';
import { Tool } from '../../reducers/tools_reducer';
import { themeLinks } from '../../utilities/branding';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import LoadingList from '../../components/utility/loading_list';
import { AppState } from '../../reducers';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { isConnectureDRXAllowed } from '../../utilities/agent_util';
import { Agent } from '../../reducers/agent_reducer';
import { ConnectureToolItemComponent } from '../../components/Tools/ConnectureToolItem';


interface ComponentProps {
	id: string;
	name: string;
	description: string | null;
	learnMore: string | null;
	url: string;
	isSSO: boolean;
}
interface DispatchProps {
	getTools: () => void;
}
interface StateProps {
	tools: Tool[];
	agent: Agent;
}
type Props = ComponentProps & StateProps & DispatchProps;

const ToolsPage: React.FC<Props> = (props) => {

	const { tools, agent, getTools } = props;

	useEffect(() => {
		getTools()
	}, []);

	const isConnectureAllowed = isConnectureDRXAllowed(agent?.fieldOfficeInfo?.FieldOfficeName, '', agent.agentCode);
	const connectureDRXCard = isConnectureAllowed ? (
		<ConnectureToolItemComponent />
	) : null;

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Sales Tools"
					rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkTools)]} />}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.tools} />
			<DesktopPadding>
				<Paper elevation={0}>
					{connectureDRXCard}
				</Paper>
				{
					tools.length
						? (
							<Paper elevation={0}>
{}
								{tools.map((tool: Tool, index: number) => (<ToolItem key={index} {...tool} />))}
								
							</Paper>
						)
						: <LoadingList />
				}
			</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	tools: state.tools.tools,
	agent: state.agent,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getTools: () => dispatch(GetTools.started()),
});

export const ToolsPageContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true)
)(ToolsPage);
