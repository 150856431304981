import React from 'react';
import { enforceStylesType } from '../../../utilities/styles_util';
import { TableCell, TableRow, Card, CardContent, Table, TableHead, Tooltip, TableSortLabel, TableBody, TableFooter, TablePagination } from '@material-ui/core';
import { Strings } from '../../../assets/common/strings';
import { Dispatch } from 'redux';

import { connect } from '@hmkts/rise';
import { IdObject, IdDictionary, desc, stableSort } from '../../../utilities/object_util';
import { FilterFieldProps } from '../filter';
import { FilterDrawer, FilterFacet, } from '../filter/filter_drawer';
import { MoreMenu, createMenuAction } from '../../nav/more_menu';
import { themePalette } from '../../../utilities/branding';
import { UpdateTable } from '../../../actions/table_actions';

type RowsPerPageType = 5 | 10 | 15 | 25 | 50 | 100;

export interface PagingOptions {
	readonly page: number;
	readonly count: number;
	readonly rowsPerPage: RowsPerPageType;
	readonly rowsPerPageOptions: RowsPerPageType[];
};

export const defaultPagingOptions: PagingOptions = ({
	page: 0,
	count: 0,
	rowsPerPage: 25,
	rowsPerPageOptions: [5, 10, 15, 25, 50, 100],
});

export interface SortOptions<T extends IdObject> {
	order: Strings.SortOrder;
	orderBy: keyof T;
	selection: IdDictionary<T>;
	selectable: number;
};

export const defaultSortOptions = (defaultOrderBy: string): SortOptions<Row> => ({
	order: Strings.SortOrder.Ascending,
	orderBy: defaultOrderBy,
	selection: {},
	selectable: 0,
});

export interface Row extends IdObject { };

export type ColumnOption = {
	field?: keyof Row;
	label?: string;
	render: (row: Row) => React.ReactElement<{}>;
}

export type StateProps = {
	paging: PagingOptions;
	options: SortOptions<Row>;
	filterSelection: { [filterLabel: string]: FilterFacet };
	tableId: string;
};

type DispatchProps = {
	updateTable: (payload: Partial<StateProps>) => void;
}

export type ComponentProps<T extends IdObject> = {
	rows: T[];
	columns: ColumnOption[];
	filterProps: FilterFieldProps[];
	onClick?: (item?: Row) => void;
	selectedRow?: Row;
	rowActions?: {
		[label: string]: {
			action: (row: Row) => void,
			isDisabled: (row: Row) => boolean
		}
	}
}

export const renderCell = (children?: React.ReactChild) => <TableCell>{children}</TableCell>

type State = {
	anchorEl?: HTMLElement;
}

type Props<T extends IdObject> = ComponentProps<T>
	& StateProps
	& DispatchProps;

class _FilterTableList<T extends IdObject> extends React.Component<Props<T>, State> {

	componentDidMount() {
		const {
			paging,
			options,
			rows,
		} = this.props;

		this.props.updateTable({
			paging: {
				...paging,
				count: rows.length
			},
			options: options,
		});
	}

	getPageStart = (): number => {
		const { page, rowsPerPage } = this.props.paging;
		return page * rowsPerPage;
	};

	getSorting = () => {
		const { order, orderBy } = this.props.options;
		return order === 'desc' ? (a: T, b: T) => desc(a, b, orderBy) : (a: T, b: T) => -desc(a, b, orderBy);
	}

	handleResetPaging = () => this.props.updateTable({
		paging: {
			...this.props.paging,
			page: 0,
			count: this.props.rows.length,
		}
	});

	onRowClick = (obj: Row) => (event: React.MouseEvent<any>) => {
		const { onClick } = this.props;

		event.stopPropagation();
		if (onClick) {
			onClick(obj);
		}
	}

	onColumnClick = (col: ColumnOption) => () => {
		if (!!col.field) {
			const {
				order,
				orderBy
			} = this.props.options;

			if (orderBy != col.field) {
				this.handleChangeSort(col.field, order);

			} else {
				const newOrder = order == Strings.SortOrder.Ascending
					? Strings.SortOrder.Descending
					: Strings.SortOrder.Ascending;

				this.handleChangeSort(orderBy, newOrder);
			}

			this.handlePageChange(null, 0);
		}
	};

	handlePageChange = (Event: React.MouseEvent<HTMLButtonElement> | null, page: number) => this.props.updateTable({
		paging: {
			...this.props.paging,
			page: page,
		}
	});

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.props.updateTable({
		paging: {
			...this.props.paging,
			rowsPerPage: Number(event.target.value) as RowsPerPageType,
		}
	});

	handleChangeSort = (facet: keyof T, direction: Strings.SortOrder) => {
		const { options } = this.props;

		this.props.updateTable({

			options: {
				...options,
				orderBy: String(facet),
				order: direction,
			}
		})
	};

	renderMoreMenu = (row: Row) => {
		const { rowActions } = this.props;
		if (!rowActions)
			return;
		else {
			const actions = Object.keys(rowActions).map(label =>
				createMenuAction(
					label,
					() => rowActions[label].action(row),
					rowActions[label].isDisabled(row)
				)
			);

			return <MoreMenu
				key={"moremenu-" + row.id}
				children={actions}
				color={themePalette.default_avatar}
			/>
		};
	};

	render() {
		const {
			paging,
			filterProps,
			rows,
			columns,
			filterSelection,
			selectedRow,
			options = defaultSortOptions("Title")
		} = this.props;

		const filterArr = Object.keys(filterSelection).map(f => filterSelection && filterSelection[f] ? filterSelection[f].filterFunction : () => true);
		const filteredRows = rows.filter(row => filterArr.every(fn => fn(row)));

		const sortedRows = stableSort(filteredRows, this.getSorting())
		const pageStart = this.getPageStart();

		const {
			order,
			orderBy
		} = options;

		return (
			<div>
				<Card style={styles.Card}>
					<FilterDrawer
						key="filter-table-drawer"
						rows={rows}
						filters={filterProps}
						headerStyle={{}}
					/>
					<CardContent key={"filter-table-content"} style={{ overflowX: 'auto' }} >
						<Table>
							<TableHead key="connected-table-list-head">
								<TableRow>
									{columns.map(col => {
										return (
											<TableCell
												key={col.label + "_key"}
												sortDirection={order}
											>
												<Tooltip
													title={"Sort"}
													placement={"bottom-start"}
													enterDelay={300}
												>
													<TableSortLabel
														active={orderBy === col.field}
														direction={order}
														onClick={this.onColumnClick(col)}
													>
														{col.label}
													</TableSortLabel>
												</Tooltip>
											</TableCell>
										);
									})}
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody key="connected-table-list-body" style={{ overflowX: 'auto', }}>
								{sortedRows.slice(pageStart, pageStart + paging.rowsPerPage).map((row) => {
									return (
										<TableRow
											hover
											key={row.id}
											onClick={this.onRowClick(row)}
											style={styles.TableRow}
											selected={row == selectedRow}
										>
											{columns && columns.map(col => row && col.render(row))}
											<TableCell>
												{this.props.rowActions && this.renderMoreMenu(row)}
											</TableCell>

										</TableRow>
									);
								})}
							</TableBody>
							<TableFooter key="connected-table-list-footer">
								<TableRow>
									<TablePagination
										{...paging}
										count={filteredRows.length}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										onChangePage={this.handlePageChange}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</CardContent>
				</Card>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps: Props<Row>): StateProps => {
	
	const { tables, filterDrawers } = state;
	const url = window.location.href;


	const filterSelection: { [filterLabel: string]: FilterFacet } = filterDrawers[url]
		? filterDrawers[url].appliedFilters
		: {};

	const table = tables[url]
		? tables[url]
		: {
			paging: defaultPagingOptions,
			options: defaultSortOptions("Title"),
			tableId: url,
		};

	return {
		...table,
		paging: {
			...table.paging,
			count: ownProps.rows.length,
		},
		filterSelection,
	};
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	updateTable: (payload) => dispatch(UpdateTable({
		tableId: window.location.href,
		tableState: payload,
	})),
});

export const FilterTableList = connect<StateProps, DispatchProps, ComponentProps<Row>>(
	mapStateToProps,
	mapDispatchToProps,
	true
)(_FilterTableList);

const styles = enforceStylesType({
	Card: { marginBottom: 140 },
	CardContent: { overflowX: "auto" },
	TableRow: { cursor: "pointer" }
});
