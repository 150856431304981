import React from 'react';
import _ from 'lodash';
import { connect } from '@hmkts/rise';
import { getFormValues } from 'redux-form';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import {
	navigateTo,
	navigateBack,
	navigateToWithoutAddingToHistory,
} from '../../actions/navigation_actions';
import { Lead } from '../../reducers/LeadReducer';
import { GetLead, createLead, UpdateLead } from '../../actions/lead_actions';
import {
	UpsertLeadEmployerForm,
	FormData,
	FORM_NAME,
} from './upsert_lead_employer_form';
import { Employer } from '../../reducers/employer_reducer';
import uuid from 'uuid';
import moment from 'moment';
import { Contact, Phone, Email } from '../../reducers/ContactReducer';
import { GetEmployer } from '../../actions/employer_actions';
import {
	objToArray,
	arrayToObj,
} from '../../utilities/formatting/data_normalizations';
import { Loaded } from '../../utilities/utilities';
import { Policy } from '../../reducers/policy_reducer';
import { GetPolicy, UpdatePolicy } from '../../actions/policy_actions';
import { normalizeMoneyNumeric } from '../../utilities/formatting/string_normalization';
import { ContactDedupeDto } from '../../reducers/dedupe_reducer';
import { ContactDedupeDialog } from '../../components/contact/contact_dedupe_dialog';
import { ContactDedupeCheck } from '../../actions/dedupe_actions';
import { Strings } from '../../assets/common/strings';

interface StateProps {
	formValues: FormData;
	syncErrors?: any;
	anyTouched: boolean;
	lead?: Lead;
	userId: string;
	impersonatingId: string;
	employer?: Loaded<Employer>;
	policy?: Policy;
	isLoading: boolean;
}

interface DispatchProps {
	getEmployer: (id: string) => void;
	getLead(id: string): void;
	createLead: (lead: Lead, policy?: Policy) => void;
	updateLead(lead: Lead): void;
	getPolicy: (id: string) => void;
	updatePolicy: (policy: Policy) => void;
	dedupeCheck: (dto: ContactDedupeDto) => void;
}

type Props = StateProps & DispatchProps & NavigationProps;

interface State {
	mode?: string;
	matchLeadToPolicy: boolean;
	lead?: Lead;
}

class UpsertLeadEmployer extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);

		this.state = {
			matchLeadToPolicy: false,
		};
	}

	componentWillMount() {
		const leadID = this.props.match.params.leadID;
		const employerID = this.props.match.params.employerID;
		const policyID = this.props.match.params.policyID;

		if (policyID && policyID !== '0') {
			this.props.getPolicy(policyID);
			this.setState({
				matchLeadToPolicy: true,
			});
		}

		if (!this.props.lead && leadID != '0') this.props.getLead(leadID);
		if (employerID != '0' && !this.props.employer)
			this.props.getEmployer(employerID);

		if (leadID == '0' && employerID != '0') {
			this.setState({
				mode: 'newLeadExistingEmployer',
			});
		} else if (leadID != '0' && employerID != '0') {
			this.setState({
				mode: 'editLead',
			});
		} else {
			this.setState({
				mode: 'newLeadNewEmployer',
			});
		}
	}

	onSave = () => {
		if (this.state.mode == 'newLeadExistingEmployer') {
			let employer = this.props.employer!.data as Employer;

			let contact = employer.contacts.filter(contact => {
				return _.get(contact, 'employerPrimaryContact');
			})[0] as Contact;

			let leadToAdd: Partial<Lead> = {
				id: uuid.v4(),
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				status: Strings.Status.NotContacted,
				vendor: this.props.formValues.vendor,
				inputSource: this.props.formValues.inputSource,
				leadType: this.props.formValues.leadType,
				leadCost: Number(normalizeMoneyNumeric(this.props.formValues.leadCost)),
				linesOfBusiness: objToArray(this.props.formValues.linesOfBusiness),
				employer: this.props.employer!.data,
				campaign: this.props.formValues.campaign,
				contacts: [contact],
				opportunityRatingCode: this.props.formValues.rating,
			};

			this.props.createLead(leadToAdd as Lead);
			this.props.navigateBack();
		} else if (this.state.mode == 'editLead') {
			let leadToEdit: Lead = Object.assign({}, this.props.lead);
			leadToEdit.campaign = this.props.formValues.campaign;
			leadToEdit.leadCost = Number(
				normalizeMoneyNumeric(this.props.formValues.leadCost.toString())
			);
			leadToEdit.vendor = this.props.formValues.vendor;
			leadToEdit.opportunityRatingCode = this.props.formValues.rating;
			leadToEdit.linesOfBusiness = objToArray(
				this.props.formValues.linesOfBusiness
			);

			this.props.updateLead(leadToEdit);
			this.props.navigateBack();
		} else {
			let emails = this.props.formValues.emails;
			let phones = this.props.formValues.phones;
			const validNewEmail =
				(this.props.formValues.email || '').length > 0 &&
				!_.get(this.props.syncErrors, 'email', false);
			const validNewPhone =
				(this.props.formValues.phone || '').length > 0 &&
				!_.get(this.props.syncErrors, 'phone', false);
			const validNewEntry = validNewEmail || validNewPhone;

			if (validNewEntry) {
				if (validNewEmail) {
					emails = (this.props.formValues.emails || []).slice();
					let email: Email = {
						emailAddress: this.props.formValues.email as string,
						type: Strings.PhoneTypes.Home,
						isPreferred: true,
						dateCreated: moment().toDate(),
						doNotEmail: false,
					};
					if (emails.length) {
						email.isPreferred = false;
					}
					emails.push(email);
				}
				if (validNewPhone) {
					phones = (this.props.formValues.phones || []).slice();
					let phone: Phone = {
						id: uuid.v4(),
						number: this.props.formValues.phone as string,
						type: Strings.PhoneTypes.Home,
						isPreferred: true,
						isCompanyDnc: false,
						wasCompanyDncSet: false,
						isNationalDnc: false,
						wasRpmPhoneNumberChanged: false,
						extension: '',
						dateCreated: moment().toDate(),
						isSmsDnc: false
					};
					if (phones.length) {
						// not the first phone
						phone.isPreferred = false;
					}
					phones.push(phone);
				}
			}

			const employerId = uuid.v4();
			let contact: Partial<Contact> = {
				wasRpmNameChanged: this.props.formValues.wasRpmNameChanged || false,
				firstName: this.props.formValues.firstName,
				lastName: this.props.formValues.lastName,
				birthDate: this.props.formValues.DOB
					? moment(this.props.formValues.DOB).toDate()
					: undefined,
				id: uuid.v4(),
				householdId: uuid.v4(),
				householdRole: 0,
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				middleName: this.props.formValues.middleName,
				isSelfEmployed: this.props.formValues.isSelfEmployed,
				weightLbs: this.props.formValues.weight,
				annualIncome: Number(
					normalizeMoneyNumeric(this.props.formValues.annualIncome)
				),
				heightIn: this.props.formValues.heightIn,
				heightFt: this.props.formValues.heightFt,
				isStudent: this.props.formValues.isStudent,
				preferredName: this.props.formValues.preferredName,
				suffix: this.props.formValues.suffix,
				languagePreference: this.props.formValues.languagePreference,
				isTobaccoUser: this.props.formValues.isTobaccoUser,
				status: this.props.formValues.status,
				salutation: this.props.formValues.salutation,
				occupation: this.props.formValues.occupation,
				jobTitle: this.props.formValues.jobTitle,
				gender: this.props.formValues.gender,
				addresses: this.props.formValues.addresses,
				emails: emails,
				phones: phones,
				familyStatusCode: this.props.formValues.maritalStatus,
				groupEligibilityStatus: this.props.formValues.groupEligibilityStatus,
				groupEnrollmentStatus: this.props.formValues.groupEnrollmentStatus,
				employerPrimaryContact: true,
				employeeStatus: this.props.formValues.employeeStatus,
				employerId: employerId,
				lastFourSsn: this.props.formValues.lastFourSsn,
				clientType: this.props.formValues.clientType,
				lastUnemploymentYear: this.props.formValues.lastUnemploymentYear,
			};

			let newEmployer: Partial<Employer> = {
				id: employerId,
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				contacts: [contact as Contact],
				owningUser: this.props.userId,
				industry: this.props.formValues.industry,
				companyName: this.props.formValues.companyName,
				website: this.props.formValues.companyWebsite,
				isAncillariesOffered: this.props.formValues.isAncillariesOffered,
				annualRevenue: Number(
					normalizeMoneyNumeric(this.props.formValues.annualRevenue)
				),
				numberOfEmployees: this.props.formValues.numberEmployees,
				numberOfPartTime: this.props.formValues.numberPartTime,
				numberOfGroupEligibles: this.props.formValues.numberGroupEligibles,
				numberOfGroupParticipants: this.props.formValues
					.numberGroupParticipants,
				currentCarrier: this.props.formValues.currentCarrier,
				employerContributionAmount: Number(
					normalizeMoneyNumeric(
						this.props.formValues.employerContributionAmount
					)
				),
				employerContributionBaseAmount: Number(
					normalizeMoneyNumeric(
						this.props.formValues.employerContributionAmountBase
					)
				),
				groupCoverageAdvantage: this.props.formValues.groupCoverageAdvantage,
				isAge65OrAboveAllowed: this.props.formValues.isEmployeesAge65Above,
				isEmployerContributesToGroupCoverage: this.props.formValues
					.isEmployerContributesGroupCoverage,
				isFamilyCoverageOffered: this.props.formValues.isFamilyCoverageOffered,
				isGroupPlanOffered: this.props.formValues.isGroupPlanOffered,
				createdOn: moment().toDate(),
				createdBy: this.props.userId,
			};

			let leadId = uuid.v4();

			let leadToSend: Partial<Lead> = {
				id: leadId,
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				status: Strings.Status.NotContacted,
				vendor: this.props.formValues.vendor,
				inputSource: this.props.formValues.inputSource,
				leadType: this.props.formValues.leadType,
				leadCost: Number(normalizeMoneyNumeric(this.props.formValues.leadCost)),
				linesOfBusiness: objToArray(this.props.formValues.linesOfBusiness),
				employer: newEmployer as Employer,
				contacts: [contact as Contact],
				opportunityRatingCode: this.props.formValues.rating,
				campaign: this.props.formValues.campaign,
			};

			if (this.state.matchLeadToPolicy && this.props.policy) {
				let updatedPolicy = this.props.policy;
				updatedPolicy.employerId = employerId;
				this.setState(
					{ lead: leadToSend as Lead },
					() => this.dedupeCheck(leadToSend as Lead, () => this.props.navigateBack())
				);
			} else {
				this.setState(
					{ lead: leadToSend as Lead },
					() => this.dedupeCheck(leadToSend as Lead)
				);
			}
		}
	};

	dedupeCheck = (lead: Lead, callback?: () => void) => {
		const contact = lead.employer.contacts[0];
		const dto = {
			phones: contact.phones ? contact.phones.map(phone => phone.number.replace(/\D/g, '')) : [],
			emails: contact.emails ? contact.emails.map(email => email.emailAddress) : [],
			callback
		}
		this.props.dedupeCheck(dto);
	}

	createInitalValues() {
		let initialValues = {
			rating: 'Warm',
			linesOfBusiness: { Employer: true },
			inputSource: 'Manual',
		} as Partial<FormData>;
		if (this.state.mode == 'editLead' && this.props.lead) {
			initialValues = {
				campaign: this.props.lead.campaign,
				leadType: this.props.lead.leadType,
				leadCost: `${this.props.lead.leadCost}`,
				vendor: this.props.lead.vendor,
				rating: this.props.lead.opportunityRatingCode,
				linesOfBusiness: arrayToObj(this.props.lead.linesOfBusiness),
				inputSource: this.props.lead.inputSource,
			};
		}
		return initialValues;
	}

	render() {
		let title = `${
			Number(this.props.match.params.leadID) ? 'Edit' : 'New'
			} Lead`;

		if (this.state.mode == 'newLeadExistingEmployer') {
			title = 'Add Lead';
		}

		const { emails, phones } = this.props.formValues || {
			emails: [],
			phones: [],
			email: '',
			phone: '',
		};
		const contactInfoCheck =
			(emails || []).length + (phones || []).length > 0 ||
			this.state.mode != 'newLeadNewEmployer';

		const topComponent = (
			<SaveCancelHeaderBarComponent
				title={title}
				subtitle={'Employer'}
				onSave={this.onSave}
				isSaveDisabled={
					!this.props.anyTouched ||
					!!this.props.syncErrors ||
					!contactInfoCheck ||
					this.props.isLoading
				}
				isLoading={this.props.isLoading}
				history={this.props.history}
			/>
		);

		return (
			<BasePageContainer
				topComponent={topComponent}
			>
				<ContactDedupeDialog
					lead={this.state.lead}
					policy={this.props.policy}
					history={this.props.history}
				/>
				<UpsertLeadEmployerForm
					mode={this.state.mode}
					initialValues={this.createInitalValues()}
					synchronErrors={this.props.syncErrors}
				/>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state,
	ownProps: Props & StateProps
): StateProps {
	const leadIndex = state.lead.leads.findIndex(loadedLead =>
		ownProps.match.params.leadID &&
		loadedLead.data.id == ownProps.match.params.leadID
	);
	const loadedLead = leadIndex == -1 ? undefined : state.lead.leads[leadIndex];
	const policyIndex = state.policy.policies.findIndex(
		loadedPolicy =>
			ownProps.match.params.policyID &&
			loadedPolicy.data.id == ownProps.match.params.policyID
	);
	const policy =
		policyIndex === -1 ? undefined : state.policy.policies[policyIndex].data;

	return {
		lead: loadedLead ? loadedLead.data : undefined,
		isLoading: state.lead.isLoading || state.dedupe.loading,
		policy,
		formValues: getFormValues(FORM_NAME)(state),
		syncErrors: state?.form?.[FORM_NAME]?.syncErrors,
		anyTouched: _.get(state, `form.${FORM_NAME}.anyTouched`, false),
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		employer: state.employer.employers.find(
			employer =>
				employer.data.id === ownProps.match.params.employerID &&
				employer.errors.length === 0
		),
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: Props
): DispatchProps & Partial<NavigationProps> {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateBack: () => dispatch(navigateBack()),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		getLead: (id: string) => dispatch(GetLead.started(id)),
		createLead: (lead: Lead, policy?: Policy) =>
			dispatch(createLead(lead, policy, ownProps.history)),
		updateLead: (lead: Lead) => dispatch(UpdateLead.started(lead)),
		getEmployer: (id: string) => {
			dispatch(GetEmployer.started(id));
		},
		getPolicy: (policyId: string) => dispatch(GetPolicy.started(policyId)),
		updatePolicy: (policy: Policy) => dispatch(UpdatePolicy.started(policy)),
		dedupeCheck: (dto: ContactDedupeDto) => dispatch(ContactDedupeCheck.started(dto))
	};
}

export const UpsertLeadEmployerContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(UpsertLeadEmployer) as React.ComponentClass<Props>;
