import { Select, Box, makeStyles, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import { AgentLicense, AgentState, LicenseForm, AgentAppointmentCount } from '../../reducers/license_appointment_reducer';
import { getAgentAppointmentCountByState, getAgentLicenses, getAgentStates, getResidentState } from '../../selectors/license_appointment_selectors';
import { US_STATES, US_State } from '../../utilities/utilities';
import { SelectListOptions } from '../utility/select_list_options';
import { GetAgentAppointmentCountByState, GetAgentLicensesByState } from '../../actions/license_appointment_actions';
import { LicenseStatusInformation } from './license_status_information';
import { AppointmentCountInformation } from './appointment_count_information';

const useStyles = makeStyles(theme => ({
	control: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'left',
	},
	label: {
		padding: '0 1em 0 0',
	},
	dropdown: {
		width: '50%',
	},
}));

interface StateProps {
	residentState: string;
	agentStates: AgentState[];
	agentLicenses: AgentLicense[];
}

interface DispatchProps {
	getAgentLicensesByState: (params: LicenseForm) => void
	getAgentAppointmentsCountByState: (params: string) => void
}

type Props = StateProps & DispatchProps;

const _LicenseAppointmentTile: React.FC<Props> = (props) => {

	const classes = useStyles();
	const { residentState, agentStates, getAgentLicensesByState, getAgentAppointmentsCountByState } = props;
	const [selectedState, setState] = useState(residentState);

	useEffect(() => {
		changeSelectedState(residentState);
	}, [residentState]);

	const options = US_STATES.filter((state: US_State) => {
		return agentStates.findIndex(
			(agentState: AgentState) => {
				return agentState.State == state.abbreviation;
			}
		) >= 0;
	});

	const handleStateChange = (event: any) => {
		const state = event.target.value;
		changeSelectedState(state);
	};

	const changeSelectedState = (state: string) => {
		setState(state);
		// to create an object of Type LicenseForm
		const params = {
			state: state,
			status: "",
			lineOfAuhtority: ""
		}
		getAgentLicensesByState(params);
		getAgentAppointmentsCountByState(state);
	}

	return (
		<Box>
			<Box className={classes.control}>
				<InputLabel
					className={classes.label}
					children={Strings.Address.State + ':'}
				/>
				<Select
					className={classes.dropdown}
					value={selectedState}
					onChange={handleStateChange}
					children={SelectListOptions({
						options: options.map(
							(state: US_State) => {
								return {
									option: state.name,
									value: state.abbreviation
								};
							}
						),
					})}
				/>
			</Box>
			<LicenseStatusInformation />
			<AppointmentCountInformation />
		</Box>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	residentState: getResidentState(state),
	agentStates: getAgentStates(state),
	agentLicenses: getAgentLicenses(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentLicensesByState: (params: LicenseForm) => dispatch(GetAgentLicensesByState.started(params)),
	getAgentAppointmentsCountByState: (params: string) => dispatch(GetAgentAppointmentCountByState.started(params))
});

export const LicenseAppointmentTile = connect(mapStateToProps, mapDispatchToProps, true)(_LicenseAppointmentTile);
