import { AppBar, IconButton, LinearProgress, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { connect } from '@hmkts/rise';
import { setDrawerStatus } from '../../actions/access_page_actions';
import { AppState } from '../../reducers/index';
import { themePalette } from '../../utilities/branding';
import { nav } from '../..';
import { Dispatch } from '@reduxjs/toolkit';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHeaderBarStyles } from '../../utilities/hooks/styles';
import classnames from 'classnames';

interface ComponentProps {
	title?: string | JSX.Element;
	subtitle?: string | JSX.Element;
	customContent?: JSX.Element;
	forceOverflow?: boolean;
	hideBackButton?: boolean;
	hideSideMenuButton?: boolean;
	isLoading?: boolean;
	rightButtons?: React.ReactNode;
	titleStyle?: React.CSSProperties;
	hideTopRow?: boolean;
}
interface StateProps {
	isDesktop: boolean;
	clickToCallEnabled: boolean;
}
interface DispatchProps {
	openDrawer: () => void;
}
type Props = ComponentProps & StateProps & DispatchProps;

const HeaderBar: React.FC<Props> = (props) => {

	const { 
		hideBackButton, hideSideMenuButton, isDesktop, hideTopRow, 
		clickToCallEnabled, customContent, isLoading, title, subtitle,
		rightButtons, forceOverflow, titleStyle
	} = props;

	const headerStyles = useHeaderBarStyles();

	const hideBack = hideBackButton || nav.history?.length === 1 || clickToCallEnabled;
	const hideHamburger = hideSideMenuButton || clickToCallEnabled || isDesktop;
	const overflowStyle = !forceOverflow
		? { overflow: 'hidden', textOverflow: 'ellipsis' }
		: {};

	return (
		<AppBar position="sticky" className={classnames(headerStyles.depth, headerStyles.top)}>
			{!hideTopRow && (
				<Toolbar>
					{!hideBack && <IconButton edge="start" color="inherit" aria-label="back" onClick={nav.goBack}>
						<ChevronLeftIcon />
					</IconButton>}
					{!hideHamburger && <IconButton edge="start" color="inherit" aria-label="menu" onClick={props.openDrawer}>
						<MenuIcon />
					</IconButton>}
					<Typography variant="h6" style={{ flexGrow: 1, ...titleStyle, ...overflowStyle }}>
						{title}
						{subtitle}
					</Typography>
					{rightButtons}
				</Toolbar>
			)}
			{customContent}
			{isLoading && <LinearProgress />}
		</AppBar>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	isDesktop: state.layout.desktop,
	clickToCallEnabled: state.clickToCall.enabled,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	openDrawer: () => dispatch(setDrawerStatus(true)),
});
export const HeaderBarComponent = connect(mapStateToProps, mapDispatchToProps, true)(
	HeaderBar
) as React.FunctionComponent<ComponentProps>;

export const iconButtonStyles: { [key: string]: React.CSSProperties } = {
	icon: {
		width: 25,
		height: 25,
		color: themePalette.negative_icon,
	},
	button: {
		marginTop: 15,
		width: 35,
		height: 35,
		padding: 0,
		float: 'left',
		verticalAlign: 'middle',
	},
};
