import React, { useEffect, useState, useRef } from 'react';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { ConnectureSAMLResponseParams, ConnectureSSOredirect } from '../../actions/agent_actions';
import { SetSideMenuBarState } from '../../actions/layout_actions';
import {PageLoader} from '../../components/utility/page_loader';
import { useParams } from 'react-router-dom';
import { isMobileDevice } from '../../utilities/is_mobile';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { EmptyMessage } from '../../components/utility/empty_message';


interface StateProps {
    samlResponseBase64: string
    agentCode: string;
}

interface DispatchProps {
    getConnectureSaml: (payload: ConnectureSAMLResponseParams) => string;
    setSideMenu: (visible: boolean) => void;
}

type Props = DispatchProps & StateProps
const ConnecturePage: React.FC<Props> = (props) => {
    const { samlResponseBase64, agentCode, getConnectureSaml, setSideMenu } = props;

    const { contactID } = useParams();
    const [loading, setLoading] = useState(true);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [saml, setSaml] = useState("");
    const formRef = useRef(document.createElement('form'));

    useEffect(() => {
        setSideMenu(false);       
        if (agentCode != "") {
            const SSOredirectParams = getSSOredirectParams(contactID, agentCode);
            getConnectureSaml(SSOredirectParams);
        }
        return () => setSideMenu(true);
    }, [agentCode]);

    useEffect(() => {
        if (samlResponseBase64 != "") {
            let decodedResp = atob(samlResponseBase64)
            let resplist = decodedResp?.split("|");
            if (resplist.length >= 0) {
                setRedirectUrl(resplist[0]);
                setSaml(resplist[1]);
            }
        }
    }, [samlResponseBase64]);

    useEffect(() => {
        if (saml != "" && redirectUrl != "") {
            formRef.current.submit();
            getConnectureSaml({ params: {}, userId: "", year: "" })
            setLoading(false);
        }
    }, [redirectUrl, saml]);

    return (
        <>
            <PageLoader open={loading}/>
            <form method="post" ref={formRef} action={redirectUrl}>
                <input name="SAMLResponse" type="hidden" value={saml} />
            </form>
            {
                isMobileDevice && !loading && (
                    <BasePageContainer
                        topComponent={
                            <HeaderBarComponent
                                title={'Connecture Redirect'}
                                hideSideMenuButton
                            />
                        }
			        >
                        <EmptyMessage text={`You were redirected to Connecture.  Use the back arrow to use ${document.title}.`} />
                    </BasePageContainer>
                )
            }
        </>
    );

}

const mapStateToProps = (state: AppState): StateProps => ({
    samlResponseBase64: state.agent.connectureSamlResponse,
    agentCode: state.agent.agentCode
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    getConnectureSaml: (samlResponse: ConnectureSAMLResponseParams) =>
        dispatch(ConnectureSSOredirect.started(samlResponse)),
    setSideMenu: (isVisible: boolean) =>  
    dispatch(SetSideMenuBarState({isVisible})),
});

export const ConnecturePageComponent = connect(
    mapStateToProps,
    mapDispatchToProps, true
)(ConnecturePage);


const getSSOredirectParams = (contactId: string, agentCode: string) => {
    return {
        userId: agentCode,
        // TODO: remove year altogether from connecture (on API side as well)
        year: '2024',
        params:
        {
            ssovalue: contactId,
        }
    } as ConnectureSAMLResponseParams;
}