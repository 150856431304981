import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Icon, Fab } from '@material-ui/core';
import { WidgetSlice } from '@hmkts/rise';
import { themePalette } from '../../utilities/branding';
import { connect } from '@hmkts/rise';
import { isDesktop, isTablet } from '../../utilities/is_mobile';
import { Contact } from '../../reducers/ContactReducer';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import ReactDOM from 'react-dom';

const fabDiv = document.createElement('div');
fabDiv.id = 'fabmenu';
document.body.appendChild(fabDiv);

export interface FabMenuItem {
	disabled: boolean;
	label: string;
	icon: string | JSX.Element;
	onClick: (id?: string) => void;
}
interface Props {
	items?: FabMenuItem[];
	placeholderPropsIds?: string[];
	onClick?: () => void;
	disabled?: boolean;
	footerPadding?: number;
	contacts?: Contact[];
	inDialog?: boolean;
	additionalHeight?: number;
}
export const FabMenu: React.FC<Props> = (props) => {

	const { items = [], placeholderPropsIds = [], contacts = [], inDialog = false, additionalHeight = 0 } = props;
	const [expanded, setExpanded] = useState(false);
	const widgetProps = useSelector((app) => WidgetSlice.selectState(app).widgetProps);

	let fabItems = items;
	if (!isBrokerage) {
		if (placeholderPropsIds && placeholderPropsIds.length && contacts.length > 0) {
			const factFinderWidget = widgetProps && widgetProps[placeholderPropsIds[0]]?.value;
			const factFinderWidgetSenior = widgetProps && widgetProps[placeholderPropsIds[1]]?.value;

			if (factFinderWidget && factFinderWidgetSenior) {

				const factFinderFab: FabMenuItem = {
					icon: factFinderWidget.icon,
					disabled: !(isTablet() || isDesktop()) || !jwt_auth.hasPermission(P.FactFinder),
					label: factFinderWidget.label,
					onClick: () => factFinderWidget.onClick(contacts),
				}

				const factFinderFabSenior: FabMenuItem = {
					icon: factFinderWidgetSenior.icon,
					disabled: !(isTablet() || isDesktop()) || !jwt_auth.hasPermission(P.FactFinder),
					label: factFinderWidgetSenior.label,
					onClick: () => factFinderWidgetSenior.onClick(contacts),
				}

				fabItems = [...[factFinderFab, factFinderFabSenior], ...items];
			}
		}
	}

	const getItemStyle = (index: number) => ({
		position: 'absolute',
		bottom: (32 + additionalHeight) + (fabItems.length - index) * 48,
		right: 28,
		zIndex: expanded ? 8 : -1,
		transform: 'translateZ(10px)'
	} as React.CSSProperties);

	const backgroundColor = props.disabled
		? themePalette.disabled_fab
		: themePalette.fab_menu_button;

	const fab = (
		<footer>
			<Fab
				disabled={props.disabled}
				style={{
					position: 'fixed',
					bottom: (20 + additionalHeight),
					right: 20,
					backgroundColor,
					color: themePalette.negative_text,
					zIndex: 8,
				}}
				onClick={() => {
					props.onClick?.();
					setExpanded(!expanded);
				}}
			>
				<Icon>{expanded ? 'clear' : 'add'}</Icon>
			</Fab>
			{fabItems.map((item, index) => (
				<div
					key={index}
					style={getItemStyle(index)}
					className={
						expanded
							? 'fab-child fab-child-open'
							: 'fab-child fab-child-closed'
					}
				>
					<Paper
						style={{
							margin: '5px 15px 0 0',
							width: 'auto',
							padding: 0,
							cursor: item.disabled ? undefined : 'pointer',
						}}
						onClick={() => {
							if (!item.disabled) {
								setExpanded(false);
								item.onClick?.();
							}
						}}
						elevation={3}
						component="span"
					>
						<span
							style={{
								padding: 5,
								backgroundColor: item.disabled
									? themePalette.disabled_fab
									: themePalette.fab_menu_button,
								color: themePalette.negative_text,
								borderRadius: 5,
							}}
						>
							{item.label}
						</span>
					</Paper>
					<Fab
						onClick={() => {
							if (!item.disabled) {
								setExpanded(false);
								if (item.onClick)
									item.onClick();
							}
						}}
						size="small"
						disabled={item.disabled}
						style={{
							backgroundColor: item.disabled
								? themePalette.disabled_fab
								: themePalette.fab_menu_button,
							color: themePalette.negative_text,
						}}
					>
						{typeof item.icon === "string"
							? <Icon>{item.icon}</Icon>
							: item.icon
						}
					</Fab>
				</div>
			))}
		</footer>
	);

	return inDialog
		? fab
		: ReactDOM.createPortal(fab, fabDiv);
}
