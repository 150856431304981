import { GridSize, Grid, ListItem, Typography,ListItemIcon } from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { StoreScrollLocation } from '../../actions/navigation_actions';
import { themePalette } from '../../utilities/branding';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export type SubtitleProps = { value: React.ReactNode; label?: string };
const Subtitle = (props: SubtitleProps) => {
	const isDiv = typeof props.value != 'string';
	return isDiv ? (
		props.value
	) : (
		<Typography variant="caption" display="block">
			{props.label ? (
				<span style={{color: themePalette.primary_text}}>{props.label + ' '}</span>
			) : null}
			{props.value}
		</Typography>
	);
};

interface InputProps {
	header: string | JSX.Element;
	id: string;
	subtitles?: SubtitleProps[];
	rightSubtitles?: SubtitleProps[];
	leftActionElement?: any;
	rightActionElement?: any;
	onClick?: (event: any) => void;
	style?: React.CSSProperties;
	leftColumnSize?: number;
	rightColumnSize?: number;
	primaryColumnSize?: number;
	secondaryColumnSize?: number;
	skipStoreScrollLocation?: boolean;
	disabled?: boolean;
}
interface StateProps {}
interface DispatchProps {
	storeScrollLocation: (location: string) => void;
}

export type CustomListItemProps = InputProps & DispatchProps & StateProps;

class _CustomListItem extends React.PureComponent<CustomListItemProps> {
	styles: { [key: string]: React.CSSProperties } = {
		centered: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		leftSubtitles: {
			textAlign: 'left',
			fontSize: '13px',
			color: themePalette.tertiary_text,
		},
		rightSubtitles: {
			textAlign: 'right',
			fontSize: '13px',
			color: themePalette.tertiary_text,
		},
	};

	composeActionColumn = (size: number, actionIcon?: any) => {
		if (size != 0) {
			return (
				<Grid
					item
					xs={size as GridSize}
					sm={size as GridSize}
					style={this.styles.centered}
				>
					{actionIcon ? actionIcon : null}
				</Grid>
			);
		}
	};

	onClick = (event: any) => {
		const { onClick, storeScrollLocation, skipStoreScrollLocation } = this.props;
		event.stopPropagation();
		if (!skipStoreScrollLocation) {
			storeScrollLocation(this.props.id);
		}
		if (onClick) {
			onClick(event);
		}
	};

	composeContentColumn = (columnProps: {
		gridSize: number;
		header?: string | JSX.Element;
		subtitles?: SubtitleProps[];
		style: React.CSSProperties;
	}) => {
		if (columnProps.gridSize != 0) {
			return (
				<Grid
					item
					xs={columnProps.gridSize as GridSize}
					sm={columnProps.gridSize as GridSize}
					onClick={this.onClick}
					style={{ cursor: 'pointer' }}
				>
					{columnProps.header ? (
						<Typography variant="body1">{columnProps.header}</Typography>
					) : null}
					{columnProps.subtitles ? (
						<div style={columnProps.style}>
							{columnProps.subtitles.map((subtitle, index) => (
								<Subtitle
									key={
										'custom-list-item-' +
										this.props.id +
										'-' +
										subtitle.value +
										index
									}
									{...subtitle}
								/>
							))}
						</div>
					) : null}
				</Grid>
			);
		}
	};

	render() {
		const leftColumnSize =
			this.props.leftColumnSize || this.props.leftColumnSize == 0
				? this.props.leftColumnSize
				: 1;
		const rightColumnSize =
			this.props.rightColumnSize || this.props.rightColumnSize == 0
				? this.props.rightColumnSize
				: 1;
		const primaryColumnSize =
			this.props.primaryColumnSize || this.props.primaryColumnSize == 0
				? this.props.primaryColumnSize
				: 6;
		const secondaryColumnSize =
			this.props.secondaryColumnSize || this.props.secondaryColumnSize == 0
				? this.props.secondaryColumnSize
				: 4;

		return (
			<ListItem
				id={this.props.id}
				style={{ paddingLeft: leftColumnSize ? 6 : undefined, ...this.props.style }}
				divider
				button
				disabled={this.props.disabled}
			>
				<Grid container>
					{this.composeActionColumn(
						leftColumnSize,
						this.props.leftActionElement
					)}
					{this.composeContentColumn({
						gridSize: primaryColumnSize,
						header: this.props.header,
						subtitles: this.props.subtitles,
						style: this.styles.leftSubtitles,
					})}
					{this.composeContentColumn({
						gridSize: secondaryColumnSize,
						subtitles: this.props.rightSubtitles,
						style: this.styles.rightSubtitles,
					})}
					{this.composeActionColumn(
						rightColumnSize,
						this.props.rightActionElement
					)}
				</Grid>
				
			</ListItem>
		);
	}
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	storeScrollLocation: (location: string) => dispatch(StoreScrollLocation(location)),
});
export const CustomListItem = connect(
	undefined, 
	mapDispatchToProps, 
	true
)(_CustomListItem) as React.ComponentClass<InputProps>;