import React from 'react';
import { connect, Dispatch } from '@hmkts/rise';
import { reset } from 'redux-form';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
	Icon,
} from '@material-ui/core';
import { AppState } from '../../reducers/index';
import { Lookup, Lookups, LookupDictionary } from '../../utilities/lookup';
import { LeadSearchResult } from '../../reducers/lead_search_reducer';
import { LeadSearchFilters } from '../../actions/lead_search_actions';
import { FORM_NAME } from './filter/lead_search_filter_form';
import { LeadSearchTableRow } from '../../components/lead_search/lead_search_table_row';
import { LeadUpdate } from '../../reducers/LeadReducer';

type StateProps = {
	lookups: LookupDictionary;
	results: LeadSearchResult[];
	currentPage: number;
	totalResults: number;
	loading: boolean;
	initialized: boolean;
};

interface DispatchProps {
	resetFilter: () => void;
}

type ComponentProps = {
	requestPage: (nextPage: number, filterOverride?: LeadSearchFilters) => void;
	saveLead: (leadId: string, leadUpdate: LeadUpdate) => void;
};
type Props = DispatchProps & StateProps & ComponentProps;

type State = {
	pageNumber: number;
};

class LeadSearchTableComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			pageNumber: 0,
		};
	}

	componentDidMount() {
		if (!this.props.initialized) {
			this.props.requestPage(0, {
				leadStatusReason: [Lookups.NotContacted.value],
			});
			this.props.resetFilter();
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.props.results !== nextProps.results) {
			if (nextProps.currentPage === 0) {
				this.setState({ pageNumber: 0 });
			}
		}
	}

	editIcon = <Icon style={styles.icon}>mode_edit</Icon>;

	openInNewTabIcon = <Icon style={styles.icon}>open_in_new</Icon>;

	rowsPerPageOptions = [];

	handlePageChange = (_event: any, pageRequested: number) => {
		this.setState({ pageNumber: pageRequested });
		if (pageRequested > this.props.currentPage) {
			this.props.requestPage(pageRequested);
		}
	};

	saveLeadUpdate = (leadId: string) => (leadUpdate: LeadUpdate) => {
		this.props.saveLead(leadId, leadUpdate);
	}

	render() {
		const {
			lookups
		} = this.props;

		const statusLookups = lookups
			.getChildren(Lookups.LeadStatus, Lookups.NoSale);

		const noSaleReasonLookups = lookups
			.getChildren([
				Lookups.DispositionReason,
				Lookups.NoSale,
			]);

		return (
			<div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name {this.openInNewTabIcon}</TableCell>
							<TableCell>Created on Date</TableCell>
							<TableCell>Address</TableCell>
							<TableCell>Primary Phone</TableCell>
							<TableCell>Primary Email</TableCell>
							<TableCell>Line of Business</TableCell>
							<TableCell>Status {this.editIcon}</TableCell>
							<TableCell>No Sale Reason {this.editIcon}</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{!this.props.loading &&
							this.props.results
								.slice(
									this.state.pageNumber * 20,
									this.state.pageNumber * 20 + 20
								)
								.map(result => (
									<LeadSearchTableRow 
										key={result.leadId}
										lead={result} 
										statusLookups={statusLookups}
										noSaleReasonLookups={noSaleReasonLookups}
										saveLeadUpdate={this.saveLeadUpdate(result.leadId)}
									/>
								))
						}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={this.props.totalResults}
					rowsPerPage={20}
					rowsPerPageOptions={this.rowsPerPageOptions}
					page={this.state.pageNumber}
					onChangePage={this.handlePageChange}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any): StateProps {
	
	return {
		lookups: state.lookup,
		results: state.leadSearch.results,
		totalResults: state.leadSearch.totalResults,
		currentPage: state.leadSearch.currentPage,
		loading: state.leadSearch.loading,
		initialized: state.leadSearch.initialized,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		resetFilter: () => dispatch(reset(FORM_NAME)),
	};
}

export const LeadSearchTable = connect(mapStateToProps, mapDispatchToProps, true)(
	LeadSearchTableComponent
) as React.ComponentClass<ComponentProps>;

const styles: { [key: string]: React.CSSProperties } = {
	icon: { 
		fontSize: 12 
	},
};
