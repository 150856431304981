import React from 'react';
import { Drawer, Grid, Card, CardHeader, Avatar, Button, IconButton, Icon } from '@material-ui/core';
import { Action as ReduxAction, Dispatch } from 'redux';
import actionCreatorFactory, { isType } from 'typescript-fsa';
import { DropdownFilter } from './drowdown_filter';
import { Row } from '../table/filter_table';
import { connect } from '@hmkts/rise';

/**
 * Actions
 */
const actionCreator = actionCreatorFactory('FilterDrawer');

type UpdateFilterDrawer = {
    filterId: string;
    filterState: StateProps;
};

const UpdateFilterDrawer = actionCreator<UpdateFilterDrawer>('UpdateFilterDrawer');

/**
 * Reducer
 */
export type FilterDrawerState = {
    [filterId: string]: StateProps;
};

export function filterDrawerReducer(
    state: FilterDrawerState = {},
    action: ReduxAction
): FilterDrawerState {
    if (isType(action, UpdateFilterDrawer)) {
        const { filterId, filterState } = action.payload;
        const oldstate = state[filterId] || {};
        return {
            ...state,
            [action.payload.filterId]: {
                ...oldstate,
                ...filterState,
            },
        };
    } else {
        return state;
    }
}

/**
 * Selectors
 */
import { createSelector } from 'reselect';

import { Filterfn, FilterFieldProps, FilterComponents } from '.';
import { Dictionary } from 'lodash';
import { SaveCancelHeaderBarComponent } from '../../Layout/SaveCancelHeaderBar';
import { themePalette } from '../../../utilities/branding';
import { BaseFilterForm } from '../../search/base_filter_form';
import { isDesktop } from '../../../utilities/is_mobile';
import { AppState } from '../../../reducers';

export const getFilterDrawerState = (state: AppState) => state.filterDrawers || {};

const getFilterDrawerId = () => window.location.href;

// Keep any to trigger init
const isFilterDrawerInitialized = (state: AppState) => !!state.filterDrawers[window.location.href];

/**
 * Selector to trigger prop change
 * @param state
 * @param ownProps
 */
export const propChange = (state: AppState, ownProps: any) => !!ownProps;

export const filterDrawreSelector = createSelector(
    [getFilterDrawerState, isFilterDrawerInitialized, getFilterDrawerId, propChange],
    (filterDrawerState, isInitialized, filterId): StateProps => isInitialized
        ? filterDrawerState[filterId]
        : {
            appliedFilters: {},
            isOpen: false,
        }
);

export type FilterFacet = {
    filterFunction: Filterfn;
    selection: Dictionary<any>;
    hintText: string;
};


type ComponentProps = {
    title?: string;
    rows: Row[];
    filters: FilterFieldProps[];
    headerStyle?: React.CSSProperties;
};

type StateProps = {
    appliedFilters: { [filterLabel: string]: FilterFacet };
    isOpen: boolean;
};

type DispatchProps = {
    updateFilterDrawer: (payload: StateProps) => void;
};

export type FilterDrawerProps = ComponentProps & StateProps & DispatchProps;

interface State {
    hintText: string;
    filterSelection: { [filterLabel: string]: FilterFacet };
};

class _FilterDrawer extends React.Component<FilterDrawerProps, State> {

    constructor(props: FilterDrawerProps) {
        super(props);

        this.state = {
            hintText: "",
            filterSelection: props.appliedFilters,
        };
    };

    componentDidMount() {
        this.setDefaultSelection(false, this.handleApplyFilters);
    }

    setDefaultSelection = (reset: boolean, callback?: () => void) => {
        const { appliedFilters, filters } = this.props;
        const filterSelection: { [filterLabel: string]: FilterFacet } = Object.assign({}, appliedFilters);

        filters.forEach(f => {
            const facet = appliedFilters && appliedFilters[f.label] && !reset
                ? appliedFilters[f.label]
                : {
                    filterFunction: () => true,
                    selection: f.defaultSelection || {},
                    hintText: '',
                }

            filterSelection[f.label] = facet;
        });
        this.setState({ filterSelection }, callback);
    }

    composeHintText = (filterSelection: { [filterLabel: string]: FilterFacet }): string => {
        const { filters } = this.props;
        return filters.map(f => filterSelection && filterSelection[f.label] ? filterSelection[f.label].hintText : '').filter(Boolean).join(", ");
    }

    handleUpdateFilterFacet = (label: string, facet: FilterFacet) => {
        const { filterSelection } = this.state;

        this.setState({
            filterSelection: {
                ...filterSelection,
                [label]: facet
            },
        })
    };

    handleToggle = (setOpen: boolean) => () => this.props.updateFilterDrawer({
        appliedFilters: this.props.appliedFilters,
        isOpen: setOpen,
    });

    handleApplyFilters = () => {
        const { filterSelection } = this.state;

        this.props.updateFilterDrawer({
            isOpen: false,
            appliedFilters: filterSelection,
        });
    }

    handleReset = () => this.setDefaultSelection(true);

    renderFilterComponent = (opts: FilterFieldProps) => {
        const { isOpen } = this.props;
        const { filterSelection } = this.state;

        const selection = filterSelection[opts.label] && filterSelection[opts.label].selection
            ? filterSelection[opts.label].selection
            : opts.defaultSelection || {};

        const props = {
            key: opts.label,
            filterIsOpen: isOpen,
            updateFilterFacet: this.handleUpdateFilterFacet,
            initialSelection: selection,
            ...opts,
        }

        switch (opts.component) {

            case (FilterComponents.Dropdown):
                return <DropdownFilter {...props} />

            case (FilterComponents.MultiselectDropdown):
                return <DropdownFilter {...props} multiselect />
        }
    }

    renderDrawer() {
        const styles = getStyles();
        const {
            isOpen,
            title = "Search",
            filters,
        } = this.props;

        return (
            <Drawer
                anchor="right"
                open={isOpen}
                onClose={this.handleToggle(false)}
                PaperProps={{ style: styles.drawer }}
            >
                <SaveCancelHeaderBarComponent
                    title={title}
                    style={this.props.headerStyle}
                    onSave={this.handleApplyFilters}
                    saveText={title}
                    onCancel={this.handleToggle(false)}
                    bypassCancelConfirmation
                />

                <Grid container>
                    <Grid item xs={12}>
                        <Card
                            key={'FILTER-HEADER'}
                            raised={false}
                            style={styles.card}
                        >
                            <CardHeader
                                avatar={<Avatar src={require('../../../assets/filter_icon.png')} />}
                                title={title}
                                subheader={this.composeHintText(this.state.filterSelection)}
                                action={
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.handleReset}
                                        style={styles.button}
                                        size={'small'}
                                    >
                                        <div style={{ color: themePalette.negative_text }}>Clear</div>
                                    </Button>
                                }
                            />
                        </Card>
                    </Grid>
                </Grid>
                <div style={styles.form}>
                    <BaseFilterForm
                        submit={this.handleApplyFilters}
                        hideOverflow
                    >
                        <Grid container spacing={2}>
                            {filters.map(filter => this.renderFilterComponent(filter))}
                        </Grid>
                    </BaseFilterForm>

                </div>
            </Drawer>)
    };

    render() {
        const styles = getStyles();

        const {
            title = "Search"
        } = this.props;

        return (
            <Card
                key={'FILTER-CARD'}
                raised={false}
                style={styles.card}
            >
                <CardHeader
                    avatar={<Avatar src={require('../../../assets/filter_icon.png')} />}
                    style={styles.pointer}
                    title={title}
                    subheader={this.composeHintText(this.props.appliedFilters)}
                    onClick={this.handleToggle(true)}
                    action={
                        <IconButton
                            style={styles.iconButton}
                            onClick={this.handleToggle(true)}
                        >
                            <Icon>tune</Icon>
                        </IconButton>
                    }
                />
                {this.renderDrawer()}
            </Card >
        );
    }
}

const mapStateToProps = (state, ownProps: FilterDrawerProps): StateProps => {
    
    return filterDrawreSelector(state, ownProps);
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    updateFilterDrawer: (payload) => dispatch(UpdateFilterDrawer({ filterId: window.location.href, filterState: payload })),
});


export const FilterDrawer = connect(
    mapStateToProps,
    mapDispatchToProps,
    true,
)(_FilterDrawer) as React.ComponentClass<ComponentProps>;

const getStyles = (): { [key: string]: React.CSSProperties } => ({
    iconButton: {
        marginTop: '10px',
        marginRight: '10px',
    },
    card: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: 0,
        paddingBottom: '2%',
    },
    pointer: {
        cursor: 'pointer'
    },
    button: {
        marginTop: '5px',
        marginRight: '10px',
    },
    drawer: {
        width: isDesktop() ? '500px' : '100%'
    },
    form: {
        padding: '0 20px',
        marginTop: 20,
    }
});
