import React from 'react';
import H from 'history';
import moment from 'moment';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes, getNavRouteByKey } from '../../components/nav/Routes';
import { compose } from 'redux';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { GraphDataFilter } from '../../components/utility/graph_data_filter';
import {
	FinanceParams,
	GetFinanceBalances,
	GetFinanceCash,
	GetFinanceInvestments,
	UpdateFinanceDates,
	GetAllFinanceData,
	UpdateFinanceFilter,
	SetFinanceDownlineAgent
} from '../../actions/finance_actions';
import ChartCard, { ChartCardProps } from '../../components/utility/chart_card';
import {
	GraphDataFilterValues,
	ChartGroup,
} from '../../reducers/performance_reducer';
import { DownlineAgent } from '../../reducers/product_reducer';
import { navigateTo } from '../../actions/navigation_actions';
import { AccountHealthTooltip } from '../../components/Finance/AccountHealthTooltip';
import { Grid } from '@material-ui/core';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { themeLinks } from '../../utilities/branding';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { Strings as S } from '../../assets/common/strings'
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

const QUARTER_DATES_NUM = 90;

interface Props {}

interface StateProps {
	filter: GraphDataFilterValues;
	downlineAgent: DownlineAgent;
	cashCharts: ChartGroup;
	balanceCharts: ChartGroup;
	investmentCharts: ChartGroup;
}
interface DispatchProps {
	getAllFinanceData: (params: FinanceParams) => any;
	getCashCharts: (params: FinanceParams) => any;
	getBalanceCharts: (params: FinanceParams) => any;
	getInvestmentCharts: (params: FinanceParams) => any;
	updateSearchFilter: (filter: string) => any;
	updateSearchDates: (params: FinanceParams) => any;
	navigateTo(route: string): void;
	setDownlineAgent: (agent?: DownlineAgent) => void;
}

interface FinancePageState {}

type FinancePageProps = Props &
	StateProps &
	DispatchProps &
	NavigationProps;

class FinancePage extends React.Component<
	FinancePageProps,
	FinancePageState
> {
	title = 'Finance';
	scrollRef: HTMLDivElement;

	componentDidMount() {
		this.applyFilters();
	}

	componentDidUpdate(prevProps: FinancePageProps) {
		if (
			prevProps.downlineAgent.agentCode != this.props.downlineAgent.agentCode
		) {
			this.applyFilters();
		}
		if (this.props.location.pathname !== navRoutes.finance.path) {
			this.scrollRef.scrollTop = 0;
		}
	}

	applyFilters = (
		formValues?: GraphDataFilterValues,
		formErrors?: any | undefined
	) => {
		if (!!formErrors && Object.keys(formErrors).length > 0) {
			return;
		}
		if (!formValues) {
			formValues = this.props.filter;
		}
		let startDate: moment.Moment | Date = moment(),
			endDate: moment.Moment | Date = moment(),
			updateDates = false,
			dateRangeOverride = 0;
		switch (formValues.dateRange) {
			case S.DateSelector.Last7Days: {
				startDate = startDate.subtract(6, 'days');
				break;
			}
			case S.DateSelector.Last30Days: {
				startDate = startDate.subtract(29, 'days');
				break;
			}
			case S.DateSelector.Last90Days: {
				startDate = startDate.subtract(89, 'days');
				break;
			}
			case S.DateSelector.MonthToDate: {
				startDate = startDate.startOf('month');
				break;
			}
			case S.DateSelector.QuarterToDate: {
				startDate = startDate.startOf('quarter');
				break;
			}
			case S.DateSelector.YearToDate: {
				startDate = startDate.startOf('year');
				break;
			}
			case S.DateSelector.LastMonth: {
				const lastMonth = startDate.subtract(1, 'month');
				startDate = lastMonth.clone().startOf('month');
				endDate = lastMonth.clone().endOf('month');
				break;
			}
			case S.DateSelector.LastQuarter: {
				const lastQuarter = startDate.subtract(1, 'quarter');
				startDate = lastQuarter.clone().startOf('quarter');
				endDate = lastQuarter.clone().endOf('quarter');
				dateRangeOverride = QUARTER_DATES_NUM;
				break;
			}
			case S.DateSelector.LastYear: {
				const lastYear = startDate.subtract(1, 'year');
				startDate = lastYear.clone().startOf('year');
				endDate = lastYear.clone().endOf('year');
				break;
			}
			default: {
				startDate = formValues.startDate;
				endDate = formValues.endDate;
				updateDates = true;
			}
		}
		const { downlineAgent } = this.props;
		const agentCode = downlineAgent.agentCode;
		const params: FinanceParams = {
			agentCode,
			startDate: moment.isMoment(startDate) ? startDate.toDate() : startDate,
			endDate: moment.isMoment(endDate) ? endDate.toDate() : endDate,
		};
		if (dateRangeOverride > 0) {
			params.dateRangeOverride = dateRangeOverride;
		}
		if (updateDates) {
			this.props.updateSearchDates(params);
		}
		this.props.updateSearchFilter(formValues.dateRange);

		switch (location.pathname) {
			case navRoutes.financeCash.path:
				return this.props.getCashCharts(params);
			case navRoutes.financeBalances.path:
				return this.props.getBalanceCharts(params);
			case navRoutes.financeInvestments.path:
				return this.props.getInvestmentCharts(params);
			default:
				return this.props.getAllFinanceData(params);
		}
	};

	buildCharts = (): ChartCardProps[] => {
		const {
			cashCharts,
			balanceCharts,
			investmentCharts,
			location,
		} = this.props;
		const financeCards: { [key: string]: ChartCardProps } = {
			financeCash: {
				color: 'primary',
				titleLeft: 'Cash',
				charts: cashCharts.charts || [],
				titleRight: cashCharts.summaryValue, 
				clickDisabled: cashCharts.summaryValue ? false : true,
				detailData: cashCharts.detail || []
			},
			financeInvestments: {
				color: 'secondary',
				titleLeft: 'Investments',
				charts: investmentCharts.charts || [],
				titleRight: investmentCharts.summaryValue,
				clickDisabled: investmentCharts.summaryValue ? false : true,
				detailData: investmentCharts.detail || []
			},
			financeBalances: {
				color: 'tertiary',
				titleLeft: 'Balances',
				charts: balanceCharts.charts || [],
				titleRight: balanceCharts.summaryValue,
				clickDisabled: balanceCharts.summaryValue ? false : true,
				detailData: balanceCharts.detail || [],
				tooltip: <AccountHealthTooltip />
			},
		};
		const getChartProps = (key: string, details?: boolean) => ({
			...financeCards[key],
			onClick: !details ? this.viewDetails(key) : undefined,
			style: { marginBottom: 20 },
			details,
		});
		switch (location.pathname) {
			case navRoutes.financeCash.path:
				return [getChartProps('financeCash', true)];
			case navRoutes.financeBalances.path:
				return [getChartProps('financeBalances', true)];
			case navRoutes.financeInvestments.path:
				return [getChartProps('financeInvestments', true)];
			default:
				return Object.keys(financeCards).map(key =>
					getChartProps(key, false)
				);
		}
	};

	viewDetails = (route: string) => () => {
		this.props.navigateTo( getNavRouteByKey(route).path);
	};

	render() {
		const { filter } = this.props;
		const allCharts: ChartCardProps[] = this.buildCharts();

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={allCharts.length > 1 ? this.title : allCharts[0].titleLeft}
						rightButtons={
							<MoreMenu
								children={[createHelpAction(themeLinks.helpLinkFinance)]}
							/>
						}
						customContent={
							<GraphDataFilter
								filterValues={filter}
								applyFilters={this.applyFilters}
								title={"Finance"}
								downlineAgent={this.props.downlineAgent}
								setDownlineAgent={this.props.setDownlineAgent}
							/>
						}
					/>
				}
				middleRef={ref => (this.scrollRef = ref)}
			>
				<PersistentNotifications />
				<NotificationSummary categorySelector={notificationCategories.finances}/>
				<DesktopPadding>
					<div style={styles.middleComponent}>
						{allCharts.map((chart, index) => (
							<ChartCard key={`chart-${index}`} {...chart}/>
						))}
					</div>
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

export const styles: { [key: string]: React.CSSProperties } = {
	middleComponent: {
		margin: 10,
	},
	root: {
		position: 'absolute',
		width: '100%',
	},
	column: {},
	noUnderline: {
		textDecoration: 'none',
		color: 'black',
	},
	lookUpAgentButton: {
		paddingLeft: 20,
	},
	card: {
		height: 260,
	},
	cardStyle: {
		marginBottom: 20,
		marginRight: 10,
		marginLeft: 10,
	},
	chipStyle: {
		marginBottom: 5,
	},
};

const mapStateToProps = (state: any): StateProps => {
	
	return {
		filter: state.finance.filter,
		downlineAgent: state.finance.downlineAgent,
		cashCharts: state.finance.cashCharts,
		balanceCharts: state.finance.balanceCharts,
		investmentCharts: state.finance.investmentCharts,
	};
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	getAllFinanceData: (params: FinanceParams) =>
		dispatch(GetAllFinanceData.started(params)),
	getCashCharts: (params: FinanceParams) =>
		dispatch(GetFinanceCash.started(params)),
	getBalanceCharts: (params: FinanceParams) =>
		dispatch(GetFinanceBalances.started(params)),
	getInvestmentCharts: (params: FinanceParams) =>
		dispatch(GetFinanceInvestments.started(params)),
	updateSearchFilter: (filter: string) =>
		dispatch(UpdateFinanceFilter({ filter })),
	updateSearchDates: (params: FinanceParams) =>
		dispatch(UpdateFinanceDates({ params })),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	setDownlineAgent: (agent?: DownlineAgent) =>
		dispatch(SetFinanceDownlineAgent({ agent })),
});

export const FinanceContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true)
)(FinancePage);

