import React from 'react';
import _ from 'lodash';
import H from 'history';
import { NavigationProps } from '../../components/nav/Routes';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import uuid from 'uuid';
import {
	ApplicationUpsertFormComponent,
	ApplicationUpsertFormValues,
} from '../../components/ApplicationUpsert/ApplicationUpsertForm';
import { getFormValues } from 'redux-form';
import { change } from 'redux-form';
import moment from 'moment';
import {
	Application,
	initialApplication,
} from '../../reducers/application_reducer';
import {
	CreateApplication,
	GetApplication,
	UpdateApplication,
} from '../../actions/application_actions';
import { GetHousehold } from '../../actions/household_actions';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Lead } from '../../reducers/LeadReducer';
import { Loaded } from '../../utilities/utilities';
import { GetEmployer } from '../../actions/employer_actions';
import { navigateBack } from '../../actions/navigation_actions';
import { ProductStatus } from '../../reducers/policy_reducer';
import { Lookup, Lookups } from '../../utilities/lookup';
import { Strings } from '../../assets/common/strings';

interface StateProps {
	userId: string;
	impersonatingId: string;
	formValues: ApplicationUpsertFormValues;
	linesOfBusiness: string[];
	carriers: string[];
	productTypes: string[];
	contacts: Loaded<Contact>[];
	leads: Loaded<Lead>[];
	employerId?: string;
	householdId?: string;
	primaryEmployerContact?: Contact;
	applications: Loaded<Application>[];
	metalLevel: string[];
	isLoading: boolean;
}

interface DispatchProps extends NavigationProps {
	createApplication: (application: Application) => void;
	changeFieldValue: (form: string, field: string, value: any) => void;
	getHousehold: (id: string) => void;
	getEmployer: (id: string) => void;
	getApplication: (id: string) => void;
	updateApplication: (application: Application) => void;
}

type Props = StateProps & DispatchProps;

interface State {
	applicationId: string;
	createOrEdit: 'Create' | 'Edit';
	formIsValidated: boolean;
	initialApplication: Application;
	householdOrEmployer: 'Household' | 'Employer';
}

class ApplicationUpsertPage extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			applicationId: '',
			createOrEdit: 'Create',
			formIsValidated: false,
			initialApplication: {} as Application,
			householdOrEmployer: 'Household'
		};
	}

	componentWillMount() {
		if (
			this.props.employerId &&
			this.props.contacts.findIndex(
				contact => contact.employerId === this.props.employerId
			) === -1
		) {
			this.props.getEmployer(this.props.employerId);
		} else if (
			this.props.householdId &&
			this.props.contacts.findIndex(
				contact => contact.householdId === this.props.householdId
			) === -1
		) {
			this.props.getHousehold(this.props.householdId);
		}

		if (this.props.employerId) {
			this.setState({ householdOrEmployer: 'Employer' });
		}

		const paramsApplicationID: string = _.get(
			this.props,
			'match.params.applicationID',
			'new'
		).toString();
		const applicationID: string =
			paramsApplicationID.toLowerCase() === 'new'
				? uuid.v4()
				: paramsApplicationID;

		const createOrEdit: 'Create' | 'Edit' =
			paramsApplicationID.toLowerCase() === 'new' ? 'Create' : 'Edit';

		const currentTime = moment.utc().toDate();

		this.setState({
			applicationId: applicationID,
			createOrEdit: createOrEdit,
			formIsValidated: false,
		});

		if (createOrEdit === 'Create') {
			this.setState({
				initialApplication: {
					appDate: currentTime,
					applicationStatus: ProductStatus.Pending,
					statusReason: 'Enrolled',
				} as Application,
			});
		} else if (createOrEdit === 'Edit' && applicationID) {
			this.props.getApplication(applicationID);
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.state.createOrEdit === 'Edit' && nextProps.applications != this.props.applications) {
			const applicationIndex = _.findIndex(
				nextProps.applications,
				loadedApplication => {
					return loadedApplication.data.id === this.state.applicationId;
				}
			);

			if (applicationIndex > -1) {
				let newApplication = _.cloneDeep(
					nextProps.applications[applicationIndex].data
				);
				const appStatusEnum = newApplication.applicationStatus;
				const applicationStatus: string = ProductStatus[appStatusEnum];
				(newApplication.applicationStatus as any) = applicationStatus;
				let insureds: { [key: string]: boolean } = {};
				if (newApplication.primaryInsured) {
					let primaryId = newApplication.primaryInsured.id;
					insureds[`${primaryId}`] = true;
				}
				if (newApplication.secondaryInsured) {
					let spouseId = newApplication.secondaryInsured.id;
					insureds[`${spouseId}`] = true;
				}
				if (newApplication.dependents && newApplication.dependents.length > 0) {
					newApplication.dependents.forEach(dependent => {
						insureds[`${dependent.id}`] = true;
					});
				}
				this.setState({
					initialApplication: {
						...newApplication,
						insureds,
					},
				});
			}
		}
	}

	onSave = () => {
		let applicationToUpsert: Application;
		if (this.state.createOrEdit === 'Create') {
			applicationToUpsert = { ...initialApplication.data };
		} else {
			applicationToUpsert = {...this.state.initialApplication};
		}
		applicationToUpsert.id = this.state.applicationId;
		applicationToUpsert.userId =
			this.props.userId != this.props.impersonatingId &&
				this.props.impersonatingId
				? this.props.impersonatingId
				: this.props.userId;
		const currentFormValues: Application = this.props.formValues.application;

		//////////////// General Info Card ////////////////
		applicationToUpsert.lineOfBusiness = currentFormValues.lineOfBusiness;
		applicationToUpsert.carrier = currentFormValues.carrier;
		if (currentFormValues.carrier === 'Not-contracted') {
			applicationToUpsert.carrierFriendlyName =
				currentFormValues.carrierFriendlyName;
		} else {
			applicationToUpsert.carrierFriendlyName = '';
		}
		applicationToUpsert.productType = currentFormValues.productType;
		applicationToUpsert.productName = currentFormValues.productName;
		applicationToUpsert.metalLevel = currentFormValues.metalLevel;
		applicationToUpsert.applicationStatus = currentFormValues.applicationStatus;
		applicationToUpsert.statusReason = currentFormValues.statusReason;
		applicationToUpsert.appDate = currentFormValues.appDate;
		applicationToUpsert.policyEndDate = currentFormValues.policyEndDate;
		applicationToUpsert.effectiveDate = currentFormValues.effectiveDate;
		applicationToUpsert.isOutstandingDocumentsNeeded = currentFormValues.isOutstandingDocumentsNeeded;
		applicationToUpsert.outstandingDocumentsDueDate = currentFormValues.outstandingDocumentsDueDate;

		//////////////// Primary Insureds Card ////////////////
		applicationToUpsert.primaryInsured = null as any;
		applicationToUpsert.secondaryInsured = null;
		applicationToUpsert.dependents = [];

		const selectedInsureds = this.props.formValues.insureds;
		for (let insuredId in selectedInsureds) {
			let currentContact: any = {};
			let householdRole: HouseholdRole = HouseholdRole.Dependent;

			this.props.contacts.forEach((contact: Loaded<Contact>) => {
				if (contact.data.id === insuredId) {
					currentContact = contact.data;
					householdRole = contact.data.householdRole;
				}
			});

			if (selectedInsureds[insuredId] === true) {
				if (
					HouseholdRole[householdRole] === HouseholdRole[HouseholdRole.Primary]
				) {
					applicationToUpsert.primaryInsured = currentContact;
				} else if (
					HouseholdRole[householdRole] ===
					HouseholdRole[HouseholdRole.Secondary]
				) {
					if (!applicationToUpsert.primaryInsured) {
						applicationToUpsert.primaryInsured = currentContact;
					} else if (currentContact != applicationToUpsert.primaryInsured) {
						applicationToUpsert.secondaryInsured = currentContact;
					}
				} else if (
					HouseholdRole[householdRole] ===
					HouseholdRole[HouseholdRole.Dependent]
				) {
					if (!applicationToUpsert.primaryInsured) {
						applicationToUpsert.primaryInsured = currentContact;
					} else if (
						currentContact != applicationToUpsert.primaryInsured &&
						currentContact != applicationToUpsert.secondaryInsured
					) {
						applicationToUpsert.dependents.push(currentContact);
					}
				}
			}
		}

		//////////////// Premium Info Card ////////////////
		const extractNumber = (numberString: string | number) =>
			typeof numberString === 'string'
				? Number(numberString.replace(/[^0-9.]+/g, ''))
				: numberString;

		const annualPremium: string = _.get(
			currentFormValues,
			'annualPremium',
			''
		) as string;

		applicationToUpsert.annualPremium = extractNumber(annualPremium);
		const monthlyPremium: string = _.get(
			currentFormValues,
			'monthlyPremium',
			''
		) as string;
		applicationToUpsert.monthlyPremium = extractNumber(monthlyPremium);
		const annualFederalSubsidy: string = _.get(
			currentFormValues,
			'annualFederalSubsidy',
			''
		) as string;
		applicationToUpsert.annualFederalSubsidy = extractNumber(
			annualFederalSubsidy
		);
		const monthlyFederalSubsidy: string = _.get(
			currentFormValues,
			'monthlyFederalSubsidy',
			''
		) as string;
		applicationToUpsert.monthlyFederalSubsidy = extractNumber(
			monthlyFederalSubsidy
		);
		const annualNetCost: string = _.get(
			currentFormValues,
			'annualNetCost',
			''
		) as string;
		applicationToUpsert.annualNetCost = extractNumber(annualNetCost);
		const monthlyNetCost: string = _.get(
			currentFormValues,
			'monthlyNetCost',
			''
		) as string;
		applicationToUpsert.monthlyNetCost = extractNumber(monthlyNetCost);

		//////////////// Policy Amounts Card ////////////////
		const individualDeductible: string = _.get(
			currentFormValues,
			'individualDeductible',
			''
		) as string;
		applicationToUpsert.individualDeductible = extractNumber(
			individualDeductible
		);
		const individualMaxOutOfPocket: string = _.get(
			currentFormValues,
			'individualMaxOutOfPocket',
			''
		) as string;
		applicationToUpsert.individualMaxOutOfPocket = extractNumber(
			individualMaxOutOfPocket
		);
		const perPersonDeductible: string = _.get(
			currentFormValues,
			'perPersonDeductible',
			''
		) as string;
		applicationToUpsert.perPersonDeductible = extractNumber(
			perPersonDeductible
		);
		const ppMaxOutOfPocket: string = _.get(
			currentFormValues,
			'ppMaxOutOfPocket',
			''
		) as string;
		applicationToUpsert.ppMaxOutOfPocket = extractNumber(ppMaxOutOfPocket);
		const familyDeductible: string = _.get(
			currentFormValues,
			'familyDeductible',
			''
		) as string;
		applicationToUpsert.familyDeductible = extractNumber(familyDeductible);
		const familyMaxOutOfPocket: string = _.get(
			currentFormValues,
			'familyMaxOutOfPocket',
			''
		) as string;
		applicationToUpsert.familyMaxOutOfPocket = extractNumber(
			familyMaxOutOfPocket
		);
		const faceAmount: string = _.get(
			currentFormValues,
			'faceAmount',
			''
		) as string;
		applicationToUpsert.faceAmount = extractNumber(faceAmount);

		const benefitAmount: string = _.get(
			currentFormValues,
			'benefitAmount',
			''
		) as string;

		applicationToUpsert.benefitAmount = extractNumber(benefitAmount);

		const drugDeductible: string = _.get(
			currentFormValues,
			'drugDeductible',
			''
		) as string;
		applicationToUpsert.drugDeductible = extractNumber(drugDeductible);

		const annualDrugCost: string = _.get(
			currentFormValues,
			'annualDrugCost',
			''
		) as string;
		applicationToUpsert.annualDrugCost = extractNumber(annualDrugCost);

		//////////////// Admin Card ////////////////
		applicationToUpsert.applicationNumber = currentFormValues.applicationNumber;
		applicationToUpsert.carrierProducerCode =
			currentFormValues.carrierProducerCode;
		applicationToUpsert.sold = currentFormValues.sold;
		applicationToUpsert.enrollmentMethod = currentFormValues.enrollmentMethod;

		//////////////// Lead Selector Card ////////////////
		applicationToUpsert.opportunityId = currentFormValues.opportunityId;

		//////////////// Injected values ////////////////
		if (this.props.employerId) {
			applicationToUpsert.employerId = this.props.employerId;
			applicationToUpsert.primaryInsured = this.props.primaryEmployerContact
				? this.props.primaryEmployerContact
				: ({} as Contact);
		}

		this.state.createOrEdit === 'Edit'
			? this.props.updateApplication(applicationToUpsert)
			: this.props.createApplication(applicationToUpsert);

		this.props.navigateBack();
	}

	public handleFormUpdate = (
		pristine: boolean,
		invalid: boolean,
		submitting: boolean
	) => {
		const isThereASelectedInsuredItem: boolean = this.atLeastOneValueIsTrue();
		const updatedInvalid: boolean = invalid || !isThereASelectedInsuredItem;

		this.setState({
			formIsValidated: !(updatedInvalid || pristine || submitting),
		});
	};

	private atLeastOneValueIsTrue = () => {
		const selectedInsureds: { [index: string]: boolean } = _.get(
			this.props.formValues,
			'insureds',
			{}
		);

		for (let insured in selectedInsureds) {
			if (selectedInsureds[insured]) {
				return true;
			}
		}

		return false;
	};

	render() {
		const headerTitle =
			this.state.createOrEdit === 'Create'
				? 'New Application'
				: 'Edit Application';

		return (
			<BasePageContainer
				topComponent={
					<SaveCancelHeaderBarComponent
						onSave={this.onSave}
						title={headerTitle}
						history={this.props.history}
						isSaveDisabled={
							!this.state.formIsValidated && !this.props.isLoading
						}
					/>
				}
				middleComponent={
					<ApplicationUpsertFormComponent
						formValues={this.props.formValues}
						initialValues={{ application: this.state.initialApplication }}
						linesOfBusiness={this.props.linesOfBusiness}
						metalLevel={this.props.metalLevel}
						carriers={this.props.carriers}
						productTypes={this.props.productTypes}
						contacts={this.props.contacts}
						leads={this.props.leads}
						changeFieldValue={this.props.changeFieldValue}
						onUpdate={this.handleFormUpdate}
						householdOrEmployer={this.state.householdOrEmployer}
					/>
				}
			/>
		);
	}
}

function mapStateToProps(state: any, ownProps: Props): StateProps {
	
	const formValues: ApplicationUpsertFormValues = getFormValues(
		'ApplicationUpsertForm'
	)(state);
	const householdId =
		ownProps.match.params.householdID != Strings.Navigation.HouseholdId
			? ownProps.match.params.householdID
			: undefined;
	const employerId =
		ownProps.match.params.employerID != Strings.Navigation.EmployerId
			? ownProps.match.params.employerID
			: undefined;
	const employerContacts = state.contact.contacts.filter(
		contact => contact.employerId == employerId
	);
	const primaryEmployerContact = _.get(
		employerContacts.find(contact => contact.data.employerPrimaryContact),
		'data'
	);
	const contacts = state.contact.contacts.filter(
		contact =>
			(!!householdId && contact.householdId === householdId) ||
			(!!employerId && contact.employerId === employerId)
	);
	const applications = state.application.applications;
	const isLoading = state.application.isLoading || state.contact.isLoading;

	let leads = state.lead.leads.filter(lead => {
		if (employerId) return lead.employerId === employerId;
		else if (householdId) return lead.householdId === householdId;
	});
	leads.sort((a, b) => {
		const sortDescending = true;
		const dateA = moment(a.data.updatedOn);
		const dateB = moment(b.data.updatedOn);
		if (dateA.isBefore(dateB)) return sortDescending ? 1 : -1;
		else if (dateA.isAfter(dateB)) return sortDescending ? -1 : 1;
		else return 0;
	});
	leads = leads.slice(0, 5);

	return {
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		formValues: formValues,
		linesOfBusiness: state.lookup.getLabels(Lookups.ProductLineOfBusiness),
		carriers: state.lookup.getLabels(Lookups.Carrier),
		productTypes: state.lookup.getLabels(Lookups.ProductType),
		metalLevel: state.lookup.getLabels(Lookups.ProductMetalLevel),
		contacts,
		leads,
		householdId,
		employerId,
		primaryEmployerContact,
		applications,
		isLoading,
	};
}

function mapDispatchToProps(dispatch: any): Partial<DispatchProps> {
	return {
		navigateBack: () => dispatch(navigateBack()),
		createApplication: (application: Application) =>
			dispatch(CreateApplication.started(application)),
		changeFieldValue: (form: string, field: string, value: any) =>
			dispatch(change(form, field, value)),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		getApplication: (id: string) => dispatch(GetApplication.started(id)),
		updateApplication: (application: Application) =>
			dispatch(UpdateApplication.started(application)),
	};
}

export const ApplicationUpsertPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(ApplicationUpsertPage);
