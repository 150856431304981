import React from 'react';
import { connect } from '@hmkts/rise';
import { compose, Dispatch } from 'redux';
import { Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import moment from 'moment';
import { Podcast, PodcastTracking } from '../../reducers/podcast_reducer';
import { UpdateTracking, ChangePodcast } from '../../actions/podcast_actions';
import './slider.css';
import { isIE, isEdge } from '../../utilities';
import { NavigationProps, navRoutes } from '../nav/Routes';
import { withRouter } from 'react-router';
import { navigateTo } from '../../actions/navigation_actions';
import { AppState } from '../../reducers';
// import { SlideUp } from '../utility/transition';
import Replay10Icon from '@material-ui/icons/Replay10';
import Forward10Icon from '@material-ui/icons/Forward10';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { themePalette } from '../../utilities/branding';
import { isIPhoneX } from '../../utilities/is_mobile';


interface StateProps {
    podcast?: Podcast,
    userId: string,
    isTracking: boolean
}
interface DispatchProps {
    updateTracking: (tracking: PodcastTracking) => void;
    changePodcast: (podcast?: Podcast) => void;
}

type Props = StateProps & DispatchProps & NavigationProps;

interface State {
    ready: boolean,
    paused: boolean,
    muted: boolean,
    loading: boolean,
    currentTime: number,
    audioElement?: HTMLAudioElement,
    sliderElement?: HTMLInputElement
}

export class PodcastPlayer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ready: true,
            paused: true,
            muted: false,
            loading: false,
            currentTime: 0,
            audioElement: undefined,
            sliderElement: undefined
        }

    }

    componentDidMount() {
        let el = document.getElementById("podcast-player") as HTMLAudioElement;
        let sl = document.getElementById("podcast-slider") as HTMLInputElement;
        if (el != null && sl != null) {
            this.setState({
                sliderElement: sl,
                paused: el.paused
            });
            this.setupAudioComponent(el);
        }
    }

    componentWillUnmount() {
        if(this.state.audioElement) {
            this.removeAudioListeners(this.state.audioElement);
        }
    }

    setupAudioComponent = (audio: HTMLAudioElement) => {
        this.setState({
            audioElement: audio
        });
        this.setupAudioListeners(audio);
    }

    setupAudioListeners = (audio: HTMLAudioElement) => {
        audio.addEventListener("play", this.onPlay)
        audio.addEventListener("pause", this.onPause)
        audio.addEventListener("timeupdate", this.onTimeUpdate)
    }

    removeAudioListeners = (audio: HTMLAudioElement) => {
        audio.removeEventListener("play", this.onPlay)
        audio.removeEventListener("pause", this.onPause)
        audio.removeEventListener("timeupdate", this.onTimeUpdate)
    }

    onPlay = () => {
        this.setState({
            paused: false
        });
    }

    onPause = () => {
        this.setState({
            paused: true
        });
    }

    lastTicks = 0;
    onTimeUpdate = () => {
        const ticks = new Date().getTime();
        if(this.state.audioElement && (ticks - this.lastTicks) > 100) {
            this.setState({
                currentTime: this.state.audioElement.currentTime
            }, () => this.lastTicks = ticks);
        }
    }

    getCurrentProgress = () => {
        let audio = this.state.audioElement;
        if (audio && audio.currentTime && audio.duration) {
            return (audio.currentTime / audio.duration) * 100
        } else {
            return 0;
        }
    }

    setPlaybackTime = (time: number) => {
        let audio = this.state.audioElement;
        if (audio) {
            if(time > audio.duration) time = audio.duration;
            if(time < 0) time = 0;
            audio.currentTime = Math.floor(time);
        }
    }

    play = () => {
        if (this.state.audioElement && this.props.podcast && this.state.audioElement.src) {
            if(Math.floor(this.state.audioElement!.currentTime) == Math.floor(this.props.podcast!.duration!))
            {
                this.setPlaybackTime(0);
            }
            this.state.audioElement.play();
        }
    }

    pause = () => {
        if (this.state.audioElement) {
            this.state.audioElement.pause();
        }
    }

    slide = (e: any) => {
        const { sliderElement } = this.state;
        let audio = this.state.audioElement;
        if (sliderElement && audio) {
            const newTime = audio.duration * Number(e.target.value) / 100.0
            this.setPlaybackTime(newTime);
        }
    }

    navigateToList = () => {
        this.props.navigateTo(navRoutes.podcastList.path);
    }

    render() {
        let audio = this.state.audioElement;
        // if (!audio) {
        //     audio = document.getElementById("podcast-player") as HTMLAudioElement;
        //     if (audio) {
        //         this.setupAudioComponent(audio);
        //     }
        // }
        const shouldDisplay = !!this.props.podcast;
        const title = this.props.podcast ? this.props.podcast.title : ''

        return (
            // <SlideUp in={shouldDisplay} mountOnEnter unmountOnExit>
                <Toolbar style={{ display: shouldDisplay ? 'inherit' : 'none', background: themePalette.default_background, padding: '0 0 env(safe-area-inset-bottom) 0' }}>
                    <input id="podcast-slider" onChange={(e) => { if(isIE) this.slide(e) }} onInput={(e) => { this.slide(e) }} style={{ display: 'block' }} className={isIE || isEdge ? "ie-slider" : "slider"} value={this.getCurrentProgress()} step={.001} min={0} max={100} type="range" />
                    <Grid alignItems="center" container justify="space-between" style={{ padding: 10 }}>
                        <Grid item xs={12} container wrap="nowrap">
                            <Grid container item xs={8} style={{ fontSize: 17 - (title.length / 30) }} direction="row" wrap="nowrap">
                                <AudiotrackIcon/>
                                <div><b>{title}</b></div>
                            </Grid>
                            <Grid item xs={4} style={{ textAlign: 'right' }}>
                                <Typography>
                                    {audio ? `${moment.duration(audio.currentTime, 'seconds').format("mm:ss", { trim: false })}/${moment.duration(audio.duration, 'seconds').format("mm:ss", { trim: false })}` : '00:00/00:00'}
                                </Typography>
                                <Typography variant="caption">
                                    {audio && audio.duration ? `${moment.duration(audio.duration - audio.currentTime, 'seconds').format("mm:ss", { trim: false })} remaining` : '00:00 remaining'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} container justify="center" alignItems="center" wrap="nowrap">
                            <Grid item>
                                <IconButton onClick={this.navigateToList}>
                                    <ListIcon/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => { this.setPlaybackTime(audio ? audio.currentTime - 10 : 0) }}>
                                    <Replay10Icon/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                {this.state.paused ?
                                    <IconButton disabled={!this.state.ready} onClick={this.play}>
                                        <PlayArrowIcon fontSize="large"/>
                                    </IconButton>
                                    :
                                    <IconButton disabled={!this.state.ready} onClick={this.pause}>
                                        <PauseIcon fontSize="large"/>
                                    </IconButton>}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => { this.setPlaybackTime(audio ? audio.currentTime + 10 : 0) }}>
                                    <Forward10Icon/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => { this.props.changePodcast(undefined) }}>
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            // </SlideUp>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    podcast: state.podcast?.currentPodcast,
    userId: state.user.id,
    isTracking: state.podcast?.isTracking
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps & Partial<NavigationProps> => ({
    updateTracking: (tracking: PodcastTracking) => dispatch(UpdateTracking.started(tracking)),
    changePodcast: (podcast?: Podcast) => dispatch(ChangePodcast(podcast)),
    navigateTo: (route: string) => dispatch(navigateTo(route)),
});

export const PodcastPlayerComponent = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps, true),
)(PodcastPlayer);
