import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { themePalette } from '../../../utilities/branding';
import { Activity } from '../../../reducers/activity_reducer';
import { determineActivityStatus } from '../activity_detail';
import { displayDate, DateTimeFormat } from '../../../utilities/date_util';
import ClampLines from 'react-clamp-lines';
import { Strings } from '../../../assets/common/strings';
import { nav } from '../../..';
import { riseStrings } from '@hmkts/rise';
import { MoreMenu, createMenuAction } from '../../../components/nav/more_menu';
import { SimpleListItem } from '../../../components/utility/simple_list_item';
import { getActivityIcon } from './activity_item';
import { SimpleDialog } from '../../../components/dialogs/simple_dialog';

interface Props {
	activity: Activity;
	handleDeleteActivity: (activity: Activity) => void;
	handleNavigateToActivity: (activity: Activity) => void;
}
export const CompletedActivityItem: React.FC<Props> = (props) => {

	const { activity, handleDeleteActivity, handleNavigateToActivity } = props;
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const handleClick = () => {
		if (activity.type === Strings.Activity.FactFinder) {
			nav.push(riseStrings._Route_Form_View.replace(`:formId`, activity.correlationId!))
		} else if (!showDeleteConfirmation)
			handleNavigateToActivity(activity);
	};

	const handleDeleteClick = () => setShowDeleteConfirmation(true);
	const handleDismiss = () => setShowDeleteConfirmation(false);
	const handleConfirmDeleteClick = () => {
		handleDismiss();
		handleDeleteActivity(activity);
	};

	const primaryText = (
		<ClampLines
			text={activity?.title || ''}
			lines={2}
			buttons={false}
		/>
	);
	const secondaryText = (
		<ClampLines
			text={`${displayDate(activity.completedOn, DateTimeFormat.DateTime)} - ${determineActivityStatus(activity)}`}
			lines={2}
			buttons={false}
		/>
	);

	return (
		<>
			<SimpleListItem
				title={primaryText}
				subtitle={secondaryText}
				icon={getActivityIcon(activity)}
				onClick={handleClick}
				hideArrow
				secondaryAction={
					activity.type != Strings.Activity.Email &&
					activity.type != Strings.Activity.FactFinder &&
					activity.type != Strings.Activity.LeadStatusChange 
						? (
							<MoreMenu
								color={themePalette.tertiary_text}
								children={[createMenuAction('Delete', handleDeleteClick)]}
							/>
						)
						: undefined
				}
			/>
			<SimpleDialog
				disableBackdropClick
				disableEscapeKeyDown
				onClose={handleDismiss}
				open={showDeleteConfirmation}
				title={'Delete Activity'}
				description={'Are you sure you want to delete this activity?'}
				actions={[
					{ children: 'Cancel', onClick: handleDismiss },
					{ 
						children: 'Delete', 
						variant: 'contained', 
						color: 'primary', 
						disableElevation: true, 
						style: { backgroundColor: themePalette.delete_remove_reject_button }, 
						onClick: handleConfirmDeleteClick 
					}
				]}
			/>
		</>
	);
}
