import React, { useState, useEffect } from 'react';
import {
	Grid,
	TextField,
	MenuItem
} from '@material-ui/core';
import { AgentLicense, AgentLicenseLineOfAuthority, LicenseForm } from '../../../reducers/license_appointment_reducer';
import { ListFilter } from '../../../components/filter/list_filter';
import { Strings } from '../../../assets/common/strings';
import { AppState } from '../../../reducers';
import { GetAgentLicenses, GetAgentStates, GetAgentLicenseLineOfAuhtorities } from '../../../actions/license_appointment_actions';
import { connect } from '@hmkts/rise';
import { Dispatch } from 'redux';

interface StateProps {
	filteredLicenses: AgentLicense[],
	filterLicenseForm: LicenseForm,
	stateList: string[];
	lioList: AgentLicenseLineOfAuthority[];
}

interface DispatchProps {
	filterLicenses: (filter: LicenseForm) => void;
	agentStates: () => void;
	agentLicenseLineOfAuthorities: () => void;
}

type Props = StateProps & DispatchProps;

const LicenseFilterPage: React.FC<Props> = (props) => {

	const { stateList, lioList, filterLicenseForm, filterLicenses, agentLicenseLineOfAuthorities, agentStates } = props;

	useEffect(() => {
		agentLicenseLineOfAuthorities();
		agentStates();
	}, [])

	const statusList = ["All", Strings.LicenseStatusIcon.Active, Strings.LicenseStatusIcon.Pending, Strings.LicenseStatusIcon.Terminated];

	const [editForm, setEditForm] = useState(filterLicenseForm);

	const searchLicenses = () => {
		filterLicenses(editForm);
	};

	const changeState = (event) => {
		setEditForm({
			...editForm,
			state: event.target.value
		});
	};

	const changeStatus = (event) => {
		setEditForm({
			...editForm,
			status: event.target.value
		});
	};

	const changeLoA = (event) => {
		setEditForm({
			...editForm,
			lineOfAuhtority: event.target.value
		});
	};

	const handleReset = () => {
		const initialForm: LicenseForm = {
			state: Strings.LicenseAppointmentFilterLabel.All,
			status: Strings.LicenseAppointmentFilterLabel.All,
			lineOfAuhtority: Strings.LicenseAppointmentFilterLabel.All,
		};
		setEditForm(initialForm);
	};

	const { state, status, lineOfAuhtority } = editForm;

	return (
		<ListFilter
			drawerTitle={'Licenses'}
			onSearch={searchLicenses}
			onReset={handleReset}
			cardSubtitle={`${state} - ${status} - ${lineOfAuhtority}`}
			drawerSubtitle={`${state} - ${status} - ${lineOfAuhtority}`}
		>
			<Grid container alignContent="center" spacing={2}>

				<Grid item xs={12}>
					<TextField
						select
						label="State"
						onChange={changeState}
						value={state}
						fullWidth
					>
						{stateList?.length > 0 && (stateList.map((state: string) => (
							<MenuItem key={state} value={state}>
								{state}
							</MenuItem>
						)))}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<TextField
						select
						label="Status"
						onChange={changeStatus}
						value={status}
						fullWidth
					>
						{statusList?.length > 0 && (statusList.map((status: string) => (
							<MenuItem key={status} value={status}>
								{status}
							</MenuItem>
						)))}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<TextField
						select
						label="Line Of Authority"
						onChange={changeLoA}
						value={lineOfAuhtority}
						fullWidth
					>
						{statusList?.length > 0 && (lioList.map((lio) => (
							<MenuItem key={lio.LineOfLicenseText} value={lio.LineOfLicenseText}>
								{lio.LineOfLicenseText}
							</MenuItem>
						)))}
					</TextField>
				</Grid>

			</Grid>
		</ListFilter>
	);
}

const mapStateToProps = (state: AppState) => {
	const stateListResult = state.licenseAppointment.agentStates;
	const stateList = [Strings.LicenseAppointmentFilterLabel.All, ...stateListResult.map(item => item.State)];
	const allLioOption = { LineOfLicenseText: Strings.LicenseAppointmentFilterLabel.All };
	const allLobOption = { LineOfBusinessText: Strings.LicenseAppointmentFilterLabel.All };
	const agentLicenseLineOfAuthorities = state.licenseAppointment.agentLicenseLineOfAuthorities;

	return {
		filteredLicenses: state.licenseAppointment.agentLicenses,
		filterLicenseForm: state.licenseAppointment.filterLicenseForm,
		stateList: stateList,
		lioList: [allLioOption, ...agentLicenseLineOfAuthorities]
	};
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	filterLicenses: (filter: LicenseForm) => dispatch(GetAgentLicenses.started(filter)),
	agentStates: () => dispatch(GetAgentStates.started()),
	agentLicenseLineOfAuthorities: () => dispatch(GetAgentLicenseLineOfAuhtorities.started()),
});

export const LicenseFilter = connect(mapStateToProps, mapDispatchToProps, true)(LicenseFilterPage);
