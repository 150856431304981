import {
	Button,
	CardActions,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
} from '@material-ui/core';
import React from 'react';
import { AcceptWelcomeVideo } from '../../actions/user_actions';
import { connect } from '@hmkts/rise';
import { navigateTo, navigateToWithoutAddingToHistory } from '../../actions/navigation_actions';
import { finishFirstTimeLogin } from '../../actions/authentication_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { AppState } from '../../reducers/index';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { themePalette, themeImages } from '../../utilities/branding';
import { Dispatch } from 'redux';

interface StateProps {
	userId: string;
}

interface DispatchProps extends Partial<NavigationProps> {
	acceptVideo: () => void;
	finishFirstTimeLogin: () => void;
}

interface Props extends StateProps, DispatchProps {
	needsVideo: boolean;
}

interface WelcomeState {
	displayLoading: boolean;
	displayError: boolean;
}

class Welcome extends React.Component<Props, WelcomeState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			displayLoading: false,
			displayError: false,
		};
	}

	skipClick = () => {
		this.props.acceptVideo();
		this.props.finishFirstTimeLogin();
		this.navigate(navRoutes.home.path);
	};

	watchClick = () => {
		this.props.acceptVideo();
		this.navigate(navRoutes.introVideo.path);
	};

	navigate = (path: string) => {
		this.props.navigateTo!(path);
	}

	renderedErrorMessage = (
		<Grid item xs={12} style={{ textAlign: 'center' }}>
			<span style={{
				color: themePalette.required_text,
			}}>
				Failed to load next page. Please try again.
			</span>
		</Grid>
	);

	render() {
		return (
			<BasePageContainer
				blockDialogs
				middleComponent={
					<div style={{
						...styles.wrapper,
						backgroundImage: `url('${themeImages.loginBackground}')`,
						backgroundColor: themePalette.login_background,
					}}>
						<Grid container alignItems="center" justify="center">
							<Grid item xs={12} style={styles.center}>
								<img
									src={themeImages.logo}
									style={styles.hmLogo}
								/>
							</Grid>
							<Grid item xs={12} style={styles.center}>
								<span style={{...styles.welcome, color: themePalette.negative_text}}>Welcome!</span>
							</Grid>
							{!isBrokerage && <Grid item xs={12} style={styles.center}>
								<span style={{...styles.mainMessage, color: themePalette.negative_text}}>
									Watch a quick video to see what<br />you can do with Agent
									Connect
								</span>
							</Grid>}
							{this.state.displayError ? this.renderedErrorMessage : null}
						</Grid>
						<Dialog open={this.state.displayLoading}>
							<DialogTitle>Loading</DialogTitle>
							<DialogContent>
								<Grid container justify="center" alignItems="center">
									<Grid item xs={12} style={styles.loading}>
										<CircularProgress />
									</Grid>
								</Grid>
							</DialogContent>
						</Dialog>
					</div>
				}
				bottomComponent={
					<CardActions style={styles.actions}>
						{
						isBrokerage
							? <span />
							: (
								<Button 
									variant="contained"
									style={{ backgroundColor: themePalette.delete_remove_reject_button }}
									onClick={this.skipClick}
									children={["No Thanks"]}
								/>
							)
						}
						<Button
							variant="contained"
							style=
							{{
								backgroundColor: themePalette.accept_button,
								color: themePalette.negative_text,
							}}
							onClick={isBrokerage ? this.skipClick : this.watchClick}
							children={["Great, let's go!"]}
						/>
					</CardActions>
				}
			/>
		);
	}
}

const styles: any = {
	wrapper: {
		width: '100%',
		height: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'absolute',
		bottom: '0px',
		right: '0px',
	},
	hmLogo: {
		marginTop: '15%',
		height: 35,
	},
	welcome: {
		marginTop: '5%',
		display: 'inline-block',
		fontSize: '46px',
		textAlign: 'center',
	},
	mainMessage: {
		marginTop: '30px',
		display: 'inline-block',
		fontSize: '16px',
		padding: '0px 30px 0px',
	},
	actions: {
		flex: 1,
		justifyContent: 'space-between',
	},
	center: { textAlign: 'center' },
	loading: {
		textAlign: 'center',
		padding: 10,
	},
};

function mapStateToProps(state: AppState): StateProps {
	return {
		userId: state.user.id,
	};
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
	return {
		navigateTo: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		finishFirstTimeLogin: () => dispatch(finishFirstTimeLogin()),
		acceptVideo: () => dispatch(AcceptWelcomeVideo()),
	};
}

export const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps, true)(Welcome);
