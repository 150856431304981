import {
	Grid,
	TextField,
	Button,
	Icon,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { Strings as S } from '../../assets/common/strings';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { VerifyCallerId, CheckCallerId } from '../../actions/click_to_call_actions';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { KeyPressEventUtility } from '../../utilities/html/keypress_event_utility';
import { enforceStylesType } from '../../utilities/styles_util';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';



interface StateProps {
	initialCallerId: string;
	isVerified: boolean;
	verifyingCallerId: boolean;
	verificationCode: string;
}

interface DispatchProps {
	handleVerify: (number: string) => void;
	checkForValidCallerId: () => void;
}

type State = {
	callerId: string;
}

type Props =
	StateProps &
	DispatchProps &
	NavigationProps;

class SettingsClickToCallCallerIdPage extends React.Component<Props, State>  {

	timer: any;
	state = {
		callerId: this.props.initialCallerId,
	};

	componentDidMount() {
		if (!this.props.isVerified) {
			this.setTimer();
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.isVerified) {
			clearInterval(this.timer);
		} else {
			this.setTimer();
		}
	}

	setTimer = () => {
		if (!this.timer) {
			this.timer = setInterval(this.props.checkForValidCallerId, 5000);
		}
	};

	callerIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ callerId: normalizePhone(event.target.value) });
	}

	verifyCallerId = () => {
		const { callerId } = this.state;
		this.props.handleVerify(callerId);
	}

	render() {
		const { isVerified, verifyingCallerId, initialCallerId } = this.props;
		const { callerId } = this.state;
		const numbersOnly = (callerId || '').replace(/\D/g, '');
		const initialNumbersOnly = (initialCallerId || '').replace(/\D/g, '').substring(1); // remove country code
		const isValid = numbersOnly.length === 10;

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Settings"
						rightButtons={
							<MoreMenu children={[createHelpAction(themeLinks.helpLinkSettings)]} />
						}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<ToggleCard title={S.Settings.ClickToCallSettings}>
						<Grid container direction="row" alignItems="baseline">
							<Icon color="primary">{isVerified ? 'done' : ''}</Icon>
							<TextField
								id="caller-id"
								label="Caller ID"
								margin="normal"
								type="tel"
								value={callerId}
								inputProps={{ maxLength: 14 }}
								onChange={this.callerIdOnChange}
								onKeyDown={KeyPressEventUtility.integerOnly}
							/>
							<Button
								onClick={this.verifyCallerId}
								disabled={!isValid || verifyingCallerId || (numbersOnly == initialNumbersOnly && isVerified)}>
								Verify
							</Button>
						</Grid>
						{
							this.props.verificationCode && !this.props.isVerified && (
								<div style={styles.verificationResponse}>
									<span style={styles.verificationResponseBold}>
										Verification code to enter when called: {this.props.verificationCode}
									</span>
								</div>
							)
						}
					</ToggleCard>
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const styles = enforceStylesType({
	leftMargin: {
		marginLeft: '1em'
	},
	verificationResponse: {
		marginLeft: '1em',
		paddingTop: '1em'
	},
	verificationResponseBold: {
		fontWeight: 600
	},
	wideText: {
		width: '25em',
		marginLeft: '1em'
	}
});

const mapStateToProps = (state: AppState): StateProps => ({
	initialCallerId: state.user.callerId,
	isVerified: Boolean(state.clickToCall.callerIdVerified),
	verifyingCallerId: state.clickToCall.verifyingCallerId,
	verificationCode: state.clickToCall.verificationCode
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	handleVerify: (number: string) => dispatch(VerifyCallerId.started(number)),
	checkForValidCallerId: () => dispatch(CheckCallerId.started(undefined))
});

export const SettingsClickToCallCallerIdContainer = connect(mapStateToProps, mapDispatchToProps, true)
	(SettingsClickToCallCallerIdPage);
