import React from 'react';
import _ from 'lodash';
import { AppState } from '../../reducers/index';
import { connect } from '@hmkts/rise';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { NavigationProps } from '../../components/nav/Routes';
import {
	Grid,
	Button,
	Icon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Typography
} from '@material-ui/core';
import { ImportHistoryTable } from '../../components/Import/ImportHistoryTable';
import { ImportHistory } from '../../reducers/file_reducer';
import {
	FileUpload,
	getImportHistory,
	getProcessedImportFile,
	getTemplateFile,
	uploadImportTable,
} from '../../actions/file_actions';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import moment from 'moment';
import { themePalette } from '../../utilities/branding';
import { WideButton } from '../../components/utility/wide_button';
import { Strings as S } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';

interface ImportPageStateProps {
	leadImports: ImportHistory[];
	isLeadImportsHistoryLoading: boolean;
	isFileDownloading: boolean;
	userId: string;
}

interface ImportPageDispatchProps {
	getFile: (fileName: string) => void;
	getProcessedLeadImportFile: (fileName: string) => void;
	getLeadImportResults: () => void;
	uploadLeadsTable: (fileUpload: FileUpload) => void;
}

type ImportPageProps = ImportPageStateProps &
	ImportPageDispatchProps &
	NavigationProps;

interface ImportPageState {
	isImportDialogOpen: boolean;
	isDownloadDialogOpen: boolean;
	pollingFileDownloadsTimer: number | void;
	importFile?: File;
	order: 'asc' | 'desc';
	orderBy: string;
}

class ImportPage extends React.Component<ImportPageProps, ImportPageState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isImportDialogOpen: false,
			isDownloadDialogOpen: false,
			pollingFileDownloadsTimer: undefined,
			order: 'desc',
			orderBy: 'createdOn',
		};
	}

	componentWillMount() {
		this.props.getLeadImportResults();
	}

	componentWillUnmount() {
		if (this.state.pollingFileDownloadsTimer) {
			clearTimeout(this.state.pollingFileDownloadsTimer);
		}
	}

	componentDidUpdate(prevProps: ImportPageProps) {
		if (
			!this.state.pollingFileDownloadsTimer &&
			this.isProcessingLeadImports()
		) {
			this.setState({ pollingFileDownloadsTimer: this.pollFileDownloads() });
		}
	}

	composeHeaderRightIcons = (): JSX.Element => {
		return (
			<Grid container justify={'flex-end'} style={{ width: 'auto' }}>
				<Grid item>
					<Button
						style={{ color: themePalette.negative_text }}
						onClick={() => this.props.getLeadImportResults()}
					>
						<Icon>sync</Icon>
					</Button>
				</Grid>
				<Grid item>
					<Button
						style={{ color: themePalette.negative_text }}
						disabled={this.props.isFileDownloading}
						onClick={() => this.setState({ isDownloadDialogOpen: true })}
					>
						Download Template
						<Icon>file_download</Icon>
					</Button>
				</Grid>
				<Grid item>
					<Button
						style={{ color: themePalette.negative_text }}
						onClick={() => {
							this.setState({ isImportDialogOpen: true });
						}}
					>
						Import
						<Icon>file_upload</Icon>
					</Button>
				</Grid>
			</Grid>
		);
	};

	renderImportDialog = () => {
		const validateFileSize =
			(_.get(this.state, 'importFile.size', 0) as number) < 5242572;
		const types = ['csv', 'xlsx', 'xls'];
		const validateFileType =
			!this.state.importFile ||
			types.some(type => this.state.importFile!.name.endsWith(type));

		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth
				maxWidth="md"
				open={this.state.isImportDialogOpen}
				onClose={() => this.setState({ isImportDialogOpen: false })}
			>
				<DialogTitle>Import</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							type="file"
							placeholder="Select a file..."
							style={{ width: 550, height: '35px' }}
							inputProps={{ style: { height: '35px', cursor: 'pointer' } }}
							onChange={(event: any) =>
								this.setState({
									importFile: _.get(event, 'target.files[0]') as File,
								})
							}
							error={!validateFileSize || !validateFileType}
							helperText={
								!validateFileSize
									? 'File should be no bigger than 5 MB'
									: !validateFileType ? 'File type not accepted' : undefined
							}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ isImportDialogOpen: false })}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.accept_button }}
						disabled={
							!validateFileSize || !validateFileType || !this.state.importFile
						}
						onClick={() => this.handleUploadFile()}
					>
						Import
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderDownloadTemplateDialog = () => {
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth
				maxWidth="md"
				open={this.state.isDownloadDialogOpen}
				onClose={() => this.setState({ isDownloadDialogOpen: false })}
			>
				<DialogTitle>Download Template</DialogTitle>
				<DialogContent>
					<WideButton
						primary={'Leads Template'}
						secondary={'Import multiple leads'}
						leftIcon={<Icon>people</Icon>}
						icon={<Icon style={{ marginRight: 0 }}>file_download</Icon>}
						onClick={() => {
							this.props.getFile('LeadImportTemplate.xlsx');
							this.setState({ isDownloadDialogOpen: false });
						}}
					/>
					<WideButton
						primary={'Application Template'}
						secondary={'Import multiple applications'}
						leftIcon={<Icon>library_books</Icon>}
						icon={<Icon style={{ marginRight: 0 }}>file_download</Icon>}
						onClick={() => {
							this.props.getFile('ApplicationImportTemplate.xlsx');
							this.setState({ isDownloadDialogOpen: false });
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.setState({ isDownloadDialogOpen: false })}
					>
						Done
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	isProcessingLeadImports = () => {
		return this.props.leadImports.some(lead => lead.status === 'Processing');
	};

	pollFileDownloads = () => {
		if (!this.isProcessingLeadImports()) {
			if (this.state.pollingFileDownloadsTimer) {
				this.setState({
					pollingFileDownloadsTimer: clearTimeout(
						this.state.pollingFileDownloadsTimer
					),
				});
			}
			return undefined;
		}

		return window.setTimeout(() => {
			this.props.getLeadImportResults();
			this.setState({ pollingFileDownloadsTimer: this.pollFileDownloads() });
		}, 5000);
	};

	handleUploadFile = () => {
		if (this.state.importFile) {
			const reader = new FileReader();

			reader.onload = () => {
				this.props.uploadLeadsTable({
					file: Array.from(new Uint8Array(reader.result as any)),
					fileName: this.state.importFile!.name,
				});
			};

			reader.readAsArrayBuffer(this.state.importFile);
		}

		this.setState({ isImportDialogOpen: false });
	};

	render() {
		const sortedHistoryData = _.orderBy(
			this.props.leadImports,
			[this.state.orderBy],
			[this.state.order]
		);
		const isLoading = this.props.isLeadImportsHistoryLoading || this.props.isFileDownloading || !!this.state.pollingFileDownloadsTimer;

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={'Import'}
						isLoading={isLoading}
						rightButtons={this.composeHeaderRightIcons()}
						customContent={<Typography style={styles.disclaimer}>{S.Disclaimer.Dnc}</Typography>}
					/>
				}
			>
				<ImportHistoryTable
					leadImports={sortedHistoryData}
					order={this.state.order}
					orderBy={this.state.orderBy}
					sortData={(order: 'asc' | 'desc', orderBy: string) =>
						this.setState({ order: order, orderBy: orderBy })
					}
					getProcessedLeadImportFile={(fileName: string) =>
						this.props.getProcessedLeadImportFile(fileName)
					}
				/>
				{this.renderImportDialog()}
				{this.renderDownloadTemplateDialog()}
			</BasePageContainer>
		);
	}
}

function mapStateToProps(state: AppState): ImportPageStateProps {
	const thirtyDaysAgoDate: Date = moment()
		.subtract(30, 'days')
		.toDate();

	return {
		leadImports: state.file.imports.filter((leadImport: ImportHistory) => {
			return new Date(leadImport.createdOn) > thirtyDaysAgoDate;
		}),
		isLeadImportsHistoryLoading: state.file.isImportHistoryLoading,
		isFileDownloading: state.file.isAnImportTemplateDownloading,
		userId: state.user.id,
	};
}

function mapDispatchToProps(dispatch: any): ImportPageDispatchProps {
	return {
		getFile: (fileName: string) => dispatch(getTemplateFile.started(fileName)),
		getProcessedLeadImportFile: (fileName: string) =>
			dispatch(getProcessedImportFile.started(fileName)),
		getLeadImportResults: () => dispatch(getImportHistory.started(undefined)),
		uploadLeadsTable: (fileUpload: FileUpload) =>
			dispatch(uploadImportTable.started(fileUpload)),
	};
}

export const ImportPageContainer = connect(mapStateToProps, mapDispatchToProps, true)(
	ImportPage
);

const styles: { [key: string]: React.CSSProperties } = enforceStylesType({
	disclaimer: {
		fontStyle: 'italic',
		color: themePalette.negative_text,
		margin: 14,
		fontSize: 12
	},
});
