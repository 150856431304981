import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Grid, Button, RiseDialog, useEffect, useDispatch, useSelector, r_pipe } from '@hmkts/rise';
import { FactFinderSlice } from '../..';
import { CheckCircleOutline, CheckCircle } from '@material-ui/icons';
import { shallowEqual } from 'react-redux';
import { Card, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardItem: {
      margin: 5,
      padding: 7,
      border: '1px solid black',
      backgroundColor: theme.palette.background.default
    },
    cardItemSelected: {
      margin: 5,
      padding: 7,
      border: '1px solid black',
      backgroundColor: theme.palette.primary.light
    },
    gridItem: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'none',
      textAlign: 'start',
      border: '1px solid black',
      overflow: 'hidden'
    },
    image: {
      width: 275,
      height: 200
    },
    imageFull: {
      width: 550,
      height: 400
    },
    title: {
      color: 'white',
    },
    titleBar: {
      background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)',
    },
    toggleIcon: {
      margin: 15,
      color: 'white'
    }
  }),
);

type OwnProps = {
  slide: FactFinderSlice.PowerPointSlide;
}

export const PowerPointTemplateCard: React.FC<OwnProps> = (props) => {
  const { slide } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const activeTemplate = useSelector(app => FactFinderSlice.selectState(app).activeTemplate || {} as FactFinderSlice.PowerPointTemplate, shallowEqual);
  const [currentSlides, setCurrentSlides] = useState<Record<string, FactFinderSlice.PowerPointSlide>>(r_pipe.listToIdRecord(activeTemplate?.slides));
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    const newSlides = r_pipe.listToIdRecord<FactFinderSlice.PowerPointSlide>(activeTemplate?.slides);
    if (currentSlides !== newSlides) {
      setCurrentSlides(newSlides);
    }
  }, [activeTemplate])

  const updateTemplate = (newTemplate: FactFinderSlice.PowerPointTemplate) => {
    dispatch(FactFinderSlice.actions.SetActiveTemplate(newTemplate));
  }

  const createSlideToggle = () => {
    const slidesToSave = {
      ...currentSlides,
      [slide.id]: {
        ...currentSlides[slide.id],
        enabled: !currentSlides[slide.id]?.enabled
      }
    };

    updateTemplate({ ...activeTemplate, slides: r_pipe.recordToList<FactFinderSlice.PowerPointSlide>(slidesToSave) });
  }

  return (
    <Card
      className={slide.enabled ? classes.cardItemSelected : classes.cardItem}
      key={slide.id}
    >
      <CardContentWrapper
        slide={slide}
        createSlideToggle={createSlideToggle}
        onClick={() => setPreviewOpen(true)}
      >
        <img src={slide.thumbnailURL} alt={slide.title} className={classes.image} />
      </CardContentWrapper>

      <RiseDialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
      >
        <CardContentWrapper
          slide={slide}
          createSlideToggle={createSlideToggle}
        >
          <img src={slide.thumbnailURL} alt={slide.title} className={classes.imageFull} />
        </CardContentWrapper>
      </RiseDialog>
    </Card>
  );
}

type CardContentProps = {
  slide: FactFinderSlice.PowerPointSlide;
  createSlideToggle: () => void;
  onClick?: () => void;
}

const CardContentWrapper: React.FC<CardContentProps> = (props) => {
  const { slide, createSlideToggle, children, onClick } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.gridItem}>
      <Button style={{ padding: 0 }} onClick={onClick} disabled={!onClick}>
        {children}
      </Button>

      <GridListTileBar
        title={slide.title}
        classes={{ root: classes.titleBar, title: classes.title }}
        actionIcon={!slide.readOnly ?
          <IconButton className={classes.toggleIcon} onClick={createSlideToggle} size="small">
            {slide.enabled ? <CheckCircle /> : <CheckCircleOutline />}
          </IconButton> : null}
      />
    </Grid>
  );
}