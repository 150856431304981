import { IconButton } from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { navigateTo } from '../../actions/navigation_actions';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { navRoutes } from '../../components/nav/Routes';
import {
	StoreLeadFilters,
	GetFilteredLeads,
	GetDashboardCounts,
} from '../../actions/lead_actions';
import { jwt_auth } from '../../utilities/auth';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { FabMenu, FabMenuItem } from '../../components/nav/fab_menu';
import { LeadFilters } from '../../reducers/LeadFilterReducer';
import { initialState as FilterInitialState } from '../../reducers/product_filter_reducer';
import {
	SearchProducts,
	ProductFilterPayload,
} from '../../actions/product_actions';
import { MoreMenu } from '../../components/nav/more_menu';
import { ProductStatuses } from '../../reducers/policy_reducer';
import { LookupDictionary, Lookup, Lookups } from '../../utilities/lookup';
import {
	NotificationSummary,
	notificationCategories,
} from '../../components/notifications/notificationSummary';
import {
	formatLeadFiltersToStore,
	formatLeadFiltersPayload,
} from '../../utilities/lead_util';
import { themePalette, themeLinks } from '../../utilities/branding';
import { PageSize } from '../../utilities/utilities';
import { P } from '../../utilities/auth/permissions';
import { CountTypeCard, BubbleProps } from '../../components/lead/count_type_card';
import { DownlineAgent } from '../../reducers/product_reducer';
import { Strings } from '../../assets/common/strings';
import { nav } from '../..';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import SearchIcon from '@material-ui/icons/Search';
import { AppState } from '../../reducers';
import { makeOpenExternalLink } from '../../utilities';

interface IProductStatus {
	[statusKey: string]: ProductStatuses;
}
const productStatusRef: IProductStatus = {
	pending: 'Pending',
	pendingCustomers: 'PendingCustomers',
	active: 'Active',
	activeCustomers: 'ActiveCustomers',
	terminated: 'Terminated',
	terminatedCustomers: 'TerminatedCustomers',
	unmatched: 'Unmatched',
};

interface StateProps {
	agentCode: string;
	statusCounts: { [key: string]: number };
	productStatusCounts: { [key in ProductStatuses]?: number };
	isLoading: boolean;
	pageNum: number;
	pageSize: number;
	lookups: LookupDictionary;
	applicationCount: number;
	policyCount: number;
	leadCount: number;
	userId: string;
	productDownlineAgent: DownlineAgent;
}
interface DispatchProps {
	getDashboardCounts: () => void;
	navigateTo: (route: string) => void;
	storeLeadFilters: (filterValues: LeadFilters) => void;
	searchProducts: (filterValues: ProductFilterPayload) => void;
	getFilteredLeads: (filters: LeadFilters) => void;
}
type Props = StateProps & DispatchProps;

class LeadDashboardPage extends React.PureComponent<Props> {

	componentDidMount() {
		this.props.getDashboardCounts();
	}

	public handleLeadFilters(status: Lookup, filterIsOpen: boolean = false) {
		const leadFilters = formatLeadFiltersToStore(status, filterIsOpen);
		this.props.storeLeadFilters(leadFilters);
		this.props.getFilteredLeads(leadFilters);
	}

	public handleProductFilters(productStatus?: Strings.ProductStatus, productMatch?: Strings.ProductMatch) {
		const productFilters: ProductFilterPayload = {
			...FilterInitialState,
			productCategoryFilter: 'all',
			productStatusFilter: productStatus || '',
			productMatchFilter: productMatch || '',
			pageNumber: 0,
			pageSize: PageSize(),
			downlineAgentCode: this.props.productDownlineAgent.agentCode || ''
		};

		this.props.searchProducts(productFilters);
	}

	makeLeadBubbleOnClick = (status: Lookup) => () => {
		this.handleLeadFilters(status);
		nav.push(navRoutes.leadList.path)
	}

	makeProductBubbleOnClick = (productStatus?: Strings.ProductStatus, productMatch?: Strings.ProductMatch) => () => {
		this.handleProductFilters(productStatus, productMatch);
		nav.push(navRoutes.productListPage.path)
	}

	getStatusCount = (status: string) => Number(
		this.props.statusCounts[status]
		|| this.props.statusCounts[status.toLowerCase()]
		|| 0
	)

	public renderNewLeadsCard() {
		const notContacted = this.props.lookups.getLabel(Strings.Status.NotContacted);
		const newLeadCount = this.getStatusCount(notContacted);

		const bubbles: BubbleProps[] = [
			{
				caption: notContacted,
				counts: [
					{ count: newLeadCount }
				],
				onClick: this.makeLeadBubbleOnClick(Lookups.NotContacted),
			},
		];

		return (
			<CountTypeCard
				title="New Leads"
				bubbles={bubbles}
			/>
		);
	}

	public renderAttemptedLeadsCard() {
		const texted = this.props.lookups.getLabel(Strings.Status.TextedAwaitingAnswer);
		const emailed = this.props.lookups.getLabel(Strings.Status.EmailedAwaitingResponse);
		const calledNoAnswer = Lookups.CalledNoAnswer.label;
		const calledNoMessage = Lookups.CalledNoMessage.label;
		const calledLeftMessage = this.props.lookups.getLabel(Strings.Status.CalledLeftMessage);

		const textedCount = this.getStatusCount(texted);
		const emailedCount = this.getStatusCount(emailed);
		const calledNoAnswerCount = this.getStatusCount(calledNoAnswer);
		const calledNoMessageCount = this.getStatusCount(calledNoMessage);
		const calledLeftMessageCount = this.getStatusCount(calledLeftMessage);

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: emailedCount }
				],
				caption: Strings.LeadType.Emailed,
				onClick: this.makeLeadBubbleOnClick(Lookups.EmailedAwaitingResponse),
			},
			{
				counts: [
					{ count: textedCount }
				],
				caption: Strings.LeadType.Texted,
				onClick: this.makeLeadBubbleOnClick(Lookups.TextedAwaitingAnswer),
			},
			{
				counts: [
					{ count: calledNoAnswerCount, caption: Strings.LeadType.NoAnswer },
					{ count: calledLeftMessageCount, caption: Strings.LeadType.LeftMessage },
					{ count: calledNoMessageCount, caption: Strings.LeadType.NoMessage },
				],
				caption: Strings.LeadType.Called,
				onClick: this.makeLeadBubbleOnClick(Lookups.Called),
				styles: {
					fontSize: '9px',
					whiteSpace: 'nowrap',
					marginTop: -4,
					marginBottom: -2,
				},
			},
		];

		return (
			<CountTypeCard
				title={this.props.lookups.getLabel(Strings.Status.Attempted)}
				bubbles={bubbles}
			/>
		);
	}

	public renderContactedLeadsCard() {
		const callbackRequested = Strings.Status.CallbackRequested;
		const appointmentSet = Strings.Status.AppointmentSet;
		const customerWillCallBack = Strings.Status.CustomerWillCallBack;

		const callbackRequestedCount = this.getStatusCount(callbackRequested);
		const appointmentSetCount = this.getStatusCount(appointmentSet);
		const custWillCallBackCount = this.getStatusCount(customerWillCallBack);

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: callbackRequestedCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.CallbackRequested),
				onClick: this.makeLeadBubbleOnClick(Lookups.CallbackRequested)
			},
			{
				counts: [
					{ count: appointmentSetCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.AppointmentSet),
				onClick: this.makeLeadBubbleOnClick(Lookups.AppointmentSet)
			},
			{
				counts: [
					{ count: custWillCallBackCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.CustomerWillCallBack),
				onClick: this.makeLeadBubbleOnClick(Lookups.CustomerWillCallBack)
			},
		];

		return (
			<CountTypeCard
				title={Strings.LeadType.Contacted}
				bubbles={bubbles}
			/>
		);
	}

	public renderDealsInProgressLeadsCard() {
		const quoted = Strings.Status.Quoted;
		const applicationInProgress = Strings.Status.ApplicationInProgress;

		const quotedCount = this.getStatusCount(quoted);
		const applicationInProgressCount = this.getStatusCount(applicationInProgress);

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: quotedCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.Quoted),
				onClick: this.makeLeadBubbleOnClick(Lookups.Quoted)
			},
			{
				counts: [
					{ count: applicationInProgressCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.ApplicationInProgress),
				onClick: this.makeLeadBubbleOnClick(Lookups.ApplicationInProgress)
			},
		];

		return (
			<CountTypeCard
				title={this.props.lookups.getLabel(Strings.Status.DealsInProgress)}
				bubbles={bubbles}
			/>
		);
	}

	public renderCompleteCard() {
		const saleCount: number =
			this.props.statusCounts[Lookups.Sale.label] ||
			this.props.statusCounts[Lookups.Sale.value] ||
			this.props.statusCounts[Lookups.Sale.label.toLowerCase()] ||
			this.props.statusCounts[Lookups.Sale.value.toLowerCase()] ||
			0;

		let noSaleCount: number = 0;
		Object.keys(this.props.statusCounts).forEach(key => {
			const lookup = this.props.lookups.findOrCreate(key) || this.props.lookups.findOrCreate(key.toLowerCase());
			if (lookup.isChildOf(Lookups.NoSale)) {
				const value = this.props.statusCounts[key] || this.props.statusCounts[key.toLowerCase()];
				noSaleCount = noSaleCount + value;
			}
		});

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: saleCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.Sale),
				onClick: this.makeLeadBubbleOnClick(Lookups.Sale)
			},
			{
				counts: [
					{ count: noSaleCount }
				],
				caption: this.props.lookups.getLabel(Strings.Status.NoSale),
				onClick: this.makeLeadBubbleOnClick(Lookups.NoSale)
			},
		];

		return (
			<CountTypeCard
				title={Strings.LeadType.Complete}
				bubbles={bubbles}
			/>
		);
	}

	public renderProductsSoldCard() {
		const pendingCount: number =
			this.props.productStatusCounts[productStatusRef.pending]
			|| 0;
		const pendingCustomerCount: number =
			this.props.productStatusCounts[productStatusRef.pendingCustomers]
			|| 0;
		const activeCount: number =
			this.props.productStatusCounts[productStatusRef.active]
			|| 0;
		const activeCustomerCount: number =
			this.props.productStatusCounts[productStatusRef.activeCustomers]
			|| 0;
		const terminatedCount: number =
			this.props.productStatusCounts[productStatusRef.terminated]
			|| 0;
		const terminatedCustomerCount: number =
			this.props.productStatusCounts[productStatusRef.terminatedCustomers]
			|| 0;

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: pendingCustomerCount, caption: Strings.ProductCountType.Customer, excludeFromTotalCount: true },
					{ count: pendingCount, caption: Strings.ProductCountType.Products }
				],
				caption: Strings.ProductStatus.Pending,
				onClick: this.makeProductBubbleOnClick(Strings.ProductStatus.Pending, undefined)
			},
			{
				counts: [
					{ count: activeCustomerCount, caption: Strings.ProductCountType.Customer, excludeFromTotalCount: true },
					{ count: activeCount, caption: Strings.ProductCountType.Products }
				],
				caption: Strings.ProductStatus.Active,
				onClick: this.makeProductBubbleOnClick(Strings.ProductStatus.Active, undefined)
			},
			{
				counts: [
					{ count: terminatedCustomerCount, caption: Strings.ProductCountType.Customer, excludeFromTotalCount: true },
					{ count: terminatedCount, caption: Strings.ProductCountType.Products }
				],
				caption: Strings.ProductStatus.Terminated,
				onClick: this.makeProductBubbleOnClick(Strings.ProductStatus.Terminated, undefined)
			},
		];

		return (
			<CountTypeCard
				title={Strings.ProductCountType.ProductsSold}
				bubbles={bubbles}
			/>
		);
	}

	public renderUnmatchedPoliciesCard() {
		const unmatchedCount: number =
			this.props.productStatusCounts[productStatusRef.unmatched] ||
			0;

		const bubbles: BubbleProps[] = [
			{
				counts: [
					{ count: unmatchedCount },
				],
				caption: Strings.ProductMatch.Unmatched,
				onClick: this.makeProductBubbleOnClick(undefined, Strings.ProductMatch.Unmatched)
			},
		];

		return (
			<CountTypeCard
				title={Strings.ProductCountType.UnmatchedPolicies}
				bubbles={bubbles}
			/>
		);
	}

	renderFabMenu() {
		const icons: FabMenuItem[] = [
			{
				label: 'Household Lead',
				icon: 'perm_identity',
				onClick: () => {
					nav.push(
						navRoutes.leadUpsert.path
							.replace(Strings.Navigation.LeadId, '0')
							.replace(Strings.Navigation.HouseholdId, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateLead),
			},
			{
				label: 'Employer Lead',
				icon: 'work',
				onClick: () => nav.push(navRoutes.employerLeadUpsert.path
					.replace(Strings.Navigation.LeadId, '0')
					.replace(Strings.Navigation.EmployerId, '0')
				),
				disabled: !jwt_auth.hasPermission(P.CreateLead),
			},
		];

        return <FabMenu items={icons} />
	}

	public render() {
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="CRM"
						isLoading={this.props.isLoading}
						rightButtons={
							<>
								<IconButton
									key="filter"
									color="inherit"
									onClick={() => {
										this.handleLeadFilters(Lookups.All, true);
										nav.push(navRoutes.leadList.path);
									}}
								>
									<SearchIcon/>
								</IconButton>
								<MoreMenu
									color={themePalette.negative_text}
									children={[
										{
											onClick: makeOpenExternalLink(themeLinks.helpLinkCRM),
											children: 'Help',
										},
									]}
								/>
							</>
						}
					/>
				}
				bottomComponent={
					jwt_auth.hasPermission(P.CreateLead) ? this.renderFabMenu() : <div />
				}
			>
				<PersistentNotifications />
				<NotificationSummary categorySelector={notificationCategories.crm}/>
				<DesktopPadding>
					{this.renderNewLeadsCard()}
					{this.renderAttemptedLeadsCard()}
					{this.renderContactedLeadsCard()}
					{this.renderDealsInProgressLeadsCard()}
					{this.renderCompleteCard()}
					{this.renderProductsSoldCard()}
					{this.renderUnmatchedPoliciesCard()}
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.user.agentID,
	statusCounts: state.lead.statusCounts,
	productStatusCounts: state.policy.productStatusCounts,
	isLoading: state.lead.isLoading,
	pageNum: state.lead.leadListPageNumber,
	pageSize: state.lead.leadListPageSize,
	lookups: state.lookup,
	applicationCount: state.application.applications.length,
	policyCount: state.policy.policies.length,
	leadCount: state.lead.leads.length,
	userId: state.user.id,
	productDownlineAgent: state.product.downlineAgent,
});
const mapDispatchToProps = (dispatch: any, ownProps: Props): DispatchProps => ({
	getFilteredLeads: (leadFilters: LeadFilters) => {
		const payload = formatLeadFiltersPayload(
			leadFilters,
			0,
			ownProps.pageSize
		);
		return dispatch(GetFilteredLeads.started(payload));
	},
	getDashboardCounts: () => dispatch(GetDashboardCounts.started()),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	storeLeadFilters: (filterValues: LeadFilters) =>
		dispatch(StoreLeadFilters(filterValues)),
	searchProducts: (filterValues: ProductFilterPayload) =>
		dispatch(SearchProducts.started(filterValues)),
});
export const LeadDashboardPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(LeadDashboardPage);