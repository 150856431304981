import { connect } from '@hmkts/rise';
import {
	Button,
	Dialog,
	Grid,
	Icon,
} from '@material-ui/core';
import React from 'react';
import { SlideUp } from '../../components/utility/transition';
import { AppState } from '../../reducers';
import { pin_auth } from '../../utilities/auth';
import { themePalette } from '../../utilities/branding';
import { isMobileDevice } from '../../utilities/is_mobile';

interface PinProps {
	isOpen: boolean;
}
interface PinState {
	pin: string;
	drawablePin: string;
	tempPin: string;
	error: string | null;
	message: string;
	validatedPin: boolean;
}

class PinPage extends React.PureComponent<PinProps, PinState> {

	constructor(props: any) {
		super(props);
		let error: string | null = null;
		if (props.incorrectAttempts > 0) {
			error =
				'Incorrect Pin. ' +
				(props.allowedAttempts - props.incorrectAttempts).toString() +
				' attempts remaining';
		}
		this.state = {
			pin: '',
			drawablePin: '_ _ _ _',
			tempPin: '',
			error,
			message: this.getDefaultMessage(false),
			validatedPin: false,
		};

		this.resetState();
	}

	componentDidUpdate() {
		if (this.state.pin.length == 4) {
			this.onPinCodeEntered();
		}
		this.resetState();
	}

	// Default values
	getDefaultMessage = (validated: boolean | undefined = undefined): string => {
		let message = 'Enter your 4 digit Pin code';
		if (pin_auth.setting_pin) {
			if (validated == undefined ? this.state.validatedPin : validated) {
				message = 'Enter a 4 digit Pin code';
			} else {
				message = 'Enter your current 4 digit Pin code';
			}
		}

		return message;
	}

	resetState = () => {
		if (pin_auth.setting_pin) {
			pin_auth.hasPin().then((hasPin: boolean) => {
				if (!hasPin) {
					this.setState({
						message: this.getDefaultMessage(true),
						validatedPin: true,
					});
				}

				if (pin_auth.reset_pin) {
					this.setState({
						message: this.getDefaultMessage(true),
						validatedPin: true,
					});
				}
			});
		}
	};

	onNumberPress = (num: number): void => {
		if (this.state.pin.length < 4) {
			this.setState({
				pin: this.state.pin + num.toString(),
				drawablePin: this.getDrawablePin(num),
			});
		}
	}

	onBackspace = (): void => {
		if (this.state.pin.length > 0) {
			this.setState({
				pin: this.state.pin.substr(0, this.state.pin.length - 1),
				drawablePin: this.getDrawablePin(-1),
			});
		}
	}

	onCancel = (): void => {
		if (pin_auth.setting_pin) {
			pin_auth.setting_pin = false;
			pin_auth.dismissPin();
		} else {
			pin_auth.cancelVerification();
		}
	}

	onPinCodeEntered = (): void => {
		if (pin_auth.setting_pin) {
			if (this.state.tempPin.length == 4) {
				if (this.state.tempPin == this.state.pin) {
					pin_auth.setPin(this.state.pin);
				} else {
					this.setState({
						pin: '',
						drawablePin: '_ _ _ _',
						tempPin: '',
						error: 'Pin Codes do not match',
						message: this.getDefaultMessage(),
					});
				}
			} else if (this.state.validatedPin) {
				const pin = this.state.pin;
				this.setState({
					pin: '',
					drawablePin: '_ _ _ _',
					tempPin: pin,
					error: null,
					message: 'Confirm Pin Code',
				});
			} else {
				pin_auth.verifyPin(this.state.pin).then((isCorrect: boolean) => {
					if (isCorrect) {
						this.setState({
							pin: '',
							drawablePin: '_ _ _ _',
							tempPin: '',
							error: null,
							message: 'Enter a 4 digit Pin code',
							validatedPin: true,
						});
					} else {
						this.setState({
							pin: '',
							drawablePin: '_ _ _ _',
							tempPin: '',
							error:
								'Incorrect Pin. ' +
								(
									pin_auth.allowed_attempts - pin_auth.incorrect_attempts
								).toString() +
								' attempts remaining',
							message: 'Enter your existing 4 digit Pin code',
							validatedPin: false,
						});
					}
				});
			}
		} else {
			pin_auth.verifyPin(this.state.pin).then((successful: boolean) => {
				if (!successful) {
					const error =
						'Incorrect Pin. ' +
						(
							pin_auth.allowed_attempts - pin_auth.incorrect_attempts
						).toString() +
						' attempts remaining';
					const pin = '';
					const drawablePin = '_ _ _ _';
					this.setState({ error, pin, drawablePin });
				} else {
					this.setState({
						pin: '',
						drawablePin: '_ _ _ _',
						tempPin: '',
						error: null,
						message: this.getDefaultMessage(true),
					});
				}
			});
		}
	}

	// Drawing
	drawNumberButton = (num: number): JSX.Element => {
		const numberButtonStyle: React.CSSProperties = {
			backgroundColor: themePalette.pin_lock_background,
			color: themePalette.negative_text,
			width: '100%',
			height: '64px',
		};

		if (num == -1) {
			return (
				<Button style={numberButtonStyle} onClick={this.onBackspace}>
					<Icon>backspace</Icon>
				</Button>
			);
		} else if (num == -2) {
			return (
				<Button style={numberButtonStyle} onClick={this.onCancel}>
					<Icon>cancel</Icon>
				</Button>
			);
		} else {
			return (
				<Button
					style={numberButtonStyle}
					onClick={() => this.onNumberPress(num)}
				>
					{num.toString()}
				</Button>
			);
		}
	}

	drawCol = (el: JSX.Element, index: number): JSX.Element => {
		return (
			<Grid key={`pin-${index}`} item xs={4}>
				{el}
			</Grid>
		);
	}

	drawRow = (values: number[]) => {
		const cols: JSX.Element[] = [];
		for (let i = 0; i < values.length; i++) {
			cols.push(this.drawCol(this.drawNumberButton(values[i]), i));
		}
		return <Grid container>{cols}</Grid>;
	}

	drawNumberPad = (): JSX.Element => {
		const numberBackgroundStyle: React.CSSProperties = {
			backgroundColor: themePalette.pin_lock_background,
			color: themePalette.negative_text,
			position: 'fixed',
			bottom: '0px',
			width: '100%',
		};
		return (
			<div style={numberBackgroundStyle}>
				{this.drawRow([1, 2, 3])}
				{this.drawRow([4, 5, 6])}
				{this.drawRow([7, 8, 9])}
				{this.drawRow([-2, 0, -1])}
			</div>
		);
	}

	getDrawablePin = (num: number): string => {
		let pinToDraw: string = '';
		let pin =
			num == -1
				? this.state.pin.substr(0, this.state.pin.length - 1)
				: this.state.pin + num.toString();
		while (pin.length < 4) {
			pin += '_';
		}
		for (let i = 0; i < pin.length; i++) {
			pinToDraw += pin.substr(i, 1) + ' ';
		}
		if (pinToDraw.length > 0) {
			pinToDraw = pinToDraw.substr(0, pinToDraw.length - 1);
		}
		return pinToDraw;
	}

	drawPinField = (): JSX.Element => {
		const pinInfoStyle = {
			...styles.pinDescriptionStyle,
			color: themePalette.sub_text,
		};
		const pinErrorStyle = {
			...styles.pinDescriptionStyle,
			color: themePalette.error,
		}
		const pinDescription = this.state.error || this.state.message;
		const pinDescriptionStyle =
			this.state.error
				? pinErrorStyle
				: pinInfoStyle;
		return (
			<div style={styles.pinDisplayContainerStyle}>
				<div style={{ textAlign: 'center', width: '100%' }}>
					<div style={styles.pinDisplayStyle}>
						{this.state.drawablePin}
						<span style={{ width: '100%', display: 'inline-block' }} />
					</div>
					<div style={pinDescriptionStyle}>{pinDescription}</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<Dialog open={this.props.isOpen && isMobileDevice} fullScreen TransitionComponent={SlideUp}>
				<div
					style={{
						backgroundColor: themePalette.secondary_background,
						height: window.innerHeight,
					}}
				>
					{this.drawPinField()}
					{this.drawNumberPad()}
				</div>
			</Dialog>	
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	pinDisplayContainerStyle: {
		display: 'flex',
		alignItems: 'center',
		height: '50%',
	},
	pinDisplayStyle: {
		color: 'black',
		fontWeight: 'bold',
		fontSize: '26px',
		textAlign: 'justify',
		verticalAlign: 'middle',
		letterSpacing: '8px',
		width: '60%',
		display: 'inline-block',
	},
	pinDescriptionStyle: {
		textAlign: 'center',
		padding: '10px',
		textOverflow: 'ellipsis',
		fontSize: '18px',
	},
};

const mapStateToProps = (state: AppState): PinProps => ({
	isOpen: state.authentication.needsPin,
});
export const PinPageContainer = connect(mapStateToProps, undefined, true)(PinPage);