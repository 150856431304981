import { change, getFormValues, Field } from 'redux-form';
import { SearchFilterFormValues } from '../../../reducers/advanced_search_reducer';
import React from 'react';
import { FormSelect, FormTextField } from '../../../utilities/forms';
import _ from 'lodash';
import uuid from 'uuid';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	FormControl,
	MenuItem,
	Grid,
	Chip,
} from '@material-ui/core';
import { connect } from '@hmkts/rise';
import {
	normalizeZipCode,
	normalizePhone,
} from '../../../utilities/formatting/data_normalizations';
import { MinMax, MultiSelect } from '../../../components/redux_form_material';
import {
	CitySearchResult,
	CountySearchResult,
	ZipCodeSearchResult,
} from '../../../reducers/geographic_search_reducer';
import {
	getCityAutoCompleteData,
	getCountyAutoCompleteData,
	getZipCodeAutoCompleteData,
} from '../../../actions/geographic_search_actions';
import { US_State, US_STATES } from '../../../utilities/utilities';
import { validatePhone } from '../../../utilities/form_validation';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import moment from 'moment';
import { themePalette } from '../../../utilities/branding';
import { isBrokerage } from '../../../utilities/brokerage_utils';
import { Lookups } from '../../../utilities/lookup';
import { BlankYesNoMenuOptionsArray } from '../../../components/utility/form';
import { AgeRangePicker } from '../../../components/redux_form_material/age_range_picker';
import { normalizeMonth, normalizeDay } from '../../../utilities/formatting/date_time_formatting';
import { normalizeNumeric, normalizeNames } from '../../../utilities/formatting/string_normalization';
import { Strings } from '../../../assets/common/strings';

interface ComponentProps {
	formName: string;
	onlyCommon?: boolean;
}
interface DispatchProps {
	changeFieldValue: (form: string, field: string, value: any) => void;
	getCityAutoCompleteData: (citySearchText: string) => void;
	getCountyAutoCompleteData: (countySearchText: string) => void;
	getZipCodeAutoCompleteData: (zipCodeSearchText: string) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
}
interface StateProps {
	formValues: SearchFilterFormValues;
	genderList: string[];
	clientTypeList: string[];
	maritalStatusList: string[];
	cityAutoCompleteOptions: CitySearchResult[];
	countyAutoCompleteOptions: CountySearchResult[];
	zipCodeAutoCompleteOptions: ZipCodeSearchResult[];
	personDemographicsCommonExpanded: boolean;
	personDemographicsAdvancedExpanded: boolean;
	personDemographicsGeographicExpanded: boolean;
}
type Props = ComponentProps & StateProps & DispatchProps;

interface State {
	currentCitySearchText: string;
	currentCountySearchText: string;
	currentZipCodeSearchText: string;
}

class _PersonDemographicFields extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			currentCitySearchText: '',
			currentCountySearchText: '',
			currentZipCodeSearchText: '',
		};
	}

	handleExpandCommonClick = () => {
		this.props.expandFilterFields('personDemographicsCommonExpanded');
	};

	handleExpandAdvancedClick = () => {
		this.props.expandFilterFields('personDemographicsAdvancedExpanded');
	};

	handleExpandGeographicsClick = () => {
		this.props.expandFilterFields('personDemographicsGeographicExpanded');
	};

	getListOfStateAbbreviations = () => {
		let listOfStatesAbbreviations: string[] = [];
		US_STATES.forEach((state: US_State) => {
			listOfStatesAbbreviations.push(state.abbreviation);
		});
		return listOfStatesAbbreviations;
	};

	stringStartsWith = (value: string, searchText: string) => {
		const searchLength: number = searchText.length;
		return (
			value.toLowerCase().slice(0, searchLength) ===
			searchText.toLowerCase().slice(0, searchLength)
		);
	};

	fetchCityData = (value: string) => {
		this.setState({ currentCitySearchText: value });
		if (value.length > 2) {
			this.props.getCityAutoCompleteData(value);
		}
	};

	filterListOfCities = () => {
		let filteredList: string[] = [];
		this.props.cityAutoCompleteOptions.forEach((city: CitySearchResult) => {
			if (
				city.CityName &&
				this.stringStartsWith(city.CityName, this.state.currentCitySearchText)
			) {
				const stateIndex: number = US_STATES.findIndex((state: US_State) => {
					return state.name === city.StateName;
				});
				if (stateIndex !== -1) {
					filteredList.push(
						city.CityName + ', ' + US_STATES[stateIndex].abbreviation
					);
				}
			}
		});
		return filteredList;
	};

	fetchCountyData = (value: string) => {
		this.setState({ currentCountySearchText: value });
		if (value.length > 2) {
			this.props.getCountyAutoCompleteData(value);
		}
	};

	filterListOfCounties = () => {
		let filteredList: string[] = [];
		this.props.countyAutoCompleteOptions.forEach(
			(county: CountySearchResult) => {
				if (
					county.CountyName &&
					this.stringStartsWith(
						county.CountyName,
						this.state.currentCountySearchText
					)
				) {
					const stateIndex: number = US_STATES.findIndex((state: US_State) => {
						return state.name === county.StateName;
					});
					if (stateIndex !== -1) {
						filteredList.push(
							county.CountyName + ', ' + US_STATES[stateIndex].abbreviation
						);
					}
				}
			}
		);
		return filteredList;
	};

	generateZipChipValue = (zipCode: string, radius: string | undefined) => {
		if (radius && radius !== 'Exact') {
			return zipCode + ' + ' + normalizeNumeric(radius) + ' miles';
		} else {
			return zipCode + ' + 0 miles';
		}
	};

	handleZipCodeSelect = (
		currentValues: string[] | undefined,
		newChipValue: string
	) => {
		let newZipCodeValues: string[] = [];
		if (Boolean(currentValues) && currentValues !== undefined) {
			newZipCodeValues = currentValues.slice();
		}

		if (!_.includes(currentValues, newChipValue)) {
			newZipCodeValues.push(newChipValue);
			this.props.changeFieldValue(
				this.props.formName,
				'geoZipsWithRadius',
				newZipCodeValues
			);

			this.props.changeFieldValue(this.props.formName, 'geoZipCode', '');
		}
	};

	handleDeleteZipCodeChip = (
		currentStoredChips: string[] | undefined,
		chipToDelete: string
	) => {
		if (currentStoredChips) {
			const currentZipCodes: string[] = currentStoredChips.slice();
			const indexToSplice = currentZipCodes.findIndex((currentChip: string) => {
				return currentChip === chipToDelete;
			});
			currentZipCodes.splice(indexToSplice, 1);
			this.props.changeFieldValue(
				this.props.formName,
				'geoZipsWithRadius',
				currentZipCodes
			);
		}
	};

	renderZipCodeChips = (geoZipsWithRadius: string[]) => {
		if (geoZipsWithRadius) {
			return geoZipsWithRadius.map((geoZipWithRadius: string) => {
				return (
					<Chip
						key={geoZipWithRadius}
						label={geoZipWithRadius}
						onDelete={() =>
							this.handleDeleteZipCodeChip(geoZipsWithRadius, geoZipWithRadius)
						}
						style={{ margin: '2px 2px' }}
					/>
				);
			});
		}
	};

	validateBirthdateMonthMin = (value: string, allValues: any, props: any) => {
		if (value && !props.formValues?.dayMin) {
			return 'Day is required';
		}

		const date = moment(
			'2000 ' + value + ' ' + props?.formValues?.dayMin,
			'YYYY MM DD'
		);
		if (!date.isValid()) {
			return 'Invalid Date';
		}

		return undefined;
	}

	validateBirthdateDayMin = (value: string, allValues: any, props: any) => {
		if (value && !props.formValues.monthMin) {
			return 'Month is required';
		}

		return undefined;
	}

	validateBirthdateMonthMax = (value: string, allValues: any, props: any) => {
		if (value && !props.formValues.dayMax) {
			return 'Day is required';
		}

		const date = moment(
			'2000 ' + value + ' ' + props?.formValues?.dayMax,
			'YYYY MM DD'
		);

		if (!date.isValid()) {
			return 'Invalid Date';
		}

		return undefined;
	}

	validateBirthdateDayMax = (value: string, alLValues: any, props: any) => {
		if (value && !props?.formValues?.monthMax) {
			return 'Month is required';
		}

		return undefined;
	}

	render() {
		const geoZipCode: string =
			(this.props.formValues && this.props.formValues.geoZipCode) || '';
		const geoRadius: string =
			(this.props.formValues && this.props.formValues.geoRadius) || '';
		const geoZipsWithRadius: string[] =
			(this.props.formValues && this.props.formValues.geoZipsWithRadius) || [];

		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{'Person Demographics'}
				</Typography>
				<div onClick={this.handleExpandCommonClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Common
						</span>
						<IconButton
							style={
								this.props.personDemographicsCommonExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.personDemographicsCommonExpanded}
					style={{ padding: '0 20px' }}
				>
					<div style={styles.addBottomMargin}>
						<AgeRangePicker name="age" />
					</div>
					<div style={styles.addBottomMargin}>
						<Field
							name="familyStatus"
							label="Family Status"
							component={MultiSelect}
							resultSet={this.props.maritalStatusList}
							fullWidth
						/>
					</div>
					<div style={styles.addBottomMargin}>
						<FormControl fullWidth>
							<Field
								name="selfEmployed"
								label={'Self Employed'}
								component={FormSelect}
								children={BlankYesNoMenuOptionsArray}
								fullWidth
							/>
						</FormControl>
					</div>
					<div style={styles.addBottomMargin}>
						<MinMax
							name="numFamilyMembers"
							title="Number of Family Members"
							min={0}
							integer
						/>
					</div>
				</Collapse>
				{!this.props.onlyCommon &&
					<>
						<div onClick={this.handleExpandAdvancedClick} style={styles.pointer}>
							<CardActions style={{ marginBottom: '0px' }}>
								<span
									style={{
										fontSize: '20px',
										color: themePalette.sub_text,
										paddingLeft: 10,
									}}
								>
									Advanced
								</span>
								<IconButton
									style={
										this.props.personDemographicsAdvancedExpanded
											? styles.expandOpen
											: styles.expand
									}
								>
									<Icon>keyboard_arrow_down</Icon>
								</IconButton>
							</CardActions>
						</div>
						<Collapse
							in={this.props.personDemographicsAdvancedExpanded}
							style={{ padding: '0 20px' }}
						>
							<div style={styles.addBottomMargin}>
								<Field
									name="phone"
									label="Enter Phone..."
									format={normalizePhone}
									component={FormTextField}
									fullWidth
									validate={[validatePhone]}
									normalize={normalizeNumeric}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									name="email"
									component={FormTextField}
									label="Enter Email..."
									fullWidth
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									name="fullName"
									component={FormTextField}
									label="Enter Full Name..."
									fullWidth
									normalize={normalizeNames}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<MinMax
									name="householdIncome"
									title={Strings.Filter.Income}
									min={0}
									integer
									minError={(this.props.formValues?.householdIncomeMin || 0) > (this.props.formValues?.householdIncomeMax || 0) ? 'Value cannot be greater than max' : undefined}
									maxError={(this.props.formValues?.householdIncomeMax || 0) < (this.props.formValues?.householdIncomeMin || 0) ? 'Value cannot be less than min' : undefined}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<MinMax
									name="lastUnemploymentYear"
									title={'Last Unemployment Year'}
									integer
									minPlaceholder={'Min Year'}
									maxPlaceholder={'Max Year'}
									minError={(this.props.formValues?.lastUnemploymentYearMin || 0) > (this.props.formValues?.lastUnemploymentYearMax || 0) ? 'Value cannot be greater than max' : undefined}
									maxError={(this.props.formValues?.lastUnemploymentYearMax || 0) < (this.props.formValues?.lastUnemploymentYearMin || 0) ? 'Value cannot be less than min' : undefined}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<FormControl fullWidth>
									<Grid container>
										<span
											style={{
												fontSize: '16px',
												color: themePalette.sub_text,
											}}
										>
											Birthdate
										</span>
									</Grid>
									<Grid container>
										<Grid item xs={2}>
											<Field
												name={'monthMin'}
												label="Month"
												component={FormTextField}
												fullWidth
												type="number"
												normalize={normalizeMonth}
												validate={[ this.validateBirthdateMonthMin ]}
											/>
										</Grid>
										<Grid item xs={2}>
											<Field
												name={'dayMin'}
												label="Day"
												component={FormTextField}
												fullWidth
												type="number"
												normalize={normalizeDay}
												validate={[ this.validateBirthdateDayMin ]}
											/>
										</Grid>
										<Grid item xs={4}>
											<span
												style={{
													margin: '35px 0 0 25px',
													color: themePalette.sub_text,
													width: '100%',
													textAlign: 'center',
												}}
											>
												to
											</span>
										</Grid>
										<Grid item xs={2}>
											<Field
												name={'monthMax'}
												label="Month"
												component={FormTextField}
												fullWidth
												type="number"
												normalize={normalizeMonth}
												validate={[ this.validateBirthdateMonthMax ]}
											/>
										</Grid>
										<Grid item xs={2}>
											<Field
												name={'dayMax'}
												label="Day"
												component={FormTextField}
												fullWidth
												type="number"
												normalize={normalizeDay}
												validate={[ this.validateBirthdateDayMax ]}
											/>
										</Grid>
									</Grid>
								</FormControl>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									label="Tobacco User"
									name="tobaccoUser"
									component={FormSelect}
									children={BlankYesNoMenuOptionsArray}
									fullWidth
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									name="gender"
									label="Gender"
									component={MultiSelect}
									resultSet={this.props.genderList}
									fullWidth
								/>
							</div>
							{!isBrokerage && <div style={styles.addBottomMargin}>
								<Field
									name="clientType"
									label="Client Type"
									component={MultiSelect}
									resultSet={this.props.clientTypeList}
									fullWidth
								/>
							</div>}
						</Collapse>
						<div onClick={this.handleExpandGeographicsClick} style={styles.pointer}>
							<CardActions style={{ marginBottom: '0px' }}>
								<span
									style={{
										fontSize: '20px',
										color: themePalette.sub_text,
										paddingLeft: 10,
									}}
								>
									Geographic
								</span>
								<IconButton
									style={
										this.props.personDemographicsGeographicExpanded
											? styles.expandOpen
											: styles.expand
									}
								>
									<Icon>keyboard_arrow_down</Icon>
								</IconButton>
							</CardActions>
						</div>
						<Collapse
							in={this.props.personDemographicsGeographicExpanded}
							style={{ padding: '0 20px' }}
						>
							<div style={styles.addBottomMargin}>
								<Field
									name="geoStates"
									label="State"
									component={MultiSelect}
									resultSet={this.getListOfStateAbbreviations()}
									fullWidth
									id={uuid.v4()}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									name="geoCities"
									label="City"
									component={MultiSelect}
									resultSet={this.filterListOfCities()}
									onChangeCallback={this.fetchCityData}
									forceAutoComplete={true}
									fullWidth
									id={uuid.v4()}
								/>
							</div>
							<div style={styles.addBottomMargin}>
								<Field
									name="geoCounties"
									label="County"
									component={MultiSelect}
									resultSet={this.filterListOfCounties()}
									onChangeCallback={this.fetchCountyData}
									forceAutoComplete={true}
									fullWidth
									id={uuid.v4()}
								/>
							</div>
							<Grid container style={{ marginBottom: 4 }}>
								<Grid item xs={6} style={{ paddingRight: 15 }}>
									<Field
										name="geoZipCode"
										label="GeoZipCode"
										component={FormTextField}
										fullWidth
										normalize={normalizeZipCode}
										error={_.includes(
											geoZipsWithRadius,
											this.generateZipChipValue(geoZipCode, geoRadius)
										)}
										helperText={
											_.includes(
												geoZipsWithRadius,
												this.generateZipChipValue(geoZipCode, geoRadius)
											)
												? 'This option has already been selected'
												: null
										}
										id={uuid.v4()}
									/>
								</Grid>
								<Grid item xs={5}>
										<Field
											name="geoRadius"
											label="Radius"
											fullWidth
											component={FormSelect}
											children={[
												'Exact',
												'5 miles',
												'10 miles',
												'15 miles',
												'20 miles',
												'25 miles',
												'30 miles',
												'35 miles',
												'40 miles',
												'45 miles',
												'50 miles',
											].map(option => <MenuItem value={option}>{option}</MenuItem>)}
										/>
								</Grid>
								<Grid item xs={1}>
									<IconButton
										onClick={() =>
											this.handleZipCodeSelect(
												geoZipsWithRadius,
												this.generateZipChipValue(geoZipCode, geoRadius)
											)
										}
										key="add-zip-chip-icon-button"
										style={styles.noPadding}
										disabled={
											!geoZipCode ||
											geoZipCode.length !== 5 ||
											_.includes(
												geoZipsWithRadius,
												this.generateZipChipValue(geoZipCode, geoRadius)
											)
										}
									>
										<Icon>add_circle</Icon>
									</IconButton>
								</Grid>
							</Grid>
							<Grid container style={styles.addBottomMargin}>
								{this.renderZipCodeChips(geoZipsWithRadius)}
							</Grid>
						</Collapse>
					</>}
			</div>
		);
	}
}

function mapStateToProps(state: any, ownProps: ComponentProps): StateProps {
	const expandedStates = state.advancedSearch.expandedStates;

	return {
		formValues: getFormValues(ownProps.formName)(state),
		genderList: state.lookup.getLabels(Lookups.Gender),
		clientTypeList: state.lookup.getLabels(Lookups.ClientType),
		maritalStatusList: state.lookup.getLabels(Lookups.MaritalStatus),
		cityAutoCompleteOptions: state.geographicSearch.cityAutoCompleteOptions,
		countyAutoCompleteOptions: state.geographicSearch.countyAutoCompleteOptions,
		zipCodeAutoCompleteOptions:
			state.geographicSearch.zipCodeAutoCompleteOptions,
		personDemographicsCommonExpanded:
			expandedStates.personDemographicsCommonExpanded,
		personDemographicsAdvancedExpanded:
			expandedStates.personDemographicsAdvancedExpanded,
		personDemographicsGeographicExpanded:
			expandedStates.personDemographicsGeographicExpanded,
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		changeFieldValue: (form: string, field: string, value: any) =>
			dispatch(change(form, field, value)),
		getCityAutoCompleteData: (citySearchText: string) =>
			dispatch(getCityAutoCompleteData.started(citySearchText)),
		getCountyAutoCompleteData: (countySearchText: string) =>
			dispatch(getCountyAutoCompleteData.started(countySearchText)),
		getZipCodeAutoCompleteData: (zipCodeSearchText: string) =>
			dispatch(getZipCodeAutoCompleteData.started(zipCodeSearchText)),
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
	};
}

export const PersonDemographicFields = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(_PersonDemographicFields);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	addBottomMargin: {
		marginBottom: 15,
	},
	pointer: {
		cursor: 'pointer',
	},
};
