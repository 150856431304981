import React from 'react';
import { Field, getFormValues } from 'redux-form';
import {
	Typography,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemText,
	Divider,
	ListItemIcon,
	ListItemSecondaryAction,
	Dialog,
	FormControlLabel,
	MenuItem,
	Menu,
	DialogContent,
	DialogActions,
	Button,
	Tooltip,
	IconButton,
	Box,
	DialogTitle,
	DialogContentText,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { connect } from '@hmkts/rise';
import { Phone } from '../../reducers/ContactReducer';
import moment from 'moment';
import { change, touch } from 'redux-form';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { TextField as StandardTextField } from '@material-ui/core';
import { Switch as StandardSwitch } from '@material-ui/core';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import {
	validatePhone,
	validateExtension,
} from '../../utilities/form_validation';
import uuid from 'uuid';
import { ValueHolder } from '../../components/redux_form_material';
import { createRequiredLabel } from '../../components/utility/required_label';
import { Strings as S, Strings } from '../../assets/common/strings';
import { enforceStylesType } from '../../utilities/styles_util';

export type PhoneFieldsData = {
	phones: Phone[];
	phone?: string;
};

interface ComponentProps {
	formName: string;
	showAsterisk: boolean;
}

interface StateProps {
	formValues: PhoneFieldsData;
}

interface DispatchProps {
	changeFieldValue(field: string, value: any): void;
	touchField(field: string): void;
}

interface State {
	showEditDialog: boolean;
	itemToEdit: number;
	editPhoneNumber: string;
	editPhoneExtension: string;
	editPhoneType: Strings.PhoneTypes;
	editPhoneIsPreferred: boolean;
	editPhoneIsNationalDnc: boolean;
	editPhoneIsCompanyDnc: boolean;
	editPhoneIsSmsDnc: boolean;
	editPhoneRPM_NumberChanged: boolean;
	anchorEl?: HTMLElement;
	phoneIndex?: number;
	isCompanyDncDialogVisible: boolean;
	isSmsDncDialogVisible: boolean;
	formHasChanged: boolean;
}

type Props = ComponentProps & StateProps & DispatchProps;

export class _PhoneFields extends React.Component<Props, State> {
	styles: { [key: string]: React.CSSProperties } = {
		editListItem: {
			margin: '20px',
			width: '90%',
		},
		field: {
			marginLeft: '15px',
		},
		moreVertStyle: {
			marginTop: -5,
			marginLeft: 6,
			cursor: S.CSS.Pointer,
		},
		saveIconStyle: {
			marginTop: 6,
			fontSize: 28,
			color: themePalette.sub_text,
			cursor: S.CSS.Pointer,
		},
		preferred: {
			color: themePalette.preferred_item,
		},
		national_dnc: {
			color: themePalette.national_dnc,
		},
		company_dnc: {
			color: themePalette.company_dnc,
		},
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			showEditDialog: false,
			itemToEdit: 0,
			editPhoneNumber: '',
			editPhoneExtension: '',
			editPhoneType: Strings.PhoneTypes.Home,
			editPhoneIsPreferred: false,
			editPhoneIsNationalDnc: false,
			editPhoneIsCompanyDnc: false,
			editPhoneIsSmsDnc: false,
			editPhoneRPM_NumberChanged: false,
			isCompanyDncDialogVisible: false,
			isSmsDncDialogVisible: false,
			formHasChanged: false,
		};
	}

	onClickAdd = () => {
		const phones = (this.props.formValues.phones || []).slice();
		let isPreferred = true;
		if (phones.length) {
			// not the first phone
			isPreferred = false;
		}
		this.setState({
			showEditDialog: true,
			itemToEdit: phones.length,
			editPhoneNumber: '',
			editPhoneExtension: '',
			editPhoneType: Strings.PhoneTypes.Home,
			editPhoneIsPreferred: isPreferred,
			editPhoneIsNationalDnc: false,
			editPhoneIsCompanyDnc: false,
			editPhoneIsSmsDnc: false,
			editPhoneRPM_NumberChanged: false,
		});
		this.setState({ anchorEl: undefined, phoneIndex: undefined });
		this.props.touchField(S.ContactInfoTypes.Phones);
	}

	onClickEdit = () => {
		if (this.state.phoneIndex !== undefined) {
			const index = this.state.phoneIndex;
			const phoneToEdit = this.props.formValues.phones[index];
			this.setState({
				showEditDialog: true,
				itemToEdit: index,
				editPhoneNumber: phoneToEdit.number,
				editPhoneExtension: phoneToEdit.extension || '',
				editPhoneType: phoneToEdit.type,
				editPhoneIsPreferred: phoneToEdit.isPreferred,
				editPhoneIsNationalDnc: phoneToEdit.isNationalDnc,
				editPhoneIsCompanyDnc: phoneToEdit.isCompanyDnc,
				editPhoneIsSmsDnc: phoneToEdit.isSmsDnc,
				editPhoneRPM_NumberChanged:
					phoneToEdit.wasRpmPhoneNumberChanged || false,
			});
			this.setState({ anchorEl: undefined, phoneIndex: undefined });
		}
	}

	onClickDelete = () => {
		if (this.state.phoneIndex !== undefined) {
			const index = this.state.phoneIndex;
			let phones = this.props.formValues.phones.slice();
			phones.splice(index, 1);
			if (phones.length > 0 && !phones.some(phone => phone.isPreferred))
				phones[0].isPreferred = true;
			this.props.changeFieldValue(S.ContactInfoTypes.Phones, phones);
			this.props.touchField(S.ContactInfoTypes.Phones);
			this.setState({ anchorEl: undefined, phoneIndex: undefined });
		}
	}

	onClickSetPreferred = () => {
		if (this.state.phoneIndex !== undefined) {
			const index = this.state.phoneIndex;
			const currentPreferredIndex = this.props.formValues.phones.findIndex(
				phone => phone.isPreferred
			);
			let phones = this.props.formValues.phones.slice();
			if (currentPreferredIndex > -1)
				phones[currentPreferredIndex].isPreferred = false;
			phones[index].isPreferred = true;
			this.props.changeFieldValue(S.ContactInfoTypes.Phones, phones);
			this.props.touchField(S.ContactInfoTypes.Phones);
			this.setState({ anchorEl: undefined, phoneIndex: undefined });
		}
	}

	onEditPhone = () => {
		let phones = (this.props.formValues.phones || []).slice();
		if (this.state.editPhoneIsPreferred) {
			this.updatePreferredPhones();
		}
		let id = uuid.v4();
		if (phones.length > this.state.itemToEdit) {
			id = phones[this.state.itemToEdit].id;
		}
		let updatedPhone: Phone = {
			id: id,
			number: this.state.editPhoneNumber,
			extension:
				this.state.editPhoneType === Strings.PhoneTypes.Business
					? this.state.editPhoneExtension
					: '',
			type: this.state.editPhoneType,
			isPreferred: this.state.editPhoneIsPreferred,
			isCompanyDnc: this.state.editPhoneIsCompanyDnc,
			wasCompanyDncSet: this.state.editPhoneIsCompanyDnc,
			isNationalDnc: this.state.editPhoneIsNationalDnc,
			wasRpmPhoneNumberChanged: this.state.editPhoneRPM_NumberChanged,
			dateCreated: moment().toDate(),
			isSmsDnc: this.state.editPhoneIsSmsDnc
		};
		phones[this.state.itemToEdit] = updatedPhone;

		let preferredPhones: Phone[] = [];
		let otherPhones: Phone[] = [];
		phones.forEach((phone: Phone) => {
			phone.isPreferred ? preferredPhones.push(phone) : otherPhones.push(phone);
		});
		let sortedPhones: Phone[] = preferredPhones.concat(otherPhones);

		this.props.changeFieldValue(S.ContactInfoTypes.Phones, sortedPhones);
		this.props.touchField(S.ContactInfoTypes.Phones);
	}

	onUpdatePhoneNumber = (event: any) => {
		let updatedPhoneNumber = normalizePhone(event.target.value);
		this.setState({
			editPhoneNumber: updatedPhoneNumber,
			editPhoneRPM_NumberChanged: true,
			formHasChanged: true,
		});
	}

	onUpdatePhoneExtension = (event: any) => {
		let updatedPhoneExtension = event.target.value;
		this.setState({
			editPhoneExtension: updatedPhoneExtension,
			formHasChanged: true,
		});
	}

	onUpdatePhoneType = (event: any) => {
		let updatedType = event.target.value;
		this.setState({
			editPhoneType: updatedType,
			formHasChanged: true,
		});
	}

	onUpdatePhonePreferred = (event: any) => {
		this.setState({
			editPhoneIsPreferred: !this.state.editPhoneIsPreferred,
			formHasChanged: true,
		});
	}

	onCompanyDnc = (event: any) => {
		const checked = event.target.checked;
		this.setState({
			isCompanyDncDialogVisible: checked,
			editPhoneIsCompanyDnc: checked,
			formHasChanged: true,
		});
	};

	onSmsDnc = (event: any) => {
		const checked = event.target.checked;
		this.setState({
			isSmsDncDialogVisible: checked,
			editPhoneIsSmsDnc: checked,
			formHasChanged: true,
		});
	};

	updatePreferredPhones = () => {
		const phones = (this.props.formValues.phones || []).slice();
		if (phones.length > 0) {
			const newFormValues = phones.forEach(phone => {
				phone.isPreferred = false;
			});

			this.props.changeFieldValue(this.props.formName, newFormValues);
		}
	}

	phoneHeader = () => {
		return (
			<Grid container>
				<Grid item xs={9} sm={10}>
					<Typography variant="subtitle1" style={{ padding: 16 }}>
						{this.props.showAsterisk
							? createRequiredLabel('Phone Numbers:')
							: 'Phone Numbers:'}
					</Typography>
				</Grid>
				<Grid item xs={3} sm={2} style={{ textAlign: S.CSS.Center }}>
					<div>
						<IconButton onClick={this.onClickAdd} style={this.styles.saveIconStyle}>
							<Icon>add_circle_outline</Icon>
						</IconButton>
					</div>
				</Grid>
			</Grid>
		);
	}

	listPhones = () => {
		const phones = this.props.formValues.phones || [];
		return (
			<List>
				{phones.map((phone: Phone, index: number) => {
					let secondaryText = '';
					let iconStyle: React.CSSProperties | undefined = undefined;
					if (phone.isNationalDnc) {
						iconStyle = this.styles.national_dnc;
						secondaryText = S.ContactInfoTypes.NationDNC;
					} else if (phone.isCompanyDnc) {
						iconStyle = this.styles.company_dnc;
						secondaryText = S.ContactInfoTypes.CompanyDNC;
					} else if (phone.isPreferred) {
						iconStyle = this.styles.preferred;
						secondaryText = S.ContactInfoTypes.Preferred;
					}
					if (secondaryText) {
						secondaryText += ', ';
					}

					secondaryText = secondaryText + phone.type;
					const extension =
						phone.extension && phone.extension.length > 0
							? ` Ext: ${phone.extension}`
							: ``;
					return (
						<div key={`${index}-${phone.number}`}>
							<Divider />
							<ListItem>
								<ListItemIcon style={iconStyle}>
									<Icon>phone</Icon>
								</ListItemIcon>
								<ListItemText
									primary={normalizePhone(phone.number).concat(extension)}
									secondary={secondaryText}
								/>
								<ListItemSecondaryAction
									style={{ paddingTop: 12, marginRight: 15 }}
								>
									<IconButton
										style={this.styles.moreVertStyle}
										onClick={event =>
											this.setState({
												anchorEl: event.currentTarget,
												phoneIndex: index,
											})
										}
									>
										<Icon>more_vert</Icon>
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</div>
					);
				})}
				<Menu
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={() =>
						this.setState({
							anchorEl: undefined,
							phoneIndex: undefined,
						})
					}
					key="menu"
				>
					<MenuItem onClick={this.onClickSetPreferred}>
						Mark as Preferred
					</MenuItem>
					<MenuItem onClick={this.onClickEdit}>Edit</MenuItem>
					<MenuItem onClick={this.onClickDelete}>Delete</MenuItem>
				</Menu>
			</List>
		);
	}

	editDialog = () => {
		const phones = this.props.formValues.phones || [];
		if (phones != []) {
			const isPhoneInvalid = validatePhone(this.state.editPhoneNumber);
			const isExtensionValid = validateExtension(this.state.editPhoneExtension);
			let isExtensionInvalid =
				this.state.editPhoneType === Strings.PhoneTypes.Business
					? validateExtension(this.state.editPhoneExtension)
					: false;
			if (isExtensionInvalid !== false) {
				if (isExtensionInvalid !== undefined) {
					isExtensionInvalid = true;
				} else {
					isExtensionInvalid = false;
				}
			}
			const phoneNumberField: JSX.Element = (
				<div>
					<Grid container>
						<StandardTextField
							label={S.Phones.Number}
							value={normalizePhone(this.state.editPhoneNumber)}
							onChange={this.onUpdatePhoneNumber}
							style={this.styles.editListItem}
							error={!!isPhoneInvalid}
							helperText={isPhoneInvalid}
						/>
					</Grid>
				</div>
			);

			const workNumberField: JSX.Element = (
				<div>
					<Grid container>
						<Grid item xs={6}>
							<StandardTextField
								label={S.Phones.Number}
								value={this.state.editPhoneNumber}
								onChange={this.onUpdatePhoneNumber}
								style={this.styles.editListItem}
								error={!!isPhoneInvalid}
								helperText={isPhoneInvalid}
							/>
						</Grid>
						<Grid item xs={6}>
							<StandardTextField
								fullWidth
								label={S.Phones.Extension}
								value={this.state.editPhoneExtension}
								onChange={this.onUpdatePhoneExtension}
								style={{ margin: '20px 20px 20px 0px', width: '90%' }}
								error={!!isExtensionValid}
								helperText={isExtensionValid}
							/>
						</Grid>
					</Grid>
				</div>
			);

			return (
				<Dialog fullScreen open={this.state.showEditDialog}>
					<SaveCancelHeaderBarComponent
						title={S.Phones.Edit}
						onSave={() => {
							this.onEditPhone();
							this.setState({ showEditDialog: false });
						}}
						onCancel={() => this.setState({ showEditDialog: false })}
						saveText="Save"
						isSaveDisabled={
							isPhoneInvalid !== undefined ||
							isExtensionInvalid ||
							!this.state.editPhoneNumber ||
							!this.state.formHasChanged
						}
					/>
					{this.state.editPhoneType === Strings.PhoneTypes.Business
						? workNumberField
						: phoneNumberField}
					<Grid container>
						<Grid item xs={6}>
							<FormControlLabel
								control={
									<StandardSwitch
										checked={this.state.editPhoneIsPreferred}
										onChange={this.onUpdatePhonePreferred}
										color={S.Theming.Primary}
									/>
								}
								style={{ marginLeft: 5, marginTop: 7 }}
								label={S.ContactInfoTypes.Preferred}
							/>
						</Grid>
						<Grid item xs={6}>
							<StandardTextField
								fullWidth
								select
								label="Type"
								value={this.state.editPhoneType || S.ContactInfoTypes.Home}
								onChange={this.onUpdatePhoneType}
							>
								<MenuItem value={S.ContactInfoTypes.Home}>Home</MenuItem>
								<MenuItem value={S.ContactInfoTypes.Business}>Work</MenuItem>
								<MenuItem value={S.ContactInfoTypes.Mobile}>Mobile</MenuItem>
							</StandardTextField>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Tooltip
								id="national-dnc-help"
								title="National Do Not Call values cannot be changed"
								placement={S.CSS.Right}
								enterTouchDelay={0}
							>
								<FormControlLabel
									disabled
									control={
										<StandardSwitch
											disabled={true}
											checked={this.state.editPhoneIsNationalDnc}
										/>
									}
									style={{ marginLeft: 5, marginTop: 7 }}
									label="National Do Not Call"
								/>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Tooltip
								id="company-dnc-help"
								title="Company Do Not Call values cannot be unset"
								placement={S.CSS.Right}
								enterTouchDelay={0}
								disableFocusListener={!this.state.editPhoneIsCompanyDnc}
								disableHoverListener={!this.state.editPhoneIsCompanyDnc}
								disableTouchListener={!this.state.editPhoneIsCompanyDnc}
							>
								<FormControlLabel
									disabled={this.state.editPhoneIsCompanyDnc}
									control={
										<StandardSwitch
											checked={this.state.editPhoneIsCompanyDnc}
											onChange={this.onCompanyDnc}
										/>
									}
									style={{ marginLeft: 5, marginTop: 7 }}
									label="Company Do Not Call"
								/>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							<Tooltip
								id="sms-dnc-help"
								title="Opt Out SMS value cannot be unset"
								placement={S.CSS.Right}
								enterTouchDelay={0}
								disableFocusListener={!this.state.editPhoneIsSmsDnc}
								disableHoverListener={!this.state.editPhoneIsSmsDnc}
								disableTouchListener={!this.state.editPhoneIsSmsDnc}
							>
								<FormControlLabel
									disabled={this.state.editPhoneIsSmsDnc}
									control={
										<StandardSwitch
											checked={this.state.editPhoneIsSmsDnc}
											onChange={this.onSmsDnc}
										/>
									}
									style={{ marginLeft: 5, marginTop: 7 }}
									label="Opt Out SMS"
								/>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} style={styles.padded}>
							<Typography variant="caption">
								<Box fontStyle="italic" marginTop={2}>
									{S.Disclaimer.Dnc}
								</Box>
							</Typography>
						</Grid>
					</Grid>
				</Dialog>
			);
		}
	};

	confirmCompanyDncDialog = () => {
		return (
			<Dialog
				open={this.state.isCompanyDncDialogVisible}
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				aria-labelledby="Confirm Company DNC Change"
			>
				<DialogTitle>This action cannot be undone.</DialogTitle>
				<DialogContent>
					<DialogContentText>This will place the number on the company do not call list.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color={S.Theming.Secondary}
						onClick={() =>
							this.setState({
								isCompanyDncDialogVisible: false,
								editPhoneIsCompanyDnc: false,
							})
						}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						onClick={() => {
							this.setState({
								isCompanyDncDialogVisible: false,
								editPhoneIsCompanyDnc: true,
							});
						}}
						style={{
							backgroundColor: themePalette.delete_remove_reject_button,
							color: themePalette.negative_text,
						}}
					>
						Set Do Not Call
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	confirmSmsDncDialog = () => {
		return (
			<Dialog
				open={this.state.isSmsDncDialogVisible}
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				aria-labelledby="Confirm Opt Out SMS"
			>
				<DialogTitle>This action cannot be undone.</DialogTitle>
				<DialogContent>
					<DialogContentText>This number will be opted out of SMS.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color={S.Theming.Secondary}
						onClick={() =>
							this.setState({
								isSmsDncDialogVisible: false,
								editPhoneIsSmsDnc: false,
							})
						}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						onClick={() => {
							this.setState({
								isSmsDncDialogVisible: false,
								editPhoneIsSmsDnc: true,
							});
						}}
						style={{
							backgroundColor: themePalette.delete_remove_reject_button,
							color: themePalette.negative_text,
						}}
					>
						Opt Out SMS
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		return (
			<div>
				{this.phoneHeader()}
				{this.listPhones()}
				<Field name={S.ContactInfoTypes.Phones} component={ValueHolder} />
				{this.editDialog()}
				{this.confirmCompanyDncDialog()}
				{this.confirmSmsDncDialog()}
			</div>
		);
	}
}

function mapStateToProps(
	state,
	ownProps: ComponentProps
): StateProps {
	
	return {
		formValues: getFormValues(ownProps.formName)(state),
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: ComponentProps
): DispatchProps {
	return {
		changeFieldValue: (field: string, value: any) => {
			dispatch(change(ownProps.formName, field, value));
		},
		touchField: (field: string) => {
			dispatch(touch(ownProps.formName, field));
		},
	};
}

export const PhoneFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_PhoneFields
);

const styles: { [key: string]: React.CSSProperties } = enforceStylesType({
	italic: {
		fontStyle: 'italic',
	},
	padded: {
		padding: 20,
	},
});
