import React, { useEffect } from 'react';
import {
    RiseCard,
    FilterAppBar,
    Tab,
    Grid,
    IconButton,
    FactFinderIconSvg,
    FabMenu,
    createMenuAction,
    RisePage,
    RiseList,
    ConfirmationDialog,
    EditIcon,
    RiseListItemProps,
    riseStrings,
    useSelector,
    RiseStrings,
    useFormActions,
    useDispatch,
    FormSlice,
    createFilter,
    createFormBuilderApi,
    useConfirmation,
    r_pipe,
    useFilterResults,
    useNavigation,
    useLoading,
} from '@hmkts/rise';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { usePowerPointState } from '../../hooks/use_powerpoint_state';
import { useRouteMatch } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { FactFinderSlice, useFactFinderActions } from '../..';

const formApi = createFormBuilderApi("factfinder/");

const filter = createFilter<FormSlice.Form>(riseStrings._FactFinders)
    .setTitle(riseStrings.FactFinders)
    .addTextFilter({
        id: "contactName",
        label: "Filter by",
        key: ["firstName", "lastName"],
        initialValue: "",
        fieldType: "text",
        map: {
            toQuery: (value) => ({ name: value }),
            toString: (value) => value
        },
    })
    .addDropdownFilter({
        id: "completedStatus",
        label: "Status",
        key: "completed",
        options: "Completed=true|In Progress=false|All=undefined|",
        initialValue: "All",
        map: {
            toString: (value) => {
                if (value === "All") {
                    return ""
                } else {
                    return value;
                }
            },
            toQuery: (value) => {
                if (value === "Completed") {
                    return { completed: true }
                } else if (value === "In Progress") {
                    return { completed: false }
                }
            },
            filter: (currentValue, isCompleted) => {
                if (currentValue === "Completed") {
                    return isCompleted;
                } else if (currentValue === "In Progress") {
                    return !isCompleted;
                } else {
                    return true;
                }
            }
        },
    })
    .addDropdownFilter({
        id: "masterFormId",
        label: "Form",
        key: "masterFormId",
        options: "Under 65=true|Over 65=false|All Forms=undefined|",
        initialValue: "All Forms",
        map: {
            toString: (value) => {
                if (value === "All Forms") {
                    return ""
                } else if (value === "Over 65") {
                    return "Over 65"
                } else if (value === "Under 65") {
                    return "Under 65"
                }
            },
            toQuery: (value) => {
                if (value === "Over 65") {
                    return { masterFormId: "H.Over65" }
                } else if (value === "Under 65") {
                    return { masterFormId: "H.Standard" }
                } else {
                    return { masterFormId: null }
                }
            },
            filter: (currentValue, masterFormId) => {
                if (currentValue === "Over 65") {
                    return masterFormId.includes("H.Over65");
                } else if (currentValue === "Under 65") {
                    return masterFormId.includes("H.Standard");
                } else {
                    return true;
                }
            }
        },
    })
    .addSortOption({
        id: "updatedOn",
        key: "updatedOn",
        initialValue: "Desc",
        options: "Asc|Desc",
        label: "Updated On",
        map: {
            toString: (label) => label !== "Updated On" ? label : "",
            toQuery: (value) => {
                return {
                    sortDirection: value.toUpperCase(),
                    sortKey: "updatedOn",
                }
            },
            compare: (a: FormSlice.Form, b: FormSlice.Form, value: "Asc" | "Desc") => {
                if (value === "Desc") {
                    if (a.updatedOn > b.updatedOn) { return -1; }
                    else if (a.updatedOn < b.updatedOn) { return 1; }
                    else { return 0; }
                } else if (value === "Asc") {
                    if (a.updatedOn > b.updatedOn) { return 1; }
                    else if (a.updatedOn < b.updatedOn) { return -1; }
                    else { return 0; }
                }
            }
        }
    })
    .addSortOption({
        id: "createdOn",
        key: "createdOn",
        initialValue: "Desc",
        options: "Asc|Desc",
        label: "Created On",
        map: {
            toString: (label) => label,
            toQuery: (value) => {
                return {
                    sortDirection: value.toUpperCase(),
                    sortKey: "createdOn",
                }
            },
            compare: (a: FormSlice.Form, b: FormSlice.Form, value: "Asc" | "Desc") => {
                if (value === "Desc") {
                    if (a.createdOn > b.createdOn) { return -1; }
                    else if (a.createdOn < b.createdOn) { return 1; }
                    else { return 0; }
                } else if (value === "Asc") {
                    if (a.createdOn > b.createdOn) { return 1; }
                    else if (a.createdOn < b.createdOn) { return -1; }
                    else { return 0; }
                }
            }
        }
    })
    .setDefaultSortKey("Updated On", "Desc")
    .setDefaultQuery({
        count: 200,
        name: "",
        sortDirection: "DESC",
        masterFormId: null,
        sortKey: "updatedOn",
    });


type PptTemplate = {
    value: string;
    titleText: string;
    subtitleText: string;
    id: string;
    updatedOn: string;
    masterTemplateId: string;
};


export const FactFinderListPage: React.FC<{}> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const match = useRouteMatch(riseStrings._Route_FactFinder_List);
    const loading = useLoading();
    const powerpoint = usePowerPointState();
    const formState = useSelector(app => FormSlice.selectState(app), shallowEqual);
    const formActions = useFormActions();
    const currentTab = useSelector(app => FactFinderSlice.selectState(app).currentTab);
    const searchEnabled = useSelector(app => FactFinderSlice.selectState(app).enableSearch);
    const { setCurrentTab } = useFactFinderActions();

    const { filteredResults, setData } = useFilterResults<FormSlice.Form>(filter);


    useEffect(() => {
        filter.init(dispatch, (formFilters: FormSlice.Filter) => {
            return dispatch(
                formApi.GetFactFinderForms(formFilters)
            );
        });

        if (match) {
            powerpoint.getTemplates();
            filter.getData();
        }
    }, []);

    const nav = useNavigation({
        path: riseStrings._Route_FactFinder_List,
        onLoad:
            () => {
                powerpoint.getTemplates();
                if (match && searchEnabled)
                    filter.getData();
            }
    })

    useEffect(() => {
        const formList = r_pipe.recordToList<FormSlice.Form>(formState.forms)
            .filter(form =>
                form.firstName
                && form.lastName
                && form.createdOn
            );

        setData(formList);
    }, [formState.forms]);

    const handleFabClick = (type: string) => () => {

        if (currentTab == riseStrings.FactFinderForm) {
            if (type == riseStrings._NewUnder65) {
                formActions.navigateToNewForm(riseStrings._NewUnder65);
            }
            if (type == riseStrings._NewOver65) {
                formActions.navigateToNewForm(riseStrings._NewOver65);
            }
        }

        if (currentTab == riseStrings.FactFinderPresentation) {
            if (type == riseStrings._NewUnder65) {
                powerpoint.newTemplate(riseStrings._NewUnder65Ppt);
                powerpoint.navigateToPresentation(riseStrings._NewUnder65Ppt);
            }
            if (type == riseStrings._NewOver65) {
                powerpoint.newTemplate(riseStrings._NewOver65Ppt);
                powerpoint.navigateToPresentation(riseStrings._NewOver65Ppt);
            }
        }
    }

    const handleSelectPresentation = (id: string) => {
        powerpoint.navigateToPresentation(id);
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setCurrentTab(newValue as RiseStrings.FactFinderTabs);
    };

    const factFinderFormList = filteredResults.map(form => ({
        key: form.id,
        value: form.id,
        masterFormId: form.masterFormId,
        titleText: form.firstName + " " + form.lastName,
        subtitleText: r_pipe.subtitleCompleteInProgress(form).titleText,
        primarySubtitles: [r_pipe.subtitleCreatedOn(form), r_pipe.subtitleUpdatedOn(form)],
        id: form.id,
        completed: form.completed,
    }) as RiseListItemProps);

    const confirmDeleteForm = useConfirmation({
        onConfirm: formActions.deleteForm,
        subtitleText: riseStrings.AreYouSureDeleteForm,
    });

    const confirmDeletePres = useConfirmation({
        onConfirm: powerpoint.deleteTemplate,
        subtitleText: riseStrings.AreYouSureDeletePresentation,
    });


    const navigateToForm = (id: string) => {
        dispatch(FactFinderSlice.actions.disableSearch());
        formActions.navigateToForm(id)
    }

    return (
        <RisePage
            exact
            path={riseStrings._Route_FactFinder_List}
        >
            <TabContext
                value={currentTab}
            >
                <FilterAppBar
                    position="sticky"
                    loadingBar
                    backAction
                    filter={filter}
                    hideFilter={currentTab === riseStrings.FactFinderPresentation}
                >
                    <TabList
                        onChange={handleTabChange}
                    >
                        <Tab
                            label={riseStrings.MyFactFinders}
                            value={riseStrings.FactFinderForm}
                            key={riseStrings.FactFinderForm}
                        />
                        <Tab
                            label={riseStrings.MyPresentations}
                            value={riseStrings.FactFinderPresentation}
                            key={riseStrings.FactFinderPresentation}
                        />
                    </TabList>

                </FilterAppBar>

                <Grid
                    gridSize="4|6|8|12"
                    style={{ paddingTop: 24 }}
                >
                    <TabPanel
                        className={classes.tabRoot}
                        value={riseStrings.FactFinderForm}
                        key={riseStrings.FactFinderForm}
                    >
                        <RiseList
                            items={factFinderFormList}
                            onClick={navigateToForm}
                            loading={loading.isLoading}
                            defaultEmptyText={riseStrings.ThereAreNoMatchesForYourSearch}
                            mapIconOptions={(item: { id: string, titleText: string, completed?: boolean }) => {
                                let options = [
                                    createMenuAction("Duplicate", formActions.createCopyForm(item.id)),
                                    createMenuAction("Delete", () => (confirmDeleteForm as any).setOpen(item.id, riseStrings.AreYouSureDeleteFormName(item.titleText))),
                                    createMenuAction("Download", formActions.createDownloadForm(item.id)),
                                ]
                                if (!item.completed) {
                                    options = [createMenuAction("Edit", () => navigateToForm(item.id)), ...options]
                                }

                                return options;
                            }}
                        />
                    </TabPanel>

                    <TabPanel
                        value={riseStrings.FactFinderPresentation}
                        key={riseStrings.FactFinderPresentation}
                    >
                        <RiseCard
                            gridSize="12"
                            square
                            titleText={riseStrings.EditAboutMeSlide}
                            headerIcon={<FactFinderIconSvg height={35} width={35} />}
                            gridStyle={{ paddingBottom: 16 }}
                            headerActions={
                                <IconButton onClick={() => nav.dispatchTo(riseStrings._Route_AboutMe)}
                                >
                                    <EditIcon />
                                </IconButton>
                            }
                        />
                        <RiseList
                            items={powerpoint.templates}
                            onClick={handleSelectPresentation}
                            loading={loading.isLoading}
                            defaultEmptyText={riseStrings.YouCurrentlyDoNotHaveAnyTemplatesSaved}

                            mapIconOptions={(item: PptTemplate) => {
                                return [
                                    createMenuAction("Edit", () => powerpoint.navigateToPresentation(item.id)),
                                    createMenuAction("Delete", () => (confirmDeletePres as any).setOpen(item.id, riseStrings.AreYouSureDeletePresentationName(item.titleText))),
                                    createMenuAction("Download", powerpoint.createDownloadTemplate(item)),
                                ]
                            }}
                        />
                    </TabPanel>
                </Grid>
            </TabContext>
            <ConfirmationDialog {...confirmDeleteForm} />
            <ConfirmationDialog {...confirmDeletePres} />


            <FabMenu
                disabled={loading.isLoading}
                items={
                    [
                        { titleText: "New " + currentTab, onClick: handleFabClick(riseStrings._NewUnder65), id: "newUnder65" },
                        { titleText: "New Senior " + currentTab, onClick: handleFabClick(riseStrings._NewOver65), id: "newOver65", icon: "65+" }
                    ]
                }

            />
        </RisePage >

    );
}

const useStyles = makeStyles(() => ({
    tabRoot: {
        padding: '0',
        width: '100%',
    },
}));
