// tslint:disable: align
import React from 'react';
import moment from 'moment';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes, getNavRouteByKey } from '../../components/nav/Routes';
import { compose } from 'redux';
import { connect } from '@hmkts/rise';
import { GraphDataFilter } from '../../components/utility/graph_data_filter';
import {
	PerformanceParams,
	GetPerformanceActivity,
	GetPerformanceLeads,
	GetPerformanceProduction,
	UpdatePerformanceFilter,
	UpdatePerformanceDates,
	UpdatePerformanceLineOfBusiness,
	GetPerformanceQuality,
	GetAllPerformanceData,
	SetPerformanceDownlineAgent,
	GetPerformanceDownlineAgent,
} from '../../actions/performance_actions';
import { TeamViewParams } from '../../actions/teamview_actions';
import ChartCard, { ChartCardProps } from '../../components/utility/chart_card';
import {
	GraphDataFilterValues,
	ChartGroup,
} from '../../reducers/performance_reducer';
import { DownlineAgent } from '../../reducers/product_reducer';
import { navigateTo } from '../../actions/navigation_actions';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { themeLinks } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { NewLeadsReports, SurebridgePersistencyReport, YtdAvReports } from '../../components/productivity';
import { AppState } from '../../reducers';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

const QUARTER_DATES_NUM = 90;

interface Props { }

interface StateProps {
	filter: GraphDataFilterValues;
	downlineAgent: DownlineAgent;
	activityCharts: ChartGroup;
	leadCharts: ChartGroup;
	productionCharts: ChartGroup;
    qualityCharts: ChartGroup;
    isDesktop?: boolean;
	loading: boolean;
}
interface DispatchProps {
	getAllPerformanceData: (params: PerformanceParams) => any;
	getActivityCharts: (params: PerformanceParams) => any;
	getLeadCharts: (params: PerformanceParams) => any;
	getProductionCharts: (params: PerformanceParams) => any;
    getQualityCharts: (params: PerformanceParams) => any;
	updateSearchFilter: (filter: string) => any;
	updateSearchDates: (params: PerformanceParams) => any;
	updateSearchLineOfBusiness: (params: PerformanceParams) => any;
	navigateTo(route: string): void;
	getDownlineAgent: (agent: string) => void;
	setDownlineAgent: (agent?: DownlineAgent) => void;
}

interface PerformancePageState {
    selectedTab: number;
 }

type PerformancePageProps = Props &
	StateProps &
	DispatchProps &
	NavigationProps;

class PerformancePage extends React.Component<
	PerformancePageProps,
	PerformancePageState
	> {
    constructor(props: PerformancePageProps) {
		super(props);
		this.state = {
			selectedTab: 0,
        };
    };

	params: TeamViewParams;
    title = 'Performance';
    scrollRef: HTMLDivElement;

	componentDidMount() {
		this.applyFilters();
		this.checkURLParams();
	}

	componentDidUpdate(prevProps: PerformancePageProps) {
		if (
			prevProps.downlineAgent.agentCode !== this.props.downlineAgent.agentCode
		) {
			this.resetTabs();
			this.applyFilters();
		}
		if (this.props.location.pathname !== navRoutes.performance.path) {
			this.scrollRef.scrollTop = 0;
		}
	}

	checkURLParams() {
		const { agentcode } = this.props.match.params;
		const isPage = ['activities', 'leads', 'production', 'quality'].indexOf(agentcode);

		if (agentcode && isPage === -1) {
			this.props.getDownlineAgent(agentcode);
		}
	}

	resetTabs = () => {
		this.setState({
			selectedTab: 0,
		});
	}

	applyFilters = (
		formValues?: GraphDataFilterValues,
		formErrors?: any | undefined,
	) => {
		if (!!formErrors && Object.keys(formErrors).length > 0) {
			return;
		}
		if (!formValues) {
			formValues = this.props.filter;
		}

		let startDate: moment.Moment | Date = moment();
		let	endDate: moment.Moment | Date = moment();
		let	updateDates = false;
		let	dateRangeOverride = 0;
		const utcOffset = moment().utcOffset();

		switch (formValues.dateRange) {
			case Strings.DateSelector.Last7Days: {
				startDate = startDate.subtract(6, 'days');
				break;
			}
			case Strings.DateSelector.Last30Days: {
				startDate = startDate.subtract(29, 'days');
				break;
			}
			case Strings.DateSelector.Last90Days: {
				startDate = startDate.subtract(89, 'days');
				break;
			}
			case Strings.DateSelector.MonthToDate: {
				startDate = startDate.startOf('month');
				break;
			}
			case Strings.DateSelector.QuarterToDate: {
				startDate = startDate.startOf('quarter');
				break;
			}
			case Strings.DateSelector.YearToDate: {
				startDate = startDate.startOf('year');
				break;
			}
			case Strings.DateSelector.LastMonth: {
				const lastMonth = startDate.subtract(1, 'month');
				startDate = lastMonth.clone().startOf('month');
				endDate = lastMonth.clone().endOf('month');
				break;
			}
			case Strings.DateSelector.LastQuarter: {
				const lastQuarter = startDate.subtract(1, 'quarter');
				startDate = lastQuarter.clone().startOf('quarter');
				endDate = lastQuarter.clone().endOf('quarter');
				dateRangeOverride = QUARTER_DATES_NUM;
				break;
			}
			case Strings.DateSelector.LastYear: {
				const lastYear = startDate.subtract(1, 'year');
				startDate = lastYear.clone().startOf('year');
				endDate = lastYear.clone().endOf('year');
				break;
			}
			default: {
				startDate = formValues.startDate;
				endDate = formValues.endDate;
				updateDates = true;
			}
		}

		const { downlineAgent } = this.props;
		const agentCode = downlineAgent.agentCode;

		const params: PerformanceParams = {
			agentCode,
			startDate: moment.isMoment(startDate) ? startDate.toDate() : startDate,
			endDate: moment.isMoment(endDate) ? endDate.toDate() : endDate,
			lineOfBusiness: formValues.lineOfBusiness || 'all',
			utcOffset,
			parentPage: Strings.TeamViewAPI.PERFORMANCE_API,
		};
		if (dateRangeOverride > 0) {
			params.dateRangeOverride = dateRangeOverride;
		}
		if (updateDates) {
			this.props.updateSearchDates(params);
		}

		this.params = params;

		this.props.updateSearchLineOfBusiness(params);
        this.props.updateSearchFilter(formValues.dateRange);

		switch (location.pathname) {
			case navRoutes.performanceActivities.path:
				return this.props.getActivityCharts(params);
			case navRoutes.performanceLeads.path:
				return this.props.getLeadCharts(params);
			case navRoutes.performanceProduction.path:
				return this.props.getProductionCharts(params);
			case navRoutes.performanceQuality.path:
				return this.props.getQualityCharts(params);
			default:
				return this.props.getAllPerformanceData(params);
		}
	};

	buildCharts = (): ChartCardProps[] => {
		const {
			activityCharts,
			leadCharts,
			productionCharts,
			qualityCharts,
			location,
		} = this.props;
		const performanceCards: { [key: string]: ChartCardProps } = {
			performanceActivities: {
				color: 'primary',
				titleLeft: 'Activities',
				charts: activityCharts.charts || [],
				detailData: activityCharts.detail || [],
			},
			performanceLeads: {
				color: 'secondary',
				titleLeft: 'Lead Performance',
				charts: leadCharts.charts || [],
				detailData: leadCharts.detail || [],
			},
			performanceProduction: {
				color: 'tertiary',
				titleLeft: 'Production',
				charts: productionCharts.charts || [],
				detailData: productionCharts.detail || [],
			},
			performanceQuality: {
				color: 'primary',
				titleLeft: 'Quality',
				charts: qualityCharts.charts || [],
				detailData: qualityCharts.detail || [],
			},
		};
		const getChartProps = (key: string, details?: boolean) => ({
			...performanceCards[key],
			onClick: !details ? this.viewDetails(key) : undefined,
			style: { marginBottom: 20 },
			details,
		});

		if (isBrokerage) {
			delete performanceCards[Strings.TeamView.PERFORMANCE_PRODUCTION];
			delete performanceCards[Strings.TeamView.PERFORMANCE_QUALITY];
		}

		switch (location.pathname) {
			case navRoutes.performanceActivities.path:
				return [getChartProps(Strings.TeamView.PERFORMANCE_ACTIVITIES, true)];
			case navRoutes.performanceLeads.path:
				return [getChartProps(Strings.TeamView.PERFORMANCE_LEADS, true)];
			case navRoutes.performanceProduction.path:
				return [getChartProps(Strings.TeamView.PERFORMANCE_PRODUCTION, true)];
			case navRoutes.performanceQuality.path:
				return [getChartProps(Strings.TeamView.PERFORMANCE_QUALITY, true)];
			default:
				return Object.keys(performanceCards).map(key =>
					getChartProps(key, false),
				);
		}
	};

	viewDetails = (route: string) => () => {
		this.props.navigateTo(getNavRouteByKey(route).path);
	};

    toggleTabs = (event: React.ChangeEvent<{}>, selectedTab: any) => {
		this.setState({selectedTab})
	}

	render() {
		const { filter, isDesktop, loading } = this.props;
		const allCharts: ChartCardProps[] = this.buildCharts();
		const title = allCharts.length > 1 ? this.title : allCharts[0].titleLeft;

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={title}
						rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkPerformance)]} />}
						isLoading={loading}
						customContent={
                            <>
                                <GraphDataFilter
                                    filterValues={filter}
                                    applyFilters={this.applyFilters}
                                    title={Strings.TeamView.PERFORMANCE}
                                    downlineAgent={this.props.downlineAgent}
                                    setDownlineAgent={this.props.setDownlineAgent}
                                    showLineOfBusinessField
                                />
                                <Paper elevation={0} square>
									<Tabs
										value={this.state.selectedTab}
										onChange={this.toggleTabs}
										indicatorColor="secondary"
										variant="fullWidth"
									>
										<Tab label={isDesktop ? Strings.TeamView.PERFORMANCE_TAB : Strings.TeamView.PERFORMANCE} value={0} />
										<Tab label={isDesktop ? Strings.TeamView.LEADS_TAB : Strings.TeamView.LEADS_TAB_MOBILE} value={1} />
										<Tab label={isDesktop ? Strings.TeamView.SALES_TAB : Strings.TeamView.SALES_TAB_MOBILE} value={2} />
									</Tabs>
								</Paper>
                            </>
						}
					/>
				}
				middleRef={ref => (this.scrollRef = ref)}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<div style={styles.middleComponent}>
						{this.state.selectedTab === 0 && (
							allCharts.map((chart, index) => (
								<ChartCard key={`chart-${index}`} {...chart} />
							)))}
						{this.params && 
							<>
								<NewLeadsReports params={this.params} hidden={this.state.selectedTab !== 1} />
								<YtdAvReports
									params={this.params}
									showSLStats={false}
									hidden={this.state.selectedTab !== 2}
								/>
								<SurebridgePersistencyReport
									params={this.params}
									showSLStats={false}
									hidden={this.state.selectedTab !== 2}
								/>
							</>
						}
					</div>
				</DesktopPadding>
			</BasePageContainer>
		);
	}
}

const styles: { [key: string]: React.CSSProperties } = {
	middleComponent: {
		margin: 10,
	},
};

const mapStateToProps = (state: AppState): StateProps => {
	return {
		filter: state.performance.filter,
		downlineAgent: state.performance.downlineAgent,
		activityCharts: state.performance.activityCharts,
		leadCharts: state.performance.leadCharts,
		productionCharts: state.performance.productionCharts,
        qualityCharts: state.performance.qualityCharts,
        isDesktop: state.layout.desktop,
		loading: state.performance.loading,
	};
};
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	getAllPerformanceData: (params: PerformanceParams) =>
		dispatch(GetAllPerformanceData.started(params)),
	getActivityCharts: (params: PerformanceParams) =>
		dispatch(GetPerformanceActivity.started(params)),
	getLeadCharts: (params: PerformanceParams) =>
		dispatch(GetPerformanceLeads.started(params)),
	getProductionCharts: (params: PerformanceParams) =>
		dispatch(GetPerformanceProduction.started(params)),
	getQualityCharts: (params: PerformanceParams) =>
        dispatch(GetPerformanceQuality.started(params)),
	updateSearchFilter: (filter: string) =>
		dispatch(UpdatePerformanceFilter({ filter })),
	updateSearchDates: (params: PerformanceParams) =>
		dispatch(UpdatePerformanceDates({ params })),
	updateSearchLineOfBusiness: (params: PerformanceParams) =>
		dispatch(UpdatePerformanceLineOfBusiness({ params })),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	getDownlineAgent: (agentCode: string) =>
		dispatch(GetPerformanceDownlineAgent(agentCode)),
	setDownlineAgent: (agent?: DownlineAgent) =>
		dispatch(SetPerformanceDownlineAgent({ agent })),
});

export const PerformanceContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true),
)(PerformancePage);
