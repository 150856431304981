import { connect } from '@hmkts/rise';
import React from 'react';
import { AppState } from '../../reducers';
import { Strings } from '../../assets/common/strings';
import { Lead } from '../../reducers/LeadReducer';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, ListItem, List, ListItemText } from '@material-ui/core';
import { CloseDedupeDialog } from '../../actions/dedupe_actions';
import { ContactDedupeResult, ContactDedupeDto } from '../../reducers/dedupe_reducer';
import { createLead } from '../../actions/lead_actions';
import { orDefault, formatPhone } from '../../assets/common/string_builders';
import { Policy } from '../../reducers/policy_reducer';
import { CreateContact } from '../../actions/contact_actions';
import H from 'history';
import http, { HttpOptions } from '../../utilities/http';
import { QueueSnackbar } from '../../actions/snackbar_actions';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';

interface InputProps {
    lead?: Lead,
    policy?: Policy,
    history: H.History,
    contact?: Contact,
}

interface StateProps {
    dedupeResults: ContactDedupeResult[],
    open: boolean,
    loading: boolean,
    callback?: Function
}

interface State {
    currentStep: string
}

interface DispatchProps {
    closeDialog: () => void;
    createLead: (lead: Lead, policy?: Policy) => void;
    createContact: (contact: Contact) => void;
}

type Props = InputProps & StateProps & DispatchProps;

class ContactDedupe extends React.Component<Props, State> {
    constructor(props: Props) {
		super(props);

		this.state = {
			currentStep: Strings.ContactDedupe.SelectContactStep
		};
    }

    componentWillReceiveProps(nextProps: Props) {
        if(nextProps.open && !nextProps.loading && this.props.loading) {
            const contactMatches = this.filterResults(nextProps, Strings.ContactDedupe.SelectContactStep);
            if(contactMatches.length == 0) {
                const householdMatches = this.filterResults(nextProps, Strings.ContactDedupe.SelectHouseholdStep);
                if(householdMatches.length == 0) {
                    this.handleSave();
                } else {
                    this.goToHouseholdStep();
                }
            }
        }
    }
    
    showErrorSnackbar = (text: string) => {
        this.closeDialog(false);
        QueueSnackbar(getSnackbarErrorProps(text));
    }

    getContact = (): Contact | undefined => {
        if(this.props.lead && this.props.lead.contacts.length > 0) {
            return this.props.lead.contacts[0];
        } else if(this.props.contact) {
            return this.props.contact;
        }
    }

    getPhonesAndEmails = (): ContactDedupeDto => {
        const contact = this.getContact();
        if(contact) {
            return {
                phones: contact.phones ? contact.phones.map(phone => phone.number) : [],
                emails: contact.emails ? contact.emails.map(email => email.emailAddress) : [],
            }
        } else {
            return {
                phones: [],
                emails: []
            };
        }
    }

    //The results returned from api will be every contact on the households that match the email/phone
    //Emails/phones on results will only contain matching emails/phones
    filterResults = (props: Props, step: string): ContactDedupeResult[] => {
        const contact = this.getContact();
        if(contact) {
            if(step == Strings.ContactDedupe.SelectContactStep) {
                //Filter down to only the contacts that match first/last name and have a matching email/phone
                return props.dedupeResults.filter((result) => {
                    const lastNameMatches = 
                        orDefault(result.lastName).toLowerCase().substring(0, 3) == orDefault(contact.lastName).toLowerCase().substring(0, 3)
                    const firstNameMatches = 
                        orDefault(result.firstName).toLowerCase().substring(0, 3) == orDefault(contact.firstName).toLowerCase().substring(0, 3);
                    const preferredNameMatches = contact.preferredName && 
                        orDefault(result.preferredName).toLowerCase().substring(0, 3) == orDefault(contact.preferredName).toLowerCase().substring(0, 3);
                    
                    return lastNameMatches && 
                        (firstNameMatches || preferredNameMatches) &&
                        (result.emails.length > 0  || result.phones.length > 0);
                });
            } else if(step == Strings.ContactDedupe.SelectHouseholdStep) {
                //Filter down to only the primaries of households that contain a matching contact
                const primaries = props.dedupeResults
                    .filter(result => result.householdRole == HouseholdRole[HouseholdRole.Primary]);

                    
                //Get the matching email/phone from the contact that matched
                primaries.forEach(primary => {
                    if(primary.phones.length == 0) {
                        const phoneMatches = props.dedupeResults
                            .filter(result => result.householdId == primary.householdId && 
                                result.phones.length > 0);

                        if(phoneMatches.length > 0) {
                            primary.phones = phoneMatches[0].phones;
                        }
                    }

                    if(primary.emails.length == 0) {
                        const emailMatches = props.dedupeResults
                            .filter(result => result.householdId == primary.householdId && 
                                result.emails.length > 0);

                        if(emailMatches.length > 0) {
                            primary.emails = emailMatches[0].emails;
                        }
                    }
                });

                return primaries;
            }
        }
        return [];
    }

    handleCancel = (event?: React.MouseEvent<HTMLElement>) => {
        this.closeDialog(false);
    }

    closeDialog = (shouldCallback: boolean) => {
        this.setState({ currentStep: Strings.ContactDedupe.SelectContactStep });
        if(this.props.callback && shouldCallback) {
            this.props.callback();
        }
        this.props.closeDialog();
    }

    handleSave = () => {
        if(this.props.lead) {
            this.props.createLead(this.props.lead, this.props.policy);
        } else if(this.props.contact) {
            this.props.createContact(this.props.contact);
        }
        this.closeDialog(true);
    }

    mergeContact = async (contact: Contact, existingId: string) : Promise<Contact> => {
        const options: HttpOptions = {
            method: 'POST',
            body: JSON.stringify(contact)
        }
        const result = await http("contact/merge/" + existingId, options);
        if(result.ok) {
            return result.json();
        } else {
            this.showErrorSnackbar('Unable to merge contact');
            return Promise.reject(result.error);
        }
    }

    selectContact = (selectedContact: ContactDedupeResult) => {
        if(this.state.currentStep == Strings.ContactDedupe.SelectContactStep) {
            if(this.props.lead) { 
                //New lead, merging with a selected contact
                this.props.lead.householdId = selectedContact.householdId;
                this.props.lead.contacts[0].employerId = '';

                this.mergeContact(this.props.lead.contacts[0], selectedContact.id)
                    .then(mergedContact => {
                        if(this.props.lead!.employer) {
                            const originalContact = this.props.lead!.contacts[0];
                            const employer = this.props.lead!.employer;

                            //Existing contact doesn't have EmployerContactJoinTable fields yet
                            mergedContact.groupEligibilityStatus = originalContact.groupEligibilityStatus;
                            mergedContact.groupEnrollmentStatus = originalContact.groupEnrollmentStatus;
                            mergedContact.employeeStatus = originalContact.employeeStatus;
                            mergedContact.employerPrimaryContact = true;
                            employer.contacts=[mergedContact];
                        }
                        if(this.props.policy) {
                            this.props.policy.primaryInsured = mergedContact;
                        }
                        this.props.lead!.contacts = [];
                        this.handleSave();
                    });
            } else if(this.props.contact) { 
                //New contact, merge with selected contact
                this.mergeContact(this.props.contact, selectedContact.id)
                    .then(() => this.closeDialog(true));
            }
        } else if(this.state.currentStep == Strings.ContactDedupe.SelectHouseholdStep) {
            if(this.props.lead) { 
                //New lead, add contact to an existing household
                this.props.lead.contacts[0].householdRole = HouseholdRole.Dependent;
                this.props.lead.contacts[0].householdId = selectedContact.householdId;
                if(this.props.lead.employer) {
                    this.props.lead.employer.contacts = this.props.lead.contacts;
                }
                this.handleSave();
            } else if(this.props.contact && this.props.contact.employerId) {
                //New employee, add them to an existing household
                this.props.contact.householdRole = HouseholdRole.Dependent;
                this.props.contact.householdId = selectedContact.householdId;
                this.handleSave();
            }
        }
    }

    goToHouseholdStep = () => {
        if(this.props.lead || (this.props.contact && this.props.contact.employerId)) {
            //Lead upsert or new employee, go to match household step
            this.setState({
                currentStep: Strings.ContactDedupe.SelectHouseholdStep
            })
        } else {
            //Adding a contact to household, just save the new contact
            this.handleSave();
        }
    }

    renderChoices() {
        const results = this.filterResults(this.props, this.state.currentStep);
        return results.map(result => {
            return (
                <ListItem 
                    button 
                    divider
                    onClick={() => {this.selectContact(result)}}
                >
                    <ListItemText 
                        disableTypography
                        primary={
                            <div>
                                <div>{(result.preferredName || result.firstName) + " " + result.lastName}</div>
                                <div>{formatPhone(result.phones[0])}</div>
                                <div>{result.emails[0]}</div>
                            </div>
                        }
                    />
                </ListItem>
            );
        });
    }

    renderContent() {
        const contact = this.getContact();
        if(!contact) return(<div />);

        const contactName = orDefault(contact.preferredName, contact.firstName) + " " + contact.lastName

        if(this.state.currentStep == Strings.ContactDedupe.SelectContactStep) {
            return (
                <div>
                    <p>
                        {contactName} may already exist in {document.title}.
                        Is {contactName} already in {document.title} as a person listed below?
                    </p>
                    <List style={{textAlign: 'center'}}>
                        {this.renderChoices()}
                        <ListItem button divider onClick={() => this.goToHouseholdStep()}>
                            <ListItemText primary="Create New Person" />
                        </ListItem>
                    </List>
                </div>
            );
        } else if(this.state.currentStep == Strings.ContactDedupe.SelectHouseholdStep) {
            return (
                <div>
                    <p>
                        Is {contactName} part of an existing household listed below?
                    </p>
                    <List style={{textAlign: 'center'}}>
                        {this.renderChoices()}
                        <ListItem button divider onClick={() => this.handleSave()}>
                            <ListItemText primary="Create New Household" />
                        </ListItem>
                    </List>
                </div>
            );
        } else return (<div />);
    }

    render() {
        return (
            <Dialog
                open={this.props.open && 
                    !this.props.loading && 
                    this.props.dedupeResults.length > 0}
                maxWidth='xs'
                transitionDuration={0}
            >
				<DialogTitle>{Strings.ContactDedupe.Title}</DialogTitle>
                <DialogContent>{this.renderContent()}</DialogContent>
                <DialogActions>
					<Button color="secondary" onClick={this.handleCancel}>
						Cancel
					</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state: any): StateProps {
	

	return {
        dedupeResults: state.dedupe.contactResults,
        open: state.dedupe.open,
        loading: state.dedupe.loading,
        callback: state.dedupe.callback
	};
}

function mapDispatchToProps(dispatch: any, ownProps: InputProps): DispatchProps {
	return {
        closeDialog: () => dispatch(CloseDedupeDialog()),
        createLead: (lead: Lead, policy?: Policy) =>
            dispatch(createLead(lead, policy, ownProps.history, false)),
        createContact: (contact: Contact) =>
			dispatch(CreateContact.started(contact, {history: ownProps.history, dupeCheck: false})),
	};
}

export const ContactDedupeDialog = connect(mapStateToProps, mapDispatchToProps, true)(
	ContactDedupe
) as React.ComponentClass<InputProps>;