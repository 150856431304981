import React from 'react';
import { Field, getFormValues } from 'redux-form';
import {
	Typography,
	Grid,
	Icon,
	List,
	ListItem,
	ListItemText,
	Divider,
	ListItemIcon,
	ListItemSecondaryAction,
	Dialog,
	FormControlLabel,
	MenuItem,
	Menu,
	Button,
	DialogActions,
	DialogContent,
	IconButton,
} from '@material-ui/core';
import { themePalette } from '../../utilities/branding';
import { AppState } from '../../reducers';
import _ from 'lodash';
import { connect } from '@hmkts/rise';
import { Email } from '../../reducers/ContactReducer';
import moment from 'moment';
import { change, touch } from 'redux-form';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { TextField as StandardTextField } from '@material-ui/core';
import { Switch as StandardSwitch } from '@material-ui/core';
import { validateEmail } from '../../utilities/form_validation';
import { ValueHolder } from '../../components/redux_form_material';
import { createRequiredLabel } from '../../components/utility/required_label';

export type EmailFieldsData = {
	emails: Email[];
	email?: string;
};

interface ComponentProps {
	formName: string;
	showAsterisk: boolean;
}

interface StateProps {
	formValues: EmailFieldsData;
}

interface DispatchProps {
	changeFieldValue(field: string, value: any): void;
	touchField(field: string): void;
}

type Props = ComponentProps & StateProps & DispatchProps;

interface State {
	showEditDialog: boolean;
	showConfirmEmailDoNotEmailDialog: boolean;
	itemToEdit: number;
	editEmailAddress: string;
	editEmailType: string;
	editEmailIsPreferred: boolean;
	editEmailIsDoNotEmail: boolean;
	editEmailUndeliverableDate?: Date;
	anchorEl?: HTMLElement;
	emailIndex?: number;
	formHasChanged: boolean;
}

class _EmailFields extends React.Component<Props, State> {
	styles: { [key: string]: React.CSSProperties } = {
		editListItem: {
			margin: '20px',
			width: '100%',
		},
		field: {
			marginLeft: '15px',
		},
		moreVertStyle: {
			marginTop: -5,
			marginLeft: 6,
			cursor: 'pointer',
		},
		saveIconStyle: {
			marginTop: 6,
			fontSize: 28,
			color: themePalette.sub_text,
			cursor: 'pointer',
		},
		dnc: {
			color: themePalette.error,
		},
		preferred: {
			color: themePalette.preferred_item,
		},
	};

	constructor(props: any) {
		super(props);

		this.state = {
			showEditDialog: false,
			showConfirmEmailDoNotEmailDialog: false,
			itemToEdit: 0,
			editEmailAddress: '',
			editEmailType: '',
			editEmailIsPreferred: false,
			editEmailIsDoNotEmail: false,
			editEmailUndeliverableDate: undefined,
			formHasChanged: false,
		};
	}

	onClickAdd = () => {
		const emails = (this.props.formValues.emails || []).slice();
		let type = 'Home';
		let isPreferred = true;
		let doNotEmail = false;
		if (emails.length) {
			// not the first email
			isPreferred = false;
		}
		this.setState({
			itemToEdit: emails.length,
			showEditDialog: true,
			editEmailAddress: '',
			editEmailType: type,
			editEmailIsPreferred: isPreferred,
			editEmailIsDoNotEmail: doNotEmail,
			anchorEl: undefined,
		});
	}

	onClickEdit = () => {
		if(typeof this.state.emailIndex !== "undefined" && this.state.emailIndex >= 0) {
			const index = this.state.emailIndex;
			const emailToEdit = this.props.formValues.emails[index];
			this.setState({
				showEditDialog: true,
				itemToEdit: index,
				editEmailAddress: emailToEdit.emailAddress || '',
				editEmailType: emailToEdit.type || '',
				editEmailIsPreferred: emailToEdit.isPreferred || false,
				editEmailIsDoNotEmail: emailToEdit.doNotEmail || false,
				editEmailUndeliverableDate: emailToEdit.emailUndeliverableDate || undefined,
				anchorEl: undefined,
				emailIndex: undefined
			});
		}
	}

	onClickDelete = () => {
		if(typeof this.state.emailIndex !== "undefined" && this.state.emailIndex >= 0) {
			const index = this.state.emailIndex;
			const emails = this.props.formValues.emails.slice();
			emails.splice(index, 1);
			if (emails.length > 0 && !emails.some(email => email.isPreferred))
				emails[0].isPreferred = true;
			this.props.changeFieldValue('emails', emails);
			this.props.touchField('emails');
			this.setState({ anchorEl: undefined, emailIndex: undefined });
		}
	}

	onClickSetPreferred = () => {
		if(typeof this.state.emailIndex !== "undefined" && this.state.emailIndex >= 0) {
			const index = this.state.emailIndex;
			const currentPreferredIndex = this.props.formValues.emails.findIndex(
				email => email.isPreferred
			);
			const emails = this.props.formValues.emails.slice();
			if (currentPreferredIndex > -1)
				emails[currentPreferredIndex].isPreferred = false;
			emails[index].isPreferred = true;
			this.props.changeFieldValue('emails', emails);
			this.props.touchField('emails');
			this.setState({ anchorEl: undefined, emailIndex: undefined });
		}
	}

	onEditEmail = () => {
		const emails = (this.props.formValues.emails || []).slice();
		if (this.state.editEmailIsPreferred) {
			this.updatePreferredEmails();
		}
		const updatedEmail: Email = {
			emailAddress: this.state.editEmailAddress,
			type: this.state.editEmailType,
			isPreferred: this.state.editEmailIsPreferred,
			doNotEmail: this.state.editEmailIsDoNotEmail,
			dateCreated: moment().toDate(),
		};
		emails[this.state.itemToEdit] = updatedEmail;
		this.props.changeFieldValue('emails', emails);
		this.props.touchField('emails');
	}

	onUpdateEmailAddress = (event: any) => {
		let updatedEmailAddress = event.target.value;
		this.setState({
			editEmailAddress: updatedEmailAddress,
			formHasChanged: true,
		});
	}

	onUpdateEmailType = (event: any) => {
		let updatedType = event.target.value;
		this.setState({
			editEmailType: updatedType,
			formHasChanged: true,
		});
	}

	onUpdateEmailPreferred = (event: any) => {
		this.setState({
			editEmailIsPreferred: !this.state.editEmailIsPreferred,
			formHasChanged: true,
		});
	}

	onConfirmEmailDoNotEmailDialog = () => {
		this.setState({
			editEmailIsDoNotEmail: true,
			showConfirmEmailDoNotEmailDialog: false,
			formHasChanged: true,
		});
	}

	emailHeader = () => {
		return (
			<Grid container>
				<Grid item xs={9} sm={10}>
					<Typography variant="subtitle1" style={{ padding: 16 }}>
						{this.props.showAsterisk
							? createRequiredLabel('Email Addresses:')
							: 'Email Addresses:'}
					</Typography>
				</Grid>
				<Grid item xs={3} sm={2} style={{ textAlign: 'center' }}>
					<div>
						<IconButton onClick={this.onClickAdd} style={this.styles.saveIconStyle}>
							<Icon>add_circle_outline</Icon>
						</IconButton>
					</div>
				</Grid>
			</Grid>
		);
	}

	listEmails = () => {
		const emails = _.get(this.props, 'formValues.emails', []);
		return (
			<List>
				{emails.map((email: Email, index: number) => {
					let secondaryText = '';
					let iconStyle: React.CSSProperties | undefined = undefined;

					if (email.doNotEmail) {
						iconStyle = this.styles.dnc;

						if (email.emailUndeliverableDate) {
							secondaryText = 'Undeliverable - ';
						} else {
							secondaryText = 'Unsubscribed - ';
						}
					}

					if (email.isPreferred) {
						secondaryText += 'Preferred';

						if (!email.doNotEmail) {
							iconStyle = this.styles.preferred;
						}
					}

					if (email.isPreferred && email.type) {
						secondaryText += ', ';
					}

					secondaryText += email.type || '';

					return (
						<div key={`${index}-${email.emailAddress}`}>
							<Divider />
							<ListItem>
								<ListItemIcon style={iconStyle}>
									<Icon>email</Icon>
								</ListItemIcon>
								<ListItemText
									primary={email.emailAddress}
									secondary={secondaryText}
								/>
								<ListItemSecondaryAction
									style={{ paddingTop: 12, marginRight: 15 }}
								>
									<IconButton
										style={this.styles.moreVertStyle}
										onClick={event =>
											this.setState({
												anchorEl: event.currentTarget,
												emailIndex: index,
											})
										}
									>
										<Icon>more_vert</Icon>
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</div>
					);
				})}
				<Menu
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={() =>
						this.setState({
							anchorEl: undefined,
							emailIndex: undefined,
						})
					}
					key="menu"
				>
					<MenuItem onClick={this.onClickSetPreferred}>
						Mark as Preferred
					</MenuItem>
					<MenuItem onClick={this.onClickEdit}>Edit</MenuItem>
					<MenuItem onClick={this.onClickDelete}>Delete</MenuItem>
				</Menu>
			</List>
		);
	}

	updatePreferredEmails = () => {
		const emails = (this.props.formValues.emails || []).slice();
		if (emails.length > 0) {
			const newFormValues = emails.forEach(email => {
				email.isPreferred = false;
			});

			this.props.changeFieldValue(this.props.formName, newFormValues);
		}
	}

	editDialog = () => {
		let emails = _.get(this.props, 'formValues.emails', []);
		if (emails != []) {
			const isEmailInvalid = validateEmail(this.state.editEmailAddress);

			return (
				<Dialog fullScreen open={this.state.showEditDialog}>
					<SaveCancelHeaderBarComponent
						title={'Edit Email'}
						onSave={() => {
							this.onEditEmail();
							this.setState({ showEditDialog: false });
						}}
						onCancel={() => this.setState({ showEditDialog: false })}
						saveText="Save"
						isSaveDisabled={!!isEmailInvalid || !this.state.editEmailAddress || !this.state.formHasChanged}
					/>
					<Grid container>
						<StandardTextField
							label="Email Address"
							value={this.state.editEmailAddress}
							onChange={this.onUpdateEmailAddress}
							style={this.styles.editListItem}
							error={!!isEmailInvalid}
							helperText={isEmailInvalid}
						/>
					</Grid>
					<Grid container>
						<Grid item xs={6}>
							<FormControlLabel
								control={
									<StandardSwitch
										checked={this.state.editEmailIsPreferred}
										onChange={this.onUpdateEmailPreferred}
										color="primary"
									/>
								}
								style={{ marginLeft: 5, marginTop: 7 }}
								label="Preferred"
							/>
						</Grid>
						<Grid item xs={6}>
							<StandardTextField
								fullWidth
								select
								label="Type"
								value={this.state.editEmailType || "Home"}
								onChange={this.onUpdateEmailType}
							>
								<MenuItem value={'Home'}>Home</MenuItem>
								<MenuItem value={'Work'}>Work</MenuItem>
							</StandardTextField>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={6}>
							<FormControlLabel
								control={
									<StandardSwitch
										checked={this.state.editEmailIsDoNotEmail}
										onClick={() =>
											this.setState({ showConfirmEmailDoNotEmailDialog: true })
										}
										disabled={this.state.editEmailIsDoNotEmail}
									/>
								}
								style={{ marginLeft: 5, marginTop: 7 }}
								label="Do Not Email"
							/>
						</Grid>
					</Grid>
					<Grid container>
						{this.state.editEmailUndeliverableDate && (
							<Grid item xs={12} style={{ marginLeft: 5, marginTop: 7 }}>
								<div>
									Undeliverable Date:{' '}
									{moment
										.utc(this.state.editEmailUndeliverableDate)
										.local()
										.format('MM/DD/YY h:mm:ss a')}
								</div>
								<div>Please update email address.</div>
							</Grid>
						)}
					</Grid>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						onClose={() =>
							this.setState({ showConfirmEmailDoNotEmailDialog: false })
						}
						open={this.state.showConfirmEmailDoNotEmailDialog}
					>
						<DialogContent>
							By performing this action, this contact will no longer receive
							emails at {this.state.editEmailAddress} from HealthMarkets. This
							action is permanent and cannot be undone.
						</DialogContent>
						<DialogActions>
							<Button
								color="secondary"
								onClick={() =>
									this.setState({ showConfirmEmailDoNotEmailDialog: false })
								}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								onClick={this.onConfirmEmailDoNotEmailDialog}
								color="primary"
								style={{
									backgroundColor: themePalette.delete_remove_reject_button,
								}}
							>
								Do Not Email
							</Button>
						</DialogActions>
					</Dialog>
				</Dialog>
			);
		}
	};
	render() {
		return (
			<div>
				{this.emailHeader()}
				{this.listEmails()}
				<Field name="emails" component={ValueHolder} />
				{this.editDialog()}
			</div>
		);
	}
}

function mapStateToProps(
	state,
	ownProps: Props
): StateProps {
	
	return {
		formValues: getFormValues(ownProps.formName)(state),
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: Props
): DispatchProps {
	return {
		changeFieldValue: (field: string, value: any) => {
			dispatch(change(ownProps.formName, field, value));
		},
		touchField: (field: string) => {
			dispatch(touch(ownProps.formName, field));
		},
	};
}

export const EmailFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_EmailFields
) as React.ComponentClass<ComponentProps>;
