import React from 'react';
import { Field } from 'redux-form';
import {
	Typography,
	Icon,
	Card,
	CardContent,
	Collapse,
	CardActions,
	IconButton,
	MenuItem,
	Grid,
} from '@material-ui/core';
import { FormSelect, FormTextField, FormSwitch } from '../../utilities/forms';
import { connect, r_pipe, r_match } from '@hmkts/rise';
import { themePalette } from '../../utilities/branding';
import {
	normalizeSSN, normalizeYear,
} from '../../utilities/formatting/data_normalizations';
import {
	validateDateString,
	validateSSN,
	validateName,
	validateNameIfExists,
	validateYear,
} from '../../utilities/form_validation';
import { change, touch } from 'redux-form';
import { createRequiredLabel } from '../../components/utility/required_label';
import { isIOS } from '../../utilities/is_mobile';
import { Lookups } from '../../utilities/lookup';

export type BasicInfoFieldsData = {
	firstName: string;
	lastName: string;
	jobTitle: string;
	salutation: string;
	preferredName: string;
	middleName: string;
	suffix: string;
	DOB: string;
	gender: string;
	maritalStatus: string;
	heightFt: number;
	heightIn: number;
	weight: number;
	annualIncome: string;
	occupation: string;
	languagePreference: string;
	isTobaccoUser: boolean;
	status: string;
	isStudent: boolean;
	isSelfEmployed: boolean;
	employeeStatus?: string;
	groupEligibilityStatus?: string;
	groupEnrollmentStatus?: string;
	lastFourSsn: string;
	wasRpmNameChanged: boolean;
	clientType?: string;
	lastUnemploymentYear?: string;
};

interface Props {
	formName: string;
	employee?: boolean;
	title?: string;
}

interface StateProps {
	suffixList: string[];
	languageList: string[];
	statusList: string[];
	genderList: string[];
	maritalStatusList: string[];
	employeeStatusList: string[];
	groupStatusList: string[];
	clientTypeList: string[];
}

interface DispatchProps {
	changeFieldValue: (form: string, field: string, value: any) => void;
	touchField(field: string): void;
}

interface State {
	expanded: boolean;
}

const mapMenu = (arr: string[]) =>arr.map(a => <MenuItem value={a}>{a}</MenuItem>)

const dobValidation = validateDateString('Date of Birth', true, true);
class _BasicInfoFields extends React.Component<
	Props & StateProps & DispatchProps,
	State
	> {
	constructor(props: any) {
		super(props);
		this.state = {
			expanded: false,
		};

		this.handleExpandClick = this.handleExpandClick.bind(this);
	}

	handleExpandClick = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	expandForm = () => {
		this.setState({ expanded: true });
	}

	handleChangingRpmNameField = (event: any) => {
		const fieldToUpdate = event.target.name;
		const value = event.target.value;
		this.props.changeFieldValue(this.props.formName, fieldToUpdate, value);
		this.props.changeFieldValue(this.props.formName, 'wasRpmNameChanged', true);
		this.touchField(event);
	};

	handleChangingRpmNameFieldSelect = (value: any, fieldToUpdate: string) => {
		this.props.changeFieldValue(this.props.formName, fieldToUpdate, value);
		this.props.changeFieldValue(this.props.formName, 'wasRpmNameChanged', true);
		this.props.touchField(name);
	};

	touchField = (event: any) => {
		this.props.touchField(event.target.name);
	};

	renderNumberField = (params: {
		name: string;
		label: string;
		normalize: any;
		validate?: any;
	}) => (
			<Field
				name={params.name}
				component={FormTextField}
				label={params.label}
				fullWidth
				type={isIOS ? 'tel' : 'number'}
				pattern={isIOS ? '/[0-9]*' : undefined}
				normalize={params.normalize}
				validate={params.validate}
				onChange={this.touchField}
			/>
		);


	render() {
		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{this.props.title || 'Basic Info'}
				</Typography>
				<Card style={{ margin: '10px' }}>
					<CardContent>
						<Field
							name="firstName"
							component={FormTextField}
							label={createRequiredLabel("First Name")}
							gridSize={"12|12"}
							onBlur={this.handleChangingRpmNameField}
							validate={[validateName]}
							maxLength={50}
						/>
						<Field
							name="middleName"
							component={FormTextField}
							label="Middle Name"
							onBlur={this.handleChangingRpmNameField}
							validate={[validateNameIfExists]}
							gridSize={"12|12"}

						/>
						<Field
							name="lastName"
							component={FormTextField}
							label={createRequiredLabel('Last Name')}
							gridSize={"12|12"}
							onBlur={this.handleChangingRpmNameField}
							validate={[validateName]}
							maxLength={50}
						/>
						<Field
							name="DOB"
							component={FormTextField}
							label="Date of Birth"
							gridSize={"12|12"}
							normalize={r_pipe?.dateString}
							validate={[dobValidation]}
							onBlur={this.touchField}
						/>
						<Field
							name="jobTitle"
							component={FormTextField}
							label="Job Title"
							onBlur={this.touchField}
							validate={[validateNameIfExists]}
							fullWidth
						/>

						<Field
							name="status"
							label="Person Status"
							component={FormSelect}
							children={this.props.statusList.map(status => (
								<MenuItem key={status} value={status}>{status}</MenuItem>
							))}
							onChange={(value: any) => {
								this.handleChangingRpmNameFieldSelect(value, 'status');
							}}
							gridSize={'12|12'}
						/>

					</CardContent>
					<div onClick={this.handleExpandClick} style={styles.pointer}>
						<CardActions style={{ marginBottom: '0px' }}>
							<span
								style={{
									fontSize: '20px',
									color: themePalette.sub_text,
									paddingLeft: 10,
								}}
							>
								Additional Info
							</span>
							<IconButton
								style={this.state.expanded ? styles.expandOpen : styles.expand}
								onFocus={this.expandForm}
							>
								<Icon>keyboard_arrow_down</Icon>
							</IconButton>
						</CardActions>
					</div>
					<Collapse in={this.state.expanded} style={{ padding: '0 20px' }} >
						<div onFocus={this.expandForm}>
							<Field
								name="salutation"
								component={FormTextField}
								label="Salutation"
								fullWidth
								onChange={this.touchField}
							/>
							<Field
								name="preferredName"
								component={FormTextField}
								label="Preferred Name"
								fullWidth
								onChange={this.touchField}
							/>

							<Field
								name="suffix"
								label="Suffix"
								component={FormSelect}
								children={this.props.suffixList.map(suffix => (
									<MenuItem value={suffix}>{suffix}</MenuItem>
								))}
								onChange={(value: any) => {
									this.handleChangingRpmNameFieldSelect(value, 'suffix');
								}}
								fullWidth
							/>

							<Field
								name="gender"
								label="Gender"
								component={FormSelect}
								children={this.props.genderList.map(gender => (
									<MenuItem value={gender}>{gender}</MenuItem>
								))}
								onChange={() => {
									this.props.touchField('gender');
								}}
								fullWidth
							/>
							<Field
								name="maritalStatus"
								label="Marital Status"
								component={FormSelect}
								children={this.props.maritalStatusList.map(status => (
									<MenuItem value={status}>{status}</MenuItem>
								))}
								onChange={() => {
									this.props.touchField('maritalStatus');
								}}
								fullWidth
							/>
							<Grid container>
								<Field
									name="heightFt"
									component={FormTextField}
									label="Height Ft."
									type={isIOS ? 'tel' : 'number'}
									normalize={r_pipe.feet}
									onChange={this.touchField}
									gridSize={"4"}
								/>
								<Field
									name="heightIn"
									component={FormTextField}
									label="Height In."
									type={isIOS ? 'tel' : 'number'}
									normalize={r_pipe.inches}
									onChange={this.touchField}
									gridSize={"4"}
								/>
								<Field
									name="weight"
									component={FormTextField}
									label="Weight (lb)"
									fullWidth
									type={isIOS ? 'tel' : 'number'}
									normalize={r_pipe.weight}
									validate={r_match.weight}
									onChange={this.touchField}
									gridSize={"4"}
								/>
							</Grid>
							<Field
								name="annualIncome"
								component={FormTextField}
								label="Annual Income"
								fullWidth
								normalize={r_pipe.dollarCents}
								onChange={this.touchField}
							/>
							<Field
								name="lastFourSsn"
								component={FormTextField}
								label="Last Four of SSN"
								fullWidth
								validate={validateSSN}
								normalize={normalizeSSN}
								onChange={this.touchField}
							/>
							<Field
								name="occupation"
								component={FormTextField}
								label="Occupation"
								fullWidth
								onChange={this.touchField}
							/>
							<div hidden={!this.props.employee}>
								<Field
									name="employeeStatus"
									label="Employee Status"
									component={FormSelect}
									children={mapMenu(this.props.employeeStatusList)}
									onChange={() => {
										this.props.touchField('employeeStatus');
									}}
									fullWidth
								/>

								<Field
									name="groupEligibilityStatus"
									label="Group Eligibility Status"
									component={FormSelect}
									children={mapMenu(this.props.groupStatusList)}
									onChange={() => {
										this.props.touchField('groupEligibilityStatus');
									}}
									fullWidth
								/>

								<Field
									name="groupEnrollmentStatus"
									label="Group Enrollment Status"
									component={FormSelect}
									children={mapMenu(this.props.groupStatusList)}
									onChange={() => {
										this.props.touchField('groupEnrollmentStatus');
									}}
									fullWidth
								/>
							</div>
							<Field
								name="languagePreference"
								label="Language Preference"
								component={FormSelect}
								children={mapMenu(this.props.languageList)}
								onChange={() => {
									this.props.touchField('languagePreference');
								}}
								fullWidth
							/>
							<Field
								name="clientType"
								label="Client Type"
								fullWidth
								component={FormSelect}
								children={mapMenu(this.props.clientTypeList)}
								onChange={() => {
									this.props.touchField('clientType');
								}}
							/>
							<Field
								name="lastUnemploymentYear"
								component={FormTextField}
								label="Last Unemployment Year"
								fullWidth
								validate={validateYear}
								normalize={normalizeYear}
								onChange={this.touchField}
							/>
							<div style={styles.contactToggles}>
								<Grid container alignItems="center">
									<Field
										gridSize="12|4"
										label="Tobacco User"
										name="isTobaccoUser"
										component={FormSwitch}
										color="primary"
										onChange={this.touchField}
									/>
									<Field
										label="Student"
										name="isStudent"
										component={FormSwitch}
										color="primary"
										onChange={this.touchField}
									/>
								</Grid>
								<Field
									label="Self Employed"
									gridSize="12|4"
									name="isSelfEmployed"
									component={FormSwitch}
									color="primary"
									onChange={this.touchField}
								/>
							</div>
						</div>
					</Collapse>
				</Card>
			</div >
		);
	}
}

function mapStateToProps(state: any): StateProps {

	return {
		suffixList: state.lookup.getLabels(Lookups.Suffix),
		languageList: state.lookup.getLabels(Lookups.Language),
		genderList: state.lookup.getLabels(Lookups.Gender),
		statusList: state.lookup.getLabels(Lookups.Status),
		maritalStatusList: state.lookup.getLabels(Lookups.MaritalStatus),
		employeeStatusList: state.lookup.getLabels(Lookups.EmployeeStatus),
		groupStatusList: state.lookup.getLabels(Lookups.EmployerGroupStatus),
		clientTypeList: state.lookup.getLabels(Lookups.ClientType),
	};
}

function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
	return {
		changeFieldValue: (form: string, field: string, value: any) =>
			dispatch(change(form, field, value)),
		touchField: (field: string) => {
			dispatch(touch(ownProps.formName, field));
		},
	};
}

export const BasicInfoFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_BasicInfoFields
);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	contactToggles: {
		marginRight: '30px',
		marginLeft: '20px',
		paddingTop: '10px',
		paddingBottom: '10px',
	},
	pointer: {
		cursor: 'pointer',
	},
};
