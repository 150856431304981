import React from 'react';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { connect } from '@hmkts/rise';
import _ from 'lodash';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	MenuItemProps,
} from '@material-ui/core';
import { navigateTo, navigateBack } from '../../actions/navigation_actions';
import { Lead } from '../../reducers/LeadReducer';
import { DeleteLead, GetLead, UpdateLead } from '../../actions/lead_actions';
import { Loaded } from '../../utilities/utilities';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { LeadStatusCard } from '../../components/lead/LeadStatusCard';
import moment from 'moment';
import { EMPTY_LEAD } from '../../utilities/empty_entities';
import { LookupDictionary, Lookup, Lookups } from '../../utilities/lookup';
import { StoreFollowupDescription } from '../../actions/activity_actions';
import { jwt_auth } from '../../utilities/auth';
import { FollowupFields } from '../../reducers/activity_reducer';
import { HouseholdRole } from '../../reducers/ContactReducer';
import { FollowUpActivityType } from '../activity/upsert_activity';
import { MoreMenu, createHelpAction, createMenuAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { P } from '../../utilities/auth/permissions';
import { fullName } from '../../assets/common/string_builders';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { LeadInfoCard } from './lead_info_card';
import { LeadProductsCard } from './lead_products_card';

interface StateProps {
	leads: Loaded<Lead>[];
	lead?: Lead;
	lookups: LookupDictionary;
	loading: boolean;
	fromAdvancedSearch: boolean;
}

interface DispatchProps {
	getLead: (id: string) => void;
	updateLead: (leadToSend: Lead) => void;
	deleteLead: (leadToSend: Lead) => void;
	navigateBack: () => void;
	navigateTo: (route: string) => void;
	storeFollowupDescription: (params: FollowupFields) => void;
}

interface LeadDetailPageState {
	deleteConfirmationVisibility: boolean;
	householdId: string;
	leadId: string;
	anchorEl?: HTMLElement;
}

type LeadDetailPageProps = StateProps & DispatchProps & NavigationProps;

class LeadDetailPage extends React.Component<
	LeadDetailPageProps,
	LeadDetailPageState
	> {
	constructor(props: any) {
		super(props);

		const householdId: string =
			this.props.match.params.householdID.toString() || 'new';
		const leadId: string = this.props.match.params.leadID.toString() || 'new';

		this.state = {
			deleteConfirmationVisibility: false,
			householdId: householdId,
			leadId: leadId,
		};
	}

	componentWillMount() {
		if (!this.props.lead) this.props.getLead(this.state.leadId);
	}

	handleNoSaleFollowUp = (status: Lookup, householdId: string, employerId: string) => {

		var followUpDescription = Lookups.NoSale.label + (status.label ? ' - ' + status.label : '');
		this.props.storeFollowupDescription({
			description: followUpDescription,
			time: moment(),
		});
		this.props.navigateTo(
			navRoutes.phoneEdit.path
				.replace(`/${Strings.Navigation.HouseholdId}?`, householdId)
				.replace(Strings.Navigation.EmployerId, employerId)
				.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL_3_MONTHS)
				.replace(Strings.Navigation.ActivityId, '0')
		);
	}
	handleEmployerFollowUp = (status: Lookup, employerId: string, followUpType: string) => {
		//schedule appointment
		if (followUpType == FollowUpActivityType.APPOINTMENT) {

			const lead = this.props.lead || null;
			const primary = lead
				? lead.contacts.find(contact => contact.householdRole == HouseholdRole.Primary)
				: null;
			const name = primary
				? fullName(primary)
				: '';
			this.props.storeFollowupDescription({
				description: status.label,
				title: name ? `Appointment with ${name}` : '',
			});
			this.props.navigateTo(
				navRoutes.appointmentCreate.path
					.replace(Strings.Navigation.EmployerId, employerId)
					.replace(`/${Strings.Navigation.HouseholdId}?`, '')
			);
		}
		//schedule phone call
		else {
			this.props.storeFollowupDescription({
				description: status.label,
				time: moment(),
			});
			this.props.navigateTo(
				navRoutes.phoneCreate.path
					.replace(`/${Strings.Navigation.HouseholdId}?`, '')
					.replace(Strings.Navigation.EmployerId, employerId)
					.replace(Strings.Navigation.FollowUp, '0')
			);
		}
	}

	handleHHFollowUp = (status: Lookup, householdId: string, followUpType: FollowUpActivityType) => {
		let followUpDescription : string = status.label;

		const lead = this.props.lead || null;
			const primary = lead
				? lead.contacts.find(contact => contact.householdRole == HouseholdRole.Primary)
				: null;
			const name = primary
				? fullName(primary)
				: '';

		//schedule appointment
		if (followUpType == FollowUpActivityType.APPOINTMENT) {
			this.props.storeFollowupDescription({
				description: followUpDescription,
				title: `Appointment with ${name}`,
				time: moment(),
			});

			this.props.navigateTo(
				navRoutes.appointmentCreate.path
					.replace(Strings.Navigation.HouseholdId, householdId)
					.replace(Strings.Navigation.Reschedule, followUpType)
			);
		}

		//schedule phone call
		else {
			this.props.storeFollowupDescription({
				description: followUpDescription,
				time: moment(),
			});

			this.props.navigateTo(
				navRoutes.phoneEdit.path
					.replace(Strings.Navigation.HouseholdId, householdId)
					.replace(Strings.Navigation.Reschedule, followUpType)
			);
		}
	}

	handleNavigateToFollowup = (
		status: Lookup,
		householdId: string,
		employerId: string,
		isFollowUp: boolean
	) => {
		if (status.isChildOf(Lookups.CreateNewAppointment)) {
			employerId ? this.handleEmployerFollowUp(status, employerId, FollowUpActivityType.APPOINTMENT) : this.handleHHFollowUp(status, householdId, FollowUpActivityType.APPOINTMENT);
		} else if (isFollowUp && status.isChildOf(Lookups.NoSale)) {
			this.handleNoSaleFollowUp(status, householdId, employerId);
		} else if (
			(isFollowUp)
			|| (status.isChildOf(Lookups.AlwaysCreateNewPhoneCall))) {
			employerId ? this.handleEmployerFollowUp(status, employerId, FollowUpActivityType.PHONE_CALL) : this.handleHHFollowUp(status, householdId, FollowUpActivityType.PHONE_CALL);
		}
	};

	deleteLead = () => {
		let wrappedLead: Loaded<Lead> | undefined = _.find(
			this.props.leads,
			(lead: Loaded<Lead>) => {
				const leadIdInState: string = lead.data.id || '';
				return leadIdInState === this.state.leadId;
			}
		);
		if (wrappedLead && wrappedLead.data) {
			let lead: any = wrappedLead.data;
			this.props.deleteLead(lead);
			this.setState({ deleteConfirmationVisibility: false });

			this.props.navigateBack();
			if (!this.props.fromAdvancedSearch) {
				this.props.navigateBack();
			}
		}
	};

	renderConfirmationDialog() {
		return (
			<Dialog
				open={this.state.deleteConfirmationVisibility}
				onClose={() => this.setState({ deleteConfirmationVisibility: false })}
			>
				<DialogTitle>Confirmation</DialogTitle>
				<DialogContent>
					Are you sure you wish to delete this lead?
				</DialogContent>
				<DialogActions>{this.composeConfirmationActionItems()}</DialogActions>
			</Dialog>
		);
	}

	composeConfirmationActionItems() {
		const actionItems: JSX.Element[] = [];
		actionItems.push(
			<Button
				key="cancel"
				color="secondary"
				onClick={() =>
					this.setState({
						deleteConfirmationVisibility: false,
						anchorEl: undefined,
					})
				}
			>
				Cancel
			</Button>,
			<Button
				key="delete"
				variant="contained"
				color="primary"
				style={{ backgroundColor: themePalette.delete_remove_reject_button }}
				onClick={this.deleteLead}
			>
				Delete
			</Button>
		);
		return actionItems;
	}

	editLead = () => {
		this.props.navigateTo(
			navRoutes.leadUpsert.path
				.replace(Strings.Navigation.HouseholdId, this.state.householdId)
				.replace(Strings.Navigation.LeadId, this.state.leadId)
		);
	};

	confirmDelete = () => {
		this.setState({ deleteConfirmationVisibility: true });
	};

	render() {
		let wrappedLead = _.find(this.props.leads, (lead: Loaded<Lead>) => {
			const leadIdInState: string = lead.data.id || '';
			return leadIdInState === this.state.leadId;
		});
		let lead = {};
		if (wrappedLead && wrappedLead.data && wrappedLead.data.contacts) {
			lead = wrappedLead.data;
		}

		const canEdit = jwt_auth.hasPermission(P.CreateLead);

		const menuItems: MenuItemProps[] = [];
		if (canEdit) {
			menuItems.push(createMenuAction('Edit', this.editLead));
			menuItems.push(createMenuAction('Delete', this.confirmDelete));
		}
		menuItems.push(createHelpAction(themeLinks.helpLinkCRM));

		const safeLead: Lead = wrappedLead ? wrappedLead.data : EMPTY_LEAD;
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Lead"
						rightButtons={<MoreMenu children={menuItems} />}
						isLoading={this.props.loading}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<LeadStatusCard
						lead={safeLead}
						updateLead={this.props.updateLead}
						navigateToEmployerFollowup={this.handleNavigateToFollowup}
						navigateToHouseholdFollowup={this.handleNavigateToFollowup}
						lookups={this.props.lookups}
					/>
					<LeadProductsCard
						title={'Requested Product Types'}
						lead={lead as Lead}
						noPadding
					/>
					<LeadInfoCard
						title={'Lead Info'}
						lead={lead as Lead}
					/>
				</DesktopPadding>
				{this.renderConfirmationDialog()}
			</BasePageContainer>
		);
	}
}

function mapStateToProps(state: AppState, props: LeadDetailPageProps): StateProps {
	
	const lead = state.lead.leads.find(
		lead => lead.data.id == props.match.params.leadID
	);
	const fromAdvancedSearch = Boolean(
		_.get(props.match.params, 'fromAdvSearch', false)
	);

	return {
		lead: lead && lead.data,
		leads: state.lead.leads,
		lookups: state.lookup,
		loading: !lead || lead.loading,
		fromAdvancedSearch,
	};
}
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
	return {
		getLead: (id: string) => dispatch(GetLead.started(id)),
		updateLead: (leadToSend: Lead) => dispatch(UpdateLead.started(leadToSend)),
		deleteLead: (leadToSend: Lead) =>
			dispatch(DeleteLead.started(leadToSend.id)),
		navigateBack: () => dispatch(navigateBack()),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		storeFollowupDescription: (params: FollowupFields) => {
			dispatch(StoreFollowupDescription(params));
		},
	};
}

export const LeadDetailPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(LeadDetailPage);

const styles: { [key: string]: React.CSSProperties } = {
	marginTen: {
		margin: 10,
	},
};
