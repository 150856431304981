import { Template } from '../../reducers/template_reducer';
import { SnackbarProps, QueueSnackbar } from '../../actions/snackbar_actions';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Lookups } from '../../utilities/lookup';
import { Strings } from '../../assets/common/strings';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { GetTemplateById, DeleteTemplate, PostTemplate, StoreEditedTemplate } from '../../actions/template_actions';
import { connect } from '@hmkts/rise';
import React, { ChangeEvent } from 'react';
import { jwt_auth } from '../../utilities/auth';
import { themePalette } from '../../utilities/branding';
import {
	Button,
	Grid,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	Switch,
	Typography,
	Card,
	CardContent,
	FormControlLabel,
	MenuItemProps,
	MenuItem,
	MenuList,
} from '@material-ui/core';
import { MoreMenu } from '../../components/nav/more_menu';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { createRequiredLabel } from '../../components/utility/required_label';
import { P } from '../../utilities/auth/permissions';
import { deleteTemplateText } from '../../assets/common/string_builders';
import { EmailAutomationEvent } from '../../reducers/email_automation_reducer';
import { getEmailAutomationsByAgentId } from '../../actions/email_automation_actions';
import { makeTrimToMaxLength } from '../../utilities/formatting/string_normalization';
import { MultiselectDropdownWithChips } from '../../components/Layout/filter';
import { getSnackbarErrorProps } from '../../utilities/snackbar_util';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { AppConfig } from '../../types/config';

const { Editor } = require('@tinymce/tinymce-react');

const STI = Strings.TemplateInput;
const SCE = Strings.ContentEditor;

interface StateProps {
	agentCode: string;
	agentId: string;
	isTemplateLoading: boolean;
	languages: string[];
	linesOfBusiness: string[];
	substitutions: string[];
	template: Template;
	events: EmailAutomationEvent[];
	emailAutomationEventTypeCategories: string[];
};

interface DispatchProps {
	deleteTemplate: (templateId: string) => void;
	getSnackbar: (props: SnackbarProps) => void;
	getTemplate: (templateId: string) => void;
	navigateBack: () => void;
	navigateTo: (route: string) => void;
	postTemplate: (template: Template) => void;
	storeEditedTemplate: (template: Template) => void;
	getEmailAutomations: () => void;
};

type Props =
	& StateProps
	& DispatchProps
	& NavigationProps;

interface State {
	templateValues: Template;

	isCancelDialogOpen: boolean;
	isDeleteDialogOpen: boolean;
	isInUseDialogOpen: boolean;
	isVariableDialogOpen: boolean;

	isEditMode: boolean;
	isNewTemplate: boolean;
};

const STM = Strings.TemplateManagement;

let IS_INITIALIZING: boolean = true;

class TemplateContentPage extends React.Component<Props, State> {
	isEmailAdmin: boolean;
	isPristine: boolean;
	moreMenuChildren: MenuItemProps[] = [];
	editor: any;

	constructor(props: Props) {
		super(props);

		this.state = {
			templateValues: props.template,
			isCancelDialogOpen: false,
			isDeleteDialogOpen: false,
			isInUseDialogOpen: false,
			isVariableDialogOpen: false,
			isEditMode: this.props.match.params.isEditMode === Strings.Boolean.True,
			isNewTemplate: (this.props.match.params.templateId || '') === STM.NewTemplate,
		};

		this.isEmailAdmin = jwt_auth.hasPermission(P.Admin);
		this.isPristine = true;

		IS_INITIALIZING = true;
	}

	componentWillMount() {
		this.props.getEmailAutomations();

		if (!this.props.match.params.templateId || this.props.match.params.templateId === '') {
			return;

		} else if ((this.props.match.params.templateId || STM.NewTemplate) !== STM.NewTemplate) {
			this.props.getTemplate(this.props.match.params.templateId);

		} else {
			IS_INITIALIZING = false;
			this.moreMenuChildren = [{
				children: Strings.MoreMenu.Edit,
				onClick: this.onClickEdit,
				disabled: false,
			}, {
				children: Strings.MoreMenu.Delete,
				onClick: this.onClickDelete,
				disabled: false,
			}];
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (nextProps.isTemplateLoading
			|| !IS_INITIALIZING
		) { return; }

		if (!this.isEmailAdmin
			&& this.state.isEditMode
			&& !this.state.isNewTemplate
			&& nextProps.template
			&& nextProps.template.emailType
			&& nextProps.template.emailType === STM.Corporate
		) {
			this.props.getSnackbar(getSnackbarErrorProps(STM.AdminOnly));
			this.props.navigateTo(navRoutes.templateManagement.path);
			return;
		}

		if (IS_INITIALIZING) {
			const menuItemDisabled = !this.isEmailAdmin
				&& nextProps.template.emailType === Strings.TemplateManagement.Corporate;

			IS_INITIALIZING = false;

			this.setState({ templateValues: nextProps.template }, () => {
				this.moreMenuChildren = [{
					children: Strings.MoreMenu.Edit,
					onClick: this.onClickEdit,
					disabled: menuItemDisabled,
				}, {
					children: Strings.MoreMenu.Delete,
					onClick: this.onClickDelete,
					disabled: menuItemDisabled,
				}];
			});
		}
	}

	closeDialog = () => {
		this.setState({
			isCancelDialogOpen: false,
			isDeleteDialogOpen: false,
			isInUseDialogOpen: false,
			isVariableDialogOpen: false,
		})
	};

	openInUseDialog = () => this.setState({ isInUseDialogOpen: true });
	openDeleteDialog = () => this.setState({ isDeleteDialogOpen: true });
	openCancelDialog = () => this.setState({ isCancelDialogOpen: true });
	openVariableDialog = () => this.setState({ isVariableDialogOpen: true });

	startEditMode = () => {
		this.setState({
			isEditMode: true,
			isCancelDialogOpen: false,
			isDeleteDialogOpen: false,
			isInUseDialogOpen: false,
			isVariableDialogOpen: false,
		});
	};

	onClickDelete = () => {
		this.props.template
			&& this.props.template.isInUseOnAutomation
			&& !this.isEmailAdmin
			? this.openInUseDialog()
			: this.openDeleteDialog();
	};

	onDeleteConfirmation = () => {
		if (!this.state.isNewTemplate) {
			this.props.deleteTemplate(this.props.template.id);
		}

		this.props.navigateBack();
	};

	onClickEdit = () => {
		!this.state.isNewTemplate
			&& this.props.template.isInUseOnAutomation
			&& !this.isEmailAdmin
			? this.openInUseDialog()
			: this.startEditMode();
	};

	onClickPreview = () => {
		if (this.isValid()) {
			this.props.storeEditedTemplate(this.state.templateValues);
			this.props.navigateTo(
				navRoutes.templatePreview.path
					.replace(Strings.Navigation.TemplateId, this.props.template.id || STM.NewTemplate)
					.replace(Strings.Navigation.From, Strings.TemplateManagement.Content),
			);
		} else {
			this.props.getSnackbar(getSnackbarErrorProps(Strings.TemplateManagement.IncompleteFields));
		}
	};

	onClickSave = () => {
		if (this.isValid()) {
			this.props.postTemplate(this.state.templateValues);
			this.props.navigateTo(
				navRoutes.templateManagement.path,
			);
		} else {
			this.props.getSnackbar(getSnackbarErrorProps(STM.FailedSave));
		}
	};

	handleEditorChange = (content: string) => {
		const templateValues: Template = {
			...this.state.templateValues,
			htmlContent: content,
		};

		this.setState({ templateValues });
	};

	handleSelectEvent = (value: string) => this.setState({
		templateValues: {
			...this.state.templateValues,
		},
	});

	handleChange = (field: string, normalize?: number, validate?: boolean) => (event: ChangeEvent<HTMLInputElement>) => {
		let text = event.currentTarget.textContent || event.currentTarget.value || '';

		if (normalize) {
			text = makeTrimToMaxLength(normalize)(text);
		}

		if (validate) {
			text = text.trimLeft();
		}

		const templateValues = {
			...this.state.templateValues,
			[field]: text,
		};

		this.setState({ templateValues });
	};

	handleLineOfBusinessChange = (newLobs: string[]) => {
		const lobs = newLobs.length === 0
			? ['All']
			: newLobs;

		this.setState(prevState => ({
			templateValues: {
				...prevState.templateValues,
				linesOfBusiness: lobs,
			},
		}));
	}
	handleEventTypeChange = (newEventTypes: string[]) => {
		const eventTypes = newEventTypes.length === 0
			? ['All']
			: newEventTypes;

		this.setState(prevState => ({
			templateValues: {
				...prevState.templateValues,
				emailAutomationEventTypeCategory: eventTypes,
			},
		}));
	}
	handleSwitchChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const templateValues: Template = {
			...this.state.templateValues,
			isDraft: checked,
		};

		this.setState({ templateValues });
	};

	isValid = () => {
		return this.state.templateValues.emailType
			&& this.state.templateValues.title
			&& this.state.templateValues.subject
			&& this.state.templateValues.language
	};

	navigateBack = () => {
		this.props.navigateBack();
	};

	render() {
		if (this.editor) {
			if (this.state.isEditMode) {
				this.editor.setMode(Strings.TinyMCE.Design);
			}
		}

		return (
			<BasePageContainer
				topStyle={{ textAlign: Strings.CSS.Center }}
				topComponent={this.renderTopComponent()}
				middleComponent={
					IS_INITIALIZING
						? <div />
						: this.renderMiddleComponent()
				}
			/>
		);
	}

	renderTopComponent = (): JSX.Element => {
		return (
			<SaveCancelHeaderBarComponent
				title={
					this.state.isEditMode
						? Strings.TemplateManagement.EditorTitle
						: Strings.TemplateManagement.DetailsTitle
				}
				cancelText={
					this.state.isEditMode
						? Strings.ButtonText.Cancel
						: Strings.ButtonText.Back
				}
				onCancel={
					this.state.isEditMode
						? this.openCancelDialog
						: this.navigateBack
				}
				onSave={this.onClickSave}
				secondaryButtonText={Strings.ButtonText.Preview}
				onSecondaryButtonClick={this.onClickPreview}
				isSecondaryButtonDisabled={!this.state.isEditMode}
				isSaveDisabled={this.props.template.emailType === STM.Corporate && !this.isEmailAdmin}
				isLoading={IS_INITIALIZING}
			/>
		);
	};

	renderMiddleComponent = (): JSX.Element => {
		return !IS_INITIALIZING
			? (
				<div>
					{this.renderContentEditor()}
					{this.renderCancelDialog()}
					{this.renderIsInUseDialog()}
					{this.renderDeleteDialog()}
				</div>
			) : (
				<div />
			);
	};

	renderContentEditor = (): JSX.Element => {
		const emailTypes: string[] = this.isEmailAdmin || !this.state.isEditMode
			? [STM.Corporate, STM.Agent]
			: [STM.Agent];

		return (
			<Card
				style={{
					backgroundColor: Strings.CSS.White,
					marginLeft: '10px',
					marginRight: '0px',
				}}
			>
				<CardContent>
					<Grid container >
						<Grid item md={7} style={{ paddingRight: '5px' }}>
							<TextField
								id={STI.EmailType}
								select
								fullWidth
								label={createRequiredLabel(STI.TType)}
								children={this.renderMenuItems(emailTypes, false)}
								disabled={!this.state.isEditMode}
								name={STI.EmailType}
								value={this.state.templateValues.emailType}
								onChange={this.handleChange(STI.EmailType)}
							/>
							<TextField
								id={STI.Title}
								fullWidth
								disabled={!this.state.isEditMode}
								label={createRequiredLabel(STI.TTitle)}
								InputLabelProps={{ shrink: true }}
								value={this.state.templateValues.title}
								onChange={this.handleChange(STI.Title, 60, true)}
								inputProps={{ style: { color: themePalette.primary_text } }}
								InputProps={{ disableUnderline: true }}
							/>
							<TextField
								id={STI.Subject}
								fullWidth
								disabled={!this.state.isEditMode}
								label={createRequiredLabel(STI.ESubject)}
								InputLabelProps={{ shrink: true }}
								value={this.state.templateValues.subject}
								onChange={this.handleChange(STI.Subject, 60, true)}
								inputProps={{ style: { color: themePalette.primary_text } }}
								InputProps={{ disableUnderline: true }}
							/>
							<TextField
								id={STI.Description}
								fullWidth
								label={STI.TDescription}
								InputLabelProps={{ shrink: true }}
								value={this.state.templateValues.description}
								disabled={!this.state.isEditMode}
								onChange={this.handleChange(STI.Description, 360)}
								inputProps={{ style: { color: themePalette.primary_text } }}
								InputProps={{ disableUnderline: true }}
							/>

						</Grid>
						<Grid item md={3} style={{ paddingLeft: '5px' }}>
							<FormControlLabel
								control={
									<Switch
										name={STI.IDraft}
										color={Strings.Theming.Primary}
										disabled={!this.state.isEditMode}
										title={STI.Draft}
										checked={this.state.templateValues.isDraft}
										onChange={this.handleSwitchChange}
									/>
								}
								label={STI.Draft}
							/>
							<MultiselectDropdownWithChips
								selected={this.state.templateValues.emailAutomationEventTypeCategory}
								label={STI.TEvent}
								options={this.props.emailAutomationEventTypeCategories}
								setValues={this.handleEventTypeChange}
								disabled={!this.state.isEditMode}
							/>
							<TextField
								label={createRequiredLabel(STI.TLanguage)}
								name={STI.Language}
								select
								fullWidth
								disabled={!this.state.isEditMode}
								children={this.renderMenuItems(
									this.props.languages,
									false,
								)}
								value={this.state.templateValues.language}
								onChange={this.handleChange(STI.Language)}
							/>
							<MultiselectDropdownWithChips
								selected={this.state.templateValues.linesOfBusiness}
								label={STI.TLOB}
								options={this.props.linesOfBusiness}
								setValues={this.handleLineOfBusinessChange}
								disabled={!this.state.isEditMode}
							/>
						</Grid>
					</Grid>
					<Grid container justify={Strings.CSS.Center}>
						<Editor
							apiKey={AppConfig.tinymce_api_key}
							value={this.state.templateValues.htmlContent}
							onEditorChange={this.handleEditorChange}

							id={SCE.ID}
							init={{
								branding: false,
								selector: SCE.Selector,
								height: 400,
								menubar: false,
								content_css: [SCE.ContentCSS],
								paste_data_images: true,
								paste_retain_style_properties: SCE.PasteStyle,
								contextmenu: SCE.ContextMenu,
								content_style: SCE.ContentStyle,
								plugins: [SCE.PluginA, SCE.PluginB, SCE.PluginC, SCE.PluginD],
								toolbar: SCE.Toolbar,
								readonly: this.state.isEditMode ? 0 : 1,
								setup: (editor: any) => {
									editor.addButton('addVariables', {
										text: SCE.Button,
										icon: false,
										onclick: () => {
											return this.openVariableDialog();
										},
									});

									if (this.state.isEditMode) {
										editor.setMode(Strings.TinyMCE.Design);
									}

									this.editor = editor;
								},
							}}
						/>
					</Grid>
					<Typography
						align={Strings.CSS.Center}
						children={STI.Hint}
						variant={Strings.CSS.Caption}
					/>
					{this.renderVariableDialog()}
				</CardContent>
			</Card>
		);
	};

	renderMenuItems = (array: string[], isLob: boolean): JSX.Element[] => {
		const menuList: JSX.Element[] = [];

		if (isLob) {
			menuList.push(
				<MenuItem value={STI.All} key={'0LOB'}>
					{STI.All}
				</MenuItem>,
			);
		}

		array.forEach((element: string, index: number) => {
			menuList.push(
				<MenuItem value={element} key={index}>
					{element}
				</MenuItem>,
			);
		});

		return menuList;
	};

	renderVariableDialog = (): JSX.Element => {
		return (
			<Dialog
				open={this.state.isVariableDialogOpen}
				onClose={this.closeDialog}
			>
				<DialogTitle children={STI.SelectVar} />
				<MenuList
					children={this.props.substitutions.map((substitution, index) => {
						return (
							<MenuItem
								key={index}
								value={substitution}
								onClick={() => {
									this.editor.insertContent(STI.LeftSub + substitution + STI.RightSub);

									const templateValues: Template = {
										...this.state.templateValues,
										htmlContent: this.editor.getContent(),
									};

									this.setState({ templateValues, isVariableDialogOpen: false });
								}}
							>
								{substitution}
							</MenuItem>
						);
					})}
				/>
			</Dialog>
		);
	};

	renderCancelDialog = (): JSX.Element => {
		return (
			<Dialog
				open={this.state.isCancelDialogOpen}
				onClose={this.closeDialog}
			>
				<DialogTitle children={STM.CancelNoSave} />
				<DialogActions>
					<Button
						color={Strings.Theming.Secondary}
						onClick={this.closeDialog}
						children={Strings.ButtonText.Stay}
					/>
					<Button
						variant="contained"
						color={Strings.Theming.Primary}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={() => this.props.navigateTo(
							navRoutes.templateManagement.path,
						)}
						children={Strings.ButtonText.Leave}
					/>
				</DialogActions>
			</Dialog >
		);
	};

	renderIsInUseDialog = (): JSX.Element => {
		return (
			<Dialog
				open={this.state.isInUseDialogOpen}
				disableEscapeKeyDown
				onClose={this.closeDialog}
			>
				<DialogTitle
					children={
						(this.props.template
							? this.props.template.title
							: Strings.TemplateManagement.ThisTemplate
						) + Strings.TemplateManagement.InUse
					}
				/>
				<DialogContent
					children={<DialogContentText children={STM.RemoveBeforeEdit} />}
				/>
				<DialogActions>
					<Button
						variant="contained"
						color={Strings.Theming.Primary}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.closeDialog}
						children={Strings.ButtonText.Ok}
					/>
				</DialogActions>
			</Dialog>
		);
	};

	renderDeleteDialog = (): JSX.Element => {
		return (
			<Dialog
				open={this.state.isDeleteDialogOpen}
				fullWidth
				onClose={this.closeDialog}
			>
				<DialogTitle
					children={deleteTemplateText(this.props.template.title || '')}
				/>
				<DialogActions>
					<Button
						color={Strings.Theming.Secondary}
						onClick={this.closeDialog}
						children={Strings.ButtonText.Cancel}
					/>
					<Button
						variant="contained"
						color={Strings.Theming.Primary}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.onDeleteConfirmation}
						children={Strings.ButtonText.Delete}
					/>
				</DialogActions>
			</Dialog >
		);
	};
}

function mapStateToProps(state): StateProps {

	return {
		linesOfBusiness: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
		languages: state.lookup.getLabels(Lookups.Language) || [Strings.Languages.English, Strings.Languages.Spanish],
		template: state.templateManagerState.editedTemplate
			|| state.templateManagerState.currentTemplate,
		isTemplateLoading: state.templateManagerState.areAgentTemplatesLoading
			|| state.templateManagerState.areCorporateTemplatesLoading,
		substitutions: state.lookup.getLabels(Lookups.EmailTemplateSubstitution),
		agentId: state.user.id,
		agentCode: state.user.agentID,
		events: state.emailAutomation.events,
		emailAutomationEventTypeCategories: state.lookup.getLabels(Lookups.EmailAutomationEventTypeCategory),
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		navigateBack: () =>
			dispatch(navigateBack()),

		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),

		getTemplate: (templateId: string) =>
			dispatch(GetTemplateById.started(templateId)),

		deleteTemplate: (templateId: string) =>
			dispatch(DeleteTemplate.started(templateId)),

		postTemplate: (template: Template) =>
			dispatch(PostTemplate.started(template)),

		getSnackbar: (props: SnackbarProps) =>
			dispatch(QueueSnackbar(props)),

		storeEditedTemplate: (template: Template) =>
			dispatch(StoreEditedTemplate(template)),

		getEmailAutomations: () => dispatch(getEmailAutomationsByAgentId()),
	};
}

export const TemplateContentContainer = connect(mapStateToProps, mapDispatchToProps, true)(TemplateContentPage);