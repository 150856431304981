import React, { useEffect } from 'react';
import { connect } from '@hmkts/rise';
import {
	Button,
} from '@material-ui/core';
import { Widget } from './base_widget';
import ChartCard from '../utility/chart_card';
import { AppState } from '../../reducers';
import {
	FinanceParams,
	GetFinanceCash,
} from '../../actions/finance_actions';
import { navRoutes } from '../nav/Routes';
import {
	getDefaultFinanceParams,
} from '../../reducers/finance_reducer';
import { ChartGroup } from '../../reducers/performance_reducer';
import FinanceSvg from '../svgs/icons/finance';
import { themePalette } from '../../utilities/branding';
import { nav } from '../..';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';

interface StateProps {
	cashCharts: ChartGroup;
}
interface DispatchProps {
	getCashCharts: (dates: FinanceParams) => any;
}
type Props = StateProps & DispatchProps;

const _FinanceWidget: React.FC<Props> = (props) => {	

	const { cashCharts, getCashCharts } = props;
	useEffect(() => {
		const dates: FinanceParams = getDefaultFinanceParams();
		getCashCharts(dates);
	}, []);

	const viewAll = () => nav.push(navRoutes.finance.path);

	const viewDetails = () => nav.push(navRoutes.financeCash.path);

	return (
		<Widget
			title={Strings.WidgetTitles.Finance}
			avatar={<FinanceSvg width={30} height={30} fill={themePalette.menuSvgFinance} />}
			onClick={viewAll}
			actionButtons={[
				<Button onClick={viewAll}>View All</Button>,
				<Button onClick={viewDetails}>View Details</Button>,
			]}
		>
			<ChartCard
				color="primary"
				titleLeft="Cash"
				charts={cashCharts.charts || []}
				titleRight={cashCharts.summaryValue}
				chartStyle={{ height: 200 }}
				compressed
				detailData={cashCharts.detail || []}
			/>
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	cashCharts: state.finance.cashCharts,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getCashCharts: (dates: FinanceParams) => dispatch(GetFinanceCash.started(dates)),
});

export const FinanceWidget = connect(mapStateToProps, mapDispatchToProps, true)(_FinanceWidget);
