import React from 'react';
import { connect } from '@hmkts/rise';
import { navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { Dispatch } from 'redux';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { WideButton } from '../../components/utility/wide_button';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { AppState } from '../../reducers';
import { useMakeCall, useNavigateTo, useOpenEmail } from '../../utilities/hooks';
import { themeMisc } from '../../utilities/branding/misc';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import { AttributionList } from '../../utilities/attribution_util';

interface StateProps {}
interface DispatchProps {}
type Props = StateProps & DispatchProps;

const HelpSupportPage: React.FC<Props> = (props) => {
	const callSupport = useMakeCall(themeMisc.helpAndSupportPhone);
	const emailSupport = useOpenEmail(themeMisc.helpAndSupportEmail);
	const navToTerms = useNavigateTo(navRoutes.termsAndConditions.path);
	const navToIntroVideo = useNavigateTo(navRoutes.introVideo.path);

	return (
		<BasePageContainer topComponent={<HeaderBarComponent title={'Help & Support'} />}>
			<PersistentNotifications />
			<DesktopPadding>
				<WideButton primary={'Terms and Conditions'} onClick={navToTerms} />

				{!isBrokerage && <WideButton primary={'Intro Video'} onClick={navToIntroVideo} />}

				<ToggleCard title={`${isBrokerage ? 'BC' : 'AC'} Support`} noPadding>
					<SimpleListItem
						title={normalizePhone(themeMisc.helpAndSupportPhone)}
						icon={<CallIcon />}
						onClick={callSupport}
						divider={false}
						hideArrow
					/>
					<SimpleListItem
						title={themeMisc.helpAndSupportEmail}
						icon={<EmailIcon />}
						onClick={emailSupport}
						divider={false}
						hideArrow
					/>
				</ToggleCard>

				<ToggleCard title={`Attribution`} noPadding>
					{AttributionList.map((attribution, index) => (
						<SimpleListItem
							key={`${attribution.title}-${index}`}
							title={attribution.title}
							icon={attribution.icon}
							divider={false}
							hideArrow
						/>
					))}
				</ToggleCard>
			</DesktopPadding>
		</BasePageContainer>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps & Partial<NavigationProps> => ({});

export const HelpSupportContainer = connect(mapStateToProps, mapDispatchToProps, true)(HelpSupportPage);
