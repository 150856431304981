import React, { useState } from 'react';
import { connect } from '@hmkts/rise';
import { Dispatch } from '@hmkts/rise';
import { ReactHeight } from 'react-height';
import { getNextDialog } from '../../selectors/dialog_selectors';
import { ClickToCall } from '../../containers/click_to_call';
import { PodcastPlayerComponent } from '../podcast/podcast_player';
import { Podcast } from '../../reducers/podcast_reducer';
import { AppState } from '../../reducers';
import { drawerWidth } from '../nav/auth_route';

interface ComponentProps {
	topComponent?: JSX.Element;
	middleComponent?: JSX.Element;
	bottomComponent?: JSX.Element;
	pageDialogs?: JSX.Element | JSX.Element[];

	middleRef?: (div: HTMLDivElement) => void;
	drawerComponent?: boolean;
	isAdvancedSearch?: boolean;

	topStyle?: React.CSSProperties;
	middleStyle?: React.CSSProperties;
	bottomStyle?: React.CSSProperties;

	blockDialogs?: boolean;

	className?: string;
}
interface StateProps {
	windowWidth?: number;
	windowHeight: number;
	dialog?: JSX.Element;
	clickToCallEnabled: boolean;
	podcast?: Podcast;
	podcastPlayer: boolean;
}
interface DispatchProps {}

interface Props extends ComponentProps, StateProps, DispatchProps {}


const BasePage: React.FC<Props> = (props) => {

	const { 
		children, topComponent, middleComponent, bottomComponent, pageDialogs, middleRef,
		drawerComponent, isAdvancedSearch, topStyle, middleStyle, bottomStyle,
		blockDialogs, className, //windowWidth, podcast,
		windowHeight, dialog, clickToCallEnabled, podcastPlayer
	} = props;

	const [topHeight, setTopHeight] = useState(0);
	// const [middleHeight, setMiddleHeight] = useState(0);
	const [bottomHeight, setBottomHeight] = useState(0);

	let marginTop = middleStyle && middleStyle.marginTop ? middleStyle.marginTop as number : 0;
	if (!marginTop) {
		marginTop = 0;
	}

	return (
		<div
			className={className}
			id="basePage"
			style={{
				...containerStyle,
				width: drawerComponent ? drawerWidth : '100%',
				height: windowHeight,
			}}
		>

			<ReactHeight
				key="top-component"
				onHeightReady={setTopHeight}
			>
				<div style={{ ...containerTopStyle, ...topStyle }}>
					{clickToCallEnabled && !drawerComponent && !isAdvancedSearch && <ClickToCall />}
					{topComponent}
				</div>
			</ReactHeight>

			{(middleComponent || children) &&
				<div
					id="middle"
					style={{
						...containerMiddleStyle,
						height: windowHeight - topHeight - bottomHeight - marginTop,
						...middleStyle,
					}}
					ref={middleRef}
				>
					{middleComponent ? middleComponent : null}
					{children ? children : null}
					{pageDialogs ? pageDialogs : null}
				</div>
			}
			
			<ReactHeight
				key="bottom-component"
				onHeightReady={setBottomHeight}
			>
				{(bottomComponent || podcastPlayer) &&
					<>
						<div style={{ ...containerBottomStyle, ...bottomStyle }}>
							{bottomComponent}
						</div>
						{
							!drawerComponent && (
								podcastPlayer && !Boolean(isAdvancedSearch) ? <PodcastPlayerComponent /> : undefined
							)
						}
					</>
				}
			</ReactHeight>

			{!Boolean(drawerComponent) && !Boolean(blockDialogs) && Boolean(dialog) && dialog}
		</div>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	windowWidth: state.layout.width,
	windowHeight: state.layout.height,
	dialog: getNextDialog(state),
	clickToCallEnabled: state.clickToCall.enabled || (state.clickToCall.session != undefined),
	podcast: state.podcast.currentPodcast,
	podcastPlayer: state.podcast.showPlayer,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});

export const BasePageContainer = connect<StateProps, DispatchProps, ComponentProps>(mapStateToProps, mapDispatchToProps, true)(
	BasePage
);

const containerStyle: React.CSSProperties = {
	overflow: 'hidden',
	top: 0,
	left: 0,
	padding: 0,
	margin: 0,
    // paddingBottom: 'env(safe-area-inset-bottom)',
};

const containerTopStyle: React.CSSProperties = {
	// overflow: 'hidden',
	// width: '100%',
};

const containerMiddleStyle: React.CSSProperties = {
	overflowX: 'hidden',
	overflowY: 'auto',
	WebkitOverflowScrolling: 'touch',
	width: '100%',
	WebkitTransform: 'translate3d(0, 0, 0)',
};

const containerBottomStyle: React.CSSProperties = {
	overflow: 'hidden',
	width: '100%',
	// paddingBottom: 'env(safe-area-inset-bottom)',
};