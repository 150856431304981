import React from 'react';
import { connect } from '@hmkts/rise';
import { NavigationProps, navRoutes } from '../nav/Routes';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import {
	Dialog,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Radio,
	Paper,
	TextField,
	Slide,
	SlideProps,
} from '@material-ui/core';
import { Activity, FollowupFields } from '../../reducers/activity_reducer';
import { Lead } from '../../reducers/LeadReducer';
import { UpdateLead, StoreInactiveLeadId } from '../../actions/lead_actions';
import { isLookupSequenceValid } from '../../utilities/lead_status_validation';

import {
	GetActivity,
	EditActivity,
	CreateActivity,
	StoreFollowupDescription,
} from '../../actions/activity_actions';
import moment from 'moment';
import { Contact } from '../../reducers/ContactReducer';
import uuid from 'uuid';
import { createNote, CreateNoteParams } from '../../actions/note_actions';
import { Lookup, Lookups, LookupDictionary } from '../../utilities/lookup';
import { GetHousehold } from '../../actions/household_actions';
import { GetEmployer } from '../../actions/employer_actions';
import { EMPTY_LEAD, EMPTY_CONTACT } from '../../utilities/empty_entities';
import { FollowUpActivityType } from '../../containers/activity/upsert_activity';
import { minWidthMatch } from '../../utilities/is_mobile';
import { cloneDeep } from '../../utilities/object_util';
import { getCurrentAgentDisplayName } from '../../selectors/agent_selectors';
import { fullName } from '../../assets/common/string_builders';
import { makeGetContactFromFirstActivity } from '../../selectors/contact_selectors';
import { makeGetNewestMatchingLeadForDispotion } from '../../selectors/lead_selectors';
import { Strings } from '../../assets/common/strings';
import { ToggleLeadInfoDialog } from '../../actions/dialogs_actions';
import { AppState } from '../../reducers';
import { push } from 'react-router-redux';

export interface OwnProps  {
	open: boolean;
	isAppointment: boolean;
	activities: Activity[];
	closeDispositionDialog: () => void;
	navigateTo: (route: string) => void;
	isContactDetailPage?: boolean;
	openLead?: Lead;
	contactId?: string;
}

interface StateProps {
	currentLead?: Lead;
	userId: string;
	contact: Contact;
	contactFullName: string;
	currentAgentName: string;
	locationHistory: string[];
	lookups: LookupDictionary;
	impersonatingId: string;
	clickToCallEnabled: boolean;
	clickToCallSid: string;
	clickToCallSessionId?: string;
}

interface DispatchProps {
	editActivity: (activityToEdit: Activity) => void;
	updateLead: (lead: Lead) => void;
	getActivity: (activityId: string) => void;
	createActivity: (activityToCreate: Activity) => void;
	createNote: (noteContent: CreateNoteParams) => void;
	storeInactiveLeadId: (leadId: string) => void;
	storeFollowupDescription: (params: FollowupFields) => void;
	getHousehold: (id: string) => void;
	getEmployer: (id: string) => void;
	closeLeadModal: () => void;
}

interface State {
	disposition: Lookup;
	noSaleSelected: boolean;
	noteContent: string;
	saveText: string;
	optionalButtonText: string;
	disableFollowUp: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

class DispositionDialog extends React.Component<Props, State> {
	leadId: string;
	constructor(props: Props) {
		super(props);

		this.state = {
			disposition: Lookups.DispositionReason,
			noSaleSelected: false,
			noteContent: '',
			saveText: 'Save',
			optionalButtonText: 'Follow-up',
			disableFollowUp: true,
		};

		this.leadId = '';
	}

	componentWillMount() {
		let requestedIds: string[] = [];
		// Load leads into Redux to try and find an open lead to update
		if (this.props.activities.length > 0) {
			this.props.activities.forEach((activity, index) => {
				if (
					activity &&
					activity.contact &&
					activity.contact.employerId &&
					requestedIds.every(id => id != activity.contact.employerId)
				) {
					requestedIds.push(activity.contact.employerId);
					this.props.getEmployer(activity.contact.employerId);
				}
				if (
					activity &&
					activity.contact &&
					activity.contact.householdId &&
					requestedIds.every(id => id != activity.contact.householdId)
				) {
					requestedIds.push(activity.contact.householdId);
					this.props.getEmployer(activity.contact.householdId);
				}
			});
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		//Support for activity list
		let requestedIds: string[] = [];
		// Load leads into Redux to try and find an open lead to update
		if (
			this.props.activities != nextProps.activities &&
			nextProps.activities.length > 0
		) {
			nextProps.activities.forEach((nextActivity, index) => {
				if (
					nextActivity &&
					nextActivity.contact &&
					nextActivity.contact.employerId &&
					this.props.activities.every(
						currentActivity =>
							currentActivity &&
							currentActivity.contact &&
							currentActivity.contact.employerId != nextActivity.contact.employerId
					)
				) {
					requestedIds.push(nextActivity.contact.employerId);
					this.props.getEmployer(nextActivity.contact.employerId);
				}
				if (
					nextActivity &&
					nextActivity.contact &&
					nextActivity.contact.householdId &&
					this.props.activities.every(
						currentActivity =>
							currentActivity &&
							currentActivity.contact &&
							currentActivity.contact.householdId != nextActivity.contact.householdId
					)
				) {
					requestedIds.push(nextActivity.contact.householdId);
					this.props.getHousehold(nextActivity.contact.householdId);
				}
			});
		}
	}

	getCurrentLead = () => this.props.openLead || this.props.currentLead || EMPTY_LEAD;

	disableSave(): boolean {
		return (
			(this.state.noSaleSelected &&
				this.state.disposition.isNotChildOf(Lookups.NoSale)) ||
			this.state.disposition.isNotChildOf(Lookups.DispositionReason) ||
			(this.state.disposition.isChildOf(Lookups.AlwaysCreateNewPhoneCall) ||
				this.state.disposition.isChildOf(Lookups.CreateNewAppointment)));
	}

	selectDisposition = (event) => {
		const disposition = this.props.lookups.findOrCreate(event.target.value);
		const disableFollowUpForLookups = [
			Lookups.NoSale,
			Lookups.DisconnectedPhone,
			Lookups.LanguageBarrier,
			Lookups.NeverInquired,
			Lookups.WrongNumber
		];

		let disableFollowUp = false;
		disableFollowUpForLookups.map((lookup) => {
			if (lookup.value === event.target.value) {
				disableFollowUp = true;
			}
		});

		this.setState({
			disposition,
			disableFollowUp: disableFollowUp
		});
	};

	completeActivity = () => {
		let activityId = '';
		this.props.activities.forEach(loadedActivity => {
			const activity = loadedActivity;
			const activityType = this.props.lookups.findOrCreate(activity.type);
			const activityToUpdate = cloneDeep(activity);

			const lookup: Lookup = this.state.disposition.isChildOf(Lookups.NoSale)
				? Lookups.NoSale : this.state.disposition;

			activityToUpdate.disposition = lookup.getFirstParentOrSelfOfType(
				[Lookups.DispositionLabel, activityType]
			).label;

			const currentTime = moment.utc().toDate();
			activityToUpdate.completedBy = this.props.userId;
			activityToUpdate.completedByName = this.props.currentAgentName;
			activityToUpdate.completedOn = currentTime;
			activityToUpdate.status = Strings.ActivityStatus.Completed;
			activityToUpdate.updatedBy = this.props.userId;
			activityToUpdate.modifiedByName = this.props.currentAgentName;
			activityToUpdate.updatedOn = currentTime;
			activityToUpdate.twilioSid = this.props.clickToCallSid;
			activityToUpdate.clickToCallDisposition = this.state.disposition == Lookups.DispositionReason ? '' : this.getLeadStatusValue(this.state.disposition);
			activityToUpdate.clickToCallSessionId = this.props.clickToCallSessionId;
			this.props.editActivity(activityToUpdate);
			activityId = activityToUpdate.id;
		});
		return activityId;
	};

	dispositionSale = (activityId: string, followUp: boolean) => {
		if (followUp) {
			this.dispositionToCreateFollowUp(activityId, FollowUpActivityType.PHONE_CALL);
		}
		else {
			const householdId =
				this.props.contact.householdId ||
				(this.props.activities.length > 0
					? this.props.activities[0].contact.householdId
					: '');

			this.props.navigateTo(
				navRoutes.household.path.replace(Strings.Navigation.HouseholdId, householdId)
			);
		}
	};


	dispositionNoSale = (activityId: string) => {
		if (this.state.disposition.isChildOf(Lookups.SkipNoSaleFollowUp)) {
			this.cancelDisposition();
		} else {

			this.props.storeInactiveLeadId(this.getCurrentLead().id);
			this.props.storeFollowupDescription({
				description: Lookups.NoSale.label + ' - ' + this.state.disposition.label,
				time: moment(),
			});
			this.props.navigateTo(
				navRoutes.phoneEdit.path
					.replace(Strings.Navigation.ActivityId, activityId)
					.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL_3_MONTHS)
			);
		}
	};

	//reschedule 1: appointment
	//reschedule 2: phonecall 3 months from now (no sale follow up)
	//reschedule 3: phonecall
	dispositionToCreateFollowUp = (activityId: string, dispositionType: FollowUpActivityType) => {
		this.props.storeFollowupDescription({
			description: this.state.disposition.label,
			time: moment(),
		});
		//disposition appointment
		if (dispositionType == FollowUpActivityType.APPOINTMENT) {
			this.props.navigateTo(
				navRoutes.appointmentEdit.path
					.replace(Strings.Navigation.ActivityId, activityId)
					.replace(Strings.Navigation.Reschedule, dispositionType)
					.replace(Strings.Navigation.HouseholdId, this.props.contact.householdId)
			);
		}
		//disposition phone call
		else {
			this.props.navigateTo(
				navRoutes.phoneEdit.path
					.replace(Strings.Navigation.ActivityId, activityId)
					.replace(Strings.Navigation.Reschedule, dispositionType)
					.replace(Strings.Navigation.HouseholdId, this.props.contact.householdId)
			);
		}

	};

	updateLeadStatus() {
		const lead = this.getCurrentLead();

		if (!this.props.contact.id || !lead.id) return;

		const newLeadStatus = this.state.disposition.getFirstParentOrSelfOfType(
			Lookups.LeadStatus
		);
		const newLeadStatusCode = newLeadStatus.getFirstParentOrSelfOfType(
			Lookups.LeadStatusCode
		);

		const currentLeadStatus = this.props.lookups.findOrCreate(
			lead.status
		);
		// prevents leads from being automatically updated to previous states
		if (
			isLookupSequenceValid(
				Lookups.DispositionStage,
				currentLeadStatus,
				newLeadStatus
			)
		) {
			const leadToUpdate = cloneDeep(lead);
			leadToUpdate.status = this.getLeadStatusValue(newLeadStatus)
			leadToUpdate.statusCode = newLeadStatusCode.value;
			this.props.updateLead(leadToUpdate);
		}
	}

	getLeadStatusValue = (status: Lookup) => {
		return status.isLookup(Lookups.Sale)
			? status.label
			: status.value
	}

	createNote() {
		if (!this.props.contact.id || !this.state.noteContent) return;

		this.props.createNote({
			content: this.state.noteContent,
			employerId:
				this.props.contact.employerPrimaryContact ||
					(this.props.isContactDetailPage && this.props.contact.employerId)
					? this.props.contact.employerId
					: '',
			householdId: this.props.contact.householdId,
		});
	}

	onClose = () => {
		this.props.closeLeadModal();
		this.props.closeDispositionDialog();
	}

	cancelDisposition = () => {
		const { clickToCallEnabled } = this.props;

		if (clickToCallEnabled) {
			this.createPhoneCall();
		}
		this.resetDisposition();
		this.onClose();
	};

	onSave = () => {
		this.saveDisposition(false);
	}

	onFollowUp = () => {
		this.saveDisposition(true);
	}

	saveDisposition = (isFollowUp: boolean) => {
		if (
			this.state.disposition.isChildOf(Lookups.TriggerNoSaleDisposition) &&
			!this.state.noSaleSelected
		) {
			this.setState({ noSaleSelected: true });
		} else {
			this.createNote();
			this.updateLeadStatus();

			const activityId =
				this.props.activities.length > 0
					? this.completeActivity()
					: this.createPhoneCall();

			if (this.state.disposition.matches(Lookups.Sale) && isFollowUp) {
				this.dispositionSale(activityId, isFollowUp);
			} else if (this.state.disposition.isChildOf(Lookups.NoSale) && isFollowUp) {
				this.dispositionNoSale(activityId);
			} else if (
				this.state.disposition.isChildOf(Lookups.CreateNewPhoneCall) && isFollowUp
				|| (this.state.disposition.isChildOf(Lookups.AlwaysCreateNewPhoneCall))) {
				this.dispositionToCreateFollowUp(activityId, FollowUpActivityType.PHONE_CALL);
			} else if (this.state.disposition.isChildOf(Lookups.CreateNewAppointment)) {
				this.dispositionToCreateFollowUp(activityId, FollowUpActivityType.APPOINTMENT);
			}

			this.resetDisposition();
			this.onClose();
		}
	};

	resetDisposition = () =>
		this.setState({
			disposition: Lookups.DispositionReason,
			noSaleSelected: false,
			noteContent: '',
			saveText: 'Save',
			optionalButtonText: 'Follow-up',
			disableFollowUp: true,
		});

	createPhoneCall = () => {
		const nowUTC = moment()
			.utc()
			.toDate();
		let activityToCreate: Activity = {
			id: uuid.v4(),
			title:
				'Call with ' +
				this.props.contact.firstName +
				' ' +
				this.props.contact.lastName,
			description: '',
			time: nowUTC,
			duration: undefined,
			isHighPriority: false,
			createdOn: nowUTC,
			createdBy: this.props.userId,
			createdByName: this.props.currentAgentName,
			location: '',
			alternatePhoneNumber: '',
			userId:
				this.props.userId != this.props.impersonatingId &&
					this.props.impersonatingId
					? this.props.impersonatingId
					: this.props.userId,
			completedByName: this.props.currentAgentName,
			completedBy: this.props.userId,
			completedOn: nowUTC,
			lead: this.getCurrentLead().id,
			status: Strings.ActivityStatus.Completed,
			type: Strings.Activity.PhoneCall,
			isDeleted: false,
			householdId: this.props.contact.householdId,
			contact: this.props.contact,
			disposition: this.state.disposition.isChildOf(Lookups.NoSale) ?
				Lookups.NoSale.getFirstParentOrSelfOfType(Lookups.DispositionLabel).label :
				this.state.disposition.getFirstParentOrSelfOfType(Lookups.DispositionLabel).label,
			googleEventId: '',
			sender: '',
			toRecipients: '',
			appointmentType: '',
			updatedOn: nowUTC,
			updatedBy: this.props.userId,
			modifiedOn: nowUTC,
			modifiedBy: this.props.userId,
			twilioSid: this.props.clickToCallSid,
			clickToCallDisposition: this.state.disposition == Lookups.DispositionReason ? '' : this.getLeadStatusValue(this.state.disposition),
			clickToCallSessionId: this.props.clickToCallSessionId
		};
		this.props.createActivity(activityToCreate);
		return activityToCreate.id;
	};

	//RENDER SECTIONS
	renderNoteSection() {
		const isEmployer = this.props.contact.employerPrimaryContact;
		return (
			<div style={{ padding: '25px' }}>
				<TextField
					placeholder={
						isEmployer
							? 'Write a note for this employer...'
							: 'Write a note for this household...'
					}
					multiline
					rowsMax={15}
					onChange={event => {
						if (event.target.value || event.target.value == '') {
							this.setState({
								noteContent: event.target.value,
							});
						}
					}}
					value={this.state.noteContent}
					style={{ width: '100%' }}
				/>
			</div>
		);
	}

	renderRadioLabel = (lookup: Lookup) => (
		<FormControlLabel
			value={lookup.value}
			key={'selection-' + lookup.value}
			control={<Radio />}
			label={lookup.label}
			color="secondary"
		/>
	);

	transition(props: SlideProps) {
		return <Slide direction="up" {...props} />;
	}

	render() {
		const activityType = this.props.isAppointment
			? Lookups.Appointment
			: Lookups.PhoneCall;
		const dispositionOptions = this.state.noSaleSelected
			? this.props.lookups.getChildren([
				Lookups.DispositionReason,
				Lookups.NoSale,
			])
			: this.props.lookups.getChildren([
				Lookups.DispositionReason,
				activityType,
			]);
		return (
			<Dialog
				open={this.props.open}
				fullScreen
				TransitionComponent={this.transition}
				onBackdropClick={this.cancelDisposition}
				onEscapeKeyDown={this.cancelDisposition}
			>
				<SaveCancelHeaderBarComponent
					title={
						minWidthMatch(450)
							? this.state.noSaleSelected
								? 'No Sale Reason'
								: 'Disposition'
							: ""
					}
					subtitle={
						minWidthMatch(450)
							? 'What happened?'
							: ""
					}
					onSave={this.onFollowUp}
					isSaveDisabled={this.state.disableFollowUp}
					isSecondaryButtonDisabled={this.disableSave()}
					onSecondaryButtonClick={this.onSave}
					secondaryButtonText={
						!this.state.disposition.isChildOf(Lookups.TriggerNoSaleDisposition)
							? 'Done'
							: 'Next'
					}
					onCancel={this.cancelDisposition}
					saveText={
						'FOLLOW-UP'
					}
					trueCancelAndClose={true}
				/>
				{this.renderNoteSection()}
				<Paper
					elevation={0}
					style={{
						padding: '16px 25px 0',
					}}
				>
					<FormControl component="fieldset">
						<RadioGroup
							name="reason"
							onChange={this.selectDisposition}
							value={this.state.disposition.value}
						>
							{dispositionOptions.map(this.renderRadioLabel)}
						</RadioGroup>
					</FormControl>
				</Paper>
			</Dialog>
		);
	}
}
const getContact = makeGetContactFromFirstActivity();
const getMatchingLead = makeGetNewestMatchingLeadForDispotion();

function makeMapStateToProps(state, ownProps: Props): StateProps {

	const loadedContact = getContact(state, ownProps);
	const contact =
		loadedContact
			? loadedContact.data
			: EMPTY_CONTACT;
	const contactFullName =
		loadedContact
			? fullName(contact)
			: '';

	const currentLead = getMatchingLead(state, ownProps);

	return {
		currentAgentName: getCurrentAgentDisplayName(state),
		currentLead,
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		contact,
		contactFullName,
		locationHistory: state.navigation.locationHistory,
		lookups: state.lookup,
		clickToCallEnabled: state.clickToCall.enabled,
		clickToCallSid: state.clickToCall.metadata.callSid,
		clickToCallSessionId: state.clickToCall.session ? state.clickToCall.session.id : undefined
	};
}

function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
	return {
		editActivity: (activityToEdit: Activity) =>
			dispatch(EditActivity.started(activityToEdit)),
		updateLead: (lead: Lead) => dispatch(UpdateLead.started(lead)),
		getActivity: (activityId: string) =>
			dispatch(GetActivity.started(activityId)),
		createActivity: (activityToCreate: Activity) =>
			dispatch(CreateActivity.started(activityToCreate)),
		createNote: (newNoteParams: CreateNoteParams) => {
			dispatch(createNote(newNoteParams));
		},
		storeInactiveLeadId: (leadId: string) => {
			dispatch(
				StoreInactiveLeadId(leadId)
			);
		},
		storeFollowupDescription: (params: FollowupFields) => {
			dispatch(StoreFollowupDescription(params));
		},
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		closeLeadModal: () => dispatch(ToggleLeadInfoDialog(false)),
	};
}

export const ActivityDispositionDialog = connect<StateProps, DispatchProps, OwnProps>(
	makeMapStateToProps,
	mapDispatchToProps,
	true
)(DispositionDialog);
