import React from 'react';
import { connect } from '@hmkts/rise';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { navRoutes } from '../../components/nav/Routes';
import { AppState } from '../../reducers/index';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SearchAgentsByTerm, UpdateAgentSearchFilter } from '../../actions/agent_search_actions';
import { themeLinks } from '../../utilities/branding';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { useNavigationProps } from '../../utilities/hooks';
import { TextField, Paper } from '@material-ui/core';
import { GAService } from '../../utilities/services/google_analytics';
import { SearchAgent } from '../../reducers/agent_search_reducer';
import CustomList from '../../components/utility/custom_list';
import { AgentSearchResultItem } from '../../components/agent_search/agent_search_result_item';

interface StateProps {
	filter: string;
	agents: SearchAgent[];
	isLoading: boolean;
}
interface DispatchProps {
	updateFilter: (filter: string) => void;
	searchAgents: (term: string) => void;
}
type Props = StateProps & DispatchProps;

const AgentSearchPage: React.FC<Props> = (props) => {

	const { filter, agents, isLoading, updateFilter, searchAgents } = props;
	const { navigateTo } = useNavigationProps();

	const navigateToAgent = (agentCode: string) => {
		navigateTo(navRoutes.agentProfile.path.replace(Strings.Navigation.AgentCode, agentCode));
	};

	const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const term = event.target.value;
		updateFilter(term);
        if (term.length >= 3) {
			GAService.sendEvent('Agent_Search', 'Search', term);
			searchAgents(term);
		}
	};

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Agent Search"
					rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkHome)]}/>}
				/>
			}
		>
			<PersistentNotifications />
			<DesktopPadding>
				<Paper elevation={0} square style={{ padding: 20 }}>
					<TextField
						label={Strings.AboutMeLabels.AgentSearchPrompt}
						onChange={handleSearch}
						value={filter}
						fullWidth
						size="small"
					/>
					{agents.length > 0 && (
						<div
							style={{
								fontSize: '12px',
								textAlign: 'center',
								paddingTop: 10,
							}}
						>
							Showing {agents.length} results
						</div>
					)}
				</Paper>
				<CustomList loading={isLoading}>
					{agents.map((agent) => (
						<AgentSearchResultItem
							key={agent.id}
							agent={agent}
							term={filter}
							onClick={navigateToAgent}
						/>
					))}
				</CustomList>
			</DesktopPadding>
		</BasePageContainer>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	filter: state.agentSearch.filter,
	agents: state.agentSearch.agents,
	isLoading: state.agentSearch.isLoading,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	updateFilter: (filter: string) => dispatch(UpdateAgentSearchFilter({ filter })),
	searchAgents: (term: string) => dispatch(SearchAgentsByTerm.started({ term, searchTerminated: false }))
});
export const AgentSearchPageContainer =
	connect(mapStateToProps, mapDispatchToProps, true)(AgentSearchPage);
