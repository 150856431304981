import H from 'history';
import {actionCreatorFactory} from 'typescript-fsa';
import { push, replace, goBack } from '@hmkts/rise';

export interface NavigatePayload { 
	history: H.History; 
	route: string 
}

const actionCreator = actionCreatorFactory('Navigation');

export const updateReactRouterHistory = actionCreator<{ history: H.History }>(
	'UPDATE_REACT_ROUTER_HISTORY'
);

export const UpdateDispositionReturnPath = actionCreator<{
	returnPath: string
}>('UPDATE_DISPOSITION_RETURN_PATH');
export function updateDispositionReturnPath(returnPath: string) {
	return UpdateDispositionReturnPath({ returnPath: returnPath });
}

export const NavigateBack = actionCreator<{ history: any }>('NAVIGATE_BACK');
export function navigateBack() {
	return goBack();
}

export const ClearInfiniteScrollLocation = actionCreator(
	'CLEAR_INFINITE_SCROLL_LOCATION'
);

export const NavigateTo = actionCreator<NavigatePayload>(
	'NAVIGATE_TO'
);

export function navigateTo(route: string) {
	return push(route);
}

export const NavigateToWithoutAddingToHistory = actionCreator<NavigatePayload>('NAVIGATE_TO_WITH_REPLACE');

export function navigateToWithoutAddingToHistory(
	route: string
) {
	return replace(route);
}

export const StoreScrollLocation = actionCreator<string>(
	'STORE_SCROLL_LOCATION'
);
export const ClearScrollLocation = actionCreator('CLEAR_SCROLL_LOCATION');


