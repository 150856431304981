import {
	deleteSavedAdvancedSearchFilters,
	selectSavedSearchName,
	upsertAdvancedSearchFilters,
	ExpandFilterFields,
} from '../../../actions/advanced_search_actions';
import uuid from 'uuid';
import jwt_auth from '../../../utilities/auth/jwt_auth';
import React from 'react';
import { Field } from 'redux-form';
import { FormSelect, FormTextField, FormRadioGroup } from '../../../utilities/forms';
import {
	Typography,
	Icon,
	Collapse,
	CardActions,
	IconButton,
	FormControl,
	TextField,
	InputLabel,
	MenuItem,
	FormControlLabel,
	Radio,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	InputAdornment,
} from '@material-ui/core';
import { connect } from '@hmkts/rise';
import { Select as MUISelect } from '@material-ui/core';
import { change, getFormValues } from 'redux-form';
import { SelectListOptions } from '../../../components/utility/select_list_options';
import {
	AdvSearchFilterPresetDto,
	DEFAULT_UNSELECTED,
	SearchFilterFormValues,
} from '../../../reducers/advanced_search_reducer';
import _ from 'lodash';
import moment from 'moment';
import { themePalette } from '../../../utilities/branding';
import { P } from '../../../utilities/auth/permissions';
import { Strings } from '../../../assets/common/strings';
import { createMenuAction, MoreMenu } from '../../../components/nav/more_menu';

interface Props {
	formName: string;
	consumerType: string;
	onReset: () => void;
}

interface StateProps {
	formValues: SearchFilterFormValues;
	userId: string;
	impersonatingId: string;
	consumerTypes: string[];
	selectedSavedSearchName: string;
	corporateFilters: AdvSearchFilterPresetDto[];
	userSavedFilters: AdvSearchFilterPresetDto[];
	savedSearchesExpanded: boolean;
	predefinedSearchesExpanded: boolean;
}

interface DispatchProps {
	changeFieldValue: (field: string, value: any) => void;
	selectSavedSearchName: (savedSearchName: string) => void;
	upsertSavedAdvancedSearchFilters: (
		filtersToSave: AdvSearchFilterPresetDto
	) => void;
	deleteSavedAdvancedSearchFilters: (id: string) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
}

interface State {
	nameToSaveSearchAs: string;
	isSaveDialogVisible: boolean;
	isDeleteDialogVisible: boolean;
}

class _SearchConfigurationFields extends React.Component<
	Props & StateProps & DispatchProps,
	State
	> {
	constructor(props: any) {
		super(props);
		this.state = {
			nameToSaveSearchAs: '',
			isSaveDialogVisible: false,
			isDeleteDialogVisible: false,
		};
	}

	sortOptions = [
		'Name Ascending',
		'Name Descending',
		'Modified Date Ascending',
		'Modified Date Descending',
		'Next Scheduled Activity Ascending',
		'Next Scheduled Activity Descending',
	];

	handleSavedSearchesExpandClick = () => {
		this.props.expandFilterFields('savedSearchesExpanded');
	};

	handleExpandClick = () => {
		this.props.expandFilterFields('predefinedSearchesExpanded');
	};

	onUpdateSavedSearchValue = (value: string) => {
		this.props.onReset();
		this.props.selectSavedSearchName(value);
		if (value === DEFAULT_UNSELECTED) {
			this.setState({ nameToSaveSearchAs: '' });
		} else {
			this.setState({ nameToSaveSearchAs: value });
		}
	};

	generateSavedSearchList = () => {
		let menuItemList: JSX.Element[] = [];
		menuItemList.push(
			<MenuItem value={DEFAULT_UNSELECTED} key={'unselected'}>
				{DEFAULT_UNSELECTED}
			</MenuItem>
		);
		menuItemList.push(
			<MenuItem
				value={'Corporate Searches'}
				key={'corporateSearches'}
				disabled={true}
			>
				{'Corporate Searches'}
			</MenuItem>
		);

		const sortedCorporateFilters: AdvSearchFilterPresetDto[] = _.sortBy(
			this.props.corporateFilters,
			['name']
		);

		sortedCorporateFilters.forEach(
			(corporateSearch: AdvSearchFilterPresetDto) => {
				if (corporateSearch.name == Strings.CorporateSearchFilers.ClientTermLifeYear) {
					corporateSearch.filters.effectiveDateTo = moment().subtract(1, 'years').endOf('month').toDate();
					corporateSearch.filters.effectiveDateFrom = moment().subtract(1, 'years').startOf('month').toDate();
				}
				if (corporateSearch.name == Strings.CorporateSearchFilers.ClientTermShortLapse) {
					corporateSearch.filters.policyEndDateTo = moment().add(1, 'months').endOf('month').toDate();
					corporateSearch.filters.policyEndDateFrom = moment().add(1, 'months').startOf('month').toDate();
				}
				menuItemList.push(
					<MenuItem value={corporateSearch.name} key={corporateSearch.id} style={styles.menuItemWrap}>
						<Grid container>
							<Grid item xs={1} />
							<Grid item xs={11}>
								{corporateSearch.name}
							</Grid>
						</Grid>
					</MenuItem>
				);
			}
		);

		menuItemList.push(
			<MenuItem value={'Agent Searches'} key={'agentSearches'} disabled={true}>
				{'Agent Searches'}
			</MenuItem>
		);

		this.props.userSavedFilters.forEach(
			(userSearch: AdvSearchFilterPresetDto) => {
				menuItemList.push(
					<MenuItem value={userSearch.name} key={userSearch.id} style={styles.menuItemWrap}>
						<Grid container>
							<Grid item xs={1} />
							<Grid item xs={11}>
								{userSearch.name}
							</Grid>
						</Grid>
					</MenuItem>
				);
			}
		);

		return menuItemList;
	};

	handleSaveMenuItemClick = () => {
		let matchingUserSavedIndex: number = this.props.userSavedFilters.findIndex(
			(filterPreset: AdvSearchFilterPresetDto) => {
				return filterPreset.name === this.props.selectedSavedSearchName;
			}
		);
		if (matchingUserSavedIndex !== -1) {
			const filtersForAlreadySavedQuery: AdvSearchFilterPresetDto = this.props
				.userSavedFilters[matchingUserSavedIndex];
			filtersForAlreadySavedQuery.filters = this.props.formValues;
			this.props.upsertSavedAdvancedSearchFilters(filtersForAlreadySavedQuery);
		} else {
			this.setState({ isSaveDialogVisible: true });
		}
	};

	handleSaveInDialogClick = () => {
		this.setState({ isSaveDialogVisible: false });

		let filtersToSave: AdvSearchFilterPresetDto = {
			id: '',
			userId:
				this.props.userId != this.props.impersonatingId &&
					this.props.impersonatingId
					? this.props.impersonatingId
					: this.props.userId,
			createdOn: moment
				.utc()
				.local()
				.toDate(),
			createdBy: this.props.userId,
			updatedOn: moment
				.utc()
				.local()
				.toDate(),
			updatedBy: this.props.userId,
			isDeleted: false,
			name: this.state.nameToSaveSearchAs,
			filters: this.props.formValues,
		};
		let matchingIndex: number = this.props.userSavedFilters.findIndex(
			(filterPreset: AdvSearchFilterPresetDto) => {
				return filterPreset.name === this.state.nameToSaveSearchAs;
			}
		);
		if (matchingIndex !== -1) {
			filtersToSave.id = this.props.userSavedFilters[matchingIndex].id;
		} else {
			filtersToSave.id = uuid.v4();
		}
		this.props.upsertSavedAdvancedSearchFilters(filtersToSave);
		this.props.selectSavedSearchName(this.state.nameToSaveSearchAs);
	};

	handleDeleteClick = () => {
		this.setState({ isDeleteDialogVisible: false });
		const matchingIndex: number = this.props.userSavedFilters.findIndex(
			(savedFilters: AdvSearchFilterPresetDto) => {
				return savedFilters.name === this.props.selectedSavedSearchName;
			}
		);
		if (matchingIndex !== -1) {
			this.props.deleteSavedAdvancedSearchFilters(
				this.props.userSavedFilters[matchingIndex].id
			);
		}
	};

	onUpdateConsumerType = (value: string) => {
		if (value == 'Active Leads') {
			this.props.onReset();
			this.props.changeFieldValue('leadStatusCode', ['Open']);
		} else if (value == 'Completed Leads') {
			this.props.onReset();
			this.props.changeFieldValue('leadStatusCode', ['Sale', 'No Sale']);
		} else if (value == 'Current Customers') {
			this.props.onReset();
			this.props.changeFieldValue('appStatus', ['Pending', 'Active']);
		} else if (value == 'Former Customers') {
			this.props.onReset();
			this.props.changeFieldValue('appStatus', ['Terminated']);
		} else if (value.toLowerCase() == Strings.ASearch.BLeads) {
			this.props.onReset();
		}
		this.props.changeFieldValue('consumerType', value);
	};

	getCurrentSavedSearchNames = () => {
		let currentSavedSearchNames: string[] = [];
		this.props.userSavedFilters.forEach(
			(userSavedSearch: AdvSearchFilterPresetDto) => {
				if (userSavedSearch.name) {
					currentSavedSearchNames.push(userSavedSearch.name);
				}
			}
		);
		currentSavedSearchNames = currentSavedSearchNames.concat(
			this.getCorporateFilterNames()
		);
		return currentSavedSearchNames;
	};

	getCorporateFilterNames = () => {
		const corporateSearchNames: string[] = [];
		this.props.corporateFilters.forEach(
			(corporateFilter: AdvSearchFilterPresetDto) => {
				if (corporateFilter.name) {
					corporateSearchNames.push(corporateFilter.name);
				}
			}
		);
		return corporateSearchNames;
	};

	isSavedSearchNameValid = (nameToSave: string): boolean => {
		const currentListOfSearchNames: string[] = this.getCurrentSavedSearchNames();
		if (_.includes(currentListOfSearchNames, nameToSave) || !nameToSave) {
			return false;
		} else {
			return true;
		}
	};

	isDeleteDisabled = () => {
		if (
			_.includes(
				this.getCorporateFilterNames(),
				this.props.selectedSavedSearchName
			) ||
			this.props.selectedSavedSearchName === DEFAULT_UNSELECTED
		) {
			return true;
		} else {
			return false;
		}
	};

	renderSaveSearchDialog = () => {
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth
				maxWidth="md"
				open={this.state.isSaveDialogVisible}
				onClose={() => this.setState({ isSaveDialogVisible: false })}
			>
				<DialogTitle>Please enter a title for this search</DialogTitle>
				<DialogContent>
					<TextField
						name="nameToSaveAs"
						error={!this.isSavedSearchNameValid(this.state.nameToSaveSearchAs)}
						helperText={
							!this.state.nameToSaveSearchAs
								? 'No name specified'
								: !this.isSavedSearchNameValid(this.state.nameToSaveSearchAs)
									? 'This name already exists'
									: null
						}
						value={this.state.nameToSaveSearchAs}
						style={{ width: 550 }}
						onChange={(event: any) =>
							this.setState({ nameToSaveSearchAs: event.target.value })
						}
						inputProps={{ maxLength: 60 }}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ isSaveDialogVisible: false })}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.accept_button }}
						disabled={
							!this.isSavedSearchNameValid(this.state.nameToSaveSearchAs)
						}
						onClick={this.handleSaveInDialogClick}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	renderDeleteSearchDialog = () => {
		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				fullWidth
				maxWidth="md"
				open={this.state.isDeleteDialogVisible}
				onClose={() => this.setState({ isDeleteDialogVisible: false })}
			>
				<DialogTitle>Are you sure you want to delete this search?</DialogTitle>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => this.setState({ isDeleteDialogVisible: false })}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
						onClick={this.handleDeleteClick}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	render() {
		return (
			<div>
				<div style={{ padding: '0 20px' }}>
					<div style={styles.addBottomMargin}>
						<Field
							name="openSearch"
							component={FormTextField}
							helperText="Search for name, address, email, phone, etc."
							InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon>search</Icon>
								</InputAdornment>
							),
						}}
							fullWidth
						/>
					</div>
					<div style={styles.addBottomMargin}>
						<Field
							name="sortBy"
							label="Sort By"
							component={FormSelect}
							children={SelectListOptions({
								options: this.sortOptions.map((sortOption: string) => {
									return {
										option: sortOption,
									};
								}),
							})}
							fullWidth
						/>
					</div>
				</div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					{'Search Configuration'}
				</Typography>
				<div
					onClick={this.handleSavedSearchesExpandClick}
					style={styles.pointer}
				>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Saved Searches
						</span>
						<IconButton
							style={
								this.props.savedSearchesExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.savedSearchesExpanded}
					style={{ padding: '0 20px' }}
				>
					<Grid container>
						<Grid item xs={11}>
							<FormControl fullWidth>
								<InputLabel>Saved Searches</InputLabel>
								<MUISelect
									value={this.props.selectedSavedSearchName}
									onChange={(event: any) =>
										this.onUpdateSavedSearchValue(event.target.value)
									}
								>
									{this.generateSavedSearchList()}
								</MUISelect>
							</FormControl>
						</Grid>
						<Grid item xs={1}>
							<MoreMenu
								color={themePalette.tertiary_text}
								children={[
									createMenuAction('Save', this.handleSaveMenuItemClick, _.includes(
										this.getCorporateFilterNames(),
										this.props.selectedSavedSearchName
									)),
									createMenuAction('Save As', () => this.setState({ isSaveDialogVisible: true }), this.props.selectedSavedSearchName === DEFAULT_UNSELECTED),
									createMenuAction('Delete', () => this.setState({ isDeleteDialogVisible: true }), this.isDeleteDisabled())
								]}
							/>
						</Grid>
					</Grid>
				</Collapse>

				<div onClick={this.handleExpandClick} style={styles.pointer}>
					<CardActions style={{ marginBottom: '0px' }}>
						<span
							style={{
								fontSize: '20px',
								color: themePalette.sub_text,
								paddingLeft: 10,
							}}
						>
							Pre-Defined Searches
						</span>
						<IconButton
							style={
								this.props.predefinedSearchesExpanded
									? styles.expandOpen
									: styles.expand
							}
						>
							<Icon>keyboard_arrow_down</Icon>
						</IconButton>
					</CardActions>
				</div>
				<Collapse
					in={this.props.predefinedSearchesExpanded}
					style={{ padding: '0 20px' }}
				>
					<FormControl fullWidth>
						<InputLabel>Consumer Type</InputLabel>
						<MUISelect
							value={this.props.consumerType}
							onChange={(event: any) =>
								this.onUpdateConsumerType(event.target.value)
							}
						>
							{SelectListOptions({
								options: this.props.consumerTypes.map(
									(consumerType: string) => {
										return {
											option: consumerType,
										};
									}
								),
							})}
						</MUISelect>
					</FormControl>
					<FormControl component="fieldset" fullWidth>
						<span
							style={{
								fontSize: '16px',
								color: themePalette.sub_text,
								paddingTop: 20,
							}}
						>
							Search Across
						</span>
						<Field name="searchAcrossPrimaryOnly" component={FormRadioGroup}>
							<FormControlLabel
								value={'Primary Only'}
								control={<Radio />}
								label={'Primary Only'}
								style={styles.radioButton}
							/>
							<FormControlLabel
								value={'Each Person'}
								control={<Radio />}
								label={'Each Person'}
								style={styles.radioButton}
							/>
						</Field>
					</FormControl>
					<div hidden={true}>
						<FormControl component="fieldset" fullWidth>
							<span
								style={{
									fontSize: '16px',
									color: themePalette.sub_text,
									paddingTop: 20,
								}}
							>
								Show Results
							</span>
							<Field name="showPrimaryResultsOnly" component={FormRadioGroup}>
								<FormControlLabel
									value={'Primary Only'}
									control={<Radio />}
									label={'Primary Only'}
									style={styles.radioButton}
								/>
								<FormControlLabel
									value={'Each Person'}
									control={<Radio />}
									label={'Each Person'}
									style={styles.radioButton}
								/>
							</Field>
						</FormControl>
					</div>
				</Collapse>
				{this.renderSaveSearchDialog()}
				{this.renderDeleteSearchDialog()}
			</div>
		);
	}
}

function mapStateToProps(state: any, ownProps: Props): StateProps {


	const { selectedSavedSearchName, userSavedFilters, corporateFilters, expandedStates } = state.advancedSearch;

	const consumerTypes: string[] = [
		Strings.ASearch.ActiveLeads,
		Strings.ASearch.CompletedLeads,
		Strings.ASearch.CurrentCustomers,
		Strings.ASearch.FormerCustomers,
	];
	if (jwt_auth.hasPermission(P.AdvancedSearchBLeads)) {
		consumerTypes.push(Strings.ASearch.BLeads);
	}

	return {
		formValues: getFormValues(ownProps.formName)(state),
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		consumerTypes,
		selectedSavedSearchName,
		userSavedFilters,
		corporateFilters,
		savedSearchesExpanded: expandedStates.savedSearchesExpanded,
		predefinedSearchesExpanded: expandedStates.predefinedSearchesExpanded,
	};
}

function mapDispatchToProps(dispatch: any, ownProps: Props): DispatchProps {
	return {
		changeFieldValue: (field: string, value: any) => {
			dispatch(change(ownProps.formName, field, value));
		},
		selectSavedSearchName: (savedSearchName: string) =>
			dispatch(selectSavedSearchName(savedSearchName)),
		expandFilterFields: (filterFieldGroup: string) =>
			dispatch(ExpandFilterFields(filterFieldGroup)),
		upsertSavedAdvancedSearchFilters: (
			filtersToSave: AdvSearchFilterPresetDto
		) => dispatch(upsertAdvancedSearchFilters.started(filtersToSave)),
		deleteSavedAdvancedSearchFilters: (id: string) =>
			dispatch(deleteSavedAdvancedSearchFilters.started(id)),
	};
}

export const SearchConfigurationFields = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(_SearchConfigurationFields);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	addBottomMargin: {
		marginBottom: 15,
	},
	pointer: {
		cursor: 'pointer',
	},
	menuItemWrap: {
		whiteSpace: 'normal',
	}
};
