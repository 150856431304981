import React from 'react';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { Strings } from '../../assets/common/strings';
import { AgentAppointmentCount } from '../../reducers/license_appointment_reducer';
import { getAgentAppointmentCountByState } from '../../selectors/license_appointment_selectors';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useStylesLicenseAppointment } from '../../utilities/agentLicenseAppointment_util';

interface StateProps {
    agentAppointments: AgentAppointmentCount[];
}

type Props = StateProps;

const _AppointmentCountInformation: React.FC<Props> = (props) => {
    const { agentAppointments } = props;
    const classes = useStylesLicenseAppointment();

    return (
        <Box className={classes.laTile_details}>
            <Typography className={classes.appointmentHeading}>Active Appointments</Typography>
            <Typography className={classes.appointmentSecondaryHeading}>(By Line of Business)</Typography>
            <div className={classes.appointmentItem}>
                <TableContainer>
                    <Table className={classes.tableFixedColumnWidth} aria-label="Appointment Table">
                        <TableHead>
                            <TableRow>
                                {
                                    Object.values(Strings.AppointmentLineOfBusinessHeading).map((value) =>
                                        <TableCell align="center" className={classes.tableCell}>{value}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {
                                    Object.keys(Strings.AppointmentLineOfBusinessHeading).map((key) =>
                                        <TableCell align="center" className={classes.tableCell}>
                                            {agentAppointments.find(e => e.LineOfBusinessText === key) != null ?
                                                agentAppointments.find(e => e.LineOfBusinessText === key)?.AppointmentCount : '0'}
                                        </TableCell>)
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    agentAppointments: getAgentAppointmentCountByState(state),
});

export const AppointmentCountInformation = connect(mapStateToProps, {}, true)(_AppointmentCountInformation);
