import React from 'react';
import { Field } from 'redux-form';
import { connect } from '@hmkts/rise';
import {
	Card,
	CardContent,
	CardActions,
	Collapse,
	Typography,
	Icon,
	IconButton,
	Grid,
	MenuItem,
} from '@material-ui/core';
import { FormSelect, FormTextField, FormSwitch } from '../../utilities/forms';
import { validateName } from '../../utilities/form_validation';
import { themePalette } from '../../utilities/branding';
import {
	formatCurrency,
	requireNumber,
} from '../../utilities/formatting/data_normalizations';
import { createRequiredLabel } from '../../components/utility/required_label';
import { isIOS } from '../../utilities/is_mobile';
import { Lookups } from '../../utilities/lookup';
import { AppState } from '../../reducers';

export type EmployerFieldsData = {
	companyName: string;
	companyWebsite: string;
	industry: string;
	annualRevenue: string;
	numberEmployees: number;
	numberPartTime: number;
	currentCarrier: string;
	employerContributionAmount: string;
	employerContributionAmountBase: string;
	numberGroupParticipants: number;
	numberGroupEligibles: number;
	groupCoverageAdvantage: string;
	isAncillariesOffered: boolean;
	isFamilyCoverageOffered: boolean;
	isEmployerContributesGroupCoverage: boolean;
	isGroupPlanOffered: boolean;
	isEmployeesAge65Above: boolean;
};

interface OwnProps { }

interface StateProps {
	industryList: string[];
	groupCoverageAdvantageList: string[];
}

interface State {
	expanded: boolean;
}

class _EmployerFields extends React.Component<OwnProps & StateProps, State> {
	constructor(props: any) {
		super(props);

		this.state = {
			expanded: false,
		};
	}

	handleExpandClick = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	expandForm = () => {
		this.setState({ expanded: true });
	}

	renderNumberField = (params: {
		name: string;
		label: string;
		normalize: any;
	}) => {
		if (isIOS) {
			return (
				<Field
					name={params.name}
					label={params.label}
					component={FormTextField}
					fullWidth
					type="tel"
					pattern="/[0-9]*"
					normalize={params.normalize}
				/>
			);
		} else {
			return (
				<Field
					name={params.name}
					label={params.label}
					component={FormTextField}
					fullWidth
					type="number"
					normalize={params.normalize}
				/>
			);
		}
	};

	render() {
		return (
			<div>
				<Typography
					variant="h5"
					style={{ paddingLeft: 16, paddingTop: 16 }}
				>
					Basic Info
				</Typography>
				<Card style={{ margin: 10 }}>
					<CardContent>
						<Field
							name="companyName"
							component={FormTextField}
							label={createRequiredLabel('Company Name')}
							validate={[validateName]}
							fullWidth
						/>
						<Field
							name="companyWebsite"
							component={FormTextField}
							label="Website"
							fullWidth
						/>
						<Field
							name="industry"
							label="Industry"
							component={FormSelect}
							children={this.props.industryList.map(option => (
								<MenuItem value={option}>{option}</MenuItem>
							))}
							fullWidth
						/>
					</CardContent>
					<div onClick={this.handleExpandClick}>
						<CardActions style={{ marginBottom: '0px' }}>
							<span
								style={{
									fontSize: '20px',
									color: themePalette.sub_text,
									paddingLeft: 10,
								}}
							>
								Additional Info
							</span>
							<IconButton
								style={this.state.expanded ? styles.expandOpen : styles.expand}
								onFocus={this.expandForm}
							>
								<Icon>keyboard_arrow_down</Icon>
							</IconButton>
						</CardActions>
					</div>
					<Collapse in={this.state.expanded} style={{ padding: '0 20px' }}>
						<div onFocus={this.expandForm}>
							<Field
								name="annualRevenue"
								label="Annual Revenue"
								component={FormTextField}
								fullWidth
								normalize={formatCurrency}
							/>
							{this.renderNumberField({
								name: 'numberEmployees',
								label: 'Number of Employees',
								normalize: requireNumber,
							})}
							{this.renderNumberField({
								name: 'numberPartTime',
								label: 'Number of Part Time',
								normalize: requireNumber,
							})}
							<Field
								name="currentCarrier"
								label="Current Carrier"
								component={FormTextField}
								fullWidth
							/>
							<Field
								name="employerContributionAmount"
								label="Employer Contribution Amount"
								component={FormTextField}
								fullWidth
								normalize={formatCurrency}
							/>
							<Field
								name="employerContributionAmountBase"
								label="Employer Contribution Amount (Base)"
								component={FormTextField}
								fullWidth
								normalize={formatCurrency}
							/>
							{this.renderNumberField({
								name: 'numberGroupParticipants',
								label: 'Number of Group Participants',
								normalize: requireNumber,
							})}
							{this.renderNumberField({
								name: 'numberGroupEligibles',
								label: 'Number of Group Eligibles',
								normalize: requireNumber,
							})}
							<Field
								name="groupCoverageAdvantage"
								label="Group Coverage Advantage"
								component={FormSelect}
								children={this.props.groupCoverageAdvantageList.map(option => (
									<MenuItem value={option}>{option}</MenuItem>
								))}
								fullWidth
							/>
							<Grid container alignItems="center">
								<Field
									gridSize={"4|12"}
									name="isAncillariesOffered"
									component={FormSwitch}
									color="primary"
								/>
								<Field
									name="isFamilyCoverageOffered"
									component={FormSwitch}
									gridSize="4|12"
									color="primary"
									label="Family Coverage Offered?"
								/>
								<Field
									name="isEmployerContributesGroupCoverage"
									component={FormSwitch}
									gridSize="4|12"
									color="primary"
									label="Employer Contributes to Group Coverage?"
								/>
								<Field
									gridSize="4|12"
									name="isGroupPlanOffered"
									component={FormSwitch}
									color="primary"
									label="Group Plan Offered?"
								/>
								<Field
									gridSize="4|12"
									name="isEmployeesAge65Above"
									component={FormSwitch}
									label="Employees Age 65 or Above?"
									color="primary"
								/>
							</Grid>
						</div>
					</Collapse>
				</Card>
			</div >
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	industryList: state.lookup ? state.lookup.getLabels(Lookups.EmployerIndustry) : [],
	groupCoverageAdvantageList: state.lookup ? state.lookup.getLabels(Lookups.GroupCoverageAdvantage) : [],
});

export const EmployerFields = connect(mapStateToProps, null, true)(_EmployerFields);

const styles: { [key: string]: React.CSSProperties } = {
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
};
