import React from 'react';
import {
    createSlice, ThunkAction, getRiseState, getDispatch, RiseStrings, nav, FactFinderIconSvg,
    FormActions, AcContact,
    riseStrings,
    Over65FactFinderIconSvg,
    HouseholdRole
} from "@hmkts/rise";
import {
    FactFinderCompleteWidget,
    PowerPointTemplateFormWidget,
    FactFinderFilterWidget,
    FactFinderBubble,
} from "./components";
import { normalizeTemplate, createPowerPointActions } from './create_powerpoint_actions';
import { FactFinderDashboardWidget } from './components/fact_finder_forms/fact_finder_dashboard_widget';
import { FactFinderListPage, AboutMePage } from './components/fact_finder_pages';
import { FactFinderPresentationPage } from './components/fact_finder_pages';
import { FactFinderFormPage } from './components/fact_finder_pages';


export const PowerPointActions = createPowerPointActions("factfinder/");

export const emptyPowerPointTemplate = {
    id: "",
    title: "",
    agentid: "",
    updatedOn: "",
    createdOn: "",
    masterTemplateId: "",
    slides: [],
    isDeleted: false,
}

export declare namespace FactFinderSlice {

    export type State = {
        id: string;
        items: any[];
        serviceUrl: string;
        aboutMeProfile?: AboutMeSlide;
        templates: { [template: string]: PowerPointTemplate; }
        hasUnsavedData: boolean;
        originalData: Partial<State>;
        masterFormId: string;
        masterTemplateId: string;
        activeTemplateId: string;
        activeTemplate?: PowerPointTemplate;
        activeTemplateDirty?: boolean;
        activeTemplateCloseOnSave: boolean;
        activeFactFinder: FactFinderState;
        currentTab: RiseStrings.FactFinderTabs;
        enableSearch?: boolean;
    }

    export type FactFinderState = {
        formId: string;
        activeSection: string;
        errorMsg?: string;
    }

    export type PowerPointTemplate = {
        id: string,
        masterTemplateId: string,
        title: string,
        agentid: string,
        updatedOn: string,
        createdOn: string,
        slides: PowerPointSlide[],
        isDeleted: boolean,
    }

    export type PowerPointSlide = {
        id: string;
        title: string;
        thumbnailURL: string;
        img: string;
        enabled: boolean;
        readOnly?: boolean;
    }

    export type AboutMeSlide = {
        aboutMeYears: string;
        appointedHealth: string;
        appointedOthers: string;
        appointedLife: string;
        appointedMedicare: string;
        aboutMeOrgs: string;
    }
}
export const FactFinderSlice = createSlice({
    name: riseStrings._FactFinder,
    initialState: {
        id: riseStrings.Widget,
        formOptions: {
            formTitle: "FactFinder",
            path: "factfinder/",
            masterFormId: riseStrings._MasterForm1,
        },
        aboutMeProfile: {
            aboutMeYears: "",
            appointedHealth: "",
            appointedOthers: "",
            appointedLife: "",
            appointedMedicare: "",
            aboutMeOrgs: "",
            isSaving: false,
        },
        activeFactFinder: {
            formId: "",
            activeSection: "",
        },
        items: [],
        serviceUrl: "factfinder/",
        [riseStrings._TemplatePPT]: {},
        [riseStrings._SlidePPT]: {},
        hasUnsavedData: false,
        originalData: {},
        activeTemplateId: '',
        activeTemplateCloseOnSave: false,
        masterFormId: riseStrings._MasterForm1,
        masterTemplateId: riseStrings._FactFinderMasterTemplateId,
        currentTab: riseStrings.FactFinderForm,
    } as FactFinderSlice.State,
    reducers: {
        disableSearch: (state, action: ThunkAction<void>) => {
            state.enableSearch = false;
        },
        enableSearch: (state, action: ThunkAction<void>) => {
            state.enableSearch = true;
        },
        SetActiveTemplateId: (state: FactFinderSlice.State, action: ThunkAction<{ templateId: string }>) => {
            state.activeTemplateId = action.payload.templateId;
            state.activeTemplateDirty = false;
        },

        SetActiveFactFinderSection: (state, action: ThunkAction<{ sectionId: string }>) => {
            state.activeFactFinder.activeSection = action.payload.sectionId
        },

        SetCloseBehavior: (state: FactFinderSlice.State, action: ThunkAction<{ activeTemplateCloseOnSave: boolean }>) => {
            state.activeTemplateCloseOnSave = action.payload.activeTemplateCloseOnSave;
        },

        SetActiveTemplate: (state, action: ThunkAction<FactFinderSlice.PowerPointTemplate>) => {
            state.activeTemplate = action.payload;
            state.activeTemplateDirty = true;
        },

        SetCurrentTab: (state: FactFinderSlice.State, action: ThunkAction<{ currentTab: RiseStrings.FactFinderTabs }>) => {
            state.currentTab = action.payload.currentTab;
        },
    },
    extraReducers: builder => {
        return builder
            .addCase(
                PowerPointActions.getTemplatesByAgentId.fulfilled,
                (state, action) => {
                    const templates = action.payload;
                    templates.forEach(template => {
                        const { entities } = normalizeTemplate(template);
                        const { templates = {} } = entities;
                        state.templates = { ...state.templates, ...templates };
                    })
                })
            .addCase(
                PowerPointActions.getAboutMe.fulfilled,
                (state, action) => {
                    state.aboutMeProfile = action.payload;
                })
            .addCase(
                PowerPointActions.saveAboutMe.fulfilled,
                (state, action) => {
                    state.aboutMeProfile = action.payload;
                })
            .addCase(
                PowerPointActions.createTemplate.fulfilled,
                (state, action) => {
                    const template = action.payload;
                    // state.templates = {
                    //     ...state.templates,
                    //     [template.id]: template
                    // };
                    state.activeTemplate = template;
                    state.activeTemplateId = template?.id || "";
                    state.activeTemplateDirty = false;
                })
            .addCase(
                PowerPointActions.saveTemplate.fulfilled,
                (state, action) => {
                    if (!!action?.payload?.id) {
                        state.templates[action.payload.id] = action.payload;
                        state.activeTemplate = undefined;
                        state.activeTemplateDirty = false;
                    }
                })
            .addCase(
                PowerPointActions.deleteTemplate.fulfilled,
                (state: FactFinderSlice.State, action) => {
                    if (!!action?.payload?.result) {
                        delete state[riseStrings._TemplatePPT][action.payload.result]
                    }
                })
            .addCase("LOGIN/LOGOUT", (state) => {
                state.templates = {};
                state.aboutMeProfile = {
                    aboutMeYears: "",
                    appointedHealth: "",
                    appointedOthers: "",
                    appointedLife: "",
                    appointedMedicare: "",
                    aboutMeOrgs: "",
                };
                state.activeFactFinder = {
                    formId: "",
                    activeSection: "",
                };
            })
            .addCase("Clear_Caches", (state) => {
                state.templates = {};
                state.aboutMeProfile = {
                    aboutMeYears: "",
                    appointedHealth: "",
                    appointedOthers: "",
                    appointedLife: "",
                    appointedMedicare: "",
                    aboutMeOrgs: "",
                };
                state.activeFactFinder = {
                    formId: "",
                    activeSection: "",
                };
            })
    },
    components: {
        [riseStrings._PowerPointTemplateForm]: PowerPointTemplateFormWidget,
        [riseStrings._FactFinderDashboardWidget]: FactFinderDashboardWidget,
        [riseStrings._FactFinderFilterWidget]: FactFinderFilterWidget,
        [riseStrings._FactFinderBubbleItem]: FactFinderBubble,
        [riseStrings._FactFinderCompleteWidget]: FactFinderCompleteWidget,
    },
    routes: [
        FactFinderListPage,
        FactFinderFormPage,
        FactFinderPresentationPage,
        AboutMePage,
    ],
    widgetProps: {
        [riseStrings._FactFinderFabItem]: {
            id: riseStrings._FactFinderFabItem,
            type: "FabMenuItem",
            value: {
                disabled: false,
                label: "New Fact Finder",
                icon: <FactFinderIconSvg color="#fff" height={35} width={35} />,
                onClick: (contacts: AcContact[]) => {
                    const riseState = getRiseState();
                    const dispatch = getDispatch();
                    const masterFormId = riseStrings._NewUnder65Id;
                    const enterprise = riseState.Config.enterpriseName.toLowerCase();
                    const householdId = contacts[0].householdId;
                    dispatch(FormActions.getNewForm({ masterFormId, enterprise, contacts }) as any);
                    nav.push(riseStrings._Route_Form_View.replace(':formId', riseStrings._NewUnder65).replace(':householdId', householdId));

                    const primaryContact = contacts.find(c => c.householdRole === HouseholdRole.Primary);
                    const primaryAddress = primaryContact?.addresses.find(a => a.isPreferred === true);
                    if (primaryAddress && primaryAddress.zipCode && !primaryAddress.county) {
                        setTimeout(() => {
                            dispatch(FormActions.zipCodeLookup(primaryAddress.zipCode) as any);
                        }, 1000)
                    }
                }
            }
        },
        [riseStrings._FactFinderFabItemSenior]: {
            id: riseStrings._FactFinderFabItemSenior,
            type: "FabMenuItem",
            value: {
                disabled: false,
                label: "New Senior FactFinder",
                icon: <Over65FactFinderIconSvg fill="#fff" width={28} height={12} viewBox={"255 0 50 250"} color="#fff" />,
                onClick: (contacts: AcContact[]) => {
                    const riseState = getRiseState();
                    const dispatch = getDispatch();
                    const masterFormId = riseStrings._NewOver65Id;
                    const enterprise = riseState.Config.enterpriseName.toLowerCase();
                    const householdId = contacts[0].householdId;
                    dispatch(FormActions.getNewForm({ masterFormId, enterprise, contacts }) as any);
                    nav.push(riseStrings._Route_Form_View.replace(':formId', riseStrings._NewOver65).replace(':householdId', householdId));
                    const primaryContact = contacts.find(c => c.householdRole === HouseholdRole.Primary);
                    const primaryAddress = primaryContact?.addresses.find(a => a.isPreferred === true);
                    if (primaryAddress && primaryAddress.zipCode && !primaryAddress.county) {
                        setTimeout(() => {
                            dispatch(FormActions.zipCodeLookup(primaryAddress.zipCode) as any);
                        }, 1000)
                    }
                }
            }
        },

    },
});