import { NavigationProps, navRoutes } from "../../components/nav/Routes";
import React from 'react';
import { AppState } from '../../reducers';
import { connect } from '@hmkts/rise';
import { GetContact } from '../../actions/contact_actions';
import { navigateToWithoutAddingToHistory } from '../../actions/navigation_actions';
import { getMatchingContactIndex, Contact } from '../../reducers/ContactReducer';
import { EMPTY_CONTACT, loaded } from '../../utilities/empty_entities';
import { Loaded } from '../../utilities/utilities';
import { Strings } from '../../assets/common/strings';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { ContactHeaderBar } from '../../components/Layout/contact_header_bar';
import { orDefault } from '../../assets/common/string_builders';
import { getId } from '../../selectors/base_selectors';

interface StateProps {
    contact: Loaded<Contact>;
    householdId: string;
}

interface DispatchProps {
    getContact: (id: string) => void;
    navigateToWithoutAddingToHistory: (route: string) => void;
}

interface State {

}

type Props = StateProps & DispatchProps & NavigationProps;

class ContactRedirect extends React.Component<Props, State> {
    componentWillMount() {
        const contactId = this.props.match.params.contactID;
        if (contactId != Strings.Navigation.ContactId) {
            this.props.getContact(contactId);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (
            this.props.contact &&
            this.props.contact.loading &&
            nextProps.contact &&
            !nextProps.contact.loading
        ) {
            if (nextProps.contact.errors && nextProps.contact.errors.length == 0) {
                this.props.navigateToWithoutAddingToHistory(navRoutes.household.path.replace(
                    Strings.Navigation.HouseholdId,
                    orDefault(nextProps.contact.householdId)
                ))
            }
        }
    }

    render() {
        const isLoading = this.props.contact ? this.props.contact.loading : true
        return (
            <BasePageContainer
                topComponent={
                    <ContactHeaderBar
                        navigateTo={(route) => this.props.navigateTo( route)}
                        title={Strings.Context.Household}
                        subtitles={[]}
                        contacts={[]}
                        primaryContact={this.props.contact.data}
                        isLoading={isLoading}
                        householdId={this.props.householdId}
                        history={this.props.history}
                    />
                }
            >
                {this.renderErrors()}
            </BasePageContainer>
        );
    }

    renderErrors() {
        if (!this.props.contact.loading
            && this.props.contact.errors
            && this.props.contact.errors.length > 0) {
            const errorCode = this.props.contact.errors[0].errorCode;
            let message = '';

            switch (errorCode) {
                case 401:
                    message = 'You do not have permission to view this household.'
                    break;
            }
            return (
                <div>{message}</div>
            )
        }
    }
}

function mapStateToProps(
    state,
    ownProps: Props
): StateProps {
    

    const contactID = ownProps.match.params.contactID;
    const matchedContactIndex = getMatchingContactIndex(
        state.contact.contacts,
        contactID
    );

    const householdId = getId(state, ownProps);

    const matchedContact =
        matchedContactIndex > -1
            ? state.contact.contacts[matchedContactIndex]
            : loaded(EMPTY_CONTACT);

    return {
        contact: matchedContact,
        householdId,
    }
}

function mapDispatchToProps(
    dispatch: any,
    ownProps: Props
): DispatchProps {
    return {
        getContact: (id: string) => dispatch(GetContact.started(id)),
        navigateToWithoutAddingToHistory: (route: string) =>
            dispatch(navigateToWithoutAddingToHistory(ownProps.history, route)),
    }
}

export const ContactRedirectContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactRedirect) as React.ComponentClass<Props>;