import { Box, InputLabel, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { Strings } from '../../assets/common/strings';
import { AgentLicense } from '../../reducers/license_appointment_reducer';
import { getAgentLicenses } from '../../selectors/license_appointment_selectors';
import moment from 'moment';
import { GetLicenseAppointmentStatusIcon, useStylesLicenseAppointment } from '../../utilities/agentLicenseAppointment_util';

interface StateProps {
	agentLicenses: AgentLicense[];
}

type Props = StateProps;

const iconProps = {
	viewBox: "0 0 24 24",
	style: {
		fontSize: "1.3rem"
	}
}

const _LicenseStatusInformation: React.FC<Props> = (props) => {
	const classes = useStylesLicenseAppointment();
	const { agentLicenses } = props;

	return (
		<Box className={classes.laTile_details}>
			<Typography className={classes.laTile_Heading}>License</Typography>
			<div className={classes.flexRowCenter}>
				<InputLabel className={classes.laTile_label} children={Strings.LicenseAppointment.Status + ': '} />
				<Typography className={classes.flexRowCenter}>
					{agentLicenses[0]?.StatusText}
					<GetLicenseAppointmentStatusIcon status={agentLicenses[0]?.StatusText} showTooltip={false} iconProps={iconProps} />
				</Typography>
			</div>
			<div className={classes.flexRowCenter}>
				<InputLabel
					className={classes.laTile_label}
					children={Strings.LicenseAppointment.LineOfAuthority + ': '}
				/>
				<Tooltip title={agentLicenses[0]?.LineOfLicenseText} placement="top">
					<Typography className={classes.textOverflow}>{agentLicenses[0]?.LineOfLicenseText}</Typography>
				</Tooltip>
			</div>
			<div className={classes.flexRowCenter}>
				<InputLabel className={classes.laTile_label} children={Strings.LicenseAppointment.Expiration + ': '} />
				<Typography>
					{agentLicenses[0]?.IsPerpetual
						? Strings.LicenseAppointment.Perpetual
						: moment(agentLicenses[0]?.ExpirationDate).format('MM/DD/YYYY')}
				</Typography>
			</div>
		</Box>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	agentLicenses: getAgentLicenses(state),
});

export const LicenseStatusInformation = connect(mapStateToProps, {}, true)(_LicenseStatusInformation);
