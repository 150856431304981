import React from 'react';
import { Dialog, DialogContent, Button, Select, MenuItem, Grid, Typography, LinearProgress, Icon, Tooltip } from '@material-ui/core';
import { connect, Dispatch } from '@hmkts/rise';
import { AppState } from '../../reducers';
import { Strings } from '../../assets/common/strings';

interface StateProps {
}

interface DispatchProps {

}

interface OwnProps {
    twilioDevice: any;
    closeDialog: () => void;
    visible: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

interface State {
    selectedDevices: { [index: string]: string };
    inputVolume: number
}

class AudioSettingsDialogComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedDevices: {
                'outputDevice': 'default',
                'inputDevice': 'default'
            },
            inputVolume: 0
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.twilioDevice && nextProps.twilioDevice.audio) {
            nextProps.twilioDevice.audio.on('inputVolume', this.audioVolumeHandler)
        }
        if (nextProps.twilioDevice && nextProps.twilioDevice.audio && nextProps.twilioDevice.audio.outputDevice) {
            let newDevices = Object.assign({}, this.state.selectedDevices);
            newDevices['outputDevice'] = nextProps.twilioDevice.audio.outputDevice.deviceId;
            newDevices['inputDevice'] = nextProps.twilioDevice.audio.inputDevice.deviceId;

            this.setState({
                selectedDevices: newDevices
            });
        }
    }

    audioVolumeHandler = (volume: number) => {
        this.setState({ inputVolume: volume });
    }

    selectDevice = (event: any) => {
        let newDevices = Object.assign({}, this.state.selectedDevices);
        newDevices[event.target.name.key] = event.target.value;

        this.setState({
            selectedDevices: newDevices
        }, () => {
            if (event.target.name.key == 'outputDevice') {
                this.props.twilioDevice.audio.speakerDevices.set(event.target.value);
            } else if (event.target.name.key == 'inputDevice') {
                this.props.twilioDevice.audio.setInputDevice(event.target.value);
            }
        });
    }

    renderDeviceList = (deviceList: any, key: string) => {
        var devices: any = [];
        deviceList.forEach((device: any, id: any) => {
            devices.push(device);
        });

        return (
            <Select
                autoWidth={true}
                value={this.state.selectedDevices[key]}
                onChange={this.selectDevice}
                disabled={key == 'outputDevice' && !this.props.twilioDevice.audio.isOutputSelectionSupported}
                inputProps={{
                    name: { key },
                    id: key
                }}
            >
                {devices.length > 0 && devices.map((device: any) => {
                    return (
                        <MenuItem value={device.deviceId}>
                            {device.label}
                        </MenuItem>
                    );
                })}
            </Select>
        )
    }

    testSpeaker = () => {
        if (this.props.twilioDevice.audio.speakerDevices) {
            this.props.twilioDevice.audio.speakerDevices.test()
        }
    }

    render() {
        return (
            <Dialog open={this.props.visible} maxWidth='sm'>
                <DialogContent>
                    {this.props.twilioDevice && this.props.twilioDevice.audio && <Grid container>
                        <Typography style={{ margin: 'auto' }} variant='h5' align='center'>Audio Settings</Typography>
                        <Grid item container>
                            <Grid item container xs={12}>
                                <Grid item xs={10}>
                                    Speaker:
                                    <br />
                                    {this.renderDeviceList(this.props.twilioDevice.audio.availableOutputDevices, 'outputDevice')}
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="Test">
                                        <Button variant="contained" color={Strings.Theming.Primary} onClick={this.testSpeaker}>
                                            Test&nbsp;
                                            <Icon>volume_up</Icon>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={12}>
                                <div style={styles.spacer} />
                                <div style={styles.spacer} />
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={3}>
                                    Microphone:
                                </Grid>
                                <Grid item xs={9}>
                                    <div style={styles.spacer} />
                                    <LinearProgress variant="determinate" value={this.state.inputVolume * 100 / (.4)} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderDeviceList(this.props.twilioDevice.audio.availableInputDevices, 'inputDevice')}
                                <div style={styles.spacer} />
                                <div style={styles.spacer} />
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            color={Strings.Theming.Primary}
                            onClick={this.props.closeDialog}
                        >
                            Close
                    </Button>
                    </Grid>}
                </DialogContent>
            </Dialog>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {

    }
}

function mapStateToProps(state): StateProps {
    
    return {

    }
}

export const AudioSettingsDialog = connect(mapStateToProps, mapDispatchToProps, true)(AudioSettingsDialogComponent) as React.ComponentClass<OwnProps>;

const styles = {
    spacer: {
        paddingTop: '.5em'
    }
}