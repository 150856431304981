import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { SaveCancelHeaderBarComponent } from '../Layout/SaveCancelHeaderBar';
import { LiveSearch } from '../search/live_search';
import { SearchResultProps } from '../utility/search_result';
import { connect } from '@hmkts/rise';
import { AgentHierarchyDto, Agent } from '../../reducers/agent_reducer';
import { GetAgentHierarchy } from '../../actions/agent_actions';
import { SearchAgentsByTerm } from '../../actions/agent_search_actions';
import { DownlineAgent } from '../../reducers/product_reducer';
import { orDefault, fullName } from '../../assets/common/string_builders';
import { getAgentHierarchy } from '../../selectors/agent_selectors';
import { AppState } from '../../reducers';
import { AgentAvatar } from './agent_avatar';
import { themePalette } from '../../utilities/branding';
import { DesktopPadding } from '../Layout/desktop_padding';
import { GAService } from '../../utilities/services/google_analytics';

interface ComponentProps {
	visible: boolean;
	currentAgentCode: string;
	confirmationDialog?: boolean;
	headerText: string;
	saveText?: string;
	downlineOnly?: boolean;
	uplineOnly?: boolean;
	onSave: (selectedAgent: DownlineAgent) => void;
	onCancel: () => void;
	searchTerminated?: boolean;
	fullScreen?: boolean;
}
interface StateProps {
	isLoading: boolean;
	agent?: Agent;
	agentResults: DownlineAgent[];
	hierarchy?: AgentHierarchyDto;
	reportsTo?: string;
}
interface DispatchProps {
	getHierarchy: (agentCode: string) => void;
	searchAgents: (term: string, searchTerminated?: boolean) => void;
}

type Props = ComponentProps & StateProps & DispatchProps

export const AgentSelector: React.FC<Props> = (props) => {

	const { 
		downlineOnly, agent, hierarchy, fullScreen=true, getHierarchy, 
		searchAgents, searchTerminated, agentResults, uplineOnly, reportsTo,
		onSave, onCancel, saveText, headerText, visible, isLoading,
	} = props;

	const [selectedAgentCode, setSelectedAgentCode] = useState('');
	useEffect(() => {
		if (downlineOnly && agent && (!hierarchy || hierarchy.agentCode !== agent.agentCode)) {
			getHierarchy(agent.agentCode);
		}

		return () => {
			searchAgents('', searchTerminated);
		}
	}, []);

	const handleSave = () => {
		const selectedAgent = agentResults.find(a => a.agentCode == selectedAgentCode);
		if (selectedAgent) {
			onSave(selectedAgent);
		}
	};

	const handleCancel = () => {
		onCancel();
		handleSearch('');
		setSelectedAgentCode('');
	};

	const handleSearch = (term: string) => {
		GAService.sendEvent('Agent_Search', 'Search', term);
		searchAgents(term, searchTerminated);
	};

	const searchResults: SearchResultProps[] = agentResults
		.filter(agent => {
			if(downlineOnly) {
				if(hierarchy) {
					return hierarchy.agentHierarchyDownline.find((downlineAgent) => agent.agentCode === downlineAgent.agentCode);
				} else {
					return false;
				}
			}
			else if (uplineOnly) {
				return agent.agentCode === reportsTo;
			}
			return true;
		})
		.map(agent => {
			const agentAvatar = <AgentAvatar agent={agent} />;
			return {
				avatar: agentAvatar,
				resultId: agent.agentCode,
				primaryText: fullName(agent),
				secondaryText: agent.city
					? orDefault(agent.city) + ', ' + orDefault(agent.state) + ' ' + orDefault(agent.zip)
					: undefined,
			};
		});
	const selectedAgent = agentResults.find(agent => agent.agentCode == selectedAgentCode);
	const resultString = selectedAgent
		? fullName(selectedAgent)
		: undefined;
	
	return (
		<Dialog 
			open={visible} 
			fullScreen={fullScreen} 
			fullWidth
			PaperProps={{ style: { backgroundColor: themePalette.secondary_background } }}
		>
			<SaveCancelHeaderBarComponent
				onSave={handleSave}
				saveText={saveText}
				title={headerText}
				isSaveDisabled={!selectedAgentCode}
				onCancel={handleCancel}
				bypassCancelConfirmation
				isLoading={isLoading}
			/>
			<DesktopPadding style={{ overflowY: 'scroll' }}>
				<LiveSearch
					results={searchResults}
					handleSearch={handleSearch}
					resetSearch={() => handleSearch('')}
					label="Search for agent by name, city, or zip"
					onSelect={setSelectedAgentCode}
					resultString={resultString}
					loading={isLoading}
				/>
			</DesktopPadding>
		</Dialog>
	);
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps): StateProps => ({
	isLoading: state.agentSearch.isLoading ||
	(!!ownProps.downlineOnly && !state.agent.hierarchy),
	agent: state.agent,
	agentResults: state.agentSearch.agents || [],
	hierarchy: getAgentHierarchy(state),
	reportsTo: state.agent.reportsTo,
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	getHierarchy: (agentCode: string) => dispatch(GetAgentHierarchy.started(agentCode)),
	searchAgents: (term: string, searchTerminated?: boolean) => 
		dispatch(SearchAgentsByTerm.started({term, searchTerminated: searchTerminated || false}))
});

export const AgentSelectorComponent = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(AgentSelector)