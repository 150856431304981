import React from 'react';
import { connect } from '@hmkts/rise';
import { compose } from 'redux';
import { Podcast, PodcastTracking } from '../../reducers/podcast_reducer';
import { UpdateTracking, ChangePodcast } from '../../actions/podcast_actions';
import { DecodedJwt } from '../../reducers/authentication_reducer';
import { getDecodedJwt } from '../../selectors/authentication_selectors';


interface InputProps {
}

interface DispatchProps {
    updateTracking: (tracking: PodcastTracking) => void,
    changePodcast: (podcast?: Podcast) => void
}

interface StateProps {
    podcast?: Podcast,
    userId: string,
    isTracking: boolean,
    jwt?: DecodedJwt
}

type Props = InputProps & StateProps & DispatchProps;

interface State {
    audioElement?: HTMLAudioElement,
    trackInterval?: any
}

export class PodcastAudio extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            audioElement: undefined,
            trackInterval: undefined
        }
    }

    componentDidMount() {
        let el = document.getElementById("podcast-player") as HTMLAudioElement;
        if (el != null) {
            this.setupAudioElement(el);
        }
    }

    componentDidUpdate() {
        let audio = this.state.audioElement;
        if (!audio) {
            audio = document.getElementById("podcast-player") as HTMLAudioElement;
            if (audio) {
                this.setupAudioElement(audio);
            }
        }
    }

    setupAudioElement(audio: HTMLAudioElement) {
        this.setState({
            audioElement: audio
        });
        audio.addEventListener("canplay", audio?.play);
        audio.addEventListener("play", this.onPlay);
        audio.addEventListener("pause", this.onPause);
    }

    componentWillReceiveProps(nextProps: Props) {
        const audioEl = this.state.audioElement;
        if(nextProps.podcast && nextProps.jwt) {
            if(!this.props.podcast || (this.props.podcast && this.props.podcast.id != nextProps.podcast.id)) {
                if(audioEl && nextProps.podcast.url) {
                    const url = nextProps.podcast.url + nextProps.jwt.fileStorageSignature + '&xyz=' + new Date().getTime()
                    audioEl.setAttribute('src', url);
                    audioEl.load();
                    setTimeout(this.onCanPlay, 500)
                }
            }
        } else {
            if (audioEl) {
                audioEl.pause();
                audioEl.setAttribute('src', '');
            }
    
            if(!nextProps.jwt) {
                this.props.changePodcast(undefined);
            }
        }
    }

    getCurrentProgress = () => {
        let audio = this.state.audioElement;
        if (audio) {
            return (audio.currentTime / audio.duration) * 100
        }
    }

    setPlaybackTime = (time: number) => {
        let audio = this.state.audioElement;
        if (audio) {
            audio.currentTime = time;
        }
    }

    clearIntervals = () => {
        if(this.state.trackInterval) {
            clearInterval(this.state.trackInterval)
        }

        this.setState({
            trackInterval: undefined
        });
    }
    
    updateTracking = () => {
        if (this.props.podcast && this.props.jwt) {
            const tracking: PodcastTracking = {
                podcastId: this.props.podcast.id,
                userId: this.props.userId,
                createdOn: new Date(),
                updatedOn: new Date(),
                progress: Math.floor(this.state.audioElement!.currentTime),
                finished: Math.floor(this.state.audioElement!.currentTime) == Math.floor(this.props.podcast.duration!)
            }
            this.props.updateTracking(tracking);
        }
    }

    onCanPlay = () => {
        if(this.state.audioElement) {
            this.state.audioElement.play();
        }
    }

    onPlay = () => {
        const {audioElement} = this.state;
        if (audioElement && this.props.podcast) {
            this.clearIntervals();
            audioElement.removeEventListener("canplay", this.onCanPlay);
            this.setState({
                trackInterval: setInterval(() => {
                    if (!audioElement.paused) {
                        if (!this.props.isTracking) {
                            this.updateTracking();
                        }
                    }
                }, 5000)
            });
        }
    }

    onPause = () => {
        this.clearIntervals();
    }

    render() {
        return (
            <audio id="podcast-player" src="" />
        );
        
    }
}

const mapStateToProps = (state: any): StateProps => ({
    podcast: state.podcast.currentPodcast,
    userId: state.user.id,
    isTracking: state.podcast.isTracking,
    jwt: getDecodedJwt(state)
});
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
    updateTracking: (tracking: PodcastTracking) => dispatch(UpdateTracking.started(tracking)),
    changePodcast: (podcast?: Podcast) => dispatch(ChangePodcast(podcast)),
});
export const PodcastAudioComponent = compose(
    connect(mapStateToProps, mapDispatchToProps, true)
)(PodcastAudio);
