import React from 'react';
import { connect, useDispatch } from '@hmkts/rise';
import { Route, Switch } from 'react-router-dom';
import { AgentHeader } from '../../components/agent/agent_header';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themeLinks, themeMisc } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { AboutMeContainer } from './about_me';
import { ContractCompensationContainer } from './contract_compensation';
import { AgentHierarchyContainer } from '../AgentProfile/agent_hierarchy';
import { AgentState } from '../../reducers/agent_reducer';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { WideButton } from '../../components/utility/wide_button';
import { Dispatch } from '@reduxjs/toolkit';
import { isAgentRole } from '../../utilities/agent_util';
import { Strings } from '../../assets/common/strings';
import { ManageSubagentsContainer } from './manage_subagents';
import { ManageReshopping } from './manage_reshopping';
import { useNavigationProps } from '../../utilities/hooks';
import { clearReshopSummaries } from '../../actions/reshop_actions';

interface StateProps {
	agent: AgentState;
}
interface DispatchProps {}
type Props = StateProps & DispatchProps & NavigationProps;

const ProfileLanding: React.FC<Props> = ({ agent, match }) => {
	const dispatch = useDispatch();
	const { agentCode } = agent;
	const { navigateTo } = useNavigationProps();

	const navigate = (path: string) => () => {
		navigateTo(path);
	};

	const renderProfile = () => (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="My Profile"
					customContent={<AgentHeader agent={agent} />}
					rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkProfile)]} />}
				/>
			}>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.profile} />
			<DesktopPadding>
				<WideButton primary={'About Me'} onClick={navigate(navRoutes.aboutMe.path)} />
				{isAgentRole([Strings.AgentRoles.SponsorAgent, Strings.ADRoles.Assistant]) && (
					<WideButton primary={'Manage SubAgents'} onClick={navigate(navRoutes.manageSubagents.path)} />
				)}
				<WideButton
					primary={'Contract & Compensation'}
					onClick={navigate(navRoutes.contractCompensation.path)}
				/>
				{(agentCode === 'insite.agent' || themeMisc.isReshopLive) && (
					<WideButton
						primary={'Manage Re-Enrollment'}
						onClick={() => {
							// clear any results in redux
							dispatch(clearReshopSummaries());
							navigate(navRoutes.manageReshopping.path)();
						}}
					/>
				)}
			</DesktopPadding>
		</BasePageContainer>
	);

	return (
		<Switch>
			<Route exact path={`${match.url}`} render={renderProfile} />
			<Route exact path={`${match.url}/aboutMe`} render={(props) => <AboutMeContainer />} />
			<Route
				exact
				path={`${match.url}/aboutMe/myHierarchy`}
				render={(props) => <AgentHierarchyContainer {...props} agentCode={agentCode} />}
			/>
			<Route
				exact
				path={`${match.url}/contractCompensation`}
				render={(props) => <ContractCompensationContainer />}
			/>
			<Route exact path={`${match.url}/manage-subagents`} render={(props) => <ManageSubagentsContainer />} />
			<Route exact path={`${match.url}/manage-reshopping`} render={(props) => <ManageReshopping />} />
		</Switch>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	agent: state.agent,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({});
export const ProfileLandingContainer = connect(mapStateToProps, mapDispatchToProps, true)(ProfileLanding);
