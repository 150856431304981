import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    MenuItem
} from '@material-ui/core';
import { AgentAppointment, AgentAppointmentLineOfBusiness, AppointmentForm } from '../../../reducers/license_appointment_reducer';
import { ListFilter } from '../../../components/filter/list_filter';
import { Strings } from '../../../assets/common/strings';
import { GetAgentAppointmentLineOfBusinesses, GetAgentAppointmentStates, GetAgentAppointments } from '../../../actions/license_appointment_actions';
import { connect } from '@hmkts/rise';
import { Dispatch } from 'redux';
import { AppState } from '../../../reducers';

interface StateProps {
    filteredAppointments: AgentAppointment[],
    filterAppointmentForm: AppointmentForm,
    appointmentStateList: string[],
    lobList: AgentAppointmentLineOfBusiness[];
}
interface DispatchProps {
    filterAppointments: (filter: AppointmentForm) => void;
    agentAppointmentStates: () => void;
    agentAppointmentLineOfBusinesses: () => void;
}

type Props = StateProps & DispatchProps;

const AppointmentFilterPage: React.FC<Props> = (props) => {

    const { appointmentStateList, lobList, filterAppointmentForm, filterAppointments, agentAppointmentStates, agentAppointmentLineOfBusinesses } = props;

    const statusList = ["All", Strings.AppointmentStatusIcon.Appointed, Strings.AppointmentStatusIcon.Pending, Strings.AppointmentStatusIcon.Terminated];

    useEffect(() => {
        agentAppointmentStates();
        agentAppointmentLineOfBusinesses();
    }, [])

    const [editAppointmentForm, setEditAppointmentForm] = useState(filterAppointmentForm);

    const { state, status, lineOfBusiness } = editAppointmentForm;
    const searchAppointments = () => {
        filterAppointments(editAppointmentForm);
    };

    const changeState = (event) => {
        setEditAppointmentForm({
            ...editAppointmentForm,
            state: event.target.value
        });
    };

    const changeStatus = (event) => {
        setEditAppointmentForm({
            ...editAppointmentForm,
            status: event.target.value
        });
    };

    const changeLoB = (event) => {
        setEditAppointmentForm({
            ...editAppointmentForm,
            lineOfBusiness: event.target.value
        });
    };

    const handleReset = () => {
        const initialForm: AppointmentForm = {
            state: Strings.LicenseAppointmentFilterLabel.All,
            status: Strings.LicenseAppointmentFilterLabel.All,
            lineOfBusiness: Strings.LicenseAppointmentFilterLabel.All,
        };
        setEditAppointmentForm(initialForm);
    };


    return (
        <ListFilter
            drawerTitle={'Appointments'}
            onSearch={searchAppointments}
            onReset={handleReset}
            cardSubtitle={`${state} - ${status} - ${lineOfBusiness}`}
            drawerSubtitle={`${state} - ${status} - ${lineOfBusiness}`}
        >
            <Grid container alignContent="center" spacing={2}>

                <Grid item xs={12}>
                    <TextField
                        select
                        label="State"
                        onChange={changeState}
                        value={state}
                        fullWidth
                    >
                        {(appointmentStateList?.length > 0 && appointmentStateList.map((state: string) => (
                            <MenuItem key={state} value={state}>
                                {state}
                            </MenuItem>
                        )))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Status"
                        onChange={changeStatus}
                        value={status}
                        fullWidth
                    >
                        {(statusList?.length > 0 && statusList.map((status: string) => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        )))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Line Of Business"
                        onChange={changeLoB}
                        value={lineOfBusiness}
                        fullWidth
                    >
                        {lobList?.length > 0 && lobList.map((lob) => {
                            if (lob.LineOfBusinessText === Strings.AppointmentLineOfBusiness.CommercialHealth) {
                                lob.LineOfBusinessText = Strings.AppointmentLineOfBusinessHeading['Commercial Health'];
                            }

                            return (
                                <MenuItem key={lob.LineOfBusinessText} value={lob.LineOfBusinessText}>
                                    {lob.LineOfBusinessText}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>

            </Grid>
        </ListFilter>
    );
}

const mapStateToProps = (state: AppState) => {
    const stateListResult = state.licenseAppointment.agentAppointmentStates;
    const stateList = [Strings.LicenseAppointmentFilterLabel.All, ...stateListResult.map(item => item.State)];
    const allLobOption = { LineOfBusinessText: Strings.LicenseAppointmentFilterLabel.All };
    const agentAppointmentLineOfBusinesses = state.licenseAppointment.agentAppointmentLineOfBusinesses;

    return {
        filteredAppointments: state.licenseAppointment.agentAppointments,
        filterAppointmentForm: state.licenseAppointment.filterAppointmentForm,
        appointmentStateList: stateList,
        lobList: [allLobOption, ...agentAppointmentLineOfBusinesses]
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    filterAppointments: (filter: AppointmentForm) => dispatch(GetAgentAppointments.started(filter)),
    agentAppointmentStates: () => dispatch(GetAgentAppointmentStates.started()),
    agentAppointmentLineOfBusinesses: () => dispatch(GetAgentAppointmentLineOfBusinesses.started()),
});

export const AppointmentFilter = connect(mapStateToProps, mapDispatchToProps, true)(AppointmentFilterPage);
