import React, { PureComponent, ChangeEvent } from 'react';
import { Table, TableCell, TableHead, TableRow, TableBody, TablePagination, TableSortLabel, Icon } from '@material-ui/core';
import { SelectedDataTableOptions } from '../../../reducers/data_table_reducer';
import { PagingOptions } from '../../../actions/notification_actions';
import { Strings } from '../../../assets/common/strings';
import { greaterIgnoreCase } from '../../../utilities/sort_util';
import { getSelectedOptions } from '../../../selectors/data_table_selectors';
import { connect, Dispatch } from '@hmkts/rise';
import { UpdateSelectedOptions } from '../../../actions/data_table_actions';
import { DataRow } from './data_row';

const rowOptions = [5, 10, 20, 50, 100];

export type TableData = {
    id: string;
};
export interface TableHeader<T> {
    label: string;
    key: keyof T | 'action';
    fieldType: 'readonly' | 'dropdown' | 'checkbox' | 'button' | 'custom';

    action?: (obj: T) => void; // action when clicking on cell or button in cell
    options?: string[]; // options for dropdown
    onOptionChange?: (id: string, value: string) => void; // dropdown change
    onCheck?: (obj: T, value: boolean) => void; // checkbox change
    buttonText?: string;
    renderCustom?: (obj: T) => JSX.Element;
}

interface StateProps {
    selectedOptions: SelectedDataTableOptions;
}
interface DispatchProps {
    updateSelectedOptions: (options: Partial<SelectedDataTableOptions>) => void;
}
interface ComponentProps<T extends TableData> {
    name: string;
    data: T[];
    total: number;
    headers: TableHeader<T>[];
    updateData?: (paging: PagingOptions) => void;
    editable?: boolean;
    disablePaging?: boolean;
    expandableRows?: boolean;
    renderExpandedRow?: (obj: T) => JSX.Element;
}
type Props = ComponentProps<TableData> & StateProps & DispatchProps;
class _DataTable extends PureComponent<Props> {

    componentDidMount() {
        this.update({});
    }

    handlePageChange = (event: any, page: number) => {
        this.update({ page });
    };

    handleRowChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.update({ rowsPerPage: parseInt(event.target.value), page: 0 });
    };

    handleSortChange = (column: TableHeader<TableData>) => () => {
        const { selectedOptions, updateSelectedOptions } = this.props;
        const sortDescending = column.key === selectedOptions.sortKey
            ? !selectedOptions.sortDescending
            : true;
        this.update({
            sortDescending,
            sortKey: column.key ? column.key.toString() : "",
        });
    };

    update = (updatedOptions: Partial<SelectedDataTableOptions>) => {
        const { updateData, updateSelectedOptions, selectedOptions, data, total } = this.props;
        const newestOptions: SelectedDataTableOptions = {
            ...selectedOptions,
            ...updatedOptions
        };
        // Default sort key
        newestOptions.sortKey = newestOptions.sortKey || Strings.AdminAccountUpsertForm.Id;

        updateSelectedOptions && updateSelectedOptions(newestOptions);

        const needMoreData = (total === 0 || data.length < total) && data.length < (newestOptions.page + 1) * newestOptions.rowsPerPage;
        needMoreData && updateData && updateData({ pageNum: newestOptions.page, pageSize: newestOptions.rowsPerPage });
    };

    render() {
        const { headers, data, total, selectedOptions, disablePaging, expandableRows, renderExpandedRow } = this.props;
        const { page, rowsPerPage, sortKey, sortDescending } = selectedOptions;

        const tableRows = (data || [])
            .sort((a, b) => {
                const greater = sortDescending ? 1 : -1;
                if (greaterIgnoreCase(a[sortKey], b[sortKey])) return greater;
                if (greaterIgnoreCase(b[sortKey], a[sortKey])) return -1 * greater;
                return 0;
            })
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

        return (
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* This extra table cell is for the expandable dropdown */}
                            {expandableRows && <TableCell />}
                            {
                                headers.map(header => (
                                    <TableCell key={header.key}>
                                        <TableSortLabel onClick={this.handleSortChange(header)}>
                                            {header.label}
                                            {sortKey === header.key &&
                                                (sortDescending
                                                    ? <Icon>arrow_drop_down</Icon>
                                                    : <Icon>arrow_drop_up</Icon>
                                                )
                                            }
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableRows.map((obj: TableData) => (
                                <DataRow 
                                    key={obj.id} 
                                    data={obj} 
                                    headers={headers} 
                                    expandable={expandableRows} 
                                    renderExpanded={renderExpandedRow} 
                                />
                            ))
                        }
                    </TableBody>
                </Table>
                {!disablePaging && 
                    <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={rowOptions}
                        page={page}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowChange}
                        classes={{
                            spacer: 'cropper-hidden'
                        }}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state, props: ComponentProps<TableData>): StateProps => ({
    selectedOptions: getSelectedOptions(state, props.name),
});
const mapDispatchToProps = (dispatch: Dispatch<any>, props: ComponentProps<TableData>): DispatchProps => ({
    updateSelectedOptions: (options: Partial<SelectedDataTableOptions>) => dispatch(UpdateSelectedOptions({
        dataTableName: props.name,
        options
    })),
});

export const DataTable = connect(
    mapStateToProps,
    mapDispatchToProps,
    true,
)(_DataTable) as React.ComponentClass<ComponentProps<any>>;
