import React, { useEffect } from 'react';
import { Strings } from '../../assets/common/strings';
import { Box, Button, Container, makeStyles } from '@material-ui/core';
import { connect } from '@hmkts/rise';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { useLinkToExternal } from '../../utilities/hooks';
import { getResourceCenterCommissionStatementReportUrl } from '../../utilities/url_util';
import { GetAgentCommissionStatementDates } from '../../actions/commission_statement_actions';
import { AppState } from '../../reducers';
import { getCurrentAgentCode } from '../../selectors/agent_selectors';
import { getCommissionStatementDates } from '../../selectors/agent_commission_statement_selectors';
import { CommissionStatementTile } from '../../components/agentCommissionStatement/commission_statement_tile';
import { Height } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: '20px',
		background: theme.palette.background.paper,
		height: '100%',
	},
}));

interface StateProps {
	loading: boolean;
	currentAgentCode: string;
	agentCommissionStatementDates: Date[];
}

interface DispatchProps {
	getAgentCommissionStatementDates: () => void;
}

type Props = StateProps & DispatchProps;
const _CommissionStatementContainer: React.FC<Props> = (props) => {
	const { loading, currentAgentCode, agentCommissionStatementDates, getAgentCommissionStatementDates } = props;
	const classes = useStyles();

	useEffect(() => {
		if (currentAgentCode != 'unknown') {
			getAgentCommissionStatementDates();
		}
	}, [currentAgentCode]);

	const navToCommissionStatement = useLinkToExternal(getResourceCenterCommissionStatementReportUrl());

	const ViewAllButton = () => (
		<Button
			disabled={agentCommissionStatementDates == undefined || agentCommissionStatementDates.length <= 0}
			onClick={navToCommissionStatement}>
			View All
		</Button>
	);
	const renderWidget = () => {
		if (loading) {
			return;
		} else if (agentCommissionStatementDates && agentCommissionStatementDates.length > 0) {
			return <CommissionStatementTile />;
		} else {
            return;
        }
	};

	return (
		<BasePageContainer
			topComponent={<HeaderBarComponent title={Strings.WidgetTitles.CommissionStatement} isLoading={loading} />}
			bottomComponent={<ViewAllButton />}>
			<Box className={classes.container}>{renderWidget()}</Box>
		</BasePageContainer>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	loading: state.commissionStatement.loading,
	currentAgentCode: getCurrentAgentCode(state),
	agentCommissionStatementDates: getCommissionStatementDates(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getAgentCommissionStatementDates: () => dispatch(GetAgentCommissionStatementDates.started()),
});

export const CommissionStatementContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(_CommissionStatementContainer);
