import React, { ComponentElement } from 'react';
import { connect } from '@hmkts/rise';
import { reset } from 'redux-form';
import { getFormValues } from 'redux-form';
import { Drawer, Button } from '@material-ui/core';
import { AppState } from '../../../reducers/index';
import { isDesktop } from '../../../utilities/is_mobile';
import { SaveCancelHeaderBarComponent } from '../../../components/Layout/SaveCancelHeaderBar';
import LeadSearchFilterForm, { FORM_NAME } from './lead_search_filter_form';
import { LeadSearchFilters } from '../../../actions/lead_search_actions';
import { Lookup, Lookups } from '../../../utilities/lookup';
import { BaseFilterForm } from '../../../components/search/base_filter_form';

type StateProps = {
	formValues: LeadSearchFilters;
};

interface DispatchProps {
	reset: () => void;

}
type ComponentProps = {
	open: boolean;
	onSearch: () => void;
	onClose: () => void;
	tagSearch?: boolean;
};

type Props = ComponentProps & StateProps & DispatchProps;

type State = {};

class LeadSearchFilter extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	handleReset = () => {
		this.props.reset();
	};

	render() {
		return (
			<Drawer
				anchor="right"
				open={this.props.open}
				onClose={this.props.onClose}
				PaperProps={{ style: { width: isDesktop() ? '500px' : '100%' } }}
			>
				<SaveCancelHeaderBarComponent
					title="Lead Search"
					saveText="Search"
					onSave={this.props.onSearch}
					onCancel={this.props.onClose}
					bypassCancelConfirmation
				/>
				<BaseFilterForm
					submit={this.props.onSearch} >
					<LeadSearchFilterForm
						initialValues={{ leadStatusReason: [Lookups.NotContacted.value] }}
						tagSearch={Boolean(this.props.tagSearch)}
					/>
				</BaseFilterForm>
				<div style={{ padding: '20px' }}>
					<Button
						variant="contained"
						fullWidth
						color="secondary"
						onClick={this.handleReset}
						size={'small'}
					>
						Reset
					</Button>
				</div>
			</Drawer>
		);
	}
}

function mapStateToProps(state: any): StateProps {
	
	return {
		formValues: getFormValues(FORM_NAME)(state),
	};
}

function mapDispatchToProps(dispatch: any): DispatchProps {
	return {
		reset: () => dispatch(reset(FORM_NAME)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps, true)(
	LeadSearchFilter
) as React.ComponentClass<ComponentProps>;
