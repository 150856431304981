import { CardActions, Collapse, connect, Grid, Icon, IconButton, TextField } from '@hmkts/rise';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { getFormValues, change } from 'redux-form';
import { ExpandFilterFields } from '../../../actions/advanced_search_actions';
import { Strings } from '../../../assets/common/strings';
import { fullName } from '../../../assets/common/string_builders';
import { AgentSelectorComponent } from '../../../components/agent/agent_selector';
import { AppState } from '../../../reducers';
import { SearchAgent, SearchFilterFormValues } from '../../../reducers/advanced_search_reducer';
import { DownlineAgent } from '../../../reducers/product_reducer';
import { getCurrentAgentCode } from '../../../selectors/agent_selectors';
import { isAgentRole } from '../../../utilities/agent_util';
import { themePalette } from '../../../utilities/branding';

interface ComponentProps {
    formName: string;
}
interface StateProps {
    hasSubagents: boolean;
    subagentExpanded: boolean;
    currentAgentCode: string;
    formValues: SearchFilterFormValues;
}
interface DispatchProps {
    changeFieldValue: (field: string, value: any) => void;
	expandFilterFields: (filterFieldGroup: string) => void;
}
type Props = StateProps & DispatchProps;

const _SubagentFields: React.FC<Props> = (props) => {

    const { hasSubagents, currentAgentCode, formValues, subagentExpanded, expandFilterFields, changeFieldValue } = props;

    const [dialogVisible, setDialogVisible] = useState(false);

    const handleExpand = (section: string) => () => expandFilterFields(section);
    const saveAgentSearch = (agent: DownlineAgent) => {
		if (agent) {
			const agentName = fullName(agent);
			const searchAgent: SearchAgent = {
				id: agent.agentCode,
				name: agentName,
			};
			setDialogVisible(false);
			changeFieldValue('subagent', searchAgent);
		}
    };
    
    const agentName: string = formValues?.subagent?.name ?? '';

    return hasSubagents ? (
        <div>
            <Typography
                variant="h5"
                style={{ paddingLeft: 16, paddingTop: 16 }}
            >
                {Strings.AboutMeLabels.Subagent}
            </Typography>
            <div onClick={handleExpand('subagentExpanded')} style={styles.expandable}>
                <CardActions style={{ marginBottom: '0px' }}>
                    <span
                        style={{
                            fontSize: '20px',
                            color: themePalette.sub_text,
                            paddingLeft: 10,
                        }}
                    >
                        Common
                    </span>
                    <IconButton style={subagentExpanded ? styles.expandOpen : styles.expand}>
                        <Icon>keyboard_arrow_down</Icon>
                    </IconButton>
                </CardActions>
            </div>
            <Collapse
                in={subagentExpanded}
                style={styles.collapsable}
            >
                <Grid container>
                    <Grid item xs={11}>
                        <div
                            style={{ ...styles.addBottomMargin }}
                        >
                            <TextField
                                id="SubAgent Name"
                                value={agentName}
                                label="Agent Name"
                                fullWidth
                                onClick={() => setDialogVisible(true)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={1} style={{ marginBottom: -5, marginTop: 5 }}>
                        <IconButton
                            key="remove-subagent-icon-button"
                            onClick={() => changeFieldValue('subagent', null)}
                        >
                            <Icon>cancel</Icon>
                        </IconButton>
                    </Grid>
                </Grid>
            </Collapse>

            <AgentSelectorComponent
                visible={dialogVisible}
                currentAgentCode={currentAgentCode}
                onSave={saveAgentSearch}
                onCancel={() => setDialogVisible(false)}
                headerText="SubAgent Search"
                saveText="Select"
                downlineOnly
            />
        </div>
    ) : <span/>;

};

const styles: { [key: string]: React.CSSProperties } = {
	expandable: {
		cursor: 'pointer',
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
		marginLeft: 'auto',
	},
	collapsable: {
		padding: '0 20px',
	},
	listStyle: {
		listStyleType: 'disc',
		margin: 0,
	},
};

const mapStateToProps = (state: AppState, ownProps: ComponentProps): StateProps => {
	const expandedStates = state.advancedSearch.expandedStates;
	const hasSubagents = isAgentRole([Strings.AgentRoles.SponsorAgent, Strings.ADRoles.Assistant]);

	return {
		hasSubagents,
		formValues: getFormValues(ownProps.formName)(state),
		currentAgentCode: getCurrentAgentCode(state),
		subagentExpanded: expandedStates.subagentExpanded,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: ComponentProps): DispatchProps => ({
	changeFieldValue: (field: string, value: any) => {
		dispatch(change(ownProps.formName, field, value));
	},
	expandFilterFields: (filterFieldGroup: string) =>
		dispatch(ExpandFilterFields(filterFieldGroup)),
});

export const SubagentFields = connect(mapStateToProps, mapDispatchToProps, true)(
	_SubagentFields
);