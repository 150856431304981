import {
    LayoutSlice, FormSlice, ConfigSlice, FormActions,
    riseStrings, useSelector, useDispatch, nav, RiseStrings
} from "@hmkts/rise";
import { FactFinderSlice } from "../fact_finder_slice";

type Options = {
    overrideFormId?: string;
    overrideForms?: { [formId: string]: FormSlice.Form; }
    onItemClick?: (formId?: string) => void;
}

export const useFactFinderActions = (options?: Options) => {
    const dispatch = useDispatch();

    const configSlice = useSelector(ConfigSlice.selectState);
    const masterFormId = useSelector(app => FactFinderSlice.selectState(app).masterFormId);

    const copyForm = (formId: string) => {
        dispatch(
            FormActions.copy({ formId: formId }))
    };

    const deleteForm = (formId: string) => {
        dispatch(
            FormActions.delete({ formId: formId }))
    }

    const displayPageInDialog = () => {
        dispatch(
            LayoutSlice.actions.DialogSet({
                id: riseStrings.Main,
                sliceName: riseStrings._FactFinder,
                componentName: riseStrings._FactFinderPage,
                title: "Fact Finder"
            }))
    };

    const displayNewFormInDialog = () => {
        dispatch(
            LayoutSlice.actions.DialogSet({
                sliceName: riseStrings._FormBuilder,
                componentName: riseStrings.FormView,
                id: riseStrings.Main,
                query: {
                    enterprise: configSlice.enterpriseName,
                    masterFormId
                },
                title: "Fact Finder"
            }))
    };

    const displayFormInDialog = (formId: string) => {
        dispatch(
            LayoutSlice.actions.DialogSet({
                sliceName: riseStrings._FormBuilder,
                componentName: riseStrings.FormView,
                id: riseStrings.Main,
                query: { formId: formId },
                title: "Fact Finder"
            })
        )
    };

    const setCurrentTab = (currentTab: RiseStrings.FactFinderTabs) => {
        dispatch(FactFinderSlice.actions.SetCurrentTab({ currentTab }));
    }

    const navigateToFormList = () => {
        nav.push(riseStrings._Route_FactFinder_List);
    }

    return {

        displayPageInDialog,
        displayNewFormInDialog,
        displayFormInDialog,
        navigateToFormList,
        setCurrentTab,

        createCopyForm: (formId: string) =>
            () => copyForm(formId),
        createDeleteForm: (formId: string) =>
            () => deleteForm(formId),

    }
};

const getStatus = <T extends { createdOn: string; updatedOn: string, completed?: boolean }>(args: T) => {
    const createdDate = new Date(args.createdOn).getDate();
    const updatedDate = new Date(args.updatedOn).getDate();

    let statusString = '';

    if (args.completed) {
        statusString = "Completed on"
    } else if (createdDate === updatedDate) {
        statusString = 'Created on';
    } else if (createdDate < updatedDate) {
        statusString = 'Updated on';
    }

    return statusString.concat(" ", new Date(args.updatedOn).toLocaleDateString())
}