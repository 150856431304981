import React from 'react';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { connect } from '@hmkts/rise';
import { AppState } from '../../reducers/index';
import { navigateBack } from '../../actions/navigation_actions';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Template, TemplateTypeOptions } from '../../reducers/template_reducer';
import { Icon } from '@material-ui/core';
import { navigateTo } from '../../actions/navigation_actions';
import { Strings as S } from '../../assets/common/strings';
import { GetAgentTemplates, GetCorporateTemplates } from '../../actions/template_actions';
import { getEmailAutomationsByAgentId } from '../../actions/email_automation_actions';
import { Dictionary } from '../../utilities/object_util';
import { composeSearchString } from '../../utilities/search_util';
import moment from 'moment';
import { Lookups } from '../../utilities/lookup';
import { ColumnOption, renderCell, FilterTableList } from '../../components/Layout/table/filter_table';
import { FilterComponents, FilterFieldProps } from '../../components/Layout/filter';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';

interface StateProps {
	areAgentTemplatesLoading: boolean;
	areCorporateTemplatesLoading: boolean;
	templates: Template[];
	linesOfBusiness: string[];
	emailEventCategories: string[];
};
interface DispatchProps {
	navigateBack: () => void;
	navigateTo: (route: string) => void;
	getAgentTemplates: () => void;
	getCorporateTemplates: () => void;
	getEmailAutomations: () => void;
};
type Props =
	& StateProps
	& DispatchProps
	& NavigationProps;

type TemplateSelectPageState = {
	isDialogOpen: boolean;
	isTemplateSelected: boolean;
	selectedTemplate?: Template;
};

class TemplateSelectPage extends React.Component<Props, TemplateSelectPageState> {
	constructor(props: any) {
		super(props);
		this.state = {
			isDialogOpen: false,
			isTemplateSelected: false,
			selectedTemplate: undefined,
		};
	}

	componentDidMount() {
		this.props.getAgentTemplates();
		this.props.getCorporateTemplates();
		this.props.getEmailAutomations();
	}

	onClickNextScreen = () => {
		this.props.navigateTo(
			navRoutes.templatePreview.path
				.replace(S.Navigation.TemplateId, this.state.selectedTemplate!.id)
				.replace(S.Navigation.From, S.TemplateManagement.Sending)
		);
	};

	setSelectedTemplate = (selectedTemplate: Template) => this.setState({
		selectedTemplate,
		isTemplateSelected: true
	});

	renderTopComponent = () => {
		return (
			<SaveCancelHeaderBarComponent
				title={S.TemplateManagement.SelectTemplate}
				onCancel={this.props.navigateBack}
				saveText={S.ButtonText.Next}
				onSave={this.onClickNextScreen}
				isSaveDisabled={!this.state.isTemplateSelected}
				isLoading={
					this.props.areCorporateTemplatesLoading ||
					this.props.areAgentTemplatesLoading
				}
			/>
		);
	};

	renderMiddleComponent = () => {
		const mapToDictionary = (strings: string[]) => {
			const dico: Dictionary<string> = {};
			strings.forEach(str => dico[str] = str);
			return dico;
		}

		const emailTypeOptions = mapToDictionary([
			TemplateTypeOptions.agent,
			TemplateTypeOptions.corporate
		]);

		const {
			linesOfBusiness,
			emailEventCategories,
			templates,
		} = this.props;

		const filters: FilterFieldProps[] = [
			{
				label: "Template Type",
				options: emailTypeOptions,
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !!selection[t.emailType],
				includeAll: true,
				component: FilterComponents.Dropdown,
				defaultSelection: { All: "All" }
			}, {
				label: "Template Event",
				options: mapToDictionary(emailEventCategories),
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !t.emailAutomationEventTypeCategory || !!t.emailAutomationEventTypeCategory.some(cat => !!selection[cat]),
				includeAll: true,
				component: FilterComponents.Dropdown,
				defaultSelection: { All: "All" }
			}, {
				label: "Lines of Business",
				options: mapToDictionary(linesOfBusiness),
				compareFn: (t: Template, selection: Dictionary<string>) => !!selection["All"] || !t.linesOfBusiness || t.linesOfBusiness.some(lob => !!selection[lob]),
				includeAll: true,
				component: FilterComponents.MultiselectDropdown,
				defaultSelection: { All: "All" }
			}
		];

		const columns: ColumnOption[] = [
			{
				label: "Title",
				field: "title",
				render: t => renderCell(t.title),
			}, {
				label: "Status",
				field: "isDraft",
				render: (t: Template) => renderCell(<Icon>{t.isDraft ? "edit" : "email"}</Icon>),
			}, {
				label: "Lines of Business",
				field: "linesOfBusiness",
				render: (t: Template) => renderCell(composeSearchString(t.linesOfBusiness, true)),
			}, {
				label: "Template Event",
				field: "emailAutomationEventTypeCategory",
				render: (t: Template) => renderCell(composeSearchString(t.emailAutomationEventTypeCategory, true)),
			}, {
				label: "Language",
				field: "language",
				render: (t: Template) => renderCell(t.language),
			}, {
				label: "Updated On",
				field: "updatedOn",
				render: (t: Template) => renderCell(moment(t.updatedOn).format(S.DateFormat.MDYYYYHMMA)), //TODO back to moment
			}, {
				label: "Template Type",
				field: "emailType",
				render: (t: Template) => renderCell(t.emailType),
			},
		];

		return <FilterTableList
			filterProps={filters}
			columns={columns}
			rows={templates.filter(template => !template.isDraft)}
			onClick={this.setSelectedTemplate}
			selectedRow={this.state.selectedTemplate}
		/>;
	}

	render() {
		return (
			<BasePageContainer
				topComponent={this.renderTopComponent()}
				middleComponent={this.renderMiddleComponent()}
			/>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	areAgentTemplatesLoading:
		state.templateManagerState.areAgentTemplatesLoading,
	areCorporateTemplatesLoading:
		state.templateManagerState.areCorporateTemplatesLoading,
	templates: state.templateManagerState.agentTemplates
		.concat(state.templateManagerState.corporateTemplates)
		.map(lt => lt.data),
	linesOfBusiness: state.lookup.getLabels(Lookups.LeadLineOfBusiness),
	emailEventCategories: state.lookup.getLabels(Lookups.EmailAutomationEventTypeCategory),
});
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	navigateBack: () =>
		dispatch(navigateBack()),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	getAgentTemplates: () =>
		dispatch(GetAgentTemplates.started(undefined)),
	getCorporateTemplates: () =>
		dispatch(GetCorporateTemplates.started(undefined)),
	getEmailAutomations: () => dispatch(getEmailAutomationsByAgentId()),
});
export const TemplateSelectContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(TemplateSelectPage);
