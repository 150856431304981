import React from 'react';
import { connect } from '@hmkts/rise';
import { getFormValues } from 'redux-form';
import { Paper, Card, CardHeader, Avatar, IconButton, Icon } from '@material-ui/core';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import { AppState } from '../../reducers/index';
import LeadSearchFilter from './filter/lead_search_filter';
import { FORM_NAME } from './filter/lead_search_filter_form';
import { LeadSearchTable } from './lead_search_table';
import { normalizeFilterStringsArray, AppliedFilters, getYesNoFromBool, stringBoolToBool } from '../../utilities/formatting/data_normalizations';
import {
	LeadSearchFilters,
	SearchLeads,
	SaveLeadStatus,
	SaveLeadStatusInput,
} from '../../actions/lead_search_actions';
import { LeadUpdate } from '../../reducers/LeadReducer';
import { Strings } from '../../assets/common/strings';
import { ListFilter } from '../../components/filter/list_filter';
import { Lookups } from '../../utilities/lookup';

interface Props extends NavigationProps {
	formValues: LeadSearchFilters;
	loading: boolean;
	search: (filters: LeadSearchFilters, page: number) => void;
	save: (input: SaveLeadStatusInput) => void;
}

interface State {
	filterOpen: boolean;
	summaryString: string;
}

class LeadSearch extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			filterOpen: false,
			summaryString: '',
		};
	}

	setSummaryString = () => {
		if (!this.props.formValues) return '';

		const appliedFiltersList: AppliedFilters = [];
		const filterObj = Object.assign(this.props.formValues);
		for (const filter in filterObj) {
			const hasValue = filterObj[filter] != [] && (filterObj[filter].length || typeof filterObj[filter] === typeof 0);
			if (filterObj[filter] && hasValue) {
				switch (filter) {
					case Strings.LeadFieldNames.Callable:
						const isCallable = stringBoolToBool(filterObj[filter]);
						appliedFiltersList.push({
							[Strings.LeadFieldNames.Callable]: getYesNoFromBool(isCallable)
						});
						break;
					case Strings.LeadFieldNames.AgeMaxMonth:
					case Strings.LeadFieldNames.AgeMaxYear:
						if (appliedFiltersList.some(filterItem => filterItem.hasOwnProperty(Strings.Filter.AgeMax))) {
							break;
						}
						const maxYear: number = filterObj[Strings.LeadFieldNames.AgeMaxYear] || 0;
						const maxMonth: number = filterObj[Strings.LeadFieldNames.AgeMaxMonth] || 0;

						const maxYearDisplay = `${maxYear}${Strings.ConsumerSearchFilters.Yr}${maxMonth}${Strings.ConsumerSearchFilters.Mo}`;
						appliedFiltersList.push({ [Strings.Filter.AgeMax]: maxYearDisplay });
						break;
					case Strings.LeadFieldNames.AgeMinMonth:
					case Strings.LeadFieldNames.AgeMinYear:
						if (appliedFiltersList.some(filterItem => filterItem.hasOwnProperty(Strings.Filter.AgeMin))) {
							break;
						}
						const minYear: number = filterObj[Strings.LeadFieldNames.AgeMinYear] || 0;
						const minMonth: number = filterObj[Strings.LeadFieldNames.AgeMinMonth] || 0;

						const minYearDisplay = `${minYear}${Strings.ConsumerSearchFilters.Yr}${minMonth}${Strings.ConsumerSearchFilters.Mo}`;
						appliedFiltersList.push({ [Strings.Filter.AgeMin]: minYearDisplay });
						break;
					default:
						appliedFiltersList.push({ [filter]: filterObj[filter] });
						break;
				}
			}
		}

		const filterStringsArray = normalizeFilterStringsArray(appliedFiltersList);
		filterStringsArray.push(appliedFiltersList.length.toString() + Strings.LeadSearch.FiltersSelected);
		const summaryString = filterStringsArray.join(', ');

		this.setState({ summaryString });
	}

	openFilter = () => {
		this.setState({ filterOpen: true });
	};

	closeFilter = () => {
		this.setState({ filterOpen: false });
	};

	handleSearch = () => {
		this.setSummaryString();
		this.closeFilter();
		this.props.search(this.props.formValues || {}, 0);
	};

	handlePageRequest = (
		pageNumber: number,
		filterOverride?: LeadSearchFilters
	) => {
		this.setSummaryString();
		this.props.search(
			filterOverride || this.props.formValues || {},
			pageNumber
		);
	};

	handleSave = (leadId: string, leadUpdate: LeadUpdate) => {
		this.props.save({
			id: leadId,
			statusCode: leadUpdate.status,
			statusReason: leadUpdate.reason,
		});
	};

	renderFilterSummary() {
		return (
			// <ListFilter
			// 	drawerTitle="Activities"
			// 	onSearch={this.handleSearch}
			// 	onReset={this.closeFilter}
			// 	cardSubtitle={this.state.summaryString}
			// 	drawerSubtitle={subtitle}
			// >
			// 	<LeadSearchFilterForm
			// 		initialValues={{ leadStatusReason: [Lookups.NotContacted.value] }}
			// 		tagSearch={false}
			// 	/>
			// </ListFilter>
			<Card raised={false} style={styles.summaryCard}>
				<CardHeader
					avatar={<Avatar src={require('../../assets/filter_icon.png')} />}
					title={Strings.LeadSearch.Search}
					subheader={this.state.summaryString}
					action={
						<IconButton onClick={this.openFilter}>
							<Icon>tune</Icon>
						</IconButton>
					}
				/>
			</Card>
		);
	}

	render() {
		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title={Strings.LeadSearch.LeadSearch}
						customContent={this.renderFilterSummary()}
						isLoading={this.props.loading}
					/>
				}
				bottomComponent={
					<LeadSearchFilter
						open={this.state.filterOpen}
						onClose={this.closeFilter}
						onSearch={this.handleSearch}
					/>
				}
			>
				<div style={styles.paperContainer}>
					<Paper elevation={0} style={styles.paper}>
						<LeadSearchTable
							requestPage={this.handlePageRequest}
							saveLead={this.handleSave}
						/>
					</Paper>
				</div>
			</BasePageContainer>
		);
	}
}

function mapStateToProps(state: any): Partial<Props> {
	
	return {
		formValues: getFormValues(FORM_NAME)(state) || { leadStatusReason: ["Not Contacted"] },
		loading: state.leadSearch.loading,
	};
}

function mapDispatchToProps(dispatch: any): Partial<Props> {
	return {
		search: (filters, page) =>
			dispatch(SearchLeads.started({ filters, pageIndex: page, pageSize: 20 })),
		save: input => dispatch(SaveLeadStatus.started(input)),
	};
}

export const LeadSearchContainer = connect(mapStateToProps, mapDispatchToProps, true)(
	LeadSearch
);

const styles: { [key: string]: React.CSSProperties } = {
	paper: {
		overflowX: 'scroll',
		overflowY: 'scroll',
		height: '98%',
		paddingLeft: 20,
	},
	paperContainer: {
		height: '100%'
	},
	summaryCard: { border: 'none', boxShadow: 'none', borderRadius: 0 },

};
