import React from 'react';
import {
    Button, Grid, Typography, makeStyles
} from '@material-ui/core';
import { FormSlice } from '@hmkts/rise';

export interface Provider extends FormSlice.ProviderResponse {}

interface Props {
    provider: Provider;
    isSelected: boolean;
    handleAddProvider: (value: Provider) => void;
}

export const ProviderDropdownListItem: React.FC<Props> = ({ provider, provider: { provider: providerData, address }, isSelected, handleAddProvider }) => {
    const classes = useStyles();

    const streetAddress = address.street2 === '' ? address.street1 : `${address.street1} ${address.street2}`;
    const providerAddress = `${streetAddress}, ${address.city} ${address.state} ${address.zipcode}`;
    const specialties = providerData.specialties.join(' | ')

    const handleAdd = () => {
        handleAddProvider(provider)
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                alignItems="center"
            >
                <Grid item xs={12} sm={8}>
                    <Typography variant="button" display="block" className={classes.providerName}>
                        {`${providerData.name}`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display="block">
                        {specialties}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display="block">
                        {providerAddress}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} container justify="flex-end" >
                    <Button
                        size="small"
                        color="secondary"
                        onClick={handleAdd}
                        disabled={isSelected}
                    >
                        {isSelected ? 'On List' : 'Add'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles({
    root: {
        padding: 16,
        borderBottom: '1px solid #dcdedf',
    },
    providerName: {
        fontWeight: 600,
    },
  });
