import { useSelector, useState, useEffect } from "@hmkts/rise";
import { PowerPointActions, FactFinderSlice } from "../fact_finder_slice";
import { shallowEqual, useDispatch } from "react-redux";

export const useAboutMeState = (
) => {
    const dispatch = useDispatch();

    const aboutMeState = useSelector(app => FactFinderSlice.selectState(app).aboutMeProfile, shallowEqual);

    const [currentForm, setCurrentForm] = useState<FactFinderSlice.AboutMeSlide>(aboutMeState || {} as FactFinderSlice.AboutMeSlide);
    const [isSaving, setSaving] = useState(false);
    const isDirty = currentForm !== aboutMeState;

    const createChangeHandler = (key: keyof FactFinderSlice.AboutMeSlide) => (event) => {
        const newValue = event.currentTarget.value
        setCurrentForm({ ...currentForm, [key]: newValue });
    }

    const save = () => {
        setSaving(true);
        dispatch(
            PowerPointActions.saveAboutMe(currentForm)
        );
    };


    const getAboutMe = () => {
        dispatch(
            PowerPointActions.getAboutMe()
        )
    }

    useEffect(() => {
        if (aboutMeState && aboutMeState != currentForm)
            setCurrentForm(aboutMeState);

        if (isSaving)
            setSaving(false);
    }, [aboutMeState, isSaving])

    return {
        currentForm,
        aboutMeState,
        isDirty,
        getAboutMe,
        save,
        createChangeHandler,
    }
};