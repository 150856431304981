import _ from 'lodash';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Drawer,
	Grid,
	Icon,
	IconButton,
	Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { connect } from '@hmkts/rise';
import {
	AdvSearchResultExportObject,
	executeAdvancedSearch,
	exportAdvancedSearchResults,
	getSavedCorporateAdvSearchFilters,
	getSavedUserAdvSearchFilters,
	SelectAdvancedSearchResult,
	selectAllAdvancedSearchResults,
	SetAdvSearchSelectAllFlag,
	setIsBLeadSearch,
	storeSelectedContactIds,
} from '../../actions/advanced_search_actions';
import { ResetInfiniteScroll } from '../../actions/infinite_scroll_actions';
import { BulkReassign } from '../../actions/lead_actions';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { jwt_auth } from '../../utilities/auth';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { ReassignDialog } from '../../components/reassign/reassign_dialog';
import { AppState } from '../../reducers';
import {
	BULK_REASSIGN_BLEAD_TYPE,
	BULK_REASSIGN_EMPLOYER_TYPE,
	BULK_REASSIGN_HOUSEHOLD_TYPE,
	BulkReassignRequestDto,
	SearchFilterFormValues,
	SearchResult,
} from '../../reducers/advanced_search_reducer';
import { getCurrentAgentCode, getCurrentAgentDisplayName } from '../../selectors/agent_selectors';
import { normalizeFilterStringsArray, AppliedFilters } from '../../utilities/formatting/data_normalizations';
import { isDesktop } from '../../utilities/is_mobile';
import { EmployerPageContainer } from '../employer/employer_page';
import { HouseholdPageContainer } from '../HouseholdPage/HouseholdPage';
import { AdvancedSearchFilterComponent } from './advanced_search_filter_component';
import { AdvancedSearchResultListItem } from './components/advanced_search_results_list_item';
import { ExportResultsDialog } from './components/export_results_dialog';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { EMPTY_GUID } from '../../utilities/empty_entities';
import { GetAgentFromJwt, GetAgencyChannels } from '../../actions/agent_actions';
import { Lookup, Lookups } from '../../utilities/lookup';
import { P } from '../../utilities/auth/permissions';
import { DialogFrame } from '../../reducers/dialogs_reducer';
import { QueueDialog } from '../../actions/dialogs_actions';
import { makeDialogInstance } from '../dialogs';
import { MergeHouseholdDialog } from '../dialogs/merge_household_dialog';
import { isIE } from '../../utilities';
import { StoreLeadRoutesIndex, UpdateLeadRoutesFromAdvancedSearch } from '../../actions/lead_routes_actions';
import { LeadRoutePages } from '../../reducers/lead_routes_reducer';
import { clearTemplateFilter } from '../../actions/template_actions';
import { StartClickToCallDialog } from '../../components/click_to_call/start_session_dialog';
import { ClickToCallSession, SessionHousehold } from '../../reducers/click_to_call_reducer';
import { EmptyMessage } from '../../components/utility/empty_message';
import { FormSlice } from "@hmkts/rise";

const InfiniteScroll = require('react-infinite-scroller');

interface DispatchProps {
	navigateTo: (route: string) => void;
	navigateBack: () => void;
	selectAdvancedSearchResult: (index: number, selected: boolean) => void;
	selectAllAdvancedSearchResults: (toggle: boolean) => void;
	storeSelectedContactIds: () => void;
	bulkReassign: (bulkReassignRequestDto: BulkReassignRequestDto) => void;
	getAgentByAgentCode: () => void;
	resetInfiniteScroll: () => void;
	executeAdvancedSearch: (
		searchFilterObject: SearchFilterFormValues,
		pageIndex: number,
		pageSize: number,
		processId?: string
	) => void;
	getSavedCorporateAdvSearchFilters: () => void;
	getSavedUserAdvSearchFilters: () => void;
	exportAdvancedSearchResults: (
		exportObject: AdvSearchResultExportObject
	) => void;
	setAdvSearchSelectAllFlag: (value: boolean) => void;
	setIsBLeadSearch: (value: boolean) => void;
	getAgencyChannels: () => void;
	queueDialog: (dialog: DialogFrame) => void;
	storeLeadRoutesIndex: (index: number) => void;
	updateLeadRoutesFromAdvancedSearchResults: () => void;
	clearEmailTemplateFilter: () => void;
	riseSetActivecontactId: (contactId: string) => void;
}

interface StateProps {
	location: any;

	appliedSearchFilters: SearchFilterFormValues;
	currentFormFilters: SearchFilterFormValues;
	searchResults: SearchResult[];

	loadedResults: number;
	numSelected: number;
	pageIndex: number;
	pageSize: number;
	totalResultCount: number;

	agentName: string;
	currentAgentCode: string;
	userId: string;
	processId?: string;

	hasMoreResults: boolean;
	isBLeadSearch: boolean;
	isSelectAll: boolean;
	resultsLoading: boolean;
	dialogIsShowing: boolean;
	selectedSavedSearchName: string;

	exportRequestLoading: boolean;
	mergeHouseholdLoading: boolean;

	AgentAdvSearchColumns: string[];
	EmployerAdvSearchColumns: string[];
	LeadAdvSearchColumns: string[];
	PersonAdvSearchColumns: string[];
	ProductAdvSearchColumns: string[];

	clickToCallSession?: ClickToCallSession;
	clickToCallSessionHousehold?: SessionHousehold;
}

type Props = DispatchProps
	& NavigationProps
	& StateProps;

interface State {
	employer_id: string;
	household_id: string;
	isDrawerOpen: boolean;
	mode: Strings.ASearchMode;
	searchIndex: number;
	showExportDialog: boolean;
	showReassignDialog: boolean;
	someSelected: boolean;
}

const ASMode = Strings.ASearchMode;
const ASFilter = Strings.ASearchFilters;
const AS = Strings.ASearch;
const CSS = Strings.CSS;

class AdvancedSearchPage extends React.Component<Props, State> {
	match = {
		params: {
			employerID: '',
			householdID: '',
		},
	};

	clampLinesRef: any = undefined;
	isListening: boolean = false;
	selectedIndex: number = -1;
	shiftSelectedIndex: number = -1;

	constructor(props: any) {
		super(props);
		this.state = {
			employer_id: '',
			household_id: '',
			isDrawerOpen: false,
			mode: ASMode.None,
			searchIndex: -1,
			showExportDialog: false,
			showReassignDialog: false,
			someSelected: false
		};
	}

	restoreHouseholdView() {
		const index = this.props.match.params.index;
		const household_id = this.props.match.params.householdId;

		if (index && household_id) {
			let nextResult: SearchResult = this.props.searchResults[index];
			if (nextResult) {
				const isEmployer: boolean = !!nextResult
					&& !!nextResult.EmployerId
					&& nextResult.EmployerId != EMPTY_GUID;

				this.clickSearchResult(
					isEmployer
						? (nextResult.EmployerId || '')
						: (nextResult.HouseholdId || ''),
					isEmployer
						? ASMode.Employer
						: ASMode.Household,
					index
				);
			}
		}
	}

	componentWillMount() {
		this.props.getSavedCorporateAdvSearchFilters();
		this.props.getSavedUserAdvSearchFilters();

		if (!this.props.currentAgentCode) {
			this.props.getAgentByAgentCode();
		}
	}

	componentDidMount() {
		if (!this.props.searchResults.length && !this.state.isDrawerOpen) {
			setTimeout(this.toggleDrawer, 200);
		}

		this.props.getAgencyChannels();
		this.restoreHouseholdView();
	}

	componentDidUpdate(prevProps: Props) {

		if (!this.props.resultsLoading && prevProps.resultsLoading) {
			let index: number = -1;
			if (this.state.household_id) {
				index = this.props.searchResults.findIndex(
					r => r.HouseholdId === this.state.household_id
				);
			} else if (this.state.employer_id) {
				index = this.props.searchResults.findIndex(
					r => r.EmployerId === this.state.employer_id
				);
			}
			index >= 0
				? this.setState({ searchIndex: index })
				: this.setState({
					mode: ASMode.None,
					household_id: '',
					searchIndex: -1,
					employer_id: '',
				});
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (!nextProps.resultsLoading && nextProps.searchResults != this.props.searchResults) {
			this.setState({
				someSelected: nextProps.searchResults.some(result => result.IsSelected)
			});
		}
	}

	componentWillUnmount() {
		this.stopListenToKeyDown();
        this.props.riseSetActivecontactId('');
	}

	listenToKeyDown = () => {
		if (this.isListening) {
			return;
		}
		this.isListening = true;
		document.addEventListener(Strings.Event.KeyDown, this.handleKeyPress, false);
	};

	stopListenToKeyDown = () => {
		if (!this.isListening) {
			return;
		}
		document.removeEventListener(Strings.Event.KeyDown, this.handleKeyPress, false);
		this.isListening = false;
	};

	handleKeyPress = (keyboardEvent: KeyboardEvent) => {
		const keyId = keyboardEvent.keyCode;

		if (this.props.appliedSearchFilters?.consumerType == Strings.ASearch.BLeads) {
			return;
		};

		// Right Arrow || Down Arrow Keys => Next Result Selected
		if (keyId == 39 || keyId == 40) {
			keyboardEvent.preventDefault();

			if (this.state.searchIndex >= -1 && this.state.searchIndex < this.props.searchResults.length) {
				let nextIndex: number = this.state.searchIndex + 1;
				let nextResult: SearchResult = this.props.searchResults[nextIndex];

				const isEmployer: boolean = !!nextResult
					&& !!nextResult.EmployerId
					&& nextResult.EmployerId != EMPTY_GUID;

				this.clickSearchResult(
					// ID
					isEmployer
						? (nextResult.EmployerId || '')
						: (nextResult.HouseholdId || ''),
					// Type
					isEmployer
						? ASMode.Employer
						: ASMode.Household,
					// Index
					nextIndex
				);
			}
			// Left Arrow || Up Arrow => Previous Result Selected
		} else if (keyId == 37 || keyId == 38) {
			keyboardEvent.preventDefault();

			if (this.state.searchIndex > 0) {
				const previousIndex: number = this.state.searchIndex - 1;
				const previousResult: SearchResult = this.props.searchResults[previousIndex];
				const isEmployer: boolean = !!previousResult
					&& !!previousResult.EmployerId
					&& previousResult.EmployerId != EMPTY_GUID;

				this.clickSearchResult(
					// ID
					isEmployer
						? (previousResult.EmployerId || '')
						: (previousResult.HouseholdId || ''),
					// Type
					isEmployer
						? ASMode.Employer
						: ASMode.Household,
					// Index
					previousIndex
				);
			}
		} else if (keyId == 16) {
			this.shiftSelectedIndex = -1;
		}
	};

	clickSearchResult = (
		id: string,
		type: Strings.ASearchMode.Household | Strings.ASearchMode.Employer,
		index: number
	) => {
		switch (type) {
			case ASMode.Household: {
				this.setState({
					mode: type,
					household_id: id,
					searchIndex: index,
					employer_id: '',
				});
				break;
			}
			case ASMode.Employer: {
				this.setState({
					mode: type,
					employer_id: id,
					searchIndex: index,
					household_id: '',
				});
				break;
			}
		}
		this.props.riseSetActivecontactId(this.props.searchResults[index].ContactId);
		this.props.updateLeadRoutesFromAdvancedSearchResults();
		this.props.storeLeadRoutesIndex(index);
		this.props.navigateTo(navRoutes.advancedSearch.path.replace(':householdId?', id).replace(':index?', index.toString()));
	};

	toggleDrawer = () => {
		this.setState({ isDrawerOpen: !this.state.isDrawerOpen }, this.toggleListener);
	};

	toggleListener = () => {
		this.state.isDrawerOpen
			? this.stopListenToKeyDown()
			: this.listenToKeyDown();
	};

	handleReassignToAgent = (agentCode: string) => {
		this.setState({ showReassignDialog: false });

		let bulkReassignRequest: BulkReassignRequestDto = {
			entities: [],
			criteria: this.props.isSelectAll === true
				? this.props.appliedSearchFilters
				: undefined,
			newAgentCode: agentCode,
		};

		if (this.props.isBLeadSearch) {
			this.props.searchResults.forEach((result: SearchResult) => {
				if (result.HouseholdId && result.IsSelected) {
					bulkReassignRequest.entities.push({
						id: result.HouseholdId,
						type: BULK_REASSIGN_BLEAD_TYPE,
					});
				}
			});

		} else {
			this.props.searchResults.forEach(result => {
				if (result.EmployerId
					&& result.EmployerId != EMPTY_GUID
					&& result.IsSelected
				) {
					bulkReassignRequest.entities.push({
						id: result.EmployerId,
						type: BULK_REASSIGN_EMPLOYER_TYPE,
					});
				} else if (result.HouseholdId && result.IsSelected) {
					bulkReassignRequest.entities.push({
						id: result.HouseholdId,
						type: BULK_REASSIGN_HOUSEHOLD_TYPE,
					});
				}
			});
		}

		this.props.bulkReassign(bulkReassignRequest);
	};

	getMatchProp = () => {
		if (this.match.params.employerID != this.state.employer_id
			|| this.match.params.householdID != this.state.household_id
		) {
			this.match = {
				params: {
					employerID: this.state.employer_id,
					householdID: this.state.household_id,
				},
			};
		}

		return this.match;
	};

	generateSelectedFiltersString = () => {
		const appliedFiltersList: AppliedFilters = [];
		const filterObj = Object.assign(this.props.appliedSearchFilters || {});

		for (const filter in filterObj) {
			if (filterObj[filter] && filterObj[filter] != []) {
				switch (filter) {
					case ASFilter.SearchPrimaryOnly:
						appliedFiltersList.push({ searchAcross: filterObj[filter] });
						break;
					case ASFilter.SearchNextDays:
						appliedFiltersList.push({ openToDo: filterObj[filter] })
						break;
					case ASFilter.SearchNextDaysFrom:
						if (moment(filterObj[filter]).year() === moment().year()) {
							appliedFiltersList.push({ openToDoFrom: moment(filterObj[filter]).format("MMM DD") })
						}
						else {
							appliedFiltersList.push({ openToDoFrom: moment(filterObj[filter]).format("MMM DD [']YY") })
						}
						break;
					case ASFilter.SearchNextDaysTo:
						if (moment(filterObj[filter]).year() === moment().year()) {
							appliedFiltersList.push({ openToDoTo: moment(filterObj[filter]).format("MMM DD") })
						}
						else {
							appliedFiltersList.push({ openToDoTo: moment(filterObj[filter]).format("MMM DD [']YY") })
						}
						break;
					case ASFilter.ShowPrimaryOnly:
						// skip since we're hiding this field
						break;
					case ASFilter.AgeMaxYear:
					case ASFilter.AgeMaxMonth:
						if (appliedFiltersList.some(filterItem => filterItem.hasOwnProperty(Strings.Filter.AgeMax))) {
							break;
						}
						const ageMaxMonth: number = filterObj[ASFilter.AgeMaxMonth] || 0;
						const ageMaxYear: number = filterObj[ASFilter.AgeMaxYear] || 0;
						const ageMaxDisplay = `${ageMaxYear}${Strings.ConsumerSearchFilters.Yr}${ageMaxMonth}${Strings.ConsumerSearchFilters.Mo}`;
						appliedFiltersList.push({ [Strings.Filter.AgeMax]: ageMaxDisplay });
						break;
					case ASFilter.AgeMinYear:
					case ASFilter.AgeMinMonth:
						if (appliedFiltersList.some(filterItem => filterItem.hasOwnProperty(Strings.Filter.AgeMin))) {
							break;
						}
						const ageMinMonth: number = filterObj[ASFilter.AgeMinMonth] || 0;
						const ageMinYear: number = filterObj[ASFilter.AgeMinYear] || 0;
						const ageMinDisplay = `${ageMinYear}${Strings.ConsumerSearchFilters.Yr}${ageMinMonth}${Strings.ConsumerSearchFilters.Mo}`;
						appliedFiltersList.push({ [Strings.Filter.AgeMin]: ageMinDisplay });
						break;
					case ASFilter.HouseholdIncomeMin:
					case ASFilter.HouseholdIncomeMax:
						if (appliedFiltersList.some(filter => filter.hasOwnProperty(Strings.Filter.Income))) {
							break;
						}
						const incomeMin: number = filterObj[ASFilter.HouseholdIncomeMin] || 0;
						const incomeMax: number = filterObj[ASFilter.HouseholdIncomeMax] || 0;
						appliedFiltersList.push({ [Strings.Filter.Income]: `${incomeMin}-${incomeMax}` });
						break;
					case ASFilter.ProcessId:
						break;
					default:
						appliedFiltersList.push({ [filter]: filterObj[filter] });
						break;
				}
			}
		}

		const filterString = normalizeFilterStringsArray(appliedFiltersList);

		return filterString.length !== 0 ? (
			<div style={{ cursor: CSS.Pointer }} onClick={this.handleClampLineClick}>
				<ClampLines
					ref={(ref: any) => (this.clampLinesRef = ref)}
					key={'filter-' + filterString.join('')}
					text={this.props.selectedSavedSearchName != ASFilter.Unselected
						? this.props.selectedSavedSearchName + " - " + filterString.join(', ')
						: filterString.join(', ')}
					lines={1}
					buttons={false}
				/>
			</div>
		) : (AS.NoFilters);
	};

	handleClampLineClick = (e: any) => {
		if (this.clampLinesRef != undefined) {
			this.clampLinesRef.clickHandler(e);
		}
	};

	displayResultCount = (): string => {
		return (this.props.isSelectAll
			? this.props.totalResultCount
			: this.props.numSelected
		) + ' of ' + this.props.totalResultCount + AS.ResultsSelected;
	};

	handleAllSelector = () => {
		if (this.props.isSelectAll) {
			this.props.setAdvSearchSelectAllFlag(false);
			this.props.selectAllAdvancedSearchResults(false);
			this.setState({ someSelected: false });

		} else if (!this.props.isSelectAll && !this.state.someSelected) {
			this.props.setAdvSearchSelectAllFlag(true);
			this.props.selectAllAdvancedSearchResults(true);

		} else if (!this.props.isSelectAll && this.state.someSelected) {
			this.props.setAdvSearchSelectAllFlag(false);
			this.props.selectAllAdvancedSearchResults(false);
			this.setState({ someSelected: false });
		}
	};

	searchFilter = () => {
		return (
			<Card elevation={0} square style={{ position: CSS.Sticky }}>
				<CardHeader
					avatar={<Avatar src={require('../../assets/filter_icon.png')} />}
					title={AS.SearchTitle}
					subheader={this.generateSelectedFiltersString()}
					action={
						<IconButton
							onClick={this.toggleDrawer}
						>
							<Icon>tune</Icon>
						</IconButton>
					}
				/>
				<CardContent style={{ paddingTop: 0 }}>
					<Grid container>
						<Grid item xs={4}>
							<Button
								variant='contained'
								color={Strings.Theming.Primary}
								size={Strings.Sizing.Small}
								disabled={this.props.totalResultCount === 0}
								onClick={this.handleAllSelector}
							>
								{this.props.isSelectAll || this.state.someSelected ? AS.Deselect : AS.Select}
							</Button>
						</Grid>
						<Grid item xs={8}>
							<div
								style={{
									textAlign: CSS.Right,
									fontSize: 12,
									color: themePalette.sub_text,
								}}
							>
								{this.displayResultCount()}
							</div>
						</Grid>
					</Grid>
				</CardContent>

				<Drawer
					anchor={CSS.Right}
					open={this.state.isDrawerOpen}
					onClose={this.toggleDrawer}
					PaperProps={{ style: { width: isDesktop() ? '500px' : '100%' } }}
				>
					<AdvancedSearchFilterComponent
						handleCancel={this.toggleDrawer}
						executeAdvancedSearch={this.executeInitialSearch}
						isDrawerOpen={this.state.isDrawerOpen}
						currentFormFilters={this.props.currentFormFilters}
					/>
				</Drawer>
			</Card>
		);
	};

	executeInitialSearch = (search: SearchFilterFormValues, isBLeadSearch: boolean) => {
		this.props.resetInfiniteScroll();
		this.props.setIsBLeadSearch(isBLeadSearch);
		this.props.executeAdvancedSearch(search, 0, this.props.pageSize, EMPTY_GUID);
	};

	loadResults = () => {
		if (this.props.hasMoreResults
			&& !this.props.resultsLoading
			&& this.props.searchResults.length < 10000
		) {
			this.props.executeAdvancedSearch(
				this.props.appliedSearchFilters,
				this.props.pageIndex,
				this.props.pageSize,
				this.props.processId
			);
		}
	};


	handleResultSelection = (event: React.MouseEvent<HTMLElement>, index: number) => {
		if ((event.shiftKey || event.ctrlKey) && this.selectedIndex != index && this.selectedIndex != -1) {
			this.shiftSelectedIndex = index;

			if (this.shiftSelectedIndex < this.selectedIndex) {
				const hold = this.selectedIndex;
				this.selectedIndex = this.shiftSelectedIndex;
				this.shiftSelectedIndex = hold;
			}

			for (let i = this.selectedIndex; i <= this.shiftSelectedIndex; i++) {
				this.props.selectAdvancedSearchResult(i, !event.ctrlKey);
			}
		} else {
			this.props.selectAdvancedSearchResult(index, !this.props.searchResults[index].IsSelected);
		}

		this.selectedIndex = index;
	};

	displayResults = () => {
		return (
			<InfiniteScroll
				key={'advanced_search_infinite_scroll'}
				initialLoad={false}
				loadMore={this.loadResults}
				hasMore={this.props.hasMoreResults && this.props.totalResultCount > 0}
				loader={
					<div key="loader" style={{ textAlign: CSS.Center }}>
						<CircularProgress />
					</div>
				}
				useWindow={false}
			>
				{this.props.searchResults.map((result, index) => {
					const highlight = index === this.state.searchIndex;
					return (
						<AdvancedSearchResultListItem
							appliedFilters={this.props.appliedSearchFilters}
							searchResult={result}
							onClickResult={this.clickSearchResult}
							onSelectResult={(event: React.MouseEvent<HTMLElement>) => this.handleResultSelection(event, index)}
							agentName={this.props.agentName}
							isSelectAllSet={this.props.isSelectAll}
							index={index}
							key={'AS_Result_List_' + index}
							highlight={highlight}
						/>
					);
				})}
			</InfiniteScroll>
		);
	};

	exportResults = (columnsToExport: string[]) => {
		let exportObject: AdvSearchResultExportObject = {
			contactIds: [],
			criteria: this.props.appliedSearchFilters,
			selectFields: columnsToExport,
		};

		if (!this.props.isSelectAll) {
			this.props.searchResults.forEach(result => {
				if (result.IsSelected) {
					exportObject.contactIds.push(result.ContactId);
				}
			});
		}

		this.props.exportAdvancedSearchResults(exportObject);

		this.setState({
			showExportDialog: false,
		});
	};

	showMergeDialog = () => {
		const selectedItems: SearchResult[] = this.props.searchResults.filter(x => x.IsSelected);
		const householdIdCount = new Set(selectedItems.map(item => item.HouseholdId)).size;
		const matchingHouseholdIdCount = selectedItems.filter(x => x.UserId == this.props.userId).length;

		if (householdIdCount != 2) {
			alert("Both of the selected contacts belong to the same household. Select contacts with different households to merge.")
		} else if (matchingHouseholdIdCount != 2) {
			alert("Cannot merge a household that does not belong to you")
		} else {
			this.props.queueDialog(makeDialogInstance(MergeHouseholdDialog));
		}
	}

	showClickToCallDialog = () => {
		this.props.queueDialog(makeDialogInstance(StartClickToCallDialog));
	}

	composeHeaderRightIcons = (): JSX.Element => {
		let isBLeadConsumerSelected: boolean = false;

		if (this.props.appliedSearchFilters
			&& this.props.appliedSearchFilters.consumerType
			&& this.props.appliedSearchFilters.consumerType === AS.BLeads
		) {
			isBLeadConsumerSelected = true;
		}
		let clickToCallButtonColor = this.props.numSelected === 0 || isBLeadConsumerSelected || this.props.clickToCallSession != undefined
			? themePalette.disabled_icon
			: themePalette.negative_icon;

		let mergeHouseholdButtonColor = this.props.numSelected != 2
			? themePalette.disabled_icon
			: themePalette.negative_icon;

		let exportButtonColor = this.props.numSelected === 0 || isBLeadConsumerSelected
			? themePalette.disabled_icon
			: themePalette.negative_icon;

		let reassignButtonColor = this.props.numSelected === 0
			? themePalette.disabled_icon
			: themePalette.negative_icon;

		let buttonColor = this.props.numSelected === 0 || isBLeadConsumerSelected
			? themePalette.disabled_icon
			: themePalette.negative_icon;

		return (
			<Grid container justify={CSS.FlexEnd} style={{ width: 'auto' }}>
				{jwt_auth.hasPermission(P.ClickToCall) && <Grid item>
					<Button
						style={{ color: clickToCallButtonColor }}
						disabled={this.props.numSelected == 0 || isBLeadConsumerSelected || this.props.clickToCallSession != undefined}
						onClick={this.showClickToCallDialog}
					>
						Click To Call
						<Icon style={{ marginLeft: 5 }}>{Strings.Icons.PhoneCall}</Icon>
					</Button>
				</Grid>}
				<Grid item>
					<Tooltip title="Only 2 households can be merged at a time">
						<span>
							<Button
								style={{ color: mergeHouseholdButtonColor }}
								disabled={this.props.numSelected != 2}
								onClick={this.showMergeDialog}

							>
								Merge
								<Icon style={{ marginLeft: 5 }}>{Strings.Icons.Merge}</Icon>
							</Button>
						</span>
					</Tooltip>

				</Grid>

				<Grid item>
					<Button
						style={{ color: exportButtonColor }}
						onClick={() =>
							this.setState({
								showExportDialog: true,
							})
						}
						disabled={this.props.numSelected === 0 || isBLeadConsumerSelected}
					>
						{AS.Export}
						<Icon style={{ marginLeft: 5 }}>{Strings.Icons.Unarchive}</Icon>
					</Button>
				</Grid>
				<Grid item>
					<Button
						style={{ color: reassignButtonColor }}
						disabled={this.props.numSelected === 0}
						onClick={() =>
							this.setState({
								showReassignDialog: true,
							})
						}
					>
						{AS.Reassign}
						<Icon style={{ marginLeft: 5 }}>{Strings.Icons.Contacts}</Icon>
					</Button>
				</Grid>
				{jwt_auth.hasPermission(P.BulkEmail) && (
					<Grid item>
						<Button
							style={{ color: buttonColor }}
							onClick={() => {
								this.props.storeSelectedContactIds();
								this.props.clearEmailTemplateFilter();
								this.props.navigateTo(navRoutes.templateSelect.path);
							}}
							disabled={this.props.numSelected === 0 || isBLeadConsumerSelected}
						>
							{AS.Email}
							<Icon style={{ marginLeft: 5 }}>{Strings.Icons.ContactMail}</Icon>
						</Button>
					</Grid>
				)}
			</Grid>
		);
	};

	render() {
		const match = this.getMatchProp();
		const { dialogIsShowing } = this.props;

		let rightPannel: JSX.Element = (
			<EmptyMessage text={AS.SelectPreview} />
		);
		if (this.state.mode == ASMode.Household) {
			rightPannel = (
				<HouseholdPageContainer
					location={location}
					match={match}
					navigateFromParent={this.props.navigateTo}
					parentHistory={this.props.history}
					isAdvancedSearch
				/>
			);
		} else if (this.state.mode == ASMode.Employer) {
			rightPannel = (
				<EmployerPageContainer
					location={location}
					match={match}
					navigateFromParent={this.props.navigateTo}
					parentHistory={this.props.history}
					employerId={this.state.employer_id}
					isAdvancedSearch
				/>
			);
		}

		const isLoading = this.props.resultsLoading || this.props.exportRequestLoading || this.props.mergeHouseholdLoading;
		return (
			<BasePageContainer
				blockDialogs={this.state.mode !== ASMode.None}
				topComponent={
					<HeaderBarComponent
						title={AS.Title}
						isLoading={isLoading}
						rightButtons={this.composeHeaderRightIcons()}
					/>
				}
			>
				<Grid container style={{ height: '100%', overflow: CSS.Hidden }}>
					<Grid item xs={6} style={{ height: '100%', padding: 10 }}>
						{this.searchFilter()}
						<div
							style={{
								height: '85%',
								overflow: dialogIsShowing && isIE
									? CSS.Hidden
									: this.props.searchResults.length >= 1
										? CSS.Scroll
										: CSS.Initial,
							}}
						>
							{this.displayResults()}
						</div>
					</Grid>
					<Grid item xs={6} style={{ padding: 10 }}>
						<div style={{ height: '100%' }}>
							{rightPannel}
						</div>
					</Grid>
				</Grid>
				<ReassignDialog
					visible={this.state.showReassignDialog}
					currentAgentCode={this.props.currentAgentCode}
					reassignToAgent={this.handleReassignToAgent}
					closeReassignDialog={() =>
						this.setState({ showReassignDialog: false })
					}
				/>
				<ExportResultsDialog
					visible={this.state.showExportDialog}
					exportResults={this.exportResults}
					closeExportDialog={() =>
						this.setState({ showExportDialog: false })
					}
					employerColumnNames={this.props.EmployerAdvSearchColumns}
					personColumnNames={this.props.PersonAdvSearchColumns}
					leadColumnNames={this.props.LeadAdvSearchColumns}
					productColumnNames={this.props.ProductAdvSearchColumns}
					agentColumnNames={this.props.AgentAdvSearchColumns}
					allColumns={this.props.EmployerAdvSearchColumns.concat(
						this.props.PersonAdvSearchColumns
					)
						.concat(this.props.LeadAdvSearchColumns)
						.concat(this.props.ProductAdvSearchColumns)
						.concat(this.props.AgentAdvSearchColumns)}
				/>
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	processId: state.advancedSearch.processId,
	location: state.navigation.locationHistory,
	searchResults: state.advancedSearch.results,
	currentFormFilters: state.advancedSearch.currentFormFilters,
	appliedSearchFilters: state.advancedSearch.appliedSearchFilters,
	numSelected: state.advancedSearch.numSelected,
	currentAgentCode: getCurrentAgentCode(state),
	hasMoreResults: state.infiniteScroll.loadMore,
	loadedResults: state.infiniteScroll.resultsReceived,
	pageSize: state.infiniteScroll.pageSize,
	pageIndex: state.infiniteScroll.pagesReceived,
	totalResultCount: state.advancedSearch.totalResultCount,
	resultsLoading: state.advancedSearch.resultsLoading,
	exportRequestLoading: state.advancedSearch.exportRequestLoading,
	isSelectAll: state.advancedSearch.isSelectAll,
	isBLeadSearch: state.advancedSearch.isBLeadSearch,
	selectedSavedSearchName: state.advancedSearch.selectedSavedSearchName,
	agentName: getCurrentAgentDisplayName(state),
	EmployerAdvSearchColumns: state.lookup.getLabels(Lookups.EmployerAdvSearchColumn),
	PersonAdvSearchColumns: state.lookup.getLabels(Lookups.PersonAdvSearchColumn),
	LeadAdvSearchColumns: state.lookup.getLabels(Lookups.LeadAdvSearchColumn),
	ProductAdvSearchColumns: state.lookup.getLabels(Lookups.ProductAdvSearchColumn),
	AgentAdvSearchColumns: state.lookup.getLabels(Lookups.AgentAdvSearchColumn),
	mergeHouseholdLoading: state.householdMetaData.householdMergeLoading,
	userId: state.agent.id,
	dialogIsShowing: Boolean(state.dialogs.length),
	clickToCallSession: state.clickToCall.session,
	clickToCallSessionHousehold: state.clickToCall.sessionHousehold,
});
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	navigateBack: () => dispatch(navigateBack()),
	selectAdvancedSearchResult: (index: number, selected: boolean) =>
		dispatch(SelectAdvancedSearchResult({ index, selected })),
	selectAllAdvancedSearchResults: (toggle: boolean) =>
		dispatch(selectAllAdvancedSearchResults(toggle)),
	storeSelectedContactIds: () => dispatch(storeSelectedContactIds()),
	bulkReassign: (bulkReassignRequestDto: BulkReassignRequestDto) => {
		dispatch(BulkReassign.started(bulkReassignRequestDto));
	},
	getAgentByAgentCode: () => dispatch(GetAgentFromJwt.started(undefined)),
	resetInfiniteScroll: () => dispatch(ResetInfiniteScroll()),
	executeAdvancedSearch: (
		searchFilterObject: SearchFilterFormValues,
		pageIndex: number,
		pageSize: number,
		processId?: string
	) =>
		dispatch(executeAdvancedSearch(searchFilterObject, pageIndex, pageSize, processId)),
	getSavedCorporateAdvSearchFilters: () =>
		dispatch(getSavedCorporateAdvSearchFilters.started(undefined)),
	getSavedUserAdvSearchFilters: () =>
		dispatch(getSavedUserAdvSearchFilters.started(undefined)),
	exportAdvancedSearchResults: (exportObject: AdvSearchResultExportObject) =>
		dispatch(exportAdvancedSearchResults.started(exportObject)),
	setAdvSearchSelectAllFlag: (value: boolean) =>
		dispatch(SetAdvSearchSelectAllFlag(value)),
	setIsBLeadSearch: (value: boolean) => dispatch(setIsBLeadSearch(value)),
	getAgencyChannels: () =>
		dispatch(GetAgencyChannels.started(undefined)),
	queueDialog: (dialog: DialogFrame) =>
		dispatch(QueueDialog(dialog)),
	storeLeadRoutesIndex: (index: number) => dispatch(StoreLeadRoutesIndex({
		index,
		origin: LeadRoutePages.AdvancedSearch,
	})),
	updateLeadRoutesFromAdvancedSearchResults: () => dispatch(UpdateLeadRoutesFromAdvancedSearch.started({})),
	clearEmailTemplateFilter: () => dispatch(clearTemplateFilter()),
	riseSetActivecontactId: (contactId: string) => dispatch(FormSlice.actions.SetActiveContactId({ contactId: contactId })),
});
export const AdvancedSearchPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(AdvancedSearchPage);
