import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from '@hmkts/rise';
import { change } from 'redux-form';
import { getFormValues } from 'redux-form';
import uuid from 'uuid';
import { CreateActivity, CreateEmployerActivity, EditActivity } from '../../actions/activity_actions';
import { ContactLiveSearch } from '../../actions/contact_actions';
import { GetEmployer, SearchEmployers } from '../../actions/employer_actions';
import { GetHousehold } from '../../actions/household_actions';
import { StoreInactiveLeadId } from '../../actions/lead_actions';
import {
	navigateBack,
	navigateTo,
	navigateToWithoutAddingToHistory,
	updateDispositionReturnPath,
} from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { SaveCancelHeaderBarComponent } from '../../components/Layout/SaveCancelHeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { Activity } from '../../reducers/activity_reducer';
import { AgentState } from '../../reducers/agent_reducer';
import { Contact, HouseholdRole } from '../../reducers/ContactReducer';
import { Employer } from '../../reducers/employer_reducer';
import { isNullOrUndefinedOrEmpty, Loaded } from '../../utilities/utilities';
import { UpsertActivityFormComponent } from './upsert_activity_form';
import { GetAgentFromJwt } from '../../actions/agent_actions';
import { composePhoneNumberFromContact } from '../../utilities/contact_util';
import { fullName } from '../../assets/common/string_builders';
import { Strings } from '../../assets/common/strings';
import { isIOS } from '../../utilities/is_mobile';

//reschedule 1: appointment
//reschedule 2: phonecall 3 months from now (no sale follow up)
//reschedule 3: phonecall
export enum FollowUpActivityType {
	APPOINTMENT = '1',
	PHONE_CALL_3_MONTHS = '2',
	PHONE_CALL = '3',
}

interface Props extends NavigationProps {
	activities: Loaded<Activity>[];
	contacts: Loaded<Contact>[];
	employers: Loaded<Employer>[];
	formValues: any;
	userId: string;
	impersonatingId: string;
	firstName: string;
	preferredName: string;
	lastName: string;
	agent: AgentState;
	inactiveLeadId: string;
	locationHistory: string[];
	followupDescription: string;
	followupTime: moment.Moment | undefined;
	followupTitle: string;
	dispositionReturnPath: string;
	createActivity: (activityToSend: Activity) => void;
	createEmployerActivity: (activityToSend: Activity) => void;
	editActivity: (activityToEdit: Activity) => void;
	searchContacts: (searchText: string) => void;
	searchEmployers: (searchText: string) => void;
	changeFieldValue: (form: string, field: string, value: any) => void;
	getAgentByAgentCode: () => void;
	getHousehold: (id: string) => void;
	getEmployer: (id: string) => void;
	storeInactiveLeadId: (leadId: string) => void;
}

interface State {
	isSaveDisabled: boolean;
	activityType: Strings.Activity;
	reschedule: boolean;
	followup: boolean;
	onInactiveLead: boolean;
}

class UpsertActivity extends React.Component<Props, State> {
	loadedActivity?: Loaded<Activity>;
	editGuid: string;
	timestamp: moment.Moment;

	constructor(props: any) {
		super(props);
		this.state = {
			isSaveDisabled: true,
			activityType: Strings.Activity.Task,
			reschedule: false,
			followup: false,
			onInactiveLead: false,
		};

		this.timestamp = moment();
	}

	getHouseholdId = () => {
		if (this.loadedActivity && this.loadedActivity.data?.contact) {
			return this.loadedActivity.data.contact.householdId;
		}
		const id = (this.props.match?.params?.householdID || '');
		return id != '0' && id.indexOf('householdID') == -1 ? id : null;
	}

	getEmployerId = () => {
		if (this.loadedActivity && this.loadedActivity.data?.contact) {
			return this.loadedActivity.data.contact.employerId;
		}
		const id = (this.props.match?.params?.employerID || '');
		return id != '0' && id.indexOf('employerID') == -1 ? id : null;
	}

	componentWillMount() {
		if (this.props.match.path.includes('/appointment/upsert')) {
			this.setState({
				activityType: Strings.Activity.Appointment,
			});
		} else if (
			this.props.match.path.includes('/phone/edit') ||
			this.props.match.path.includes('/phone/create')
		) {
			this.setState({
				activityType: Strings.Activity.PhoneCall,
			});
		} else {
			this.setState({
				activityType: Strings.Activity.Task,
			});
		}

		const rescheduleOrFollowup = Number(this.props.match.params.reschedule);
		if (rescheduleOrFollowup !== 0) {
			if (rescheduleOrFollowup === 1) {
				this.setState({
					reschedule: true,
				});
			} else if (rescheduleOrFollowup === 2 || rescheduleOrFollowup === 3) {
				this.setState({
					followup: true,
				});
				if (this.props.inactiveLeadId != '') {
					this.setState({
						onInactiveLead: true,
					});
				}
			}
		}

		if (!this.props.firstName) {
			this.props.getAgentByAgentCode();
		}

		const householdId = this.getHouseholdId();
		if (
			householdId &&
			this.props.contacts?.filter(contact => contact.householdId == householdId)
				.length == 0
		) {
			this.props.getHousehold(householdId);
		}

		const employerId = this.getEmployerId();
		if (
			employerId &&
			this.props.contacts?.filter(contact => contact.employerId == employerId)
				.length == 0
		) {
			this.props.getEmployer(employerId);
		}
	}

	public handleFormUpdate = (
		pristine: boolean,
		invalid: boolean,
		submitting: boolean,
		override: boolean
	) => {
		let isContactOrEmployerSelected = true;

		if (this.state.activityType == Strings.Activity.PhoneCall || this.state.activityType == Strings.Activity.Appointment) {
			isContactOrEmployerSelected = this.contactOREmployerSelected();
		}

		const updatedInvalid: boolean = invalid || !isContactOrEmployerSelected;

		this.setState({
			isSaveDisabled: (
				updatedInvalid ||
				submitting ||
				(override && (pristine || moment().diff(this.timestamp, 'seconds') < 1))
			),
		});
	};

	private contactOREmployerSelected = () => {

		const hasContact = !!this.props?.formValues['contactValue'];
		const hasEmployer = !!this.props?.formValues['employerValue'];

		return hasContact || hasEmployer;
	};

	navigateBackFromFollowup() {
		if (this.props.dispositionReturnPath) {
			this.props.navigateToWithoutAddingToHistory(this.props.dispositionReturnPath);
			this.props.updateDispositionReturnPath('');
		} else {
			this.props.navigateBack();
		}

	}

	onCancel = () => {
		this.props.storeInactiveLeadId('');
		if (this.state.followup || this.state.reschedule) {
			this.navigateBackFromFollowup();
		} else {
			this.props.navigateBack();
		}
	}

	onSave = () => {
		const userName = fullName(this.props);
		const employer: Employer | undefined = this.props.formValues.employerValue;

		const nowUTC = moment()
			.utc()
			.toDate();
		let employerContact =
			employer && employer.contacts
				? employer.contacts.filter(e => e.employerPrimaryContact)[0]
				: undefined;
		if (this.loadedActivity && !this.state.reschedule && !this.state.followup) {
			let activityToEdit: Activity = Object.assign(
				{},
				this.loadedActivity.data
			);
			activityToEdit.title = this.props.formValues?.activityTitle;
			activityToEdit.description = this.props.formValues?.activityDescription;
			let time = moment(this.props.formValues?.dueByTime)
			let date = moment(this.props.formValues?.dueByDate);
			if (!isIOS) {
				date.set('hours', time.hours());
				date.set('minutes', time.minutes());
			}
			activityToEdit.time = date.utc().toDate(),
				activityToEdit.duration = this.props.formValues?.duration;
			activityToEdit.isHighPriority =
				this.props.formValues?.isHighPriority || false;

			activityToEdit.modifiedOn = nowUTC;
			activityToEdit.modifiedBy = this.props.userId;
			activityToEdit.modifiedByName = userName;
			activityToEdit.updatedOn = nowUTC;
			activityToEdit.updatedBy = this.props.userId;

			if (this.loadedActivity.data?.contact && employerContact) {
				if (
					employer &&
					this.loadedActivity.data?.contact.employerId == employer.id
				) {
					if (
						employerContact &&
						this.loadedActivity.data?.contact.id != employerContact.id
					) {
						employerContact = this.loadedActivity.data?.contact;
					}
				}
			}
			if (this.state.activityType == Strings.Activity.PhoneCall) {
				activityToEdit.alternatePhoneNumber = this.props.formValues?.altPhone;
				activityToEdit.contact =
					this.props.formValues?.contactValue || employerContact;
			}
			if (this.state.activityType == Strings.Activity.Appointment) {
				activityToEdit.location = this.props.formValues?.location;
				activityToEdit.contact =
					this.props.formValues?.contactValue || employerContact;
			}
			this.props.editActivity(activityToEdit);
			this.props.storeInactiveLeadId('');
			this.props.navigateBack();
		} else {
			let newId = uuid.v4();
			let time = moment(this.props.formValues?.dueByTime)
			let date = moment(this.props.formValues?.dueByDate);
			if (!isIOS) {
				date.set('hours', time.hours());
				date.set('minutes', time.minutes());
			}
			let activityToSend: Activity = {
				id: newId,
				title: this.props.formValues?.activityTitle,
				description: this.props.formValues?.activityDescription,
				time: date.utc().toDate(),
				duration: this.props.formValues?.duration,
				isHighPriority: this.props.formValues?.isHighPriority || false,
				createdOn: nowUTC,
				createdBy: this.props.userId,
				createdByName: userName,
				modifiedOn: nowUTC,
				modifiedBy: this.props.userId,
				updatedOn: nowUTC,
				updatedBy: this.props.userId,
				location: '',
				alternatePhoneNumber: '',
				userId:
					this.props.userId != this.props.impersonatingId &&
						this.props.impersonatingId
						? this.props.impersonatingId
						: this.props.userId,
				status: Strings.ActivityStatus.Open,
				type: this.state.activityType,
				isDeleted: false,
				googleEventId: '',
				sender: '',
				toRecipients: '',
				appointmentType: '',
				contact: {} as Contact,
				twilioSid: '',
				clickToCallDisposition: '',
				clickToCallSessionId: undefined
			};

			if (this.state.activityType == Strings.Activity.PhoneCall) {
				activityToSend.alternatePhoneNumber = this.props.formValues?.altPhone;
				activityToSend.contact =
					this.props.formValues?.contactValue || employerContact;
			}
			if (this.state.activityType == Strings.Activity.Appointment) {
				activityToSend.location = this.props.formValues?.location;
				activityToSend.contact =
					this.props.formValues?.contactValue || employerContact;
			}
			if (this.state.onInactiveLead) {
				activityToSend.lead = this.props.inactiveLeadId;
			}

			if (employerContact) {
				this.props.createEmployerActivity(activityToSend);
			} else {
				this.props.createActivity(activityToSend);
			}

			this.props.storeInactiveLeadId('');

			if (this.state.followup || this.state.reschedule) {
				// Detail Flow:
				// Activity List -> Detail -> Disposition -> No Sale Reason -> Activity List
				// HH -> Detail -> Disposition -> No Sale Reason -> Household

				// Non-Detail Flow:
				// Activity List -> Disposition -> No Sale Reason -> Activity List
				// Person || HH -Action Icon-> Disposition -> No Sale Reason -> Person || HH
				this.navigateBackFromFollowup();
			} else {
				this.props.navigateBack();
			}
		}
	}

	navigateToPerson = (householdId: string, id: string) => {
		this.props.navigateToWithoutAddingToHistory(
			navRoutes.contactDetail.path
				.replace(Strings.Navigation.HouseholdId, householdId)
				.replace(Strings.Navigation.ContactId, id)
		);
	}

	navigateToEmployer = (employerId: string) => {
		this.props.navigateToWithoutAddingToHistory(
			navRoutes.employer.path.replace(Strings.Navigation.EmployerId, employerId)
		);
	}

	handleContactSearch = (searchText: string) => {
		this.props.searchContacts(searchText);
	};

	handleEmployerSearch = (searchText: string) => {
		this.props.searchEmployers(searchText);
	};

	createActivityTitle = (activity: string, description: string) => {
		if (activity == 'Phone Call') {
			return `${activity} ${description}`;
		} else {
			return `${description}`;

		}
	}

	public render() {
		const {
			agent
		} = this.props;

		let pageTitle: string = _.startCase(this.state.activityType);

		const businessAddresses = agent.addresses?.filter(address => address.addressType === Strings.AddressType.BusinessAddress);
		const storefrontAddresses = agent.storefrontAddresses?.filter(address => address.type === Strings.AddressType.Storefront);

		this.loadedActivity = this.props.activities.find(
			activity =>
				activity.data && activity.data.id == this.props.match?.params?.activity_id
		);

		let currentDateAndHour = moment().set({
			minute: 0,
			second: 0,
		});
		let selectedContact = this.loadedActivity
			? this.loadedActivity.data.contact
			: undefined;

		let initialValues: any = {
			dueByDate: currentDateAndHour
				.clone()
				.add(1, 'day')
				.toDate(),
			dueByTime: currentDateAndHour
				.clone()
				.add(1, 'day')
				.toDate(),
			duration: '30 minutes',
			activityTitle: this.createActivityTitle(this.state.activityType, this.props.followupTitle)
		};

		const householdId = this.getHouseholdId();
		let householdContacts: Loaded<Contact>[];
		if (
			this.props.match.params.householdID == Strings.Navigation.HouseholdId ||
			householdId == null
		) {
			householdContacts = [];
		} else {
			householdContacts = householdId
				? this.props.contacts.filter(c => {
					return c.householdId === householdId;
				})
				: [];
		}

		if (this.loadedActivity) {
			const activity = this.loadedActivity.data;
			initialValues.activityTitle = activity?.title;
			initialValues.activityDescription = activity?.description;
			initialValues.duration = activity?.duration;
			initialValues.isHighPriority = activity?.isHighPriority;
			if (!this.state.reschedule) {
				initialValues.dueByDate = moment
					.utc(activity?.time)
					.local()
					.toDate();
				initialValues.dueByTime = moment
					.utc(activity?.time)
					.local()
					.toDate();
			} else {
				initialValues.duration = '30 minutes'; // since auto created & dispo'd activities have null duration
			}

			if (
				this.state.activityType == Strings.Activity.Appointment ||
				this.state.activityType == Strings.Activity.PhoneCall
			) {
				initialValues.contact =
					activity?.contact?.firstName +
					' ' +
					activity?.contact?.lastName;
				initialValues.contactValue = activity?.contact;
			}
			if (this.state.activityType == Strings.Activity.Appointment) {
				initialValues.location = activity?.location;
				if (this.state.reschedule) {
					initialValues.activityTitle =
						'Appointment with ' + initialValues.contact;
				}
			}
			if (this.state.activityType == Strings.Activity.PhoneCall) {
				initialValues.altPhone = activity?.alternatePhoneNumber;
			}
		} else {
			// No loaded activity but try to have sensible defaults
			const primary = householdContacts.find(c => c.data?.householdRole === HouseholdRole.Primary);
			initialValues.contact = primary ? `${primary.data.preferredName || primary.data.firstName} ${primary.data.lastName}` : '';
			if (this.state.activityType === Strings.Activity.Appointment) {
				initialValues.activityTitle = `Appointment${initialValues.contact ? ` with ${initialValues.contact}` : ''}`;
			} else if (this.state.activityType === Strings.Activity.PhoneCall) {
				initialValues.activityTitle = `Phone Call${initialValues.contact ? ` with ${initialValues.contact}` : ''}`;
			}
		}

		if (this.state.followup) {
			const id = (this.props.match.params.householdID || '');
			let contactName = "";
			let phoneNumber = "";
			if (id) {
				let contact = selectedContact || this.props.contacts
					.find(c => c.employerId == id || c.householdId == id)?.data;
				if (contact) {
					contactName = contact.firstName + " " + contact.lastName;
					phoneNumber = composePhoneNumberFromContact(contact);
				}
			}

			pageTitle = 'Schedule Follow-up Call';
			initialValues.activityTitle = contactName && phoneNumber
				? 'Follow-up with ' + contactName + ". " + phoneNumber
				: 'Follow-up';

			var followupDate: moment.Moment;
			if (Number(this.props.match.params.reschedule) == 2) {
				currentDateAndHour.startOf('isoWeek').add(1, 'week');
				followupDate = currentDateAndHour.add(11, 'week');
				initialValues.dueByDate = followupDate.toDate();
			}
			else {
				followupDate = currentDateAndHour.add(1, 'day');

				initialValues.dueByDate = followupDate.toDate();
				initialValues.dueByTime = followupDate.toDate();

			}

			initialValues.dueByDate = followupDate.toDate();

			initialValues.duration = Strings.FollowUpDuration.Fifteen;

			let activity = this.loadedActivity
				? (this.loadedActivity.data as Activity)
				: undefined;

			if (Number(this.props.match.params.reschedule) == 3) {
				initialValues.duration = '30 minutes';
			} else {
				if (activity && activity.time) {
					const followupTime = moment.utc(activity.time).local();
					followupDate.hour(followupTime.get('hour'));
					followupDate.minute(followupTime.get('minute'));
					initialValues.dueByTime = followupDate.toDate();
				} else {
					let currentDateAndHour = moment().set({
						minute: 0,
						second: 0,
					});
					followupDate.hour(currentDateAndHour.get('hour'));
					followupDate.minute(currentDateAndHour.get('minute'));
					initialValues.dueByTime = followupDate.toDate();
				}
			}

			if (this.props.followupDescription && this.props.followupTime && this.state.activityType == Strings.Activity.PhoneCall) {
				const activityDateString = this.props.followupTime.isValid()
					? this.props.followupTime.format('LT') +
					', ' +
					this.props.followupTime.format('M/D/YYYY')
					: '';
				initialValues.activityDescription =
					this.props.followupDescription + ' - ' + activityDateString;
				if (phoneNumber) {
					initialValues.activityDescription = initialValues.activityDescription + ' - ' + phoneNumber;
				}
			}
		}

		if (this.state.reschedule) {
			if (this.props.followupDescription && this.props.followupTime && this.state.activityType == Strings.Activity.Appointment) {
				const activityDateString = this.props.followupTime.isValid()
					? this.props.followupTime.format('LT') +
					', ' +
					this.props.followupTime.format('M/D/YYYY')
					: '';
				initialValues.activityDescription =
					this.props.followupDescription + ' - ' + activityDateString;
			}
		}

		if (this.state.followup) {
			const contacts: Loaded<Contact>[] = householdContacts.filter(
				(c: Loaded<Contact>) => {
					return c.data.householdRole === HouseholdRole.Primary;
				}
			);

			if (contacts && contacts.length > 0) {
				selectedContact = contacts[0].data;
			}
		}

		const employerId = this.getEmployerId();
		let selectedEmployer: Employer | undefined = undefined;
		if (selectedContact || employerId) {
			if (
				(selectedContact &&
					!isNullOrUndefinedOrEmpty(selectedContact.employerId)) ||
				employerId
			) {
				const matchedEmployers = this.props.employers.filter(
					emp =>
						(selectedContact &&
							emp.data.id == selectedContact.employerId) ||
						emp.data.id == employerId
				);
				if (matchedEmployers.length > 0) {
					selectedEmployer = matchedEmployers[0].data;
				}
			}
		}

		let presetEmployers: Loaded<Employer>[];
		if (this.props.match.params.employerId == Strings.Navigation.EmployerId) {
			presetEmployers = [];
		} else {
			presetEmployers = employerId
				? this.props.employers.filter(e => {
					return e.data.id === employerId;
				})
				: [];
		}

		let firstName: string = '';
		let lastName: string = '';

		if (this.props.agent?.firstName) {
			firstName = this.props.agent.firstName;
		}
		if (this.props.agent?.lastName) {
			lastName = this.props.agent.lastName;
		}

		return (
			<BasePageContainer
				topComponent={
					<SaveCancelHeaderBarComponent
						isSaveDisabled={this.state.isSaveDisabled}
						onSave={this.onSave}
						onCancel={this.onCancel}
						title={pageTitle}
						saveText="Save"
					/>
				}
				middleComponent={
					<UpsertActivityFormComponent
						formValues={this.props.formValues}
						initialValues={initialValues}
						onUpdate={this.handleFormUpdate}
						type={this.state.activityType}
						contacts={this.props.contacts}
						businessAddresses={businessAddresses}
						storefrontAddresses={storefrontAddresses}
						formName="UpsertActivityForm"
						changeFieldValue={this.props.changeFieldValue}
						preselectedContact={selectedContact}
						agentFirstName={firstName}
						agentLastName={lastName}
						navigateToPerson={this.navigateToPerson}
						presetContacts={householdContacts}
						employers={this.props.employers}
						preselectedEmployer={selectedEmployer}
						presetEmployers={presetEmployers}
						navigateToEmployer={this.navigateToEmployer}
						followup={this.state.followup}
						handleContactUpdate={this.handleContactSearch}
						handleEmployerUpdate={this.handleEmployerSearch}
					/>
				}
			/>
		);
	}
}

function mapStateToProps(state): Partial<Props> {

	return {
		activities: state.activity.activities,
		formValues: getFormValues('UpsertActivityForm')(state),
		userId: state.user.id,
		impersonatingId: state.user.impersonatingId,
		firstName: state.agent.firstName,
		preferredName: state.agent.preferredName,
		lastName: state.agent.lastName,
		contacts: state.contact.contacts,
		agent: state.agent,
		locationHistory: state.navigation.locationHistory,
		employers: state.employer.employers,
		inactiveLeadId: state.lead.inactiveLeadIdForActivity,
		followupDescription: state.activity.followupDescription,
		followupTime: state.activity.followupTime,
		followupTitle: state.activity.followupTitle,
		dispositionReturnPath: state.navigation.dispositionReturnPath
	};
}

function mapDispatchToProps(dispatch: any): Partial<Props> {
	return {
		createActivity: (activityToSend: Activity) =>
			dispatch(CreateActivity.started(activityToSend)),
		navigateBack: () => dispatch(navigateBack()),
		navigateTo: (route: string) =>
			dispatch(navigateTo(route)),
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),
		updateDispositionReturnPath: (returnPath: string) =>
			dispatch(updateDispositionReturnPath(returnPath)),
		editActivity: (activityToEdit: Activity) =>
			dispatch(EditActivity.started(activityToEdit)),
		searchContacts: (searchText: string) =>
			dispatch(
				ContactLiveSearch.started({
					searchText,
					includeEmployees: true,
					pageRequested: 0,
					pageSize: 20,
				})
			),
		getAgentByAgentCode: () => dispatch(GetAgentFromJwt.started(undefined)),
		changeFieldValue: (form: string, field: string, value: any) => {
			dispatch(change(form, field, value));
		},
		searchEmployers: (searchText: string) =>
			dispatch(SearchEmployers.started(searchText)),
		createEmployerActivity: (activityToSend: Activity) =>
			dispatch(CreateEmployerActivity.started(activityToSend)),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		getEmployer: (id: string) => dispatch(GetEmployer.started(id)),
		storeInactiveLeadId: (leadId: string) => {
			dispatch(StoreInactiveLeadId(leadId));
		}
	};
}

export const UpsertActivityContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(UpsertActivity);

